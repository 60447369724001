import { useCallback } from "react"
import UserAgentParser from "ua-parser-js"

import { sendLog } from "../../api"
import { getQuestionData } from "../../store/survey/selectors"
import { getConfig } from "../../config"

import { useSelector } from "../redux"
import { useUserId, useQuizHistory } from "../useQuizHistory"

const getAmplitude = () =>
  window?.amplitude ?? {
    getInstance: () => ({
      logEvent: () => undefined,
      getUserId: () => undefined,
      setUserProperties: () => undefined,
      setUserId: () => undefined,
    }),
  }

export const track = (event: string, params?: Record<string, string | string[] | number>) => {
  getAmplitude().getInstance().logEvent(event, params)
  sendLog(event, params)
}

export const captureException = (message: string) => {
  track("exception", { message })
}

export const captureError = (message: string) => {
  track("error", { message })
}

/* Copy in packages/paywall-react/src/utils/analytics/useAmplitude.ts */
const makeParams = (
  initObj: Record<string, string | string[]> = {},
  input: Record<string, { values: string[] }>
): Record<string, string[] | string> => {
  const result = initObj
  for (const i in input) {
    // eslint-disable-next-line no-prototype-builtins
    if (input.hasOwnProperty(i)) {
      const values = input[i]?.values
      if (values) {
        result[i] = values
      }
    }
  }
  return result
}

export const getAmplitudeUserId = () => {
  const instance = getAmplitude().getInstance() ?? {}
  if (instance.getUserId) {
    return instance.getUserId()
  }
  return undefined
}

export const setAmplitudeUserId = (internalUserId: UserId) => {
  const instance = getAmplitude().getInstance()
  instance.setUserProperties({ internal_user_id: internalUserId })
  instance.setUserId(internalUserId)
}

export const useAmplitude = () => {
  const { internalUserId } = useUserId()
  const {
    params: { question: question_id, quiz: quiz_id },
  } = useQuizHistory()
  const questionData = useSelector(
    getQuestionData(quiz_id!, question_id!),
    (a, b) => a?.parameters?.id === b?.parameters?.id
  )

  const logInit = useCallback(() => {
    const userAgent = new UserAgentParser(window.navigator.userAgent).getResult()
    getAmplitude().getInstance().setUserProperties({
      environment: getConfig().environment,
      userAgent,
    })
  }, [internalUserId])

  const logQuizView = useCallback(() => {
    const params = makeParams(
      { quiz_id: String(quiz_id), question_id: String(question_id) },
      questionData?.parameters?.analytics_event_parameters?.parameters ?? {}
    )

    track("quiz_view", params)
  }, [questionData, quiz_id, question_id])

  const logNextQuiz = useCallback(
    (answer: string[] = []) => {
      const questionParameters = questionData?.parameters
      const defaultParams = {
        quiz_id: String(quiz_id),
        question_id: String(question_id),
        answer,
        answers_count: String(answer.length),
      }
      if (questionParameters?.hide_answers) {
        defaultParams.answer = "__HIDDEN__" as unknown as string[]
      }

      const params = makeParams(
        defaultParams,
        questionParameters!.analytics_event_parameters?.parameters ?? {}
      )

      track("quiz_next_press", params)
    },
    [quiz_id, question_id, questionData]
  )

  const logPrevQuiz = useCallback(() => {
    const params = makeParams(
      { quiz_id: String(quiz_id), question_id: String(question_id) },
      questionData?.parameters?.analytics_event_parameters?.parameters ?? {}
    )

    track("quiz_back_press", params)
  }, [quiz_id, question_id, questionData])

  const logInterviewQuizStarted = useCallback(() => {
    track("interview_quiz_started")
  }, [])

  const logInterviewQuizCompleted = useCallback(() => {
    track("interview_quiz_completed")
  }, [])

  const logLogin = useCallback((authUserId: UserId) => {
    // don't change user_id!!!
    // see https://paltaskincare.atlassian.net/browse/GEN-2991 and related issues
    // getAmplitude().getInstance().setUserId(authUserId)
    const provider = authUserId.split("|")[0] ?? ""
    track("login", { provider })
  }, [])

  const logCompleteAction = useCallback((actions: string[]) => {
    track("complete_action", { actions })
  }, [])

  const validateEmail = useCallback((status: string) => {
    track("validate_email", { status })
  }, [])

  const logSignUp = useCallback(() => {
    track("sign_up", {}) // GEN-5232 For pinterest export
  }, [])

  const logPageView = useCallback((url: string, action: string) => {
    track("page_view", { url, action })
  }, [])

  const logErrorView = useCallback(() => {
    track("error_view")
  }, [])

  const logNextTime = useCallback((elapsedTime: number) => {
    track("next_timing", { elapsedTime })
  }, [])

  const logProductFitResult = useCallback(
    (productFitList: string[], productNotFitList: string[]) => {
      track("product_fit_result", { productFitList, productNotFitList })
    },
    []
  )

  const logFaceScanAnalysisTime = useCallback((times: Record<string, number>) => {
    track("face_scan_analysis_time", times)
  }, [])

  const logFaceScanError = useCallback(() => {
    track("face_scan_analysis_error")
  }, [])

  const logFaceScanStatus = useCallback((status: string) => {
    track("face_scan_change_status", { status })
  }, [])

  const logFaceScanPushModalView = useCallback(() => {
    track("face_scan_push_modal_view")
  }, [])

  return {
    logInit,
    logQuizView,
    logNextQuiz,
    logPrevQuiz,
    logInterviewQuizStarted,
    logInterviewQuizCompleted,
    logLogin,
    logCompleteAction,
    validateEmail,
    logSignUp,
    logErrorView,
    logPageView,
    logProductFitResult,
    logNextTime,
    logFaceScanAnalysisTime,
    logFaceScanError,
    logFaceScanStatus,
    logFaceScanPushModalView,
  }
}
