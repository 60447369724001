import { extendTheme } from "@chakra-ui/react"
/* FIXME */
import { getComponents } from "~/theme/components"
import { getTextStyles } from "./textStyles"
import { getGlobalStyles } from "./globalStyles"
import { getColors } from "./colors"
import { shadows } from "./shadows"
import { getFonts } from "./fonts"

const breakpoints = {
  sm: "320px",
  md: "375px",
  lg: "450px",
  xl: "900px",
  "2xl": "1200px",
}
const fontSizes = {
  icon: "20px",
}
const radii = {
  brandSmall: "16px",
  norm: "20px",
  "brand-lg": "28px",
  brand5: "5px",
  brand8: "8px",
  brand16: "16px",
  brand24: "24px",
  huge: "50px",
}

const globalStyles = (_theme: Theme) => {
  const globalStyles = getGlobalStyles()
  const additional = {
    html: {
      ...globalStyles["html"],
      overflowX: "hidden",
    },
    "#root, .App": {
      ...globalStyles["#root, .App"],
      overflowX: "hidden",
    },
  }
  return { ...globalStyles, ...additional }
}

export const getTheme = (theme: Theme) =>
  extendTheme({
    breakpoints,
    colors: getColors(theme),
    fonts: getFonts(theme),
    textStyles: getTextStyles(theme),
    fontSizes,
    radii,
    shadows,
    styles: {
      global: globalStyles(theme),
    },
    components: getComponents(theme),
  })
