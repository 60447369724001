/* eslint-disable */
import { ActionOpenMyProducts, ActionOpenProductScanner, ActionSequence, ActionType } from "./actions";
import { Request } from "./networking";
import {
  AttributedString,
  Color,
  FilledButton,
  Image,
  ImageContentMode,
  imageContentModeFromJSON,
  imageContentModeToJSON,
  SnackBar,
  Tag,
} from "./presentation";
import { SummaryListItem } from "./skin_id";

export const protobufPackage = "products";

export enum ProductQualification {
  /** PRODUCT_QUALIFICATION_NONE - So that every meaningful status is not zero, hence exported. */
  PRODUCT_QUALIFICATION_NONE = "PRODUCT_QUALIFICATION_NONE",
  EXCELLENT = "EXCELLENT",
  GOOD = "GOOD",
  QUESTIONABLE = "QUESTIONABLE",
  BAD = "BAD",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function productQualificationFromJSON(object: any): ProductQualification {
  switch (object) {
    case 0:
    case "PRODUCT_QUALIFICATION_NONE":
      return ProductQualification.PRODUCT_QUALIFICATION_NONE;
    case 1:
    case "EXCELLENT":
      return ProductQualification.EXCELLENT;
    case 2:
    case "GOOD":
      return ProductQualification.GOOD;
    case 3:
    case "QUESTIONABLE":
      return ProductQualification.QUESTIONABLE;
    case 4:
    case "BAD":
      return ProductQualification.BAD;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProductQualification.UNRECOGNIZED;
  }
}

export function productQualificationToJSON(object: ProductQualification): string {
  switch (object) {
    case ProductQualification.PRODUCT_QUALIFICATION_NONE:
      return "PRODUCT_QUALIFICATION_NONE";
    case ProductQualification.EXCELLENT:
      return "EXCELLENT";
    case ProductQualification.GOOD:
      return "GOOD";
    case ProductQualification.QUESTIONABLE:
      return "QUESTIONABLE";
    case ProductQualification.BAD:
      return "BAD";
    case ProductQualification.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ProductKeyFunctionType {
  PRODUCT_KEY_FUNCTION_TYPE_NONE = "PRODUCT_KEY_FUNCTION_TYPE_NONE",
  PRODUCT_KEY_FUNCTION_TYPE_BE_AWARE = "PRODUCT_KEY_FUNCTION_TYPE_BE_AWARE",
  PRODUCT_KEY_FUNCTION_TYPE_GOOD_FOR = "PRODUCT_KEY_FUNCTION_TYPE_GOOD_FOR",
  PRODUCT_KEY_FUNCTION_TYPE_DOES_NOT_AFFECT = "PRODUCT_KEY_FUNCTION_TYPE_DOES_NOT_AFFECT",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function productKeyFunctionTypeFromJSON(object: any): ProductKeyFunctionType {
  switch (object) {
    case 0:
    case "PRODUCT_KEY_FUNCTION_TYPE_NONE":
      return ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_NONE;
    case 1:
    case "PRODUCT_KEY_FUNCTION_TYPE_BE_AWARE":
      return ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_BE_AWARE;
    case 2:
    case "PRODUCT_KEY_FUNCTION_TYPE_GOOD_FOR":
      return ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_GOOD_FOR;
    case 3:
    case "PRODUCT_KEY_FUNCTION_TYPE_DOES_NOT_AFFECT":
      return ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_DOES_NOT_AFFECT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProductKeyFunctionType.UNRECOGNIZED;
  }
}

export function productKeyFunctionTypeToJSON(object: ProductKeyFunctionType): string {
  switch (object) {
    case ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_NONE:
      return "PRODUCT_KEY_FUNCTION_TYPE_NONE";
    case ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_BE_AWARE:
      return "PRODUCT_KEY_FUNCTION_TYPE_BE_AWARE";
    case ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_GOOD_FOR:
      return "PRODUCT_KEY_FUNCTION_TYPE_GOOD_FOR";
    case ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_DOES_NOT_AFFECT:
      return "PRODUCT_KEY_FUNCTION_TYPE_DOES_NOT_AFFECT";
    case ProductKeyFunctionType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ProductPoraScore {
  /** @deprecated */
  scale:
    | ProductPoraScore_Scale
    | undefined;
  /** 0 - 100 */
  value: number;
  colors: ProductPoraScore_Colors | undefined;
  title: string;
}

export interface ProductPoraScore_Colors {
  background: Color | undefined;
  foreground: Color | undefined;
}

/** @deprecated */
export interface ProductPoraScore_Scale {
  items: ProductPoraScore_Scale_Item[];
}

/** @deprecated */
export interface ProductPoraScore_Scale_Item {
  /** 0 - 100 */
  from_value: number;
  /** 0 - 100 */
  to_value: number;
}

export interface MyProductsScreenRequest {
  request: Request | undefined;
}

export interface MyProductsScreenResponse {
  /** TODO: Remove this field when the My Products screen is supported as a standalone entity */
  my_products: ActionOpenMyProducts | undefined;
}

export interface OpenProductScannerRequest {
  request: Request | undefined;
}

export interface OpenProductScannerResponse {
  product_scanner: ActionOpenProductScanner | undefined;
}

export interface OpenFaceScannerRequest {
  request: Request | undefined;
}

export interface OpenFaceScannerResponse {
  face_scanner: ActionSequence | undefined;
}

export interface ProductsRecognitionRequest {
  request: Request | undefined;
  image: Image | undefined;
  forced_ingredients: string;
  /** Unique ID of the user photo sent by client */
  user_photo_id: string;
  ocr_result: ProductsRecognitionRequest_OCR | undefined;
  forced_product_text: string;
  search_variants_count: number;
  forced_product_url: string;
  forced_product_brand: string;
  forced_product_name: string;
  forced_product_image: string;
  forced_product_step: string;
  forced_product_description: string;
  forced_product_price: string;
  forced_product_rating: string;
  forced_product_reviews_count: string;
  skip_alternatives: boolean;
  evaluations_request_id: string;
}

export interface ProductsRecognitionRequest_OCR {
  lines: ProductsRecognitionRequest_OCR_Line[];
  recognition_duration: number;
  barcode: ProductsRecognitionRequest_OCR_Barcode | undefined;
}

export interface ProductsRecognitionRequest_OCR_Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface ProductsRecognitionRequest_OCR_Line {
  text: string;
  confidence: number;
  bounding_box: ProductsRecognitionRequest_OCR_Rect | undefined;
  cluster_id: number;
}

export interface ProductsRecognitionRequest_OCR_Barcode {
  code: string;
  symbology: string;
  recognition_duration: number;
}

export interface ProductsRecognitionRequests {
  requests: ProductsRecognitionRequest[];
}

export interface ProductRecognitionError {
  error_title: string;
  error_text: string;
  user_action: ProductRecognitionError_UserAction;
  next_step_request: ProductRecognitionErrorNextStepRequest | undefined;
}

export enum ProductRecognitionError_UserAction {
  USER_ACTION_NOTHING = "USER_ACTION_NOTHING",
  USER_ACTION_RETRY = "USER_ACTION_RETRY",
  USER_ACTION_NEW_PHOTO = "USER_ACTION_NEW_PHOTO",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function productRecognitionError_UserActionFromJSON(object: any): ProductRecognitionError_UserAction {
  switch (object) {
    case 0:
    case "USER_ACTION_NOTHING":
      return ProductRecognitionError_UserAction.USER_ACTION_NOTHING;
    case 1:
    case "USER_ACTION_RETRY":
      return ProductRecognitionError_UserAction.USER_ACTION_RETRY;
    case 2:
    case "USER_ACTION_NEW_PHOTO":
      return ProductRecognitionError_UserAction.USER_ACTION_NEW_PHOTO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProductRecognitionError_UserAction.UNRECOGNIZED;
  }
}

export function productRecognitionError_UserActionToJSON(object: ProductRecognitionError_UserAction): string {
  switch (object) {
    case ProductRecognitionError_UserAction.USER_ACTION_NOTHING:
      return "USER_ACTION_NOTHING";
    case ProductRecognitionError_UserAction.USER_ACTION_RETRY:
      return "USER_ACTION_RETRY";
    case ProductRecognitionError_UserAction.USER_ACTION_NEW_PHOTO:
      return "USER_ACTION_NEW_PHOTO";
    case ProductRecognitionError_UserAction.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ProductRecognitionErrorNextStepRequest {
  request: Request | undefined;
  product_scan_id: string;
}

export interface ProductRecognitionErrorNextStepResponse {
  action: ActionType | undefined;
}

export interface ProductRecognitionDetectedObject {
  name: string;
  score: number;
}

export interface ProductsRecognitionResponse {
  response?: { $case: "product_screen"; product_screen: ProductScreen } | {
    $case: "error";
    error: ProductRecognitionError;
  };
  detected_objects: ProductRecognitionDetectedObject[];
  search_variants: ProductsRecognitionResponse_SearchVariants | undefined;
  request_id: string;
}

export interface ProductsRecognitionResponse_SearchVariants {
  navigation_title: string;
  items_title: string;
  items: ProductCard[];
  top_button: ProductsRecognitionResponse_SearchVariants_Button | undefined;
}

export interface ProductsRecognitionResponse_SearchVariants_Button {
  title: string;
  action: ActionType | undefined;
}

export interface ProgramSummaryContent {
  title: string;
  subtitle: string;
  scan_id: string;
  list: SummaryListItem[];
  bubble_text: AttributedString | undefined;
  is_expanded: boolean;
}

export interface ProductCard {
  qualification: ProductQualification;
  qualification_title: string;
  title: string;
  brand: string;
  image: string;
  rating: string;
  /**
   * use tags_v2 instead
   *
   * @deprecated
   */
  tags: string[];
  origin_link: string;
  product_id: string;
  product_screen: ProductScreen | undefined;
  pora_score: ProductPoraScore | undefined;
  tags_v2: Tag[];
}

export interface ProductsSection {
  title: string;
  qualification_title: string;
  step_title: string;
  products: ProductCard[];
  info_bubble: ProductsSection_Bubble | undefined;
}

export interface ProductsSection_Bubble {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  buttons: ProductsSection_Bubble_Button[];
}

export interface ProductsSection_Bubble_Button {
  title: AttributedString | undefined;
  action: ActionType | undefined;
}

export interface ProductsSectionsGroup {
  title: string;
  url_title: string;
  url: string;
  /**
   * use sections_v2 instead
   *
   * @deprecated
   */
  sections: ProductsSection[];
  sections_v2: ProductsSectionsGroup_Section[];
}

export interface ProductsSectionsGroup_Section {
  section?: { $case: "products"; products: ProductsSection } | {
    $case: "alternatives";
    alternatives: ProductsSectionsGroup_Alternatives;
  } | { $case: "info"; info: ProductsSectionsGroup_Info };
}

export interface ProductsSectionsGroup_Alternatives {
  id: string;
  title: AttributedString | undefined;
  products: ProductCard[];
  should_show_section: boolean;
}

export interface ProductsSectionsGroup_Info {
  title: AttributedString | undefined;
  bubble_text: AttributedString | undefined;
}

export interface ProductsListScreen {
  summary:
    | ProgramSummaryContent
    | undefined;
  /**
   * use Segment's section_groups instead
   *
   * @deprecated
   */
  section_groups: ProductsSectionsGroup[];
  title: string;
  segments: ProductsListScreen_Segment[];
  selected_segment_index: number;
  bottom_button_title: string;
  is_close_button_available: boolean;
}

export interface ProductsListScreen_Segment {
  title: AttributedString | undefined;
  image: string;
  section_groups: ProductsSectionsGroup[];
}

export interface ProductsRecommendationsScreen {
  section_groups: ProductsSectionsGroup[];
}

export interface ProductKeyFunctionInfoScreen {
  function_type: ProductKeyFunctionType;
  title: AttributedString | undefined;
  subtitle: string;
  description: string;
}

export interface ProductIngredientsListInfoScreen {
  screen_title: string;
  product_title: string;
  product_image: string;
  sections: ProductIngredientsListInfoScreen_Section[];
}

export interface ProductIngredientsListInfoScreen_Section {
  content: ProductIngredientsListInfoScreen_Section_Content | undefined;
}

export interface ProductIngredientsListInfoScreen_Section_Content {
  content?: { $case: "ingredients"; ingredients: ProductIngredientsListInfoScreen_Section_Content_Ingredients } | {
    $case: "info";
    info: ProductIngredientsListInfoScreen_Section_Content_Info;
  };
}

export interface ProductIngredientsListInfoScreen_Section_Content_Info {
  title: string;
  subtitle: string;
  description: AttributedString | undefined;
}

export interface ProductIngredientsListInfoScreen_Section_Content_Ingredients {
  ingredients: ProductIngredientsListInfoScreen_Section_Content_Ingredient[];
}

export interface ProductIngredientsListInfoScreen_Section_Content_Ingredient {
  title: string;
  info_screen: ProductIngredientInfoScreen | undefined;
  risk_title: string;
  risk_color: Color | undefined;
}

export interface ProductIngredientInfoScreen {
  title: string;
  /** @deprecated */
  subtitle: string;
  /** @deprecated */
  description_title: string;
  /** @deprecated */
  description: string;
  sections: ProductIngredientInfoScreen_Section[];
}

export interface ProductIngredientInfoScreen_Section {
  section?:
    | { $case: "header_tags"; header_tags: ProductIngredientInfoScreen_Section_HeaderTags }
    | { $case: "health_risks"; health_risks: ProductIngredientInfoScreen_Section_HealthRisks }
    | { $case: "details"; details: ProductIngredientInfoScreen_Section_Details }
    | { $case: "scientific_sources"; scientific_sources: ProductIngredientInfoScreen_Section_ScientificSources };
}

export interface ProductIngredientInfoScreen_Section_HeaderTags {
  title: AttributedString | undefined;
  header_tags: Tag[];
}

export interface ProductIngredientInfoScreen_Section_HealthRisks {
  title: AttributedString | undefined;
  health_risks: ProductIngredientInfoScreen_Section_HealthRisks_HealthRisk[];
}

export interface ProductIngredientInfoScreen_Section_HealthRisks_HealthRisk {
  icon: string;
  title: string;
}

export interface ProductIngredientInfoScreen_Section_Details {
  title: AttributedString | undefined;
  description: string;
  line_limit: number;
  is_expanded: boolean;
}

export interface ProductIngredientInfoScreen_Section_ScientificSources {
  title: AttributedString | undefined;
  scientific_sources: ProductIngredientInfoScreen_Section_ScientificSources_ScientificSource[];
}

export interface ProductIngredientInfoScreen_Section_ScientificSources_ScientificSource {
  title: string;
  link: AttributedString | undefined;
}

export interface ProductInfoScreen {
  title: AttributedString | undefined;
  subtitle: AttributedString | undefined;
  description: AttributedString | undefined;
}

export interface ProductStepInfoScreen {
  nav_bar: ProductStepInfoScreen_NavBar | undefined;
  header: ProductStepInfoScreen_Header | undefined;
  shortcuts: ProductStepInfoScreen_Shortcuts | undefined;
  sections: ProductStepInfoScreen_Section[];
}

export interface ProductStepInfoScreen_NavBar {
  title: string;
  image: string;
}

export interface ProductStepInfoScreen_Header {
  title: string;
  scale: ProductStepInfoScreen_Header_Scale | undefined;
}

export interface ProductStepInfoScreen_Header_Scale {
  steps_count: number;
  filled_steps_count: number;
  value: string;
}

export interface ProductStepInfoScreen_Shortcuts {
  shortcuts: ProductStepInfoScreen_Shortcuts_Shortcut[];
}

export interface ProductStepInfoScreen_Shortcuts_Shortcut {
  title: string;
  target_section_index: number;
}

export interface ProductStepInfoScreen_Section {
  section?: { $case: "ingredients"; ingredients: ProductScreen_Section_Ingredients } | {
    $case: "info";
    info: ProductStepInfoScreen_Section_Info;
  };
}

export interface ProductStepInfoScreen_Section_Info {
  title: string;
  info: AttributedString | undefined;
  line_limit: number;
  lazy_request_data: LazyProductStepInfoSectionRequestData | undefined;
}

export interface ProductAlternativesScreen {
  title: string;
  products: ProductAlternativesScreen_Product[];
  lazy_request: ProductScreenAlternativesRequest | undefined;
}

export interface ProductAlternativesScreen_Product {
  brand: string;
  title: string;
  price: string;
  image: string;
  tags: Tag[];
  product_screen: ProductScreen | undefined;
  pora_score: ProductPoraScore | undefined;
  product_id: string;
}

export interface ProductStoresInfoScreen {
  title: string;
  stores: ProductStoresInfoScreen_Store[];
}

export interface ProductStoresInfoScreen_Store {
  image_url: string;
  name: string;
  price: string;
  web_page_url: string;
  info: ProductStoresInfoScreen_Store_Info | undefined;
}

export interface ProductStoresInfoScreen_Store_Info {
  info?: { $case: "rating"; rating: ProductStoresInfoScreen_Store_Rating } | { $case: "text"; text: string };
}

export interface ProductStoresInfoScreen_Store_Rating {
  value: string;
  subtitle: string;
  fade: boolean;
}

/** Use instead of ProductPoraScoreInfoScreen */
export interface ProductFitScoreInfoScreen {
  nav_bar: ProductFitScoreInfoScreen_NavBar | undefined;
  fit_score: ProductPoraScore | undefined;
  title: AttributedString | undefined;
  tags: AttributedString[];
  sections: ProductFitScoreInfoScreen_Section[];
}

export interface ProductFitScoreInfoScreen_NavBar {
  title: string;
  image: string;
}

export interface ProductFitScoreInfoScreen_Section {
  section?:
    | { $case: "what_is_score"; what_is_score: ProductFitScoreInfoScreen_Section_WhatIsScore }
    | { $case: "approved_by"; approved_by: ProductFitScoreInfoScreen_Section_ApprovedBy }
    | { $case: "general_info"; general_info: ProductFitScoreInfoScreen_Section_GeneralInfo }
    | { $case: "sources"; sources: ProductFitScoreInfoScreen_Section_Sources }
    | { $case: "example"; example: ProductFitScoreInfoScreen_Section_Example };
}

export interface ProductFitScoreInfoScreen_Section_WhatIsScore {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  evaluations: ProductFitScoreInfoScreen_Section_WhatIsScore_Evaluation[];
}

export interface ProductFitScoreInfoScreen_Section_WhatIsScore_Evaluation {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  icon: string;
}

export interface ProductFitScoreInfoScreen_Section_ApprovedBy {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  avatar_image: string;
  signature_image: string;
}

export interface ProductFitScoreInfoScreen_Section_GeneralInfo {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
}

export interface ProductFitScoreInfoScreen_Section_Sources {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  sources: ProductFitScoreInfoScreen_Section_Sources_Source[];
}

export interface ProductFitScoreInfoScreen_Section_Sources_Source {
  image: string;
  title: AttributedString | undefined;
  url: string;
}

export interface ProductFitScoreInfoScreen_Section_Example {
  title: AttributedString | undefined;
  description: AttributedString | undefined;
  product_card: ProductFitScoreInfoScreen_Section_Example_ProductCard | undefined;
  score_scale: ProductFitScoreInfoScreen_Section_Example_ScoreScale | undefined;
}

export interface ProductFitScoreInfoScreen_Section_Example_ProductCard {
  title: AttributedString | undefined;
  fit_score: ProductPoraScore | undefined;
  image: string;
  description_title: AttributedString | undefined;
  description: AttributedString | undefined;
}

export interface ProductFitScoreInfoScreen_Section_Example_ScoreScale {
  segments: ProductFitScoreInfoScreen_Section_Example_ScoreScale_Segment[];
}

export interface ProductFitScoreInfoScreen_Section_Example_ScoreScale_Segment {
  color: Color | undefined;
  label: AttributedString | undefined;
  value: number;
}

/**
 * This screen is deprecated. Use ProductFitScoreInfoScreen instead.
 *
 * @deprecated
 */
export interface ProductPoraScoreInfoScreen {
  nav_bar: ProductPoraScoreInfoScreen_NavBar | undefined;
  pora_score: ProductPoraScore | undefined;
  description_title: string;
  description: ProductPoraScoreInfoScreen_Description | undefined;
}

export interface ProductPoraScoreInfoScreen_NavBar {
  title: string;
  image: string;
}

export interface ProductPoraScoreInfoScreen_Description {
  items: ProductPoraScoreInfoScreen_Description_Item[];
}

export interface ProductPoraScoreInfoScreen_Description_Item {
  item?: { $case: "string"; string: AttributedString } | {
    $case: "scale";
    scale: ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale;
  };
}

export interface ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale {
  segments: ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale_Segment[];
}

export interface ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale_Segment {
  color: Color | undefined;
  label: string;
}

export interface ActionOpenProductScreenPayload {
  product_id: string;
  product_screen: ProductScreen | undefined;
}

export interface ProductScreenRequest {
  request: Request | undefined;
  product_id: string;
}

export interface LazyProductSectionRequestData {
  data?:
    | { $case: "info"; info: LazyProductSectionRequestData_InfoRequestData }
    | { $case: "key_functions"; key_functions: LazyProductSectionRequestData_KeyFunctionsRequestData }
    | { $case: "header_data"; header_data: LazyProductSectionRequestData_HeaderDataRequest }
    | { $case: "alternatives_data"; alternatives_data: LazyProductSectionRequestData_AlternativesDataRequest };
}

export interface LazyProductSectionRequestData_InfoRequestData {
  section_title: string;
  prompt: string;
}

export interface LazyProductSectionRequestData_KeyFunctionsRequestData {
  product_id: string;
}

export interface LazyProductSectionRequestData_HeaderDataRequest {
  product_id: string;
}

/** Use ProductAlternativesScreen's lazy_request instead */
export interface LazyProductSectionRequestData_AlternativesDataRequest {
  product_id: string;
}

export interface LazyProductStepInfoSectionRequestData {
  data?: { $case: "info"; info: LazyProductStepInfoSectionRequestData_InfoRequestData };
}

export interface LazyProductStepInfoSectionRequestData_InfoRequestData {
  section_title: string;
  prompt: string;
}

export interface LazyProductSectionRequest {
  request: Request | undefined;
  data: LazyProductSectionRequestData | undefined;
}

export interface LazyProductStepInfoSectionRequest {
  request: Request | undefined;
  data: LazyProductStepInfoSectionRequestData | undefined;
}

export interface LazyProductSectionResponse {
  response?: { $case: "section"; section: ProductScreen_Section } | { $case: "error_message"; error_message: string };
}

export interface LazyProductStepInfoSectionResponse {
  response?: { $case: "section"; section: ProductStepInfoScreen_Section } | {
    $case: "error_message";
    error_message: string;
  };
}

export interface ProductScreenHeaderKeyFunctionsRequest {
  request: Request | undefined;
  product_id: string;
}

export interface ProductScreenHeaderPoraScoreRequest {
  request: Request | undefined;
  product_id: string;
}

export interface ProductScreenHeaderQuickSummaryRequest {
  request: Request | undefined;
  url: string;
}

export interface ProductScreenAlternativesRequest {
  request: Request | undefined;
  url: string;
}

export interface DeleteScanRequest {
  request: Request | undefined;
  id: string;
  category: string;
}

export interface DeleteScanResponse {
  deleted_id: string;
}

export interface ProductsScanHistoryRequest {
  request: Request | undefined;
  start_index: number;
  category: string;
}

export interface ProductsScanHistoryResponse {
  /**
   * use sections instead
   *
   * @deprecated
   */
  history_items: ProductsScanHistoryItem[];
  /**
   * The backend is now serving all the content
   *
   * @deprecated
   */
  next_page_start_index: number;
  /**
   * The backend is now serving all the content
   *
   * @deprecated
   */
  has_more: boolean;
  /**
   * use items_total_count in Section
   *
   * @deprecated
   */
  items_total_count: string;
  sections: ProductsScanHistoryResponse_Section[];
}

export interface ProductsScanHistoryResponse_Section {
  section_title: string;
  items: ProductsScanHistoryItem[];
}

export interface ProductsScanHistoryItem {
  id: string;
  image: string;
  qualification: ProductQualification;
  qualification_title: string;
  title: string;
  subtitle: string;
  /** @deprecated */
  time_ago: string;
  action: ActionType | undefined;
  is_disabled: boolean;
  footer_title: string;
  pora_score: ProductPoraScore | undefined;
  image_content_mode: ImageContentMode;
  tags: Tag[];
}

export interface ProductScreen {
  sections: ProductScreen_Section[];
  /** id of scanned product. Use it after scanning to fetch product screen */
  id: string;
  buttons: ProductScreen_Button[];
  is_go_home_available: boolean;
  go_home_post_action:
    | ActionType
    | undefined;
  /** id of product. Use it anywhere to fetch product screen */
  product_id: string;
  analytics_data: ProductScreen_AnalyticsData | undefined;
  is_closable_by_swipe: boolean;
  snack_bar: SnackBar | undefined;
  assistant_button: ImageButton | undefined;
  alternativesScreen:
    | ProductAlternativesScreen
    | undefined;
  /** @deprecated */
  report_quiz_id: string;
  report_action: ActionType | undefined;
}

/** @deprecated */
export interface ProductScreen_Section {
  section?:
    | { $case: "header"; header: ProductScreen_Section_Header }
    | { $case: "ratings_and_prices"; ratings_and_prices: ProductScreen_Section_RatingsAndPrices }
    | { $case: "info"; info: ProductScreen_Section_Info }
    | { $case: "list_with_icons"; list_with_icons: ProductScreen_Section_ListWithIcons }
    | { $case: "skin_id_match"; skin_id_match: ProductScreen_Section_SkinIDMatch }
    | { $case: "video_tutorial"; video_tutorial: ProductScreen_Section_VideoTutorial }
    | { $case: "alternatives"; alternatives: ProductScreen_Section_Alternatives }
    | { $case: "tiles_header"; tiles_header: ProductScreen_Section_TilesHeader }
    | { $case: "key_functions"; key_functions: ProductScreen_Section_KeyFunctions }
    | { $case: "ingredients"; ingredients: ProductScreen_Section_Ingredients }
    | { $case: "DEPRECATED_header"; DEPRECATED_header: ProductScreen_Section_Header };
}

export interface ProductScreen_Section_Header {
  image_urls: string[];
  brand: string;
  title: string;
  subtitle: ProductScreen_Section_Header_Subtitle | undefined;
}

export interface ProductScreen_Section_Header_Subtitle {
  items: ProductScreen_Section_Header_Subtitle_Item[];
}

export interface ProductScreen_Section_Header_Subtitle_Item {
  item?: { $case: "text"; text: string } | { $case: "rating"; rating: string };
}

export interface ProductScreen_Section_SkinIDMatch {
  items: ProductScreen_Section_SkinIDMatch_Item[];
  header: ProductScreen_Section_SkinIDMatch_Header | undefined;
}

export interface ProductScreen_Section_SkinIDMatch_Header {
  qualification: ProductQualification;
  title: string;
  message: string;
}

export interface ProductScreen_Section_SkinIDMatch_Item {
  qualification: ProductQualification;
  title: string;
  content: ProductScreen_Section_SkinIDMatch_Item_Content | undefined;
}

export interface ProductScreen_Section_SkinIDMatch_Item_Content {
  content?: { $case: "list"; list: ProductScreen_Section_SkinIDMatch_Item_Content_List } | {
    $case: "banners";
    banners: ProductScreen_Section_SkinIDMatch_Item_Content_Banners;
  };
}

export interface ProductScreen_Section_SkinIDMatch_Item_Content_List {
  items: ProductScreen_Section_SkinIDMatch_Item_Content_List_Item[];
}

export interface ProductScreen_Section_SkinIDMatch_Item_Content_List_Item {
  icon: string;
  text: string;
}

export interface ProductScreen_Section_SkinIDMatch_Item_Content_Banners {
  banners: ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner[];
}

export interface ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner {
  title: string;
  items: ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner_Item[];
}

export interface ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner_Item {
  icon: string;
  text: string;
}

export interface ProductScreen_Section_RatingsAndPrices {
  title: string;
  average_rating: ProductScreen_Section_RatingsAndPrices_AverageRating | undefined;
  stores: ProductScreen_Section_RatingsAndPrices_Store[];
}

export interface ProductScreen_Section_RatingsAndPrices_AverageRating {
  value: string;
  subtitle: string;
}

export interface ProductScreen_Section_RatingsAndPrices_Store {
  image_url: string;
  name: string;
  price: string;
  DEPRECATED_rating: string;
  DEPRECATED_rating_subtitle: string;
  web_page_url: string;
  qualification?: { $case: "rating"; rating: ProductScreen_Section_RatingsAndPrices_Store_Rating } | {
    $case: "text";
    text: string;
  };
}

export interface ProductScreen_Section_RatingsAndPrices_Store_Rating {
  value: string;
  subtitle: string;
  fade: boolean;
}

export interface ProductScreen_Section_Info {
  title: string;
  DEPRECATED_info: string;
  attributed_info: AttributedString | undefined;
  lazy_request_data: LazyProductSectionRequestData | undefined;
  button: ProductScreen_Section_Info_Button | undefined;
}

export interface ProductScreen_Section_Info_Button {
  title: string;
  info_screen: ProductInfoScreen | undefined;
}

export interface ProductScreen_Section_ListWithIcons {
  title: string;
  items: ProductScreen_Section_ListWithIcons_Item[];
}

export interface ProductScreen_Section_ListWithIcons_Item {
  icon: string;
  text: string;
}

export interface ProductScreen_Section_VideoTutorial {
  section_title: string;
  tutorial_title: string;
  image_url: string;
  tags: Tag[];
  action: ActionType | undefined;
}

/** Use ProductAlternativesScreen instead */
export interface ProductScreen_Section_Alternatives {
  title: string;
  cards: ProductScreen_Section_Alternatives_Card[];
  lazy_request_data: LazyProductSectionRequestData | undefined;
}

export interface ProductScreen_Section_Alternatives_Card {
  brand: string;
  title: string;
  price: string;
  image: string;
  tags: Tag[];
  product_screen: ProductScreen | undefined;
  pora_score: ProductPoraScore | undefined;
  save_button: ImageButton | undefined;
  should_show_save_loading: boolean;
  should_disable_save_button: boolean;
  product_id: string;
}

export interface ProductScreen_Section_TilesHeader {
  images:
    | ProductScreen_Section_TilesHeader_Images
    | undefined;
  /** Optional. If absent, make a dedicated request to fetch them */
  key_functions: ProductScreen_Section_TilesHeader_KeyFunctions | undefined;
  title:
    | ProductScreen_Section_TilesHeader_Title
    | undefined;
  /** @deprecated */
  shortcuts: ProductScreen_Section_TilesHeader_Shortcut[];
  /**
   * Deprecated, use `grid` instead
   *
   * @deprecated
   */
  shortcut_rows: ProductScreen_Section_TilesHeader_Shortcuts | undefined;
  product_id: string;
  pora_score: ProductScreen_Section_TilesHeader_Score | undefined;
  text_review: ProductScreen_Section_TilesHeader_TextReview | undefined;
  assistant_summary: ProductScreen_Section_TilesHeader_AssistantSummary | undefined;
  grid: ProductScreen_Section_TilesHeader_Grid | undefined;
}

export interface ProductScreen_Section_TilesHeader_TextReview {
  title: string;
  image: string;
  text: string;
  action: ActionType | undefined;
  lazy_request: ProductScreenHeaderQuickSummaryRequest | undefined;
}

export interface ProductScreen_Section_TilesHeader_Score {
  score:
    | ProductPoraScore
    | undefined;
  /**
   * Deprecated, use `ProductFitScoreInfoScreen` instead
   *
   * @deprecated
   */
  info_screen: ProductPoraScoreInfoScreen | undefined;
  should_load: boolean;
  score_info_screen: ProductFitScoreInfoScreen | undefined;
}

export interface ProductScreen_Section_TilesHeader_Images {
  product_image: string;
  /**
   * Unique ID of the user photo sent by client
   * Absent if the product was not scanned
   */
  user_photo_id: string;
}

export interface ProductScreen_Section_TilesHeader_KeyFunctions {
  /** @deprecated */
  be_aware_tags: string[];
  /** @deprecated */
  good_for_tags: string[];
  attributed_be_aware_tags: AttributedString[];
  attributed_good_for_tags: AttributedString[];
}

export interface ProductScreen_Section_TilesHeader_Title {
  product_type: AttributedString | undefined;
  text: AttributedString | undefined;
}

export interface ProductScreen_Section_TilesHeader_Grid {
  items: ProductScreen_Section_TilesHeader_Grid_Item[];
}

export interface ProductScreen_Section_TilesHeader_Grid_Item {
  item?: { $case: "shortcuts_row"; shortcuts_row: ProductScreen_Section_TilesHeader_Shortcuts_Row } | {
    $case: "assistant_summary";
    assistant_summary: ProductScreen_Section_TilesHeader_AssistantSummary;
  };
}

export interface ProductScreen_Section_TilesHeader_Shortcuts {
  rows: ProductScreen_Section_TilesHeader_Shortcuts_Row[];
}

export interface ProductScreen_Section_TilesHeader_Shortcuts_Row {
  shortcuts: ProductScreen_Section_TilesHeader_Shortcut[];
  style: ProductScreen_Section_TilesHeader_Shortcuts_Row_Style;
}

export enum ProductScreen_Section_TilesHeader_Shortcuts_Row_Style {
  STYLE_NONE = "STYLE_NONE",
  STYLE_MEDIUM = "STYLE_MEDIUM",
  STYLE_LARGE = "STYLE_LARGE",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function productScreen_Section_TilesHeader_Shortcuts_Row_StyleFromJSON(
  object: any,
): ProductScreen_Section_TilesHeader_Shortcuts_Row_Style {
  switch (object) {
    case 0:
    case "STYLE_NONE":
      return ProductScreen_Section_TilesHeader_Shortcuts_Row_Style.STYLE_NONE;
    case 1:
    case "STYLE_MEDIUM":
      return ProductScreen_Section_TilesHeader_Shortcuts_Row_Style.STYLE_MEDIUM;
    case 2:
    case "STYLE_LARGE":
      return ProductScreen_Section_TilesHeader_Shortcuts_Row_Style.STYLE_LARGE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProductScreen_Section_TilesHeader_Shortcuts_Row_Style.UNRECOGNIZED;
  }
}

export function productScreen_Section_TilesHeader_Shortcuts_Row_StyleToJSON(
  object: ProductScreen_Section_TilesHeader_Shortcuts_Row_Style,
): string {
  switch (object) {
    case ProductScreen_Section_TilesHeader_Shortcuts_Row_Style.STYLE_NONE:
      return "STYLE_NONE";
    case ProductScreen_Section_TilesHeader_Shortcuts_Row_Style.STYLE_MEDIUM:
      return "STYLE_MEDIUM";
    case ProductScreen_Section_TilesHeader_Shortcuts_Row_Style.STYLE_LARGE:
      return "STYLE_LARGE";
    case ProductScreen_Section_TilesHeader_Shortcuts_Row_Style.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ProductScreen_Section_TilesHeader_Shortcut {
  shortcut?: { $case: "empty"; empty: ProductScreen_Section_TilesHeader_Shortcut_Empty } | {
    $case: "content";
    content: ProductScreen_Section_TilesHeader_Shortcut_Content;
  };
}

export interface ProductScreen_Section_TilesHeader_Shortcut_Empty {
  message: string;
}

export interface ProductScreen_Section_TilesHeader_Shortcut_Content {
  content?:
    | { $case: "rating"; rating: ProductScreen_Section_TilesHeader_Shortcut_Content_Rating }
    | { $case: "tutorial"; tutorial: ProductScreen_Section_TilesHeader_Shortcut_Content_Tutorial }
    | { $case: "prices"; prices: ProductScreen_Section_TilesHeader_Shortcut_Content_Prices }
    | { $case: "alternatives"; alternatives: ProductScreen_Section_TilesHeader_Shortcut_Content_Alternatives }
    | { $case: "ingredients"; ingredients: ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients }
    | { $case: "reviewSummary"; reviewSummary: ProductScreen_Section_TilesHeader_Shortcut_Content_ReviewSummary };
}

export interface ProductScreen_Section_TilesHeader_Shortcut_Content_Rating {
  value: string;
  accented: boolean;
  subtitle: string;
  info_screen: ProductStoresInfoScreen | undefined;
}

export interface ProductScreen_Section_TilesHeader_Shortcut_Content_Tutorial {
  image: string;
  title: string;
  tag: Tag | undefined;
  video_title: string;
  action: ActionType | undefined;
}

export interface ProductScreen_Section_TilesHeader_Shortcut_Content_Prices {
  title: string;
  subtitle: string;
  tag: AttributedString | undefined;
  info_screen: ProductStoresInfoScreen | undefined;
}

export interface ProductScreen_Section_TilesHeader_Shortcut_Content_Alternatives {
  preview_images: string[];
}

export interface ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients {
  title:
    | AttributedString
    | undefined;
  /** @deprecated */
  subtitle: string;
  items: ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients_Item[];
  /** @deprecated */
  ingredients_list: ProductScreen_Section_Ingredients | undefined;
  ingredients_list_info_screen: ProductIngredientsListInfoScreen | undefined;
}

export interface ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients_Item {
  title: AttributedString | undefined;
  risk_color: Color | undefined;
}

export interface ProductScreen_Section_TilesHeader_Shortcut_Content_ReviewSummary {
  image: string;
  title: AttributedString | undefined;
  subtitle: AttributedString | undefined;
  action: ActionType | undefined;
}

export interface ProductScreen_Section_TilesHeader_AssistantSummary {
  title: AttributedString | undefined;
  message: AttributedString | undefined;
  buttons: ProductScreen_Section_TilesHeader_AssistantSummary_Button[];
  /** Return 0 if no limit */
  default_buttons_max_count: number;
  more_button_title: AttributedString | undefined;
}

export interface ProductScreen_Section_TilesHeader_AssistantSummary_Button {
  title: AttributedString | undefined;
  is_primary: boolean;
  action: ActionType | undefined;
}

export interface ProductScreen_Section_KeyFunctions {
  title: string;
  subtitle: string;
  sections: ProductScreen_Section_KeyFunctions_Section[];
  lazy_request_data: LazyProductSectionRequestData | undefined;
}

export interface ProductScreen_Section_KeyFunctions_Section {
  /** @deprecated */
  title: string;
  /** @deprecated */
  functions_type: ProductKeyFunctionType;
  /** @deprecated */
  functions: ProductScreen_Section_KeyFunctions_Function[];
  is_always_visible: boolean;
  content?: { $case: "tags"; tags: ProductScreen_Section_KeyFunctions_Section_Tags } | {
    $case: "scales";
    scales: ProductScreen_Section_KeyFunctions_Section_Scales;
  };
}

export interface ProductScreen_Section_KeyFunctions_Section_Tags {
  title: string;
  functions_type: ProductKeyFunctionType;
  functions: ProductScreen_Section_KeyFunctions_Function[];
}

export interface ProductScreen_Section_KeyFunctions_Section_Scales {
  scales: ProductScreen_Section_KeyFunctions_Section_Scales_Scale[];
  limit: number;
}

export interface ProductScreen_Section_KeyFunctions_Section_Scales_Scale {
  label: string;
  value: string;
  steps_count: number;
  filled_steps_count: number;
  info_screen: ProductStepInfoScreen | undefined;
}

export interface ProductScreen_Section_KeyFunctions_Function {
  /** @deprecated */
  title: string;
  info_screen: ProductKeyFunctionInfoScreen | undefined;
  attributed_title: AttributedString | undefined;
}

export interface ProductScreen_Section_Ingredients {
  title: string;
  ingredients: ProductScreen_Section_Ingredients_Ingredient[];
  is_always_visible: boolean;
  ingredients_count_to_show: number;
}

export interface ProductScreen_Section_Ingredients_Ingredient {
  title: string;
  info_screen: ProductIngredientInfoScreen | undefined;
  risk_title: string;
  risk_color: Color | undefined;
}

export interface ProductScreen_Button {
  /** @deprecated */
  title: string;
  /** @deprecated */
  deprecated_action:
    | ActionType
    | undefined;
  /** @deprecated */
  is_active: boolean;
  /** @deprecated */
  style: ProductScreen_Button_Style;
  action: ProductScreen_Button_Action | undefined;
  filled_button: FilledButton | undefined;
}

export enum ProductScreen_Button_Style {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function productScreen_Button_StyleFromJSON(object: any): ProductScreen_Button_Style {
  switch (object) {
    case 0:
    case "PRIMARY":
      return ProductScreen_Button_Style.PRIMARY;
    case 1:
    case "SECONDARY":
      return ProductScreen_Button_Style.SECONDARY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProductScreen_Button_Style.UNRECOGNIZED;
  }
}

export function productScreen_Button_StyleToJSON(object: ProductScreen_Button_Style): string {
  switch (object) {
    case ProductScreen_Button_Style.PRIMARY:
      return "PRIMARY";
    case ProductScreen_Button_Style.SECONDARY:
      return "SECONDARY";
    case ProductScreen_Button_Style.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ProductScreen_Button_Action {
  action?: { $case: "close"; close: ProductScreen_Button_Action_Close } | {
    $case: "dynamic";
    dynamic: ProductScreen_Button_Action_Dynamic;
  };
}

export enum ProductScreen_Button_Action_TargetScreen {
  TARGET_SCREEN_NONE = "TARGET_SCREEN_NONE",
  TARGET_SCREEN_HOME = "TARGET_SCREEN_HOME",
  /** @deprecated */
  TARGET_SCREEN_PRODUCTS = "TARGET_SCREEN_PRODUCTS",
  TARGET_SCREEN_ANY = "TARGET_SCREEN_ANY",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function productScreen_Button_Action_TargetScreenFromJSON(
  object: any,
): ProductScreen_Button_Action_TargetScreen {
  switch (object) {
    case 0:
    case "TARGET_SCREEN_NONE":
      return ProductScreen_Button_Action_TargetScreen.TARGET_SCREEN_NONE;
    case 1:
    case "TARGET_SCREEN_HOME":
      return ProductScreen_Button_Action_TargetScreen.TARGET_SCREEN_HOME;
    case 2:
    case "TARGET_SCREEN_PRODUCTS":
      return ProductScreen_Button_Action_TargetScreen.TARGET_SCREEN_PRODUCTS;
    case 3:
    case "TARGET_SCREEN_ANY":
      return ProductScreen_Button_Action_TargetScreen.TARGET_SCREEN_ANY;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProductScreen_Button_Action_TargetScreen.UNRECOGNIZED;
  }
}

export function productScreen_Button_Action_TargetScreenToJSON(
  object: ProductScreen_Button_Action_TargetScreen,
): string {
  switch (object) {
    case ProductScreen_Button_Action_TargetScreen.TARGET_SCREEN_NONE:
      return "TARGET_SCREEN_NONE";
    case ProductScreen_Button_Action_TargetScreen.TARGET_SCREEN_HOME:
      return "TARGET_SCREEN_HOME";
    case ProductScreen_Button_Action_TargetScreen.TARGET_SCREEN_PRODUCTS:
      return "TARGET_SCREEN_PRODUCTS";
    case ProductScreen_Button_Action_TargetScreen.TARGET_SCREEN_ANY:
      return "TARGET_SCREEN_ANY";
    case ProductScreen_Button_Action_TargetScreen.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ProductScreen_Button_Action_Close {
}

export interface ProductScreen_Button_Action_Dynamic {
  action: ActionType | undefined;
  target_screen: ProductScreen_Button_Action_TargetScreen;
}

export interface ProductScreen_AnalyticsData {
  name: string;
  brand: string;
  expert_analysis_used: boolean;
}

export interface ProductsSearchScreen {
  promo: ProductsSearchScreen_PromoText | undefined;
  productCount: number;
  evolutionActions: ProductsSearchScreen_Action[];
  video: string;
  buttonText: string;
}

export interface ProductsSearchScreen_PromoText {
  header: AttributedString | undefined;
  description: AttributedString | undefined;
}

export interface ProductsSearchScreen_Particles {
  density: number;
  scale: number;
}

export interface ProductsSearchScreen_ProductCounter {
  count: number;
  duration: number;
}

export interface ProductsSearchScreen_Action {
  action?: { $case: "born"; born: ProductsSearchScreen_Action_Born } | {
    $case: "blast";
    blast: ProductsSearchScreen_Action_Blast;
  } | { $case: "finalMove"; finalMove: ProductsSearchScreen_Action_FinalMove };
}

export interface ProductsSearchScreen_Action_Born {
  particles: ProductsSearchScreen_Particles | undefined;
  delay: number;
  sleep: number;
}

export interface ProductsSearchScreen_Action_Blast {
  promo: ProductsSearchScreen_PromoText | undefined;
  particles: ProductsSearchScreen_Particles | undefined;
  counter: ProductsSearchScreen_ProductCounter | undefined;
  waveLifetime: number;
  sleep: number;
}

export interface ProductsSearchScreen_Action_FinalMove {
  promo: ProductsSearchScreen_PromoText | undefined;
  productCount: number;
  particlesScale: number;
  duration: number;
}

export interface SubscriptionManagementPageData {
  provider: string;
  subscription_id: string;
  user_id: string;
}

export interface EncryptedSubscriptionManagementPageData {
  encrypted_smp: string;
}

export interface ImageButton {
  /**
   * Use resources instead
   *
   * @deprecated
   */
  image: string;
  action: ActionType | undefined;
  resources: ImageButton_Resource[];
  /** animation do not start for this duration */
  before_animation_delay: number;
}

export interface ImageButton_Resource {
  name: string;
  /** from 0 to 100 % */
  appear_probability: number;
}

export interface ProductPageReport {
  product_id: string;
  user_id: string;
  comment: string;
  problem_tags: ProductPageReport_ProblemTag[];
  screenshot: Image | undefined;
}

export enum ProductPageReport_ProblemTag {
  PROBLEM_LEVEL_NONE = "PROBLEM_LEVEL_NONE",
  PROBLEM_LEVEL_DANGEROUS = "PROBLEM_LEVEL_DANGEROUS",
  PROBLEM_LEVEL_NON_DANGEROUS = "PROBLEM_LEVEL_NON_DANGEROUS",
  UNRECOGNIZED = "UNRECOGNIZED",
}

export function productPageReport_ProblemTagFromJSON(object: any): ProductPageReport_ProblemTag {
  switch (object) {
    case 0:
    case "PROBLEM_LEVEL_NONE":
      return ProductPageReport_ProblemTag.PROBLEM_LEVEL_NONE;
    case 1:
    case "PROBLEM_LEVEL_DANGEROUS":
      return ProductPageReport_ProblemTag.PROBLEM_LEVEL_DANGEROUS;
    case 2:
    case "PROBLEM_LEVEL_NON_DANGEROUS":
      return ProductPageReport_ProblemTag.PROBLEM_LEVEL_NON_DANGEROUS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProductPageReport_ProblemTag.UNRECOGNIZED;
  }
}

export function productPageReport_ProblemTagToJSON(object: ProductPageReport_ProblemTag): string {
  switch (object) {
    case ProductPageReport_ProblemTag.PROBLEM_LEVEL_NONE:
      return "PROBLEM_LEVEL_NONE";
    case ProductPageReport_ProblemTag.PROBLEM_LEVEL_DANGEROUS:
      return "PROBLEM_LEVEL_DANGEROUS";
    case ProductPageReport_ProblemTag.PROBLEM_LEVEL_NON_DANGEROUS:
      return "PROBLEM_LEVEL_NON_DANGEROUS";
    case ProductPageReport_ProblemTag.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ProductPageReportRequest {
  request: Request | undefined;
  report: ProductPageReport | undefined;
}

function createBaseProductPoraScore(): ProductPoraScore {
  return { scale: undefined, value: 0, colors: undefined, title: "" };
}

export const ProductPoraScore = {
  fromJSON(object: any): ProductPoraScore {
    return {
      scale: isSet(object.scale) ? ProductPoraScore_Scale.fromJSON(object.scale) : undefined,
      value: isSet(object.value) ? Number(object.value) : 0,
      colors: isSet(object.colors) ? ProductPoraScore_Colors.fromJSON(object.colors) : undefined,
      title: isSet(object.title) ? String(object.title) : "",
    };
  },

  toJSON(message: ProductPoraScore): unknown {
    const obj: any = {};
    message.scale !== undefined &&
      (obj.scale = message.scale ? ProductPoraScore_Scale.toJSON(message.scale) : undefined);
    message.value !== undefined && (obj.value = Math.round(message.value));
    message.colors !== undefined &&
      (obj.colors = message.colors ? ProductPoraScore_Colors.toJSON(message.colors) : undefined);
    message.title !== undefined && (obj.title = message.title);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductPoraScore>, I>>(base?: I): ProductPoraScore {
    return ProductPoraScore.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductPoraScore>, I>>(object: I): ProductPoraScore {
    const message = createBaseProductPoraScore();
    message.scale = (object.scale !== undefined && object.scale !== null)
      ? ProductPoraScore_Scale.fromPartial(object.scale)
      : undefined;
    message.value = object.value ?? 0;
    message.colors = (object.colors !== undefined && object.colors !== null)
      ? ProductPoraScore_Colors.fromPartial(object.colors)
      : undefined;
    message.title = object.title ?? "";
    return message;
  },
};

function createBaseProductPoraScore_Colors(): ProductPoraScore_Colors {
  return { background: undefined, foreground: undefined };
}

export const ProductPoraScore_Colors = {
  fromJSON(object: any): ProductPoraScore_Colors {
    return {
      background: isSet(object.background) ? Color.fromJSON(object.background) : undefined,
      foreground: isSet(object.foreground) ? Color.fromJSON(object.foreground) : undefined,
    };
  },

  toJSON(message: ProductPoraScore_Colors): unknown {
    const obj: any = {};
    message.background !== undefined &&
      (obj.background = message.background ? Color.toJSON(message.background) : undefined);
    message.foreground !== undefined &&
      (obj.foreground = message.foreground ? Color.toJSON(message.foreground) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductPoraScore_Colors>, I>>(base?: I): ProductPoraScore_Colors {
    return ProductPoraScore_Colors.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductPoraScore_Colors>, I>>(object: I): ProductPoraScore_Colors {
    const message = createBaseProductPoraScore_Colors();
    message.background = (object.background !== undefined && object.background !== null)
      ? Color.fromPartial(object.background)
      : undefined;
    message.foreground = (object.foreground !== undefined && object.foreground !== null)
      ? Color.fromPartial(object.foreground)
      : undefined;
    return message;
  },
};

function createBaseProductPoraScore_Scale(): ProductPoraScore_Scale {
  return { items: [] };
}

export const ProductPoraScore_Scale = {
  fromJSON(object: any): ProductPoraScore_Scale {
    return {
      items: Array.isArray(object?.items) ? object.items.map((e: any) => ProductPoraScore_Scale_Item.fromJSON(e)) : [],
    };
  },

  toJSON(message: ProductPoraScore_Scale): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map((e) => e ? ProductPoraScore_Scale_Item.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductPoraScore_Scale>, I>>(base?: I): ProductPoraScore_Scale {
    return ProductPoraScore_Scale.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductPoraScore_Scale>, I>>(object: I): ProductPoraScore_Scale {
    const message = createBaseProductPoraScore_Scale();
    message.items = object.items?.map((e) => ProductPoraScore_Scale_Item.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductPoraScore_Scale_Item(): ProductPoraScore_Scale_Item {
  return { from_value: 0, to_value: 0 };
}

export const ProductPoraScore_Scale_Item = {
  fromJSON(object: any): ProductPoraScore_Scale_Item {
    return {
      from_value: isSet(object.from_value) ? Number(object.from_value) : 0,
      to_value: isSet(object.to_value) ? Number(object.to_value) : 0,
    };
  },

  toJSON(message: ProductPoraScore_Scale_Item): unknown {
    const obj: any = {};
    message.from_value !== undefined && (obj.from_value = Math.round(message.from_value));
    message.to_value !== undefined && (obj.to_value = Math.round(message.to_value));
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductPoraScore_Scale_Item>, I>>(base?: I): ProductPoraScore_Scale_Item {
    return ProductPoraScore_Scale_Item.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductPoraScore_Scale_Item>, I>>(object: I): ProductPoraScore_Scale_Item {
    const message = createBaseProductPoraScore_Scale_Item();
    message.from_value = object.from_value ?? 0;
    message.to_value = object.to_value ?? 0;
    return message;
  },
};

function createBaseMyProductsScreenRequest(): MyProductsScreenRequest {
  return { request: undefined };
}

export const MyProductsScreenRequest = {
  fromJSON(object: any): MyProductsScreenRequest {
    return { request: isSet(object.request) ? Request.fromJSON(object.request) : undefined };
  },

  toJSON(message: MyProductsScreenRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MyProductsScreenRequest>, I>>(base?: I): MyProductsScreenRequest {
    return MyProductsScreenRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MyProductsScreenRequest>, I>>(object: I): MyProductsScreenRequest {
    const message = createBaseMyProductsScreenRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseMyProductsScreenResponse(): MyProductsScreenResponse {
  return { my_products: undefined };
}

export const MyProductsScreenResponse = {
  fromJSON(object: any): MyProductsScreenResponse {
    return { my_products: isSet(object.my_products) ? ActionOpenMyProducts.fromJSON(object.my_products) : undefined };
  },

  toJSON(message: MyProductsScreenResponse): unknown {
    const obj: any = {};
    message.my_products !== undefined &&
      (obj.my_products = message.my_products ? ActionOpenMyProducts.toJSON(message.my_products) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<MyProductsScreenResponse>, I>>(base?: I): MyProductsScreenResponse {
    return MyProductsScreenResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MyProductsScreenResponse>, I>>(object: I): MyProductsScreenResponse {
    const message = createBaseMyProductsScreenResponse();
    message.my_products = (object.my_products !== undefined && object.my_products !== null)
      ? ActionOpenMyProducts.fromPartial(object.my_products)
      : undefined;
    return message;
  },
};

function createBaseOpenProductScannerRequest(): OpenProductScannerRequest {
  return { request: undefined };
}

export const OpenProductScannerRequest = {
  fromJSON(object: any): OpenProductScannerRequest {
    return { request: isSet(object.request) ? Request.fromJSON(object.request) : undefined };
  },

  toJSON(message: OpenProductScannerRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<OpenProductScannerRequest>, I>>(base?: I): OpenProductScannerRequest {
    return OpenProductScannerRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OpenProductScannerRequest>, I>>(object: I): OpenProductScannerRequest {
    const message = createBaseOpenProductScannerRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseOpenProductScannerResponse(): OpenProductScannerResponse {
  return { product_scanner: undefined };
}

export const OpenProductScannerResponse = {
  fromJSON(object: any): OpenProductScannerResponse {
    return {
      product_scanner: isSet(object.product_scanner)
        ? ActionOpenProductScanner.fromJSON(object.product_scanner)
        : undefined,
    };
  },

  toJSON(message: OpenProductScannerResponse): unknown {
    const obj: any = {};
    message.product_scanner !== undefined && (obj.product_scanner = message.product_scanner
      ? ActionOpenProductScanner.toJSON(message.product_scanner)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<OpenProductScannerResponse>, I>>(base?: I): OpenProductScannerResponse {
    return OpenProductScannerResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OpenProductScannerResponse>, I>>(object: I): OpenProductScannerResponse {
    const message = createBaseOpenProductScannerResponse();
    message.product_scanner = (object.product_scanner !== undefined && object.product_scanner !== null)
      ? ActionOpenProductScanner.fromPartial(object.product_scanner)
      : undefined;
    return message;
  },
};

function createBaseOpenFaceScannerRequest(): OpenFaceScannerRequest {
  return { request: undefined };
}

export const OpenFaceScannerRequest = {
  fromJSON(object: any): OpenFaceScannerRequest {
    return { request: isSet(object.request) ? Request.fromJSON(object.request) : undefined };
  },

  toJSON(message: OpenFaceScannerRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<OpenFaceScannerRequest>, I>>(base?: I): OpenFaceScannerRequest {
    return OpenFaceScannerRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OpenFaceScannerRequest>, I>>(object: I): OpenFaceScannerRequest {
    const message = createBaseOpenFaceScannerRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    return message;
  },
};

function createBaseOpenFaceScannerResponse(): OpenFaceScannerResponse {
  return { face_scanner: undefined };
}

export const OpenFaceScannerResponse = {
  fromJSON(object: any): OpenFaceScannerResponse {
    return { face_scanner: isSet(object.face_scanner) ? ActionSequence.fromJSON(object.face_scanner) : undefined };
  },

  toJSON(message: OpenFaceScannerResponse): unknown {
    const obj: any = {};
    message.face_scanner !== undefined &&
      (obj.face_scanner = message.face_scanner ? ActionSequence.toJSON(message.face_scanner) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<OpenFaceScannerResponse>, I>>(base?: I): OpenFaceScannerResponse {
    return OpenFaceScannerResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<OpenFaceScannerResponse>, I>>(object: I): OpenFaceScannerResponse {
    const message = createBaseOpenFaceScannerResponse();
    message.face_scanner = (object.face_scanner !== undefined && object.face_scanner !== null)
      ? ActionSequence.fromPartial(object.face_scanner)
      : undefined;
    return message;
  },
};

function createBaseProductsRecognitionRequest(): ProductsRecognitionRequest {
  return {
    request: undefined,
    image: undefined,
    forced_ingredients: "",
    user_photo_id: "",
    ocr_result: undefined,
    forced_product_text: "",
    search_variants_count: 0,
    forced_product_url: "",
    forced_product_brand: "",
    forced_product_name: "",
    forced_product_image: "",
    forced_product_step: "",
    forced_product_description: "",
    forced_product_price: "",
    forced_product_rating: "",
    forced_product_reviews_count: "",
    skip_alternatives: false,
    evaluations_request_id: "",
  };
}

export const ProductsRecognitionRequest = {
  fromJSON(object: any): ProductsRecognitionRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      image: isSet(object.image) ? Image.fromJSON(object.image) : undefined,
      forced_ingredients: isSet(object.forced_ingredients) ? String(object.forced_ingredients) : "",
      user_photo_id: isSet(object.user_photo_id) ? String(object.user_photo_id) : "",
      ocr_result: isSet(object.ocr_result) ? ProductsRecognitionRequest_OCR.fromJSON(object.ocr_result) : undefined,
      forced_product_text: isSet(object.forced_product_text) ? String(object.forced_product_text) : "",
      search_variants_count: isSet(object.search_variants_count) ? Number(object.search_variants_count) : 0,
      forced_product_url: isSet(object.forced_product_url) ? String(object.forced_product_url) : "",
      forced_product_brand: isSet(object.forced_product_brand) ? String(object.forced_product_brand) : "",
      forced_product_name: isSet(object.forced_product_name) ? String(object.forced_product_name) : "",
      forced_product_image: isSet(object.forced_product_image) ? String(object.forced_product_image) : "",
      forced_product_step: isSet(object.forced_product_step) ? String(object.forced_product_step) : "",
      forced_product_description: isSet(object.forced_product_description)
        ? String(object.forced_product_description)
        : "",
      forced_product_price: isSet(object.forced_product_price) ? String(object.forced_product_price) : "",
      forced_product_rating: isSet(object.forced_product_rating) ? String(object.forced_product_rating) : "",
      forced_product_reviews_count: isSet(object.forced_product_reviews_count)
        ? String(object.forced_product_reviews_count)
        : "",
      skip_alternatives: isSet(object.skip_alternatives) ? Boolean(object.skip_alternatives) : false,
      evaluations_request_id: isSet(object.evaluations_request_id) ? String(object.evaluations_request_id) : "",
    };
  },

  toJSON(message: ProductsRecognitionRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    message.image !== undefined && (obj.image = message.image ? Image.toJSON(message.image) : undefined);
    message.forced_ingredients !== undefined && (obj.forced_ingredients = message.forced_ingredients);
    message.user_photo_id !== undefined && (obj.user_photo_id = message.user_photo_id);
    message.ocr_result !== undefined &&
      (obj.ocr_result = message.ocr_result ? ProductsRecognitionRequest_OCR.toJSON(message.ocr_result) : undefined);
    message.forced_product_text !== undefined && (obj.forced_product_text = message.forced_product_text);
    message.search_variants_count !== undefined &&
      (obj.search_variants_count = Math.round(message.search_variants_count));
    message.forced_product_url !== undefined && (obj.forced_product_url = message.forced_product_url);
    message.forced_product_brand !== undefined && (obj.forced_product_brand = message.forced_product_brand);
    message.forced_product_name !== undefined && (obj.forced_product_name = message.forced_product_name);
    message.forced_product_image !== undefined && (obj.forced_product_image = message.forced_product_image);
    message.forced_product_step !== undefined && (obj.forced_product_step = message.forced_product_step);
    message.forced_product_description !== undefined &&
      (obj.forced_product_description = message.forced_product_description);
    message.forced_product_price !== undefined && (obj.forced_product_price = message.forced_product_price);
    message.forced_product_rating !== undefined && (obj.forced_product_rating = message.forced_product_rating);
    message.forced_product_reviews_count !== undefined &&
      (obj.forced_product_reviews_count = message.forced_product_reviews_count);
    message.skip_alternatives !== undefined && (obj.skip_alternatives = message.skip_alternatives);
    message.evaluations_request_id !== undefined && (obj.evaluations_request_id = message.evaluations_request_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsRecognitionRequest>, I>>(base?: I): ProductsRecognitionRequest {
    return ProductsRecognitionRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsRecognitionRequest>, I>>(object: I): ProductsRecognitionRequest {
    const message = createBaseProductsRecognitionRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.image = (object.image !== undefined && object.image !== null) ? Image.fromPartial(object.image) : undefined;
    message.forced_ingredients = object.forced_ingredients ?? "";
    message.user_photo_id = object.user_photo_id ?? "";
    message.ocr_result = (object.ocr_result !== undefined && object.ocr_result !== null)
      ? ProductsRecognitionRequest_OCR.fromPartial(object.ocr_result)
      : undefined;
    message.forced_product_text = object.forced_product_text ?? "";
    message.search_variants_count = object.search_variants_count ?? 0;
    message.forced_product_url = object.forced_product_url ?? "";
    message.forced_product_brand = object.forced_product_brand ?? "";
    message.forced_product_name = object.forced_product_name ?? "";
    message.forced_product_image = object.forced_product_image ?? "";
    message.forced_product_step = object.forced_product_step ?? "";
    message.forced_product_description = object.forced_product_description ?? "";
    message.forced_product_price = object.forced_product_price ?? "";
    message.forced_product_rating = object.forced_product_rating ?? "";
    message.forced_product_reviews_count = object.forced_product_reviews_count ?? "";
    message.skip_alternatives = object.skip_alternatives ?? false;
    message.evaluations_request_id = object.evaluations_request_id ?? "";
    return message;
  },
};

function createBaseProductsRecognitionRequest_OCR(): ProductsRecognitionRequest_OCR {
  return { lines: [], recognition_duration: 0, barcode: undefined };
}

export const ProductsRecognitionRequest_OCR = {
  fromJSON(object: any): ProductsRecognitionRequest_OCR {
    return {
      lines: Array.isArray(object?.lines)
        ? object.lines.map((e: any) => ProductsRecognitionRequest_OCR_Line.fromJSON(e))
        : [],
      recognition_duration: isSet(object.recognition_duration) ? Number(object.recognition_duration) : 0,
      barcode: isSet(object.barcode) ? ProductsRecognitionRequest_OCR_Barcode.fromJSON(object.barcode) : undefined,
    };
  },

  toJSON(message: ProductsRecognitionRequest_OCR): unknown {
    const obj: any = {};
    if (message.lines) {
      obj.lines = message.lines.map((e) => e ? ProductsRecognitionRequest_OCR_Line.toJSON(e) : undefined);
    } else {
      obj.lines = [];
    }
    message.recognition_duration !== undefined && (obj.recognition_duration = message.recognition_duration);
    message.barcode !== undefined &&
      (obj.barcode = message.barcode ? ProductsRecognitionRequest_OCR_Barcode.toJSON(message.barcode) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsRecognitionRequest_OCR>, I>>(base?: I): ProductsRecognitionRequest_OCR {
    return ProductsRecognitionRequest_OCR.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsRecognitionRequest_OCR>, I>>(
    object: I,
  ): ProductsRecognitionRequest_OCR {
    const message = createBaseProductsRecognitionRequest_OCR();
    message.lines = object.lines?.map((e) => ProductsRecognitionRequest_OCR_Line.fromPartial(e)) || [];
    message.recognition_duration = object.recognition_duration ?? 0;
    message.barcode = (object.barcode !== undefined && object.barcode !== null)
      ? ProductsRecognitionRequest_OCR_Barcode.fromPartial(object.barcode)
      : undefined;
    return message;
  },
};

function createBaseProductsRecognitionRequest_OCR_Rect(): ProductsRecognitionRequest_OCR_Rect {
  return { x: 0, y: 0, width: 0, height: 0 };
}

export const ProductsRecognitionRequest_OCR_Rect = {
  fromJSON(object: any): ProductsRecognitionRequest_OCR_Rect {
    return {
      x: isSet(object.x) ? Number(object.x) : 0,
      y: isSet(object.y) ? Number(object.y) : 0,
      width: isSet(object.width) ? Number(object.width) : 0,
      height: isSet(object.height) ? Number(object.height) : 0,
    };
  },

  toJSON(message: ProductsRecognitionRequest_OCR_Rect): unknown {
    const obj: any = {};
    message.x !== undefined && (obj.x = message.x);
    message.y !== undefined && (obj.y = message.y);
    message.width !== undefined && (obj.width = message.width);
    message.height !== undefined && (obj.height = message.height);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsRecognitionRequest_OCR_Rect>, I>>(
    base?: I,
  ): ProductsRecognitionRequest_OCR_Rect {
    return ProductsRecognitionRequest_OCR_Rect.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsRecognitionRequest_OCR_Rect>, I>>(
    object: I,
  ): ProductsRecognitionRequest_OCR_Rect {
    const message = createBaseProductsRecognitionRequest_OCR_Rect();
    message.x = object.x ?? 0;
    message.y = object.y ?? 0;
    message.width = object.width ?? 0;
    message.height = object.height ?? 0;
    return message;
  },
};

function createBaseProductsRecognitionRequest_OCR_Line(): ProductsRecognitionRequest_OCR_Line {
  return { text: "", confidence: 0, bounding_box: undefined, cluster_id: 0 };
}

export const ProductsRecognitionRequest_OCR_Line = {
  fromJSON(object: any): ProductsRecognitionRequest_OCR_Line {
    return {
      text: isSet(object.text) ? String(object.text) : "",
      confidence: isSet(object.confidence) ? Number(object.confidence) : 0,
      bounding_box: isSet(object.bounding_box)
        ? ProductsRecognitionRequest_OCR_Rect.fromJSON(object.bounding_box)
        : undefined,
      cluster_id: isSet(object.cluster_id) ? Number(object.cluster_id) : 0,
    };
  },

  toJSON(message: ProductsRecognitionRequest_OCR_Line): unknown {
    const obj: any = {};
    message.text !== undefined && (obj.text = message.text);
    message.confidence !== undefined && (obj.confidence = message.confidence);
    message.bounding_box !== undefined && (obj.bounding_box = message.bounding_box
      ? ProductsRecognitionRequest_OCR_Rect.toJSON(message.bounding_box)
      : undefined);
    message.cluster_id !== undefined && (obj.cluster_id = Math.round(message.cluster_id));
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsRecognitionRequest_OCR_Line>, I>>(
    base?: I,
  ): ProductsRecognitionRequest_OCR_Line {
    return ProductsRecognitionRequest_OCR_Line.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsRecognitionRequest_OCR_Line>, I>>(
    object: I,
  ): ProductsRecognitionRequest_OCR_Line {
    const message = createBaseProductsRecognitionRequest_OCR_Line();
    message.text = object.text ?? "";
    message.confidence = object.confidence ?? 0;
    message.bounding_box = (object.bounding_box !== undefined && object.bounding_box !== null)
      ? ProductsRecognitionRequest_OCR_Rect.fromPartial(object.bounding_box)
      : undefined;
    message.cluster_id = object.cluster_id ?? 0;
    return message;
  },
};

function createBaseProductsRecognitionRequest_OCR_Barcode(): ProductsRecognitionRequest_OCR_Barcode {
  return { code: "", symbology: "", recognition_duration: 0 };
}

export const ProductsRecognitionRequest_OCR_Barcode = {
  fromJSON(object: any): ProductsRecognitionRequest_OCR_Barcode {
    return {
      code: isSet(object.code) ? String(object.code) : "",
      symbology: isSet(object.symbology) ? String(object.symbology) : "",
      recognition_duration: isSet(object.recognition_duration) ? Number(object.recognition_duration) : 0,
    };
  },

  toJSON(message: ProductsRecognitionRequest_OCR_Barcode): unknown {
    const obj: any = {};
    message.code !== undefined && (obj.code = message.code);
    message.symbology !== undefined && (obj.symbology = message.symbology);
    message.recognition_duration !== undefined && (obj.recognition_duration = message.recognition_duration);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsRecognitionRequest_OCR_Barcode>, I>>(
    base?: I,
  ): ProductsRecognitionRequest_OCR_Barcode {
    return ProductsRecognitionRequest_OCR_Barcode.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsRecognitionRequest_OCR_Barcode>, I>>(
    object: I,
  ): ProductsRecognitionRequest_OCR_Barcode {
    const message = createBaseProductsRecognitionRequest_OCR_Barcode();
    message.code = object.code ?? "";
    message.symbology = object.symbology ?? "";
    message.recognition_duration = object.recognition_duration ?? 0;
    return message;
  },
};

function createBaseProductsRecognitionRequests(): ProductsRecognitionRequests {
  return { requests: [] };
}

export const ProductsRecognitionRequests = {
  fromJSON(object: any): ProductsRecognitionRequests {
    return {
      requests: Array.isArray(object?.requests)
        ? object.requests.map((e: any) => ProductsRecognitionRequest.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductsRecognitionRequests): unknown {
    const obj: any = {};
    if (message.requests) {
      obj.requests = message.requests.map((e) => e ? ProductsRecognitionRequest.toJSON(e) : undefined);
    } else {
      obj.requests = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsRecognitionRequests>, I>>(base?: I): ProductsRecognitionRequests {
    return ProductsRecognitionRequests.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsRecognitionRequests>, I>>(object: I): ProductsRecognitionRequests {
    const message = createBaseProductsRecognitionRequests();
    message.requests = object.requests?.map((e) => ProductsRecognitionRequest.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductRecognitionError(): ProductRecognitionError {
  return {
    error_title: "",
    error_text: "",
    user_action: ProductRecognitionError_UserAction.USER_ACTION_NOTHING,
    next_step_request: undefined,
  };
}

export const ProductRecognitionError = {
  fromJSON(object: any): ProductRecognitionError {
    return {
      error_title: isSet(object.error_title) ? String(object.error_title) : "",
      error_text: isSet(object.error_text) ? String(object.error_text) : "",
      user_action: isSet(object.user_action)
        ? productRecognitionError_UserActionFromJSON(object.user_action)
        : ProductRecognitionError_UserAction.USER_ACTION_NOTHING,
      next_step_request: isSet(object.next_step_request)
        ? ProductRecognitionErrorNextStepRequest.fromJSON(object.next_step_request)
        : undefined,
    };
  },

  toJSON(message: ProductRecognitionError): unknown {
    const obj: any = {};
    message.error_title !== undefined && (obj.error_title = message.error_title);
    message.error_text !== undefined && (obj.error_text = message.error_text);
    message.user_action !== undefined &&
      (obj.user_action = productRecognitionError_UserActionToJSON(message.user_action));
    message.next_step_request !== undefined && (obj.next_step_request = message.next_step_request
      ? ProductRecognitionErrorNextStepRequest.toJSON(message.next_step_request)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductRecognitionError>, I>>(base?: I): ProductRecognitionError {
    return ProductRecognitionError.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductRecognitionError>, I>>(object: I): ProductRecognitionError {
    const message = createBaseProductRecognitionError();
    message.error_title = object.error_title ?? "";
    message.error_text = object.error_text ?? "";
    message.user_action = object.user_action ?? ProductRecognitionError_UserAction.USER_ACTION_NOTHING;
    message.next_step_request = (object.next_step_request !== undefined && object.next_step_request !== null)
      ? ProductRecognitionErrorNextStepRequest.fromPartial(object.next_step_request)
      : undefined;
    return message;
  },
};

function createBaseProductRecognitionErrorNextStepRequest(): ProductRecognitionErrorNextStepRequest {
  return { request: undefined, product_scan_id: "" };
}

export const ProductRecognitionErrorNextStepRequest = {
  fromJSON(object: any): ProductRecognitionErrorNextStepRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      product_scan_id: isSet(object.product_scan_id) ? String(object.product_scan_id) : "",
    };
  },

  toJSON(message: ProductRecognitionErrorNextStepRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    message.product_scan_id !== undefined && (obj.product_scan_id = message.product_scan_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductRecognitionErrorNextStepRequest>, I>>(
    base?: I,
  ): ProductRecognitionErrorNextStepRequest {
    return ProductRecognitionErrorNextStepRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductRecognitionErrorNextStepRequest>, I>>(
    object: I,
  ): ProductRecognitionErrorNextStepRequest {
    const message = createBaseProductRecognitionErrorNextStepRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.product_scan_id = object.product_scan_id ?? "";
    return message;
  },
};

function createBaseProductRecognitionErrorNextStepResponse(): ProductRecognitionErrorNextStepResponse {
  return { action: undefined };
}

export const ProductRecognitionErrorNextStepResponse = {
  fromJSON(object: any): ProductRecognitionErrorNextStepResponse {
    return { action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined };
  },

  toJSON(message: ProductRecognitionErrorNextStepResponse): unknown {
    const obj: any = {};
    message.action !== undefined && (obj.action = message.action ? ActionType.toJSON(message.action) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductRecognitionErrorNextStepResponse>, I>>(
    base?: I,
  ): ProductRecognitionErrorNextStepResponse {
    return ProductRecognitionErrorNextStepResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductRecognitionErrorNextStepResponse>, I>>(
    object: I,
  ): ProductRecognitionErrorNextStepResponse {
    const message = createBaseProductRecognitionErrorNextStepResponse();
    message.action = (object.action !== undefined && object.action !== null)
      ? ActionType.fromPartial(object.action)
      : undefined;
    return message;
  },
};

function createBaseProductRecognitionDetectedObject(): ProductRecognitionDetectedObject {
  return { name: "", score: 0 };
}

export const ProductRecognitionDetectedObject = {
  fromJSON(object: any): ProductRecognitionDetectedObject {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      score: isSet(object.score) ? Number(object.score) : 0,
    };
  },

  toJSON(message: ProductRecognitionDetectedObject): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.score !== undefined && (obj.score = message.score);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductRecognitionDetectedObject>, I>>(
    base?: I,
  ): ProductRecognitionDetectedObject {
    return ProductRecognitionDetectedObject.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductRecognitionDetectedObject>, I>>(
    object: I,
  ): ProductRecognitionDetectedObject {
    const message = createBaseProductRecognitionDetectedObject();
    message.name = object.name ?? "";
    message.score = object.score ?? 0;
    return message;
  },
};

function createBaseProductsRecognitionResponse(): ProductsRecognitionResponse {
  return { response: undefined, detected_objects: [], search_variants: undefined, request_id: "" };
}

export const ProductsRecognitionResponse = {
  fromJSON(object: any): ProductsRecognitionResponse {
    return {
      response: isSet(object.product_screen)
        ? { $case: "product_screen", product_screen: ProductScreen.fromJSON(object.product_screen) }
        : isSet(object.error)
        ? { $case: "error", error: ProductRecognitionError.fromJSON(object.error) }
        : undefined,
      detected_objects: Array.isArray(object?.detected_objects)
        ? object.detected_objects.map((e: any) => ProductRecognitionDetectedObject.fromJSON(e))
        : [],
      search_variants: isSet(object.search_variants)
        ? ProductsRecognitionResponse_SearchVariants.fromJSON(object.search_variants)
        : undefined,
      request_id: isSet(object.request_id) ? String(object.request_id) : "",
    };
  },

  toJSON(message: ProductsRecognitionResponse): unknown {
    const obj: any = {};
    message.response?.$case === "product_screen" && (obj.product_screen = message.response?.product_screen
      ? ProductScreen.toJSON(message.response?.product_screen)
      : undefined);
    message.response?.$case === "error" &&
      (obj.error = message.response?.error ? ProductRecognitionError.toJSON(message.response?.error) : undefined);
    if (message.detected_objects) {
      obj.detected_objects = message.detected_objects.map((e) =>
        e ? ProductRecognitionDetectedObject.toJSON(e) : undefined
      );
    } else {
      obj.detected_objects = [];
    }
    message.search_variants !== undefined && (obj.search_variants = message.search_variants
      ? ProductsRecognitionResponse_SearchVariants.toJSON(message.search_variants)
      : undefined);
    message.request_id !== undefined && (obj.request_id = message.request_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsRecognitionResponse>, I>>(base?: I): ProductsRecognitionResponse {
    return ProductsRecognitionResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsRecognitionResponse>, I>>(object: I): ProductsRecognitionResponse {
    const message = createBaseProductsRecognitionResponse();
    if (
      object.response?.$case === "product_screen" &&
      object.response?.product_screen !== undefined &&
      object.response?.product_screen !== null
    ) {
      message.response = {
        $case: "product_screen",
        product_screen: ProductScreen.fromPartial(object.response.product_screen),
      };
    }
    if (object.response?.$case === "error" && object.response?.error !== undefined && object.response?.error !== null) {
      message.response = { $case: "error", error: ProductRecognitionError.fromPartial(object.response.error) };
    }
    message.detected_objects = object.detected_objects?.map((e) => ProductRecognitionDetectedObject.fromPartial(e)) ||
      [];
    message.search_variants = (object.search_variants !== undefined && object.search_variants !== null)
      ? ProductsRecognitionResponse_SearchVariants.fromPartial(object.search_variants)
      : undefined;
    message.request_id = object.request_id ?? "";
    return message;
  },
};

function createBaseProductsRecognitionResponse_SearchVariants(): ProductsRecognitionResponse_SearchVariants {
  return { navigation_title: "", items_title: "", items: [], top_button: undefined };
}

export const ProductsRecognitionResponse_SearchVariants = {
  fromJSON(object: any): ProductsRecognitionResponse_SearchVariants {
    return {
      navigation_title: isSet(object.navigation_title) ? String(object.navigation_title) : "",
      items_title: isSet(object.items_title) ? String(object.items_title) : "",
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => ProductCard.fromJSON(e))
        : [],
      top_button: isSet(object.top_button)
        ? ProductsRecognitionResponse_SearchVariants_Button.fromJSON(object.top_button)
        : undefined,
    };
  },

  toJSON(message: ProductsRecognitionResponse_SearchVariants): unknown {
    const obj: any = {};
    message.navigation_title !== undefined && (obj.navigation_title = message.navigation_title);
    message.items_title !== undefined && (obj.items_title = message.items_title);
    if (message.items) {
      obj.items = message.items.map((e) => e ? ProductCard.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    message.top_button !== undefined && (obj.top_button = message.top_button
      ? ProductsRecognitionResponse_SearchVariants_Button.toJSON(message.top_button)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsRecognitionResponse_SearchVariants>, I>>(
    base?: I,
  ): ProductsRecognitionResponse_SearchVariants {
    return ProductsRecognitionResponse_SearchVariants.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsRecognitionResponse_SearchVariants>, I>>(
    object: I,
  ): ProductsRecognitionResponse_SearchVariants {
    const message = createBaseProductsRecognitionResponse_SearchVariants();
    message.navigation_title = object.navigation_title ?? "";
    message.items_title = object.items_title ?? "";
    message.items = object.items?.map((e) => ProductCard.fromPartial(e)) || [];
    message.top_button = (object.top_button !== undefined && object.top_button !== null)
      ? ProductsRecognitionResponse_SearchVariants_Button.fromPartial(object.top_button)
      : undefined;
    return message;
  },
};

function createBaseProductsRecognitionResponse_SearchVariants_Button(): ProductsRecognitionResponse_SearchVariants_Button {
  return { title: "", action: undefined };
}

export const ProductsRecognitionResponse_SearchVariants_Button = {
  fromJSON(object: any): ProductsRecognitionResponse_SearchVariants_Button {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
    };
  },

  toJSON(message: ProductsRecognitionResponse_SearchVariants_Button): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.action !== undefined && (obj.action = message.action ? ActionType.toJSON(message.action) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsRecognitionResponse_SearchVariants_Button>, I>>(
    base?: I,
  ): ProductsRecognitionResponse_SearchVariants_Button {
    return ProductsRecognitionResponse_SearchVariants_Button.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsRecognitionResponse_SearchVariants_Button>, I>>(
    object: I,
  ): ProductsRecognitionResponse_SearchVariants_Button {
    const message = createBaseProductsRecognitionResponse_SearchVariants_Button();
    message.title = object.title ?? "";
    message.action = (object.action !== undefined && object.action !== null)
      ? ActionType.fromPartial(object.action)
      : undefined;
    return message;
  },
};

function createBaseProgramSummaryContent(): ProgramSummaryContent {
  return { title: "", subtitle: "", scan_id: "", list: [], bubble_text: undefined, is_expanded: false };
}

export const ProgramSummaryContent = {
  fromJSON(object: any): ProgramSummaryContent {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      subtitle: isSet(object.subtitle) ? String(object.subtitle) : "",
      scan_id: isSet(object.scan_id) ? String(object.scan_id) : "",
      list: Array.isArray(object?.list) ? object.list.map((e: any) => SummaryListItem.fromJSON(e)) : [],
      bubble_text: isSet(object.bubble_text) ? AttributedString.fromJSON(object.bubble_text) : undefined,
      is_expanded: isSet(object.is_expanded) ? Boolean(object.is_expanded) : false,
    };
  },

  toJSON(message: ProgramSummaryContent): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.subtitle !== undefined && (obj.subtitle = message.subtitle);
    message.scan_id !== undefined && (obj.scan_id = message.scan_id);
    if (message.list) {
      obj.list = message.list.map((e) => e ? SummaryListItem.toJSON(e) : undefined);
    } else {
      obj.list = [];
    }
    message.bubble_text !== undefined &&
      (obj.bubble_text = message.bubble_text ? AttributedString.toJSON(message.bubble_text) : undefined);
    message.is_expanded !== undefined && (obj.is_expanded = message.is_expanded);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProgramSummaryContent>, I>>(base?: I): ProgramSummaryContent {
    return ProgramSummaryContent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProgramSummaryContent>, I>>(object: I): ProgramSummaryContent {
    const message = createBaseProgramSummaryContent();
    message.title = object.title ?? "";
    message.subtitle = object.subtitle ?? "";
    message.scan_id = object.scan_id ?? "";
    message.list = object.list?.map((e) => SummaryListItem.fromPartial(e)) || [];
    message.bubble_text = (object.bubble_text !== undefined && object.bubble_text !== null)
      ? AttributedString.fromPartial(object.bubble_text)
      : undefined;
    message.is_expanded = object.is_expanded ?? false;
    return message;
  },
};

function createBaseProductCard(): ProductCard {
  return {
    qualification: ProductQualification.PRODUCT_QUALIFICATION_NONE,
    qualification_title: "",
    title: "",
    brand: "",
    image: "",
    rating: "",
    tags: [],
    origin_link: "",
    product_id: "",
    product_screen: undefined,
    pora_score: undefined,
    tags_v2: [],
  };
}

export const ProductCard = {
  fromJSON(object: any): ProductCard {
    return {
      qualification: isSet(object.qualification)
        ? productQualificationFromJSON(object.qualification)
        : ProductQualification.PRODUCT_QUALIFICATION_NONE,
      qualification_title: isSet(object.qualification_title) ? String(object.qualification_title) : "",
      title: isSet(object.title) ? String(object.title) : "",
      brand: isSet(object.brand) ? String(object.brand) : "",
      image: isSet(object.image) ? String(object.image) : "",
      rating: isSet(object.rating) ? String(object.rating) : "",
      tags: Array.isArray(object?.tags)
        ? object.tags.map((e: any) => String(e))
        : [],
      origin_link: isSet(object.origin_link) ? String(object.origin_link) : "",
      product_id: isSet(object.product_id) ? String(object.product_id) : "",
      product_screen: isSet(object.product_screen) ? ProductScreen.fromJSON(object.product_screen) : undefined,
      pora_score: isSet(object.pora_score) ? ProductPoraScore.fromJSON(object.pora_score) : undefined,
      tags_v2: Array.isArray(object?.tags_v2)
        ? object.tags_v2.map((e: any) => Tag.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductCard): unknown {
    const obj: any = {};
    message.qualification !== undefined && (obj.qualification = productQualificationToJSON(message.qualification));
    message.qualification_title !== undefined && (obj.qualification_title = message.qualification_title);
    message.title !== undefined && (obj.title = message.title);
    message.brand !== undefined && (obj.brand = message.brand);
    message.image !== undefined && (obj.image = message.image);
    message.rating !== undefined && (obj.rating = message.rating);
    if (message.tags) {
      obj.tags = message.tags.map((e) => e);
    } else {
      obj.tags = [];
    }
    message.origin_link !== undefined && (obj.origin_link = message.origin_link);
    message.product_id !== undefined && (obj.product_id = message.product_id);
    message.product_screen !== undefined &&
      (obj.product_screen = message.product_screen ? ProductScreen.toJSON(message.product_screen) : undefined);
    message.pora_score !== undefined &&
      (obj.pora_score = message.pora_score ? ProductPoraScore.toJSON(message.pora_score) : undefined);
    if (message.tags_v2) {
      obj.tags_v2 = message.tags_v2.map((e) => e ? Tag.toJSON(e) : undefined);
    } else {
      obj.tags_v2 = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductCard>, I>>(base?: I): ProductCard {
    return ProductCard.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductCard>, I>>(object: I): ProductCard {
    const message = createBaseProductCard();
    message.qualification = object.qualification ?? ProductQualification.PRODUCT_QUALIFICATION_NONE;
    message.qualification_title = object.qualification_title ?? "";
    message.title = object.title ?? "";
    message.brand = object.brand ?? "";
    message.image = object.image ?? "";
    message.rating = object.rating ?? "";
    message.tags = object.tags?.map((e) => e) || [];
    message.origin_link = object.origin_link ?? "";
    message.product_id = object.product_id ?? "";
    message.product_screen = (object.product_screen !== undefined && object.product_screen !== null)
      ? ProductScreen.fromPartial(object.product_screen)
      : undefined;
    message.pora_score = (object.pora_score !== undefined && object.pora_score !== null)
      ? ProductPoraScore.fromPartial(object.pora_score)
      : undefined;
    message.tags_v2 = object.tags_v2?.map((e) => Tag.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductsSection(): ProductsSection {
  return { title: "", qualification_title: "", step_title: "", products: [], info_bubble: undefined };
}

export const ProductsSection = {
  fromJSON(object: any): ProductsSection {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      qualification_title: isSet(object.qualification_title) ? String(object.qualification_title) : "",
      step_title: isSet(object.step_title) ? String(object.step_title) : "",
      products: Array.isArray(object?.products) ? object.products.map((e: any) => ProductCard.fromJSON(e)) : [],
      info_bubble: isSet(object.info_bubble) ? ProductsSection_Bubble.fromJSON(object.info_bubble) : undefined,
    };
  },

  toJSON(message: ProductsSection): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.qualification_title !== undefined && (obj.qualification_title = message.qualification_title);
    message.step_title !== undefined && (obj.step_title = message.step_title);
    if (message.products) {
      obj.products = message.products.map((e) => e ? ProductCard.toJSON(e) : undefined);
    } else {
      obj.products = [];
    }
    message.info_bubble !== undefined &&
      (obj.info_bubble = message.info_bubble ? ProductsSection_Bubble.toJSON(message.info_bubble) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsSection>, I>>(base?: I): ProductsSection {
    return ProductsSection.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsSection>, I>>(object: I): ProductsSection {
    const message = createBaseProductsSection();
    message.title = object.title ?? "";
    message.qualification_title = object.qualification_title ?? "";
    message.step_title = object.step_title ?? "";
    message.products = object.products?.map((e) => ProductCard.fromPartial(e)) || [];
    message.info_bubble = (object.info_bubble !== undefined && object.info_bubble !== null)
      ? ProductsSection_Bubble.fromPartial(object.info_bubble)
      : undefined;
    return message;
  },
};

function createBaseProductsSection_Bubble(): ProductsSection_Bubble {
  return { title: undefined, description: undefined, buttons: [] };
}

export const ProductsSection_Bubble = {
  fromJSON(object: any): ProductsSection_Bubble {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      buttons: Array.isArray(object?.buttons)
        ? object.buttons.map((e: any) => ProductsSection_Bubble_Button.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductsSection_Bubble): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.description !== undefined &&
      (obj.description = message.description ? AttributedString.toJSON(message.description) : undefined);
    if (message.buttons) {
      obj.buttons = message.buttons.map((e) => e ? ProductsSection_Bubble_Button.toJSON(e) : undefined);
    } else {
      obj.buttons = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsSection_Bubble>, I>>(base?: I): ProductsSection_Bubble {
    return ProductsSection_Bubble.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsSection_Bubble>, I>>(object: I): ProductsSection_Bubble {
    const message = createBaseProductsSection_Bubble();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.description = (object.description !== undefined && object.description !== null)
      ? AttributedString.fromPartial(object.description)
      : undefined;
    message.buttons = object.buttons?.map((e) => ProductsSection_Bubble_Button.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductsSection_Bubble_Button(): ProductsSection_Bubble_Button {
  return { title: undefined, action: undefined };
}

export const ProductsSection_Bubble_Button = {
  fromJSON(object: any): ProductsSection_Bubble_Button {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
    };
  },

  toJSON(message: ProductsSection_Bubble_Button): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.action !== undefined && (obj.action = message.action ? ActionType.toJSON(message.action) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsSection_Bubble_Button>, I>>(base?: I): ProductsSection_Bubble_Button {
    return ProductsSection_Bubble_Button.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsSection_Bubble_Button>, I>>(
    object: I,
  ): ProductsSection_Bubble_Button {
    const message = createBaseProductsSection_Bubble_Button();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.action = (object.action !== undefined && object.action !== null)
      ? ActionType.fromPartial(object.action)
      : undefined;
    return message;
  },
};

function createBaseProductsSectionsGroup(): ProductsSectionsGroup {
  return { title: "", url_title: "", url: "", sections: [], sections_v2: [] };
}

export const ProductsSectionsGroup = {
  fromJSON(object: any): ProductsSectionsGroup {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      url_title: isSet(object.url_title) ? String(object.url_title) : "",
      url: isSet(object.url) ? String(object.url) : "",
      sections: Array.isArray(object?.sections) ? object.sections.map((e: any) => ProductsSection.fromJSON(e)) : [],
      sections_v2: Array.isArray(object?.sections_v2)
        ? object.sections_v2.map((e: any) => ProductsSectionsGroup_Section.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductsSectionsGroup): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.url_title !== undefined && (obj.url_title = message.url_title);
    message.url !== undefined && (obj.url = message.url);
    if (message.sections) {
      obj.sections = message.sections.map((e) => e ? ProductsSection.toJSON(e) : undefined);
    } else {
      obj.sections = [];
    }
    if (message.sections_v2) {
      obj.sections_v2 = message.sections_v2.map((e) => e ? ProductsSectionsGroup_Section.toJSON(e) : undefined);
    } else {
      obj.sections_v2 = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsSectionsGroup>, I>>(base?: I): ProductsSectionsGroup {
    return ProductsSectionsGroup.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsSectionsGroup>, I>>(object: I): ProductsSectionsGroup {
    const message = createBaseProductsSectionsGroup();
    message.title = object.title ?? "";
    message.url_title = object.url_title ?? "";
    message.url = object.url ?? "";
    message.sections = object.sections?.map((e) => ProductsSection.fromPartial(e)) || [];
    message.sections_v2 = object.sections_v2?.map((e) => ProductsSectionsGroup_Section.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductsSectionsGroup_Section(): ProductsSectionsGroup_Section {
  return { section: undefined };
}

export const ProductsSectionsGroup_Section = {
  fromJSON(object: any): ProductsSectionsGroup_Section {
    return {
      section: isSet(object.products)
        ? { $case: "products", products: ProductsSection.fromJSON(object.products) }
        : isSet(object.alternatives)
        ? { $case: "alternatives", alternatives: ProductsSectionsGroup_Alternatives.fromJSON(object.alternatives) }
        : isSet(object.info)
        ? { $case: "info", info: ProductsSectionsGroup_Info.fromJSON(object.info) }
        : undefined,
    };
  },

  toJSON(message: ProductsSectionsGroup_Section): unknown {
    const obj: any = {};
    message.section?.$case === "products" &&
      (obj.products = message.section?.products ? ProductsSection.toJSON(message.section?.products) : undefined);
    message.section?.$case === "alternatives" && (obj.alternatives = message.section?.alternatives
      ? ProductsSectionsGroup_Alternatives.toJSON(message.section?.alternatives)
      : undefined);
    message.section?.$case === "info" &&
      (obj.info = message.section?.info ? ProductsSectionsGroup_Info.toJSON(message.section?.info) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsSectionsGroup_Section>, I>>(base?: I): ProductsSectionsGroup_Section {
    return ProductsSectionsGroup_Section.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsSectionsGroup_Section>, I>>(
    object: I,
  ): ProductsSectionsGroup_Section {
    const message = createBaseProductsSectionsGroup_Section();
    if (
      object.section?.$case === "products" &&
      object.section?.products !== undefined &&
      object.section?.products !== null
    ) {
      message.section = { $case: "products", products: ProductsSection.fromPartial(object.section.products) };
    }
    if (
      object.section?.$case === "alternatives" &&
      object.section?.alternatives !== undefined &&
      object.section?.alternatives !== null
    ) {
      message.section = {
        $case: "alternatives",
        alternatives: ProductsSectionsGroup_Alternatives.fromPartial(object.section.alternatives),
      };
    }
    if (object.section?.$case === "info" && object.section?.info !== undefined && object.section?.info !== null) {
      message.section = { $case: "info", info: ProductsSectionsGroup_Info.fromPartial(object.section.info) };
    }
    return message;
  },
};

function createBaseProductsSectionsGroup_Alternatives(): ProductsSectionsGroup_Alternatives {
  return { id: "", title: undefined, products: [], should_show_section: false };
}

export const ProductsSectionsGroup_Alternatives = {
  fromJSON(object: any): ProductsSectionsGroup_Alternatives {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      products: Array.isArray(object?.products) ? object.products.map((e: any) => ProductCard.fromJSON(e)) : [],
      should_show_section: isSet(object.should_show_section) ? Boolean(object.should_show_section) : false,
    };
  },

  toJSON(message: ProductsSectionsGroup_Alternatives): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    if (message.products) {
      obj.products = message.products.map((e) => e ? ProductCard.toJSON(e) : undefined);
    } else {
      obj.products = [];
    }
    message.should_show_section !== undefined && (obj.should_show_section = message.should_show_section);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsSectionsGroup_Alternatives>, I>>(
    base?: I,
  ): ProductsSectionsGroup_Alternatives {
    return ProductsSectionsGroup_Alternatives.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsSectionsGroup_Alternatives>, I>>(
    object: I,
  ): ProductsSectionsGroup_Alternatives {
    const message = createBaseProductsSectionsGroup_Alternatives();
    message.id = object.id ?? "";
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.products = object.products?.map((e) => ProductCard.fromPartial(e)) || [];
    message.should_show_section = object.should_show_section ?? false;
    return message;
  },
};

function createBaseProductsSectionsGroup_Info(): ProductsSectionsGroup_Info {
  return { title: undefined, bubble_text: undefined };
}

export const ProductsSectionsGroup_Info = {
  fromJSON(object: any): ProductsSectionsGroup_Info {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      bubble_text: isSet(object.bubble_text) ? AttributedString.fromJSON(object.bubble_text) : undefined,
    };
  },

  toJSON(message: ProductsSectionsGroup_Info): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.bubble_text !== undefined &&
      (obj.bubble_text = message.bubble_text ? AttributedString.toJSON(message.bubble_text) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsSectionsGroup_Info>, I>>(base?: I): ProductsSectionsGroup_Info {
    return ProductsSectionsGroup_Info.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsSectionsGroup_Info>, I>>(object: I): ProductsSectionsGroup_Info {
    const message = createBaseProductsSectionsGroup_Info();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.bubble_text = (object.bubble_text !== undefined && object.bubble_text !== null)
      ? AttributedString.fromPartial(object.bubble_text)
      : undefined;
    return message;
  },
};

function createBaseProductsListScreen(): ProductsListScreen {
  return {
    summary: undefined,
    section_groups: [],
    title: "",
    segments: [],
    selected_segment_index: 0,
    bottom_button_title: "",
    is_close_button_available: false,
  };
}

export const ProductsListScreen = {
  fromJSON(object: any): ProductsListScreen {
    return {
      summary: isSet(object.summary) ? ProgramSummaryContent.fromJSON(object.summary) : undefined,
      section_groups: Array.isArray(object?.section_groups)
        ? object.section_groups.map((e: any) => ProductsSectionsGroup.fromJSON(e))
        : [],
      title: isSet(object.title) ? String(object.title) : "",
      segments: Array.isArray(object?.segments)
        ? object.segments.map((e: any) => ProductsListScreen_Segment.fromJSON(e))
        : [],
      selected_segment_index: isSet(object.selected_segment_index) ? Number(object.selected_segment_index) : 0,
      bottom_button_title: isSet(object.bottom_button_title) ? String(object.bottom_button_title) : "",
      is_close_button_available: isSet(object.is_close_button_available)
        ? Boolean(object.is_close_button_available)
        : false,
    };
  },

  toJSON(message: ProductsListScreen): unknown {
    const obj: any = {};
    message.summary !== undefined &&
      (obj.summary = message.summary ? ProgramSummaryContent.toJSON(message.summary) : undefined);
    if (message.section_groups) {
      obj.section_groups = message.section_groups.map((e) => e ? ProductsSectionsGroup.toJSON(e) : undefined);
    } else {
      obj.section_groups = [];
    }
    message.title !== undefined && (obj.title = message.title);
    if (message.segments) {
      obj.segments = message.segments.map((e) => e ? ProductsListScreen_Segment.toJSON(e) : undefined);
    } else {
      obj.segments = [];
    }
    message.selected_segment_index !== undefined &&
      (obj.selected_segment_index = Math.round(message.selected_segment_index));
    message.bottom_button_title !== undefined && (obj.bottom_button_title = message.bottom_button_title);
    message.is_close_button_available !== undefined &&
      (obj.is_close_button_available = message.is_close_button_available);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsListScreen>, I>>(base?: I): ProductsListScreen {
    return ProductsListScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsListScreen>, I>>(object: I): ProductsListScreen {
    const message = createBaseProductsListScreen();
    message.summary = (object.summary !== undefined && object.summary !== null)
      ? ProgramSummaryContent.fromPartial(object.summary)
      : undefined;
    message.section_groups = object.section_groups?.map((e) => ProductsSectionsGroup.fromPartial(e)) || [];
    message.title = object.title ?? "";
    message.segments = object.segments?.map((e) => ProductsListScreen_Segment.fromPartial(e)) || [];
    message.selected_segment_index = object.selected_segment_index ?? 0;
    message.bottom_button_title = object.bottom_button_title ?? "";
    message.is_close_button_available = object.is_close_button_available ?? false;
    return message;
  },
};

function createBaseProductsListScreen_Segment(): ProductsListScreen_Segment {
  return { title: undefined, image: "", section_groups: [] };
}

export const ProductsListScreen_Segment = {
  fromJSON(object: any): ProductsListScreen_Segment {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      image: isSet(object.image) ? String(object.image) : "",
      section_groups: Array.isArray(object?.section_groups)
        ? object.section_groups.map((e: any) => ProductsSectionsGroup.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductsListScreen_Segment): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.image !== undefined && (obj.image = message.image);
    if (message.section_groups) {
      obj.section_groups = message.section_groups.map((e) => e ? ProductsSectionsGroup.toJSON(e) : undefined);
    } else {
      obj.section_groups = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsListScreen_Segment>, I>>(base?: I): ProductsListScreen_Segment {
    return ProductsListScreen_Segment.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsListScreen_Segment>, I>>(object: I): ProductsListScreen_Segment {
    const message = createBaseProductsListScreen_Segment();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.image = object.image ?? "";
    message.section_groups = object.section_groups?.map((e) => ProductsSectionsGroup.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductsRecommendationsScreen(): ProductsRecommendationsScreen {
  return { section_groups: [] };
}

export const ProductsRecommendationsScreen = {
  fromJSON(object: any): ProductsRecommendationsScreen {
    return {
      section_groups: Array.isArray(object?.section_groups)
        ? object.section_groups.map((e: any) => ProductsSectionsGroup.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductsRecommendationsScreen): unknown {
    const obj: any = {};
    if (message.section_groups) {
      obj.section_groups = message.section_groups.map((e) => e ? ProductsSectionsGroup.toJSON(e) : undefined);
    } else {
      obj.section_groups = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsRecommendationsScreen>, I>>(base?: I): ProductsRecommendationsScreen {
    return ProductsRecommendationsScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsRecommendationsScreen>, I>>(
    object: I,
  ): ProductsRecommendationsScreen {
    const message = createBaseProductsRecommendationsScreen();
    message.section_groups = object.section_groups?.map((e) => ProductsSectionsGroup.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductKeyFunctionInfoScreen(): ProductKeyFunctionInfoScreen {
  return {
    function_type: ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_NONE,
    title: undefined,
    subtitle: "",
    description: "",
  };
}

export const ProductKeyFunctionInfoScreen = {
  fromJSON(object: any): ProductKeyFunctionInfoScreen {
    return {
      function_type: isSet(object.function_type)
        ? productKeyFunctionTypeFromJSON(object.function_type)
        : ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_NONE,
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      subtitle: isSet(object.subtitle) ? String(object.subtitle) : "",
      description: isSet(object.description) ? String(object.description) : "",
    };
  },

  toJSON(message: ProductKeyFunctionInfoScreen): unknown {
    const obj: any = {};
    message.function_type !== undefined && (obj.function_type = productKeyFunctionTypeToJSON(message.function_type));
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.subtitle !== undefined && (obj.subtitle = message.subtitle);
    message.description !== undefined && (obj.description = message.description);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductKeyFunctionInfoScreen>, I>>(base?: I): ProductKeyFunctionInfoScreen {
    return ProductKeyFunctionInfoScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductKeyFunctionInfoScreen>, I>>(object: I): ProductKeyFunctionInfoScreen {
    const message = createBaseProductKeyFunctionInfoScreen();
    message.function_type = object.function_type ?? ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_NONE;
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.subtitle = object.subtitle ?? "";
    message.description = object.description ?? "";
    return message;
  },
};

function createBaseProductIngredientsListInfoScreen(): ProductIngredientsListInfoScreen {
  return { screen_title: "", product_title: "", product_image: "", sections: [] };
}

export const ProductIngredientsListInfoScreen = {
  fromJSON(object: any): ProductIngredientsListInfoScreen {
    return {
      screen_title: isSet(object.screen_title) ? String(object.screen_title) : "",
      product_title: isSet(object.product_title) ? String(object.product_title) : "",
      product_image: isSet(object.product_image) ? String(object.product_image) : "",
      sections: Array.isArray(object?.sections)
        ? object.sections.map((e: any) => ProductIngredientsListInfoScreen_Section.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductIngredientsListInfoScreen): unknown {
    const obj: any = {};
    message.screen_title !== undefined && (obj.screen_title = message.screen_title);
    message.product_title !== undefined && (obj.product_title = message.product_title);
    message.product_image !== undefined && (obj.product_image = message.product_image);
    if (message.sections) {
      obj.sections = message.sections.map((e) => e ? ProductIngredientsListInfoScreen_Section.toJSON(e) : undefined);
    } else {
      obj.sections = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductIngredientsListInfoScreen>, I>>(
    base?: I,
  ): ProductIngredientsListInfoScreen {
    return ProductIngredientsListInfoScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductIngredientsListInfoScreen>, I>>(
    object: I,
  ): ProductIngredientsListInfoScreen {
    const message = createBaseProductIngredientsListInfoScreen();
    message.screen_title = object.screen_title ?? "";
    message.product_title = object.product_title ?? "";
    message.product_image = object.product_image ?? "";
    message.sections = object.sections?.map((e) => ProductIngredientsListInfoScreen_Section.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductIngredientsListInfoScreen_Section(): ProductIngredientsListInfoScreen_Section {
  return { content: undefined };
}

export const ProductIngredientsListInfoScreen_Section = {
  fromJSON(object: any): ProductIngredientsListInfoScreen_Section {
    return {
      content: isSet(object.content)
        ? ProductIngredientsListInfoScreen_Section_Content.fromJSON(object.content)
        : undefined,
    };
  },

  toJSON(message: ProductIngredientsListInfoScreen_Section): unknown {
    const obj: any = {};
    message.content !== undefined && (obj.content = message.content
      ? ProductIngredientsListInfoScreen_Section_Content.toJSON(message.content)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductIngredientsListInfoScreen_Section>, I>>(
    base?: I,
  ): ProductIngredientsListInfoScreen_Section {
    return ProductIngredientsListInfoScreen_Section.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductIngredientsListInfoScreen_Section>, I>>(
    object: I,
  ): ProductIngredientsListInfoScreen_Section {
    const message = createBaseProductIngredientsListInfoScreen_Section();
    message.content = (object.content !== undefined && object.content !== null)
      ? ProductIngredientsListInfoScreen_Section_Content.fromPartial(object.content)
      : undefined;
    return message;
  },
};

function createBaseProductIngredientsListInfoScreen_Section_Content(): ProductIngredientsListInfoScreen_Section_Content {
  return { content: undefined };
}

export const ProductIngredientsListInfoScreen_Section_Content = {
  fromJSON(object: any): ProductIngredientsListInfoScreen_Section_Content {
    return {
      content: isSet(object.ingredients)
        ? {
          $case: "ingredients",
          ingredients: ProductIngredientsListInfoScreen_Section_Content_Ingredients.fromJSON(object.ingredients),
        }
        : isSet(object.info)
        ? { $case: "info", info: ProductIngredientsListInfoScreen_Section_Content_Info.fromJSON(object.info) }
        : undefined,
    };
  },

  toJSON(message: ProductIngredientsListInfoScreen_Section_Content): unknown {
    const obj: any = {};
    message.content?.$case === "ingredients" && (obj.ingredients = message.content?.ingredients
      ? ProductIngredientsListInfoScreen_Section_Content_Ingredients.toJSON(message.content?.ingredients)
      : undefined);
    message.content?.$case === "info" && (obj.info = message.content?.info
      ? ProductIngredientsListInfoScreen_Section_Content_Info.toJSON(message.content?.info)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductIngredientsListInfoScreen_Section_Content>, I>>(
    base?: I,
  ): ProductIngredientsListInfoScreen_Section_Content {
    return ProductIngredientsListInfoScreen_Section_Content.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductIngredientsListInfoScreen_Section_Content>, I>>(
    object: I,
  ): ProductIngredientsListInfoScreen_Section_Content {
    const message = createBaseProductIngredientsListInfoScreen_Section_Content();
    if (
      object.content?.$case === "ingredients" &&
      object.content?.ingredients !== undefined &&
      object.content?.ingredients !== null
    ) {
      message.content = {
        $case: "ingredients",
        ingredients: ProductIngredientsListInfoScreen_Section_Content_Ingredients.fromPartial(
          object.content.ingredients,
        ),
      };
    }
    if (object.content?.$case === "info" && object.content?.info !== undefined && object.content?.info !== null) {
      message.content = {
        $case: "info",
        info: ProductIngredientsListInfoScreen_Section_Content_Info.fromPartial(object.content.info),
      };
    }
    return message;
  },
};

function createBaseProductIngredientsListInfoScreen_Section_Content_Info(): ProductIngredientsListInfoScreen_Section_Content_Info {
  return { title: "", subtitle: "", description: undefined };
}

export const ProductIngredientsListInfoScreen_Section_Content_Info = {
  fromJSON(object: any): ProductIngredientsListInfoScreen_Section_Content_Info {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      subtitle: isSet(object.subtitle) ? String(object.subtitle) : "",
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
    };
  },

  toJSON(message: ProductIngredientsListInfoScreen_Section_Content_Info): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.subtitle !== undefined && (obj.subtitle = message.subtitle);
    message.description !== undefined &&
      (obj.description = message.description ? AttributedString.toJSON(message.description) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductIngredientsListInfoScreen_Section_Content_Info>, I>>(
    base?: I,
  ): ProductIngredientsListInfoScreen_Section_Content_Info {
    return ProductIngredientsListInfoScreen_Section_Content_Info.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductIngredientsListInfoScreen_Section_Content_Info>, I>>(
    object: I,
  ): ProductIngredientsListInfoScreen_Section_Content_Info {
    const message = createBaseProductIngredientsListInfoScreen_Section_Content_Info();
    message.title = object.title ?? "";
    message.subtitle = object.subtitle ?? "";
    message.description = (object.description !== undefined && object.description !== null)
      ? AttributedString.fromPartial(object.description)
      : undefined;
    return message;
  },
};

function createBaseProductIngredientsListInfoScreen_Section_Content_Ingredients(): ProductIngredientsListInfoScreen_Section_Content_Ingredients {
  return { ingredients: [] };
}

export const ProductIngredientsListInfoScreen_Section_Content_Ingredients = {
  fromJSON(object: any): ProductIngredientsListInfoScreen_Section_Content_Ingredients {
    return {
      ingredients: Array.isArray(object?.ingredients)
        ? object.ingredients.map((e: any) => ProductIngredientsListInfoScreen_Section_Content_Ingredient.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductIngredientsListInfoScreen_Section_Content_Ingredients): unknown {
    const obj: any = {};
    if (message.ingredients) {
      obj.ingredients = message.ingredients.map((e) =>
        e ? ProductIngredientsListInfoScreen_Section_Content_Ingredient.toJSON(e) : undefined
      );
    } else {
      obj.ingredients = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductIngredientsListInfoScreen_Section_Content_Ingredients>, I>>(
    base?: I,
  ): ProductIngredientsListInfoScreen_Section_Content_Ingredients {
    return ProductIngredientsListInfoScreen_Section_Content_Ingredients.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductIngredientsListInfoScreen_Section_Content_Ingredients>, I>>(
    object: I,
  ): ProductIngredientsListInfoScreen_Section_Content_Ingredients {
    const message = createBaseProductIngredientsListInfoScreen_Section_Content_Ingredients();
    message.ingredients =
      object.ingredients?.map((e) => ProductIngredientsListInfoScreen_Section_Content_Ingredient.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductIngredientsListInfoScreen_Section_Content_Ingredient(): ProductIngredientsListInfoScreen_Section_Content_Ingredient {
  return { title: "", info_screen: undefined, risk_title: "", risk_color: undefined };
}

export const ProductIngredientsListInfoScreen_Section_Content_Ingredient = {
  fromJSON(object: any): ProductIngredientsListInfoScreen_Section_Content_Ingredient {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      info_screen: isSet(object.info_screen) ? ProductIngredientInfoScreen.fromJSON(object.info_screen) : undefined,
      risk_title: isSet(object.risk_title) ? String(object.risk_title) : "",
      risk_color: isSet(object.risk_color) ? Color.fromJSON(object.risk_color) : undefined,
    };
  },

  toJSON(message: ProductIngredientsListInfoScreen_Section_Content_Ingredient): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.info_screen !== undefined &&
      (obj.info_screen = message.info_screen ? ProductIngredientInfoScreen.toJSON(message.info_screen) : undefined);
    message.risk_title !== undefined && (obj.risk_title = message.risk_title);
    message.risk_color !== undefined &&
      (obj.risk_color = message.risk_color ? Color.toJSON(message.risk_color) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductIngredientsListInfoScreen_Section_Content_Ingredient>, I>>(
    base?: I,
  ): ProductIngredientsListInfoScreen_Section_Content_Ingredient {
    return ProductIngredientsListInfoScreen_Section_Content_Ingredient.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductIngredientsListInfoScreen_Section_Content_Ingredient>, I>>(
    object: I,
  ): ProductIngredientsListInfoScreen_Section_Content_Ingredient {
    const message = createBaseProductIngredientsListInfoScreen_Section_Content_Ingredient();
    message.title = object.title ?? "";
    message.info_screen = (object.info_screen !== undefined && object.info_screen !== null)
      ? ProductIngredientInfoScreen.fromPartial(object.info_screen)
      : undefined;
    message.risk_title = object.risk_title ?? "";
    message.risk_color = (object.risk_color !== undefined && object.risk_color !== null)
      ? Color.fromPartial(object.risk_color)
      : undefined;
    return message;
  },
};

function createBaseProductIngredientInfoScreen(): ProductIngredientInfoScreen {
  return { title: "", subtitle: "", description_title: "", description: "", sections: [] };
}

export const ProductIngredientInfoScreen = {
  fromJSON(object: any): ProductIngredientInfoScreen {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      subtitle: isSet(object.subtitle) ? String(object.subtitle) : "",
      description_title: isSet(object.description_title) ? String(object.description_title) : "",
      description: isSet(object.description) ? String(object.description) : "",
      sections: Array.isArray(object?.sections)
        ? object.sections.map((e: any) => ProductIngredientInfoScreen_Section.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductIngredientInfoScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.subtitle !== undefined && (obj.subtitle = message.subtitle);
    message.description_title !== undefined && (obj.description_title = message.description_title);
    message.description !== undefined && (obj.description = message.description);
    if (message.sections) {
      obj.sections = message.sections.map((e) => e ? ProductIngredientInfoScreen_Section.toJSON(e) : undefined);
    } else {
      obj.sections = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductIngredientInfoScreen>, I>>(base?: I): ProductIngredientInfoScreen {
    return ProductIngredientInfoScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductIngredientInfoScreen>, I>>(object: I): ProductIngredientInfoScreen {
    const message = createBaseProductIngredientInfoScreen();
    message.title = object.title ?? "";
    message.subtitle = object.subtitle ?? "";
    message.description_title = object.description_title ?? "";
    message.description = object.description ?? "";
    message.sections = object.sections?.map((e) => ProductIngredientInfoScreen_Section.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductIngredientInfoScreen_Section(): ProductIngredientInfoScreen_Section {
  return { section: undefined };
}

export const ProductIngredientInfoScreen_Section = {
  fromJSON(object: any): ProductIngredientInfoScreen_Section {
    return {
      section: isSet(object.header_tags)
        ? {
          $case: "header_tags",
          header_tags: ProductIngredientInfoScreen_Section_HeaderTags.fromJSON(object.header_tags),
        }
        : isSet(object.health_risks)
        ? {
          $case: "health_risks",
          health_risks: ProductIngredientInfoScreen_Section_HealthRisks.fromJSON(object.health_risks),
        }
        : isSet(object.details)
        ? { $case: "details", details: ProductIngredientInfoScreen_Section_Details.fromJSON(object.details) }
        : isSet(object.scientific_sources)
        ? {
          $case: "scientific_sources",
          scientific_sources: ProductIngredientInfoScreen_Section_ScientificSources.fromJSON(object.scientific_sources),
        }
        : undefined,
    };
  },

  toJSON(message: ProductIngredientInfoScreen_Section): unknown {
    const obj: any = {};
    message.section?.$case === "header_tags" && (obj.header_tags = message.section?.header_tags
      ? ProductIngredientInfoScreen_Section_HeaderTags.toJSON(message.section?.header_tags)
      : undefined);
    message.section?.$case === "health_risks" && (obj.health_risks = message.section?.health_risks
      ? ProductIngredientInfoScreen_Section_HealthRisks.toJSON(message.section?.health_risks)
      : undefined);
    message.section?.$case === "details" && (obj.details = message.section?.details
      ? ProductIngredientInfoScreen_Section_Details.toJSON(message.section?.details)
      : undefined);
    message.section?.$case === "scientific_sources" && (obj.scientific_sources = message.section?.scientific_sources
      ? ProductIngredientInfoScreen_Section_ScientificSources.toJSON(message.section?.scientific_sources)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductIngredientInfoScreen_Section>, I>>(
    base?: I,
  ): ProductIngredientInfoScreen_Section {
    return ProductIngredientInfoScreen_Section.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductIngredientInfoScreen_Section>, I>>(
    object: I,
  ): ProductIngredientInfoScreen_Section {
    const message = createBaseProductIngredientInfoScreen_Section();
    if (
      object.section?.$case === "header_tags" &&
      object.section?.header_tags !== undefined &&
      object.section?.header_tags !== null
    ) {
      message.section = {
        $case: "header_tags",
        header_tags: ProductIngredientInfoScreen_Section_HeaderTags.fromPartial(object.section.header_tags),
      };
    }
    if (
      object.section?.$case === "health_risks" &&
      object.section?.health_risks !== undefined &&
      object.section?.health_risks !== null
    ) {
      message.section = {
        $case: "health_risks",
        health_risks: ProductIngredientInfoScreen_Section_HealthRisks.fromPartial(object.section.health_risks),
      };
    }
    if (
      object.section?.$case === "details" && object.section?.details !== undefined && object.section?.details !== null
    ) {
      message.section = {
        $case: "details",
        details: ProductIngredientInfoScreen_Section_Details.fromPartial(object.section.details),
      };
    }
    if (
      object.section?.$case === "scientific_sources" &&
      object.section?.scientific_sources !== undefined &&
      object.section?.scientific_sources !== null
    ) {
      message.section = {
        $case: "scientific_sources",
        scientific_sources: ProductIngredientInfoScreen_Section_ScientificSources.fromPartial(
          object.section.scientific_sources,
        ),
      };
    }
    return message;
  },
};

function createBaseProductIngredientInfoScreen_Section_HeaderTags(): ProductIngredientInfoScreen_Section_HeaderTags {
  return { title: undefined, header_tags: [] };
}

export const ProductIngredientInfoScreen_Section_HeaderTags = {
  fromJSON(object: any): ProductIngredientInfoScreen_Section_HeaderTags {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      header_tags: Array.isArray(object?.header_tags)
        ? object.header_tags.map((e: any) => Tag.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductIngredientInfoScreen_Section_HeaderTags): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    if (message.header_tags) {
      obj.header_tags = message.header_tags.map((e) => e ? Tag.toJSON(e) : undefined);
    } else {
      obj.header_tags = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductIngredientInfoScreen_Section_HeaderTags>, I>>(
    base?: I,
  ): ProductIngredientInfoScreen_Section_HeaderTags {
    return ProductIngredientInfoScreen_Section_HeaderTags.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductIngredientInfoScreen_Section_HeaderTags>, I>>(
    object: I,
  ): ProductIngredientInfoScreen_Section_HeaderTags {
    const message = createBaseProductIngredientInfoScreen_Section_HeaderTags();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.header_tags = object.header_tags?.map((e) => Tag.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductIngredientInfoScreen_Section_HealthRisks(): ProductIngredientInfoScreen_Section_HealthRisks {
  return { title: undefined, health_risks: [] };
}

export const ProductIngredientInfoScreen_Section_HealthRisks = {
  fromJSON(object: any): ProductIngredientInfoScreen_Section_HealthRisks {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      health_risks: Array.isArray(object?.health_risks)
        ? object.health_risks.map((e: any) => ProductIngredientInfoScreen_Section_HealthRisks_HealthRisk.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductIngredientInfoScreen_Section_HealthRisks): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    if (message.health_risks) {
      obj.health_risks = message.health_risks.map((e) =>
        e ? ProductIngredientInfoScreen_Section_HealthRisks_HealthRisk.toJSON(e) : undefined
      );
    } else {
      obj.health_risks = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductIngredientInfoScreen_Section_HealthRisks>, I>>(
    base?: I,
  ): ProductIngredientInfoScreen_Section_HealthRisks {
    return ProductIngredientInfoScreen_Section_HealthRisks.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductIngredientInfoScreen_Section_HealthRisks>, I>>(
    object: I,
  ): ProductIngredientInfoScreen_Section_HealthRisks {
    const message = createBaseProductIngredientInfoScreen_Section_HealthRisks();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.health_risks =
      object.health_risks?.map((e) => ProductIngredientInfoScreen_Section_HealthRisks_HealthRisk.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductIngredientInfoScreen_Section_HealthRisks_HealthRisk(): ProductIngredientInfoScreen_Section_HealthRisks_HealthRisk {
  return { icon: "", title: "" };
}

export const ProductIngredientInfoScreen_Section_HealthRisks_HealthRisk = {
  fromJSON(object: any): ProductIngredientInfoScreen_Section_HealthRisks_HealthRisk {
    return {
      icon: isSet(object.icon) ? String(object.icon) : "",
      title: isSet(object.title) ? String(object.title) : "",
    };
  },

  toJSON(message: ProductIngredientInfoScreen_Section_HealthRisks_HealthRisk): unknown {
    const obj: any = {};
    message.icon !== undefined && (obj.icon = message.icon);
    message.title !== undefined && (obj.title = message.title);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductIngredientInfoScreen_Section_HealthRisks_HealthRisk>, I>>(
    base?: I,
  ): ProductIngredientInfoScreen_Section_HealthRisks_HealthRisk {
    return ProductIngredientInfoScreen_Section_HealthRisks_HealthRisk.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductIngredientInfoScreen_Section_HealthRisks_HealthRisk>, I>>(
    object: I,
  ): ProductIngredientInfoScreen_Section_HealthRisks_HealthRisk {
    const message = createBaseProductIngredientInfoScreen_Section_HealthRisks_HealthRisk();
    message.icon = object.icon ?? "";
    message.title = object.title ?? "";
    return message;
  },
};

function createBaseProductIngredientInfoScreen_Section_Details(): ProductIngredientInfoScreen_Section_Details {
  return { title: undefined, description: "", line_limit: 0, is_expanded: false };
}

export const ProductIngredientInfoScreen_Section_Details = {
  fromJSON(object: any): ProductIngredientInfoScreen_Section_Details {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? String(object.description) : "",
      line_limit: isSet(object.line_limit) ? Number(object.line_limit) : 0,
      is_expanded: isSet(object.is_expanded) ? Boolean(object.is_expanded) : false,
    };
  },

  toJSON(message: ProductIngredientInfoScreen_Section_Details): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.description !== undefined && (obj.description = message.description);
    message.line_limit !== undefined && (obj.line_limit = Math.round(message.line_limit));
    message.is_expanded !== undefined && (obj.is_expanded = message.is_expanded);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductIngredientInfoScreen_Section_Details>, I>>(
    base?: I,
  ): ProductIngredientInfoScreen_Section_Details {
    return ProductIngredientInfoScreen_Section_Details.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductIngredientInfoScreen_Section_Details>, I>>(
    object: I,
  ): ProductIngredientInfoScreen_Section_Details {
    const message = createBaseProductIngredientInfoScreen_Section_Details();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.description = object.description ?? "";
    message.line_limit = object.line_limit ?? 0;
    message.is_expanded = object.is_expanded ?? false;
    return message;
  },
};

function createBaseProductIngredientInfoScreen_Section_ScientificSources(): ProductIngredientInfoScreen_Section_ScientificSources {
  return { title: undefined, scientific_sources: [] };
}

export const ProductIngredientInfoScreen_Section_ScientificSources = {
  fromJSON(object: any): ProductIngredientInfoScreen_Section_ScientificSources {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      scientific_sources: Array.isArray(object?.scientific_sources)
        ? object.scientific_sources.map((e: any) =>
          ProductIngredientInfoScreen_Section_ScientificSources_ScientificSource.fromJSON(e)
        )
        : [],
    };
  },

  toJSON(message: ProductIngredientInfoScreen_Section_ScientificSources): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    if (message.scientific_sources) {
      obj.scientific_sources = message.scientific_sources.map((e) =>
        e ? ProductIngredientInfoScreen_Section_ScientificSources_ScientificSource.toJSON(e) : undefined
      );
    } else {
      obj.scientific_sources = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductIngredientInfoScreen_Section_ScientificSources>, I>>(
    base?: I,
  ): ProductIngredientInfoScreen_Section_ScientificSources {
    return ProductIngredientInfoScreen_Section_ScientificSources.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductIngredientInfoScreen_Section_ScientificSources>, I>>(
    object: I,
  ): ProductIngredientInfoScreen_Section_ScientificSources {
    const message = createBaseProductIngredientInfoScreen_Section_ScientificSources();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.scientific_sources =
      object.scientific_sources?.map((e) =>
        ProductIngredientInfoScreen_Section_ScientificSources_ScientificSource.fromPartial(e)
      ) || [];
    return message;
  },
};

function createBaseProductIngredientInfoScreen_Section_ScientificSources_ScientificSource(): ProductIngredientInfoScreen_Section_ScientificSources_ScientificSource {
  return { title: "", link: undefined };
}

export const ProductIngredientInfoScreen_Section_ScientificSources_ScientificSource = {
  fromJSON(object: any): ProductIngredientInfoScreen_Section_ScientificSources_ScientificSource {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      link: isSet(object.link) ? AttributedString.fromJSON(object.link) : undefined,
    };
  },

  toJSON(message: ProductIngredientInfoScreen_Section_ScientificSources_ScientificSource): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.link !== undefined && (obj.link = message.link ? AttributedString.toJSON(message.link) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductIngredientInfoScreen_Section_ScientificSources_ScientificSource>, I>>(
    base?: I,
  ): ProductIngredientInfoScreen_Section_ScientificSources_ScientificSource {
    return ProductIngredientInfoScreen_Section_ScientificSources_ScientificSource.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductIngredientInfoScreen_Section_ScientificSources_ScientificSource>, I>>(
    object: I,
  ): ProductIngredientInfoScreen_Section_ScientificSources_ScientificSource {
    const message = createBaseProductIngredientInfoScreen_Section_ScientificSources_ScientificSource();
    message.title = object.title ?? "";
    message.link = (object.link !== undefined && object.link !== null)
      ? AttributedString.fromPartial(object.link)
      : undefined;
    return message;
  },
};

function createBaseProductInfoScreen(): ProductInfoScreen {
  return { title: undefined, subtitle: undefined, description: undefined };
}

export const ProductInfoScreen = {
  fromJSON(object: any): ProductInfoScreen {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      subtitle: isSet(object.subtitle) ? AttributedString.fromJSON(object.subtitle) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
    };
  },

  toJSON(message: ProductInfoScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.subtitle !== undefined &&
      (obj.subtitle = message.subtitle ? AttributedString.toJSON(message.subtitle) : undefined);
    message.description !== undefined &&
      (obj.description = message.description ? AttributedString.toJSON(message.description) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductInfoScreen>, I>>(base?: I): ProductInfoScreen {
    return ProductInfoScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductInfoScreen>, I>>(object: I): ProductInfoScreen {
    const message = createBaseProductInfoScreen();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.subtitle = (object.subtitle !== undefined && object.subtitle !== null)
      ? AttributedString.fromPartial(object.subtitle)
      : undefined;
    message.description = (object.description !== undefined && object.description !== null)
      ? AttributedString.fromPartial(object.description)
      : undefined;
    return message;
  },
};

function createBaseProductStepInfoScreen(): ProductStepInfoScreen {
  return { nav_bar: undefined, header: undefined, shortcuts: undefined, sections: [] };
}

export const ProductStepInfoScreen = {
  fromJSON(object: any): ProductStepInfoScreen {
    return {
      nav_bar: isSet(object.nav_bar) ? ProductStepInfoScreen_NavBar.fromJSON(object.nav_bar) : undefined,
      header: isSet(object.header) ? ProductStepInfoScreen_Header.fromJSON(object.header) : undefined,
      shortcuts: isSet(object.shortcuts) ? ProductStepInfoScreen_Shortcuts.fromJSON(object.shortcuts) : undefined,
      sections: Array.isArray(object?.sections)
        ? object.sections.map((e: any) => ProductStepInfoScreen_Section.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductStepInfoScreen): unknown {
    const obj: any = {};
    message.nav_bar !== undefined &&
      (obj.nav_bar = message.nav_bar ? ProductStepInfoScreen_NavBar.toJSON(message.nav_bar) : undefined);
    message.header !== undefined &&
      (obj.header = message.header ? ProductStepInfoScreen_Header.toJSON(message.header) : undefined);
    message.shortcuts !== undefined &&
      (obj.shortcuts = message.shortcuts ? ProductStepInfoScreen_Shortcuts.toJSON(message.shortcuts) : undefined);
    if (message.sections) {
      obj.sections = message.sections.map((e) => e ? ProductStepInfoScreen_Section.toJSON(e) : undefined);
    } else {
      obj.sections = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductStepInfoScreen>, I>>(base?: I): ProductStepInfoScreen {
    return ProductStepInfoScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductStepInfoScreen>, I>>(object: I): ProductStepInfoScreen {
    const message = createBaseProductStepInfoScreen();
    message.nav_bar = (object.nav_bar !== undefined && object.nav_bar !== null)
      ? ProductStepInfoScreen_NavBar.fromPartial(object.nav_bar)
      : undefined;
    message.header = (object.header !== undefined && object.header !== null)
      ? ProductStepInfoScreen_Header.fromPartial(object.header)
      : undefined;
    message.shortcuts = (object.shortcuts !== undefined && object.shortcuts !== null)
      ? ProductStepInfoScreen_Shortcuts.fromPartial(object.shortcuts)
      : undefined;
    message.sections = object.sections?.map((e) => ProductStepInfoScreen_Section.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductStepInfoScreen_NavBar(): ProductStepInfoScreen_NavBar {
  return { title: "", image: "" };
}

export const ProductStepInfoScreen_NavBar = {
  fromJSON(object: any): ProductStepInfoScreen_NavBar {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      image: isSet(object.image) ? String(object.image) : "",
    };
  },

  toJSON(message: ProductStepInfoScreen_NavBar): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.image !== undefined && (obj.image = message.image);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductStepInfoScreen_NavBar>, I>>(base?: I): ProductStepInfoScreen_NavBar {
    return ProductStepInfoScreen_NavBar.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductStepInfoScreen_NavBar>, I>>(object: I): ProductStepInfoScreen_NavBar {
    const message = createBaseProductStepInfoScreen_NavBar();
    message.title = object.title ?? "";
    message.image = object.image ?? "";
    return message;
  },
};

function createBaseProductStepInfoScreen_Header(): ProductStepInfoScreen_Header {
  return { title: "", scale: undefined };
}

export const ProductStepInfoScreen_Header = {
  fromJSON(object: any): ProductStepInfoScreen_Header {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      scale: isSet(object.scale) ? ProductStepInfoScreen_Header_Scale.fromJSON(object.scale) : undefined,
    };
  },

  toJSON(message: ProductStepInfoScreen_Header): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.scale !== undefined &&
      (obj.scale = message.scale ? ProductStepInfoScreen_Header_Scale.toJSON(message.scale) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductStepInfoScreen_Header>, I>>(base?: I): ProductStepInfoScreen_Header {
    return ProductStepInfoScreen_Header.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductStepInfoScreen_Header>, I>>(object: I): ProductStepInfoScreen_Header {
    const message = createBaseProductStepInfoScreen_Header();
    message.title = object.title ?? "";
    message.scale = (object.scale !== undefined && object.scale !== null)
      ? ProductStepInfoScreen_Header_Scale.fromPartial(object.scale)
      : undefined;
    return message;
  },
};

function createBaseProductStepInfoScreen_Header_Scale(): ProductStepInfoScreen_Header_Scale {
  return { steps_count: 0, filled_steps_count: 0, value: "" };
}

export const ProductStepInfoScreen_Header_Scale = {
  fromJSON(object: any): ProductStepInfoScreen_Header_Scale {
    return {
      steps_count: isSet(object.steps_count) ? Number(object.steps_count) : 0,
      filled_steps_count: isSet(object.filled_steps_count) ? Number(object.filled_steps_count) : 0,
      value: isSet(object.value) ? String(object.value) : "",
    };
  },

  toJSON(message: ProductStepInfoScreen_Header_Scale): unknown {
    const obj: any = {};
    message.steps_count !== undefined && (obj.steps_count = Math.round(message.steps_count));
    message.filled_steps_count !== undefined && (obj.filled_steps_count = Math.round(message.filled_steps_count));
    message.value !== undefined && (obj.value = message.value);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductStepInfoScreen_Header_Scale>, I>>(
    base?: I,
  ): ProductStepInfoScreen_Header_Scale {
    return ProductStepInfoScreen_Header_Scale.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductStepInfoScreen_Header_Scale>, I>>(
    object: I,
  ): ProductStepInfoScreen_Header_Scale {
    const message = createBaseProductStepInfoScreen_Header_Scale();
    message.steps_count = object.steps_count ?? 0;
    message.filled_steps_count = object.filled_steps_count ?? 0;
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseProductStepInfoScreen_Shortcuts(): ProductStepInfoScreen_Shortcuts {
  return { shortcuts: [] };
}

export const ProductStepInfoScreen_Shortcuts = {
  fromJSON(object: any): ProductStepInfoScreen_Shortcuts {
    return {
      shortcuts: Array.isArray(object?.shortcuts)
        ? object.shortcuts.map((e: any) => ProductStepInfoScreen_Shortcuts_Shortcut.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductStepInfoScreen_Shortcuts): unknown {
    const obj: any = {};
    if (message.shortcuts) {
      obj.shortcuts = message.shortcuts.map((e) => e ? ProductStepInfoScreen_Shortcuts_Shortcut.toJSON(e) : undefined);
    } else {
      obj.shortcuts = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductStepInfoScreen_Shortcuts>, I>>(base?: I): ProductStepInfoScreen_Shortcuts {
    return ProductStepInfoScreen_Shortcuts.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductStepInfoScreen_Shortcuts>, I>>(
    object: I,
  ): ProductStepInfoScreen_Shortcuts {
    const message = createBaseProductStepInfoScreen_Shortcuts();
    message.shortcuts = object.shortcuts?.map((e) => ProductStepInfoScreen_Shortcuts_Shortcut.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductStepInfoScreen_Shortcuts_Shortcut(): ProductStepInfoScreen_Shortcuts_Shortcut {
  return { title: "", target_section_index: 0 };
}

export const ProductStepInfoScreen_Shortcuts_Shortcut = {
  fromJSON(object: any): ProductStepInfoScreen_Shortcuts_Shortcut {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      target_section_index: isSet(object.target_section_index) ? Number(object.target_section_index) : 0,
    };
  },

  toJSON(message: ProductStepInfoScreen_Shortcuts_Shortcut): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.target_section_index !== undefined && (obj.target_section_index = Math.round(message.target_section_index));
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductStepInfoScreen_Shortcuts_Shortcut>, I>>(
    base?: I,
  ): ProductStepInfoScreen_Shortcuts_Shortcut {
    return ProductStepInfoScreen_Shortcuts_Shortcut.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductStepInfoScreen_Shortcuts_Shortcut>, I>>(
    object: I,
  ): ProductStepInfoScreen_Shortcuts_Shortcut {
    const message = createBaseProductStepInfoScreen_Shortcuts_Shortcut();
    message.title = object.title ?? "";
    message.target_section_index = object.target_section_index ?? 0;
    return message;
  },
};

function createBaseProductStepInfoScreen_Section(): ProductStepInfoScreen_Section {
  return { section: undefined };
}

export const ProductStepInfoScreen_Section = {
  fromJSON(object: any): ProductStepInfoScreen_Section {
    return {
      section: isSet(object.ingredients)
        ? { $case: "ingredients", ingredients: ProductScreen_Section_Ingredients.fromJSON(object.ingredients) }
        : isSet(object.info)
        ? { $case: "info", info: ProductStepInfoScreen_Section_Info.fromJSON(object.info) }
        : undefined,
    };
  },

  toJSON(message: ProductStepInfoScreen_Section): unknown {
    const obj: any = {};
    message.section?.$case === "ingredients" && (obj.ingredients = message.section?.ingredients
      ? ProductScreen_Section_Ingredients.toJSON(message.section?.ingredients)
      : undefined);
    message.section?.$case === "info" &&
      (obj.info = message.section?.info ? ProductStepInfoScreen_Section_Info.toJSON(message.section?.info) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductStepInfoScreen_Section>, I>>(base?: I): ProductStepInfoScreen_Section {
    return ProductStepInfoScreen_Section.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductStepInfoScreen_Section>, I>>(
    object: I,
  ): ProductStepInfoScreen_Section {
    const message = createBaseProductStepInfoScreen_Section();
    if (
      object.section?.$case === "ingredients" &&
      object.section?.ingredients !== undefined &&
      object.section?.ingredients !== null
    ) {
      message.section = {
        $case: "ingredients",
        ingredients: ProductScreen_Section_Ingredients.fromPartial(object.section.ingredients),
      };
    }
    if (object.section?.$case === "info" && object.section?.info !== undefined && object.section?.info !== null) {
      message.section = { $case: "info", info: ProductStepInfoScreen_Section_Info.fromPartial(object.section.info) };
    }
    return message;
  },
};

function createBaseProductStepInfoScreen_Section_Info(): ProductStepInfoScreen_Section_Info {
  return { title: "", info: undefined, line_limit: 0, lazy_request_data: undefined };
}

export const ProductStepInfoScreen_Section_Info = {
  fromJSON(object: any): ProductStepInfoScreen_Section_Info {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      info: isSet(object.info) ? AttributedString.fromJSON(object.info) : undefined,
      line_limit: isSet(object.line_limit) ? Number(object.line_limit) : 0,
      lazy_request_data: isSet(object.lazy_request_data)
        ? LazyProductStepInfoSectionRequestData.fromJSON(object.lazy_request_data)
        : undefined,
    };
  },

  toJSON(message: ProductStepInfoScreen_Section_Info): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.info !== undefined && (obj.info = message.info ? AttributedString.toJSON(message.info) : undefined);
    message.line_limit !== undefined && (obj.line_limit = Math.round(message.line_limit));
    message.lazy_request_data !== undefined && (obj.lazy_request_data = message.lazy_request_data
      ? LazyProductStepInfoSectionRequestData.toJSON(message.lazy_request_data)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductStepInfoScreen_Section_Info>, I>>(
    base?: I,
  ): ProductStepInfoScreen_Section_Info {
    return ProductStepInfoScreen_Section_Info.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductStepInfoScreen_Section_Info>, I>>(
    object: I,
  ): ProductStepInfoScreen_Section_Info {
    const message = createBaseProductStepInfoScreen_Section_Info();
    message.title = object.title ?? "";
    message.info = (object.info !== undefined && object.info !== null)
      ? AttributedString.fromPartial(object.info)
      : undefined;
    message.line_limit = object.line_limit ?? 0;
    message.lazy_request_data = (object.lazy_request_data !== undefined && object.lazy_request_data !== null)
      ? LazyProductStepInfoSectionRequestData.fromPartial(object.lazy_request_data)
      : undefined;
    return message;
  },
};

function createBaseProductAlternativesScreen(): ProductAlternativesScreen {
  return { title: "", products: [], lazy_request: undefined };
}

export const ProductAlternativesScreen = {
  fromJSON(object: any): ProductAlternativesScreen {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      products: Array.isArray(object?.products)
        ? object.products.map((e: any) => ProductAlternativesScreen_Product.fromJSON(e))
        : [],
      lazy_request: isSet(object.lazy_request)
        ? ProductScreenAlternativesRequest.fromJSON(object.lazy_request)
        : undefined,
    };
  },

  toJSON(message: ProductAlternativesScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    if (message.products) {
      obj.products = message.products.map((e) => e ? ProductAlternativesScreen_Product.toJSON(e) : undefined);
    } else {
      obj.products = [];
    }
    message.lazy_request !== undefined && (obj.lazy_request = message.lazy_request
      ? ProductScreenAlternativesRequest.toJSON(message.lazy_request)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductAlternativesScreen>, I>>(base?: I): ProductAlternativesScreen {
    return ProductAlternativesScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductAlternativesScreen>, I>>(object: I): ProductAlternativesScreen {
    const message = createBaseProductAlternativesScreen();
    message.title = object.title ?? "";
    message.products = object.products?.map((e) => ProductAlternativesScreen_Product.fromPartial(e)) || [];
    message.lazy_request = (object.lazy_request !== undefined && object.lazy_request !== null)
      ? ProductScreenAlternativesRequest.fromPartial(object.lazy_request)
      : undefined;
    return message;
  },
};

function createBaseProductAlternativesScreen_Product(): ProductAlternativesScreen_Product {
  return {
    brand: "",
    title: "",
    price: "",
    image: "",
    tags: [],
    product_screen: undefined,
    pora_score: undefined,
    product_id: "",
  };
}

export const ProductAlternativesScreen_Product = {
  fromJSON(object: any): ProductAlternativesScreen_Product {
    return {
      brand: isSet(object.brand) ? String(object.brand) : "",
      title: isSet(object.title) ? String(object.title) : "",
      price: isSet(object.price) ? String(object.price) : "",
      image: isSet(object.image) ? String(object.image) : "",
      tags: Array.isArray(object?.tags)
        ? object.tags.map((e: any) => Tag.fromJSON(e))
        : [],
      product_screen: isSet(object.product_screen) ? ProductScreen.fromJSON(object.product_screen) : undefined,
      pora_score: isSet(object.pora_score) ? ProductPoraScore.fromJSON(object.pora_score) : undefined,
      product_id: isSet(object.product_id) ? String(object.product_id) : "",
    };
  },

  toJSON(message: ProductAlternativesScreen_Product): unknown {
    const obj: any = {};
    message.brand !== undefined && (obj.brand = message.brand);
    message.title !== undefined && (obj.title = message.title);
    message.price !== undefined && (obj.price = message.price);
    message.image !== undefined && (obj.image = message.image);
    if (message.tags) {
      obj.tags = message.tags.map((e) => e ? Tag.toJSON(e) : undefined);
    } else {
      obj.tags = [];
    }
    message.product_screen !== undefined &&
      (obj.product_screen = message.product_screen ? ProductScreen.toJSON(message.product_screen) : undefined);
    message.pora_score !== undefined &&
      (obj.pora_score = message.pora_score ? ProductPoraScore.toJSON(message.pora_score) : undefined);
    message.product_id !== undefined && (obj.product_id = message.product_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductAlternativesScreen_Product>, I>>(
    base?: I,
  ): ProductAlternativesScreen_Product {
    return ProductAlternativesScreen_Product.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductAlternativesScreen_Product>, I>>(
    object: I,
  ): ProductAlternativesScreen_Product {
    const message = createBaseProductAlternativesScreen_Product();
    message.brand = object.brand ?? "";
    message.title = object.title ?? "";
    message.price = object.price ?? "";
    message.image = object.image ?? "";
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.product_screen = (object.product_screen !== undefined && object.product_screen !== null)
      ? ProductScreen.fromPartial(object.product_screen)
      : undefined;
    message.pora_score = (object.pora_score !== undefined && object.pora_score !== null)
      ? ProductPoraScore.fromPartial(object.pora_score)
      : undefined;
    message.product_id = object.product_id ?? "";
    return message;
  },
};

function createBaseProductStoresInfoScreen(): ProductStoresInfoScreen {
  return { title: "", stores: [] };
}

export const ProductStoresInfoScreen = {
  fromJSON(object: any): ProductStoresInfoScreen {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      stores: Array.isArray(object?.stores)
        ? object.stores.map((e: any) => ProductStoresInfoScreen_Store.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductStoresInfoScreen): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    if (message.stores) {
      obj.stores = message.stores.map((e) => e ? ProductStoresInfoScreen_Store.toJSON(e) : undefined);
    } else {
      obj.stores = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductStoresInfoScreen>, I>>(base?: I): ProductStoresInfoScreen {
    return ProductStoresInfoScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductStoresInfoScreen>, I>>(object: I): ProductStoresInfoScreen {
    const message = createBaseProductStoresInfoScreen();
    message.title = object.title ?? "";
    message.stores = object.stores?.map((e) => ProductStoresInfoScreen_Store.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductStoresInfoScreen_Store(): ProductStoresInfoScreen_Store {
  return { image_url: "", name: "", price: "", web_page_url: "", info: undefined };
}

export const ProductStoresInfoScreen_Store = {
  fromJSON(object: any): ProductStoresInfoScreen_Store {
    return {
      image_url: isSet(object.image_url) ? String(object.image_url) : "",
      name: isSet(object.name) ? String(object.name) : "",
      price: isSet(object.price) ? String(object.price) : "",
      web_page_url: isSet(object.web_page_url) ? String(object.web_page_url) : "",
      info: isSet(object.info) ? ProductStoresInfoScreen_Store_Info.fromJSON(object.info) : undefined,
    };
  },

  toJSON(message: ProductStoresInfoScreen_Store): unknown {
    const obj: any = {};
    message.image_url !== undefined && (obj.image_url = message.image_url);
    message.name !== undefined && (obj.name = message.name);
    message.price !== undefined && (obj.price = message.price);
    message.web_page_url !== undefined && (obj.web_page_url = message.web_page_url);
    message.info !== undefined &&
      (obj.info = message.info ? ProductStoresInfoScreen_Store_Info.toJSON(message.info) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductStoresInfoScreen_Store>, I>>(base?: I): ProductStoresInfoScreen_Store {
    return ProductStoresInfoScreen_Store.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductStoresInfoScreen_Store>, I>>(
    object: I,
  ): ProductStoresInfoScreen_Store {
    const message = createBaseProductStoresInfoScreen_Store();
    message.image_url = object.image_url ?? "";
    message.name = object.name ?? "";
    message.price = object.price ?? "";
    message.web_page_url = object.web_page_url ?? "";
    message.info = (object.info !== undefined && object.info !== null)
      ? ProductStoresInfoScreen_Store_Info.fromPartial(object.info)
      : undefined;
    return message;
  },
};

function createBaseProductStoresInfoScreen_Store_Info(): ProductStoresInfoScreen_Store_Info {
  return { info: undefined };
}

export const ProductStoresInfoScreen_Store_Info = {
  fromJSON(object: any): ProductStoresInfoScreen_Store_Info {
    return {
      info: isSet(object.rating)
        ? { $case: "rating", rating: ProductStoresInfoScreen_Store_Rating.fromJSON(object.rating) }
        : isSet(object.text)
        ? { $case: "text", text: String(object.text) }
        : undefined,
    };
  },

  toJSON(message: ProductStoresInfoScreen_Store_Info): unknown {
    const obj: any = {};
    message.info?.$case === "rating" &&
      (obj.rating = message.info?.rating
        ? ProductStoresInfoScreen_Store_Rating.toJSON(message.info?.rating)
        : undefined);
    message.info?.$case === "text" && (obj.text = message.info?.text);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductStoresInfoScreen_Store_Info>, I>>(
    base?: I,
  ): ProductStoresInfoScreen_Store_Info {
    return ProductStoresInfoScreen_Store_Info.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductStoresInfoScreen_Store_Info>, I>>(
    object: I,
  ): ProductStoresInfoScreen_Store_Info {
    const message = createBaseProductStoresInfoScreen_Store_Info();
    if (object.info?.$case === "rating" && object.info?.rating !== undefined && object.info?.rating !== null) {
      message.info = { $case: "rating", rating: ProductStoresInfoScreen_Store_Rating.fromPartial(object.info.rating) };
    }
    if (object.info?.$case === "text" && object.info?.text !== undefined && object.info?.text !== null) {
      message.info = { $case: "text", text: object.info.text };
    }
    return message;
  },
};

function createBaseProductStoresInfoScreen_Store_Rating(): ProductStoresInfoScreen_Store_Rating {
  return { value: "", subtitle: "", fade: false };
}

export const ProductStoresInfoScreen_Store_Rating = {
  fromJSON(object: any): ProductStoresInfoScreen_Store_Rating {
    return {
      value: isSet(object.value) ? String(object.value) : "",
      subtitle: isSet(object.subtitle) ? String(object.subtitle) : "",
      fade: isSet(object.fade) ? Boolean(object.fade) : false,
    };
  },

  toJSON(message: ProductStoresInfoScreen_Store_Rating): unknown {
    const obj: any = {};
    message.value !== undefined && (obj.value = message.value);
    message.subtitle !== undefined && (obj.subtitle = message.subtitle);
    message.fade !== undefined && (obj.fade = message.fade);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductStoresInfoScreen_Store_Rating>, I>>(
    base?: I,
  ): ProductStoresInfoScreen_Store_Rating {
    return ProductStoresInfoScreen_Store_Rating.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductStoresInfoScreen_Store_Rating>, I>>(
    object: I,
  ): ProductStoresInfoScreen_Store_Rating {
    const message = createBaseProductStoresInfoScreen_Store_Rating();
    message.value = object.value ?? "";
    message.subtitle = object.subtitle ?? "";
    message.fade = object.fade ?? false;
    return message;
  },
};

function createBaseProductFitScoreInfoScreen(): ProductFitScoreInfoScreen {
  return { nav_bar: undefined, fit_score: undefined, title: undefined, tags: [], sections: [] };
}

export const ProductFitScoreInfoScreen = {
  fromJSON(object: any): ProductFitScoreInfoScreen {
    return {
      nav_bar: isSet(object.nav_bar) ? ProductFitScoreInfoScreen_NavBar.fromJSON(object.nav_bar) : undefined,
      fit_score: isSet(object.fit_score) ? ProductPoraScore.fromJSON(object.fit_score) : undefined,
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      tags: Array.isArray(object?.tags) ? object.tags.map((e: any) => AttributedString.fromJSON(e)) : [],
      sections: Array.isArray(object?.sections)
        ? object.sections.map((e: any) => ProductFitScoreInfoScreen_Section.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductFitScoreInfoScreen): unknown {
    const obj: any = {};
    message.nav_bar !== undefined &&
      (obj.nav_bar = message.nav_bar ? ProductFitScoreInfoScreen_NavBar.toJSON(message.nav_bar) : undefined);
    message.fit_score !== undefined &&
      (obj.fit_score = message.fit_score ? ProductPoraScore.toJSON(message.fit_score) : undefined);
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    if (message.tags) {
      obj.tags = message.tags.map((e) => e ? AttributedString.toJSON(e) : undefined);
    } else {
      obj.tags = [];
    }
    if (message.sections) {
      obj.sections = message.sections.map((e) => e ? ProductFitScoreInfoScreen_Section.toJSON(e) : undefined);
    } else {
      obj.sections = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductFitScoreInfoScreen>, I>>(base?: I): ProductFitScoreInfoScreen {
    return ProductFitScoreInfoScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductFitScoreInfoScreen>, I>>(object: I): ProductFitScoreInfoScreen {
    const message = createBaseProductFitScoreInfoScreen();
    message.nav_bar = (object.nav_bar !== undefined && object.nav_bar !== null)
      ? ProductFitScoreInfoScreen_NavBar.fromPartial(object.nav_bar)
      : undefined;
    message.fit_score = (object.fit_score !== undefined && object.fit_score !== null)
      ? ProductPoraScore.fromPartial(object.fit_score)
      : undefined;
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.tags = object.tags?.map((e) => AttributedString.fromPartial(e)) || [];
    message.sections = object.sections?.map((e) => ProductFitScoreInfoScreen_Section.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductFitScoreInfoScreen_NavBar(): ProductFitScoreInfoScreen_NavBar {
  return { title: "", image: "" };
}

export const ProductFitScoreInfoScreen_NavBar = {
  fromJSON(object: any): ProductFitScoreInfoScreen_NavBar {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      image: isSet(object.image) ? String(object.image) : "",
    };
  },

  toJSON(message: ProductFitScoreInfoScreen_NavBar): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.image !== undefined && (obj.image = message.image);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductFitScoreInfoScreen_NavBar>, I>>(
    base?: I,
  ): ProductFitScoreInfoScreen_NavBar {
    return ProductFitScoreInfoScreen_NavBar.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductFitScoreInfoScreen_NavBar>, I>>(
    object: I,
  ): ProductFitScoreInfoScreen_NavBar {
    const message = createBaseProductFitScoreInfoScreen_NavBar();
    message.title = object.title ?? "";
    message.image = object.image ?? "";
    return message;
  },
};

function createBaseProductFitScoreInfoScreen_Section(): ProductFitScoreInfoScreen_Section {
  return { section: undefined };
}

export const ProductFitScoreInfoScreen_Section = {
  fromJSON(object: any): ProductFitScoreInfoScreen_Section {
    return {
      section: isSet(object.what_is_score)
        ? {
          $case: "what_is_score",
          what_is_score: ProductFitScoreInfoScreen_Section_WhatIsScore.fromJSON(object.what_is_score),
        }
        : isSet(object.approved_by)
        ? {
          $case: "approved_by",
          approved_by: ProductFitScoreInfoScreen_Section_ApprovedBy.fromJSON(object.approved_by),
        }
        : isSet(object.general_info)
        ? {
          $case: "general_info",
          general_info: ProductFitScoreInfoScreen_Section_GeneralInfo.fromJSON(object.general_info),
        }
        : isSet(object.sources)
        ? { $case: "sources", sources: ProductFitScoreInfoScreen_Section_Sources.fromJSON(object.sources) }
        : isSet(object.example)
        ? { $case: "example", example: ProductFitScoreInfoScreen_Section_Example.fromJSON(object.example) }
        : undefined,
    };
  },

  toJSON(message: ProductFitScoreInfoScreen_Section): unknown {
    const obj: any = {};
    message.section?.$case === "what_is_score" && (obj.what_is_score = message.section?.what_is_score
      ? ProductFitScoreInfoScreen_Section_WhatIsScore.toJSON(message.section?.what_is_score)
      : undefined);
    message.section?.$case === "approved_by" && (obj.approved_by = message.section?.approved_by
      ? ProductFitScoreInfoScreen_Section_ApprovedBy.toJSON(message.section?.approved_by)
      : undefined);
    message.section?.$case === "general_info" && (obj.general_info = message.section?.general_info
      ? ProductFitScoreInfoScreen_Section_GeneralInfo.toJSON(message.section?.general_info)
      : undefined);
    message.section?.$case === "sources" && (obj.sources = message.section?.sources
      ? ProductFitScoreInfoScreen_Section_Sources.toJSON(message.section?.sources)
      : undefined);
    message.section?.$case === "example" && (obj.example = message.section?.example
      ? ProductFitScoreInfoScreen_Section_Example.toJSON(message.section?.example)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductFitScoreInfoScreen_Section>, I>>(
    base?: I,
  ): ProductFitScoreInfoScreen_Section {
    return ProductFitScoreInfoScreen_Section.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductFitScoreInfoScreen_Section>, I>>(
    object: I,
  ): ProductFitScoreInfoScreen_Section {
    const message = createBaseProductFitScoreInfoScreen_Section();
    if (
      object.section?.$case === "what_is_score" &&
      object.section?.what_is_score !== undefined &&
      object.section?.what_is_score !== null
    ) {
      message.section = {
        $case: "what_is_score",
        what_is_score: ProductFitScoreInfoScreen_Section_WhatIsScore.fromPartial(object.section.what_is_score),
      };
    }
    if (
      object.section?.$case === "approved_by" &&
      object.section?.approved_by !== undefined &&
      object.section?.approved_by !== null
    ) {
      message.section = {
        $case: "approved_by",
        approved_by: ProductFitScoreInfoScreen_Section_ApprovedBy.fromPartial(object.section.approved_by),
      };
    }
    if (
      object.section?.$case === "general_info" &&
      object.section?.general_info !== undefined &&
      object.section?.general_info !== null
    ) {
      message.section = {
        $case: "general_info",
        general_info: ProductFitScoreInfoScreen_Section_GeneralInfo.fromPartial(object.section.general_info),
      };
    }
    if (
      object.section?.$case === "sources" && object.section?.sources !== undefined && object.section?.sources !== null
    ) {
      message.section = {
        $case: "sources",
        sources: ProductFitScoreInfoScreen_Section_Sources.fromPartial(object.section.sources),
      };
    }
    if (
      object.section?.$case === "example" && object.section?.example !== undefined && object.section?.example !== null
    ) {
      message.section = {
        $case: "example",
        example: ProductFitScoreInfoScreen_Section_Example.fromPartial(object.section.example),
      };
    }
    return message;
  },
};

function createBaseProductFitScoreInfoScreen_Section_WhatIsScore(): ProductFitScoreInfoScreen_Section_WhatIsScore {
  return { title: undefined, description: undefined, evaluations: [] };
}

export const ProductFitScoreInfoScreen_Section_WhatIsScore = {
  fromJSON(object: any): ProductFitScoreInfoScreen_Section_WhatIsScore {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      evaluations: Array.isArray(object?.evaluations)
        ? object.evaluations.map((e: any) => ProductFitScoreInfoScreen_Section_WhatIsScore_Evaluation.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductFitScoreInfoScreen_Section_WhatIsScore): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.description !== undefined &&
      (obj.description = message.description ? AttributedString.toJSON(message.description) : undefined);
    if (message.evaluations) {
      obj.evaluations = message.evaluations.map((e) =>
        e ? ProductFitScoreInfoScreen_Section_WhatIsScore_Evaluation.toJSON(e) : undefined
      );
    } else {
      obj.evaluations = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductFitScoreInfoScreen_Section_WhatIsScore>, I>>(
    base?: I,
  ): ProductFitScoreInfoScreen_Section_WhatIsScore {
    return ProductFitScoreInfoScreen_Section_WhatIsScore.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductFitScoreInfoScreen_Section_WhatIsScore>, I>>(
    object: I,
  ): ProductFitScoreInfoScreen_Section_WhatIsScore {
    const message = createBaseProductFitScoreInfoScreen_Section_WhatIsScore();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.description = (object.description !== undefined && object.description !== null)
      ? AttributedString.fromPartial(object.description)
      : undefined;
    message.evaluations =
      object.evaluations?.map((e) => ProductFitScoreInfoScreen_Section_WhatIsScore_Evaluation.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductFitScoreInfoScreen_Section_WhatIsScore_Evaluation(): ProductFitScoreInfoScreen_Section_WhatIsScore_Evaluation {
  return { title: undefined, description: undefined, icon: "" };
}

export const ProductFitScoreInfoScreen_Section_WhatIsScore_Evaluation = {
  fromJSON(object: any): ProductFitScoreInfoScreen_Section_WhatIsScore_Evaluation {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      icon: isSet(object.icon) ? String(object.icon) : "",
    };
  },

  toJSON(message: ProductFitScoreInfoScreen_Section_WhatIsScore_Evaluation): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.description !== undefined &&
      (obj.description = message.description ? AttributedString.toJSON(message.description) : undefined);
    message.icon !== undefined && (obj.icon = message.icon);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductFitScoreInfoScreen_Section_WhatIsScore_Evaluation>, I>>(
    base?: I,
  ): ProductFitScoreInfoScreen_Section_WhatIsScore_Evaluation {
    return ProductFitScoreInfoScreen_Section_WhatIsScore_Evaluation.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductFitScoreInfoScreen_Section_WhatIsScore_Evaluation>, I>>(
    object: I,
  ): ProductFitScoreInfoScreen_Section_WhatIsScore_Evaluation {
    const message = createBaseProductFitScoreInfoScreen_Section_WhatIsScore_Evaluation();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.description = (object.description !== undefined && object.description !== null)
      ? AttributedString.fromPartial(object.description)
      : undefined;
    message.icon = object.icon ?? "";
    return message;
  },
};

function createBaseProductFitScoreInfoScreen_Section_ApprovedBy(): ProductFitScoreInfoScreen_Section_ApprovedBy {
  return { title: undefined, description: undefined, avatar_image: "", signature_image: "" };
}

export const ProductFitScoreInfoScreen_Section_ApprovedBy = {
  fromJSON(object: any): ProductFitScoreInfoScreen_Section_ApprovedBy {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      avatar_image: isSet(object.avatar_image) ? String(object.avatar_image) : "",
      signature_image: isSet(object.signature_image) ? String(object.signature_image) : "",
    };
  },

  toJSON(message: ProductFitScoreInfoScreen_Section_ApprovedBy): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.description !== undefined &&
      (obj.description = message.description ? AttributedString.toJSON(message.description) : undefined);
    message.avatar_image !== undefined && (obj.avatar_image = message.avatar_image);
    message.signature_image !== undefined && (obj.signature_image = message.signature_image);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductFitScoreInfoScreen_Section_ApprovedBy>, I>>(
    base?: I,
  ): ProductFitScoreInfoScreen_Section_ApprovedBy {
    return ProductFitScoreInfoScreen_Section_ApprovedBy.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductFitScoreInfoScreen_Section_ApprovedBy>, I>>(
    object: I,
  ): ProductFitScoreInfoScreen_Section_ApprovedBy {
    const message = createBaseProductFitScoreInfoScreen_Section_ApprovedBy();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.description = (object.description !== undefined && object.description !== null)
      ? AttributedString.fromPartial(object.description)
      : undefined;
    message.avatar_image = object.avatar_image ?? "";
    message.signature_image = object.signature_image ?? "";
    return message;
  },
};

function createBaseProductFitScoreInfoScreen_Section_GeneralInfo(): ProductFitScoreInfoScreen_Section_GeneralInfo {
  return { title: undefined, description: undefined };
}

export const ProductFitScoreInfoScreen_Section_GeneralInfo = {
  fromJSON(object: any): ProductFitScoreInfoScreen_Section_GeneralInfo {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
    };
  },

  toJSON(message: ProductFitScoreInfoScreen_Section_GeneralInfo): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.description !== undefined &&
      (obj.description = message.description ? AttributedString.toJSON(message.description) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductFitScoreInfoScreen_Section_GeneralInfo>, I>>(
    base?: I,
  ): ProductFitScoreInfoScreen_Section_GeneralInfo {
    return ProductFitScoreInfoScreen_Section_GeneralInfo.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductFitScoreInfoScreen_Section_GeneralInfo>, I>>(
    object: I,
  ): ProductFitScoreInfoScreen_Section_GeneralInfo {
    const message = createBaseProductFitScoreInfoScreen_Section_GeneralInfo();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.description = (object.description !== undefined && object.description !== null)
      ? AttributedString.fromPartial(object.description)
      : undefined;
    return message;
  },
};

function createBaseProductFitScoreInfoScreen_Section_Sources(): ProductFitScoreInfoScreen_Section_Sources {
  return { title: undefined, description: undefined, sources: [] };
}

export const ProductFitScoreInfoScreen_Section_Sources = {
  fromJSON(object: any): ProductFitScoreInfoScreen_Section_Sources {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      sources: Array.isArray(object?.sources)
        ? object.sources.map((e: any) => ProductFitScoreInfoScreen_Section_Sources_Source.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductFitScoreInfoScreen_Section_Sources): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.description !== undefined &&
      (obj.description = message.description ? AttributedString.toJSON(message.description) : undefined);
    if (message.sources) {
      obj.sources = message.sources.map((e) =>
        e ? ProductFitScoreInfoScreen_Section_Sources_Source.toJSON(e) : undefined
      );
    } else {
      obj.sources = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductFitScoreInfoScreen_Section_Sources>, I>>(
    base?: I,
  ): ProductFitScoreInfoScreen_Section_Sources {
    return ProductFitScoreInfoScreen_Section_Sources.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductFitScoreInfoScreen_Section_Sources>, I>>(
    object: I,
  ): ProductFitScoreInfoScreen_Section_Sources {
    const message = createBaseProductFitScoreInfoScreen_Section_Sources();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.description = (object.description !== undefined && object.description !== null)
      ? AttributedString.fromPartial(object.description)
      : undefined;
    message.sources = object.sources?.map((e) => ProductFitScoreInfoScreen_Section_Sources_Source.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductFitScoreInfoScreen_Section_Sources_Source(): ProductFitScoreInfoScreen_Section_Sources_Source {
  return { image: "", title: undefined, url: "" };
}

export const ProductFitScoreInfoScreen_Section_Sources_Source = {
  fromJSON(object: any): ProductFitScoreInfoScreen_Section_Sources_Source {
    return {
      image: isSet(object.image) ? String(object.image) : "",
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      url: isSet(object.url) ? String(object.url) : "",
    };
  },

  toJSON(message: ProductFitScoreInfoScreen_Section_Sources_Source): unknown {
    const obj: any = {};
    message.image !== undefined && (obj.image = message.image);
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.url !== undefined && (obj.url = message.url);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductFitScoreInfoScreen_Section_Sources_Source>, I>>(
    base?: I,
  ): ProductFitScoreInfoScreen_Section_Sources_Source {
    return ProductFitScoreInfoScreen_Section_Sources_Source.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductFitScoreInfoScreen_Section_Sources_Source>, I>>(
    object: I,
  ): ProductFitScoreInfoScreen_Section_Sources_Source {
    const message = createBaseProductFitScoreInfoScreen_Section_Sources_Source();
    message.image = object.image ?? "";
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.url = object.url ?? "";
    return message;
  },
};

function createBaseProductFitScoreInfoScreen_Section_Example(): ProductFitScoreInfoScreen_Section_Example {
  return { title: undefined, description: undefined, product_card: undefined, score_scale: undefined };
}

export const ProductFitScoreInfoScreen_Section_Example = {
  fromJSON(object: any): ProductFitScoreInfoScreen_Section_Example {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
      product_card: isSet(object.product_card)
        ? ProductFitScoreInfoScreen_Section_Example_ProductCard.fromJSON(object.product_card)
        : undefined,
      score_scale: isSet(object.score_scale)
        ? ProductFitScoreInfoScreen_Section_Example_ScoreScale.fromJSON(object.score_scale)
        : undefined,
    };
  },

  toJSON(message: ProductFitScoreInfoScreen_Section_Example): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.description !== undefined &&
      (obj.description = message.description ? AttributedString.toJSON(message.description) : undefined);
    message.product_card !== undefined && (obj.product_card = message.product_card
      ? ProductFitScoreInfoScreen_Section_Example_ProductCard.toJSON(message.product_card)
      : undefined);
    message.score_scale !== undefined && (obj.score_scale = message.score_scale
      ? ProductFitScoreInfoScreen_Section_Example_ScoreScale.toJSON(message.score_scale)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductFitScoreInfoScreen_Section_Example>, I>>(
    base?: I,
  ): ProductFitScoreInfoScreen_Section_Example {
    return ProductFitScoreInfoScreen_Section_Example.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductFitScoreInfoScreen_Section_Example>, I>>(
    object: I,
  ): ProductFitScoreInfoScreen_Section_Example {
    const message = createBaseProductFitScoreInfoScreen_Section_Example();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.description = (object.description !== undefined && object.description !== null)
      ? AttributedString.fromPartial(object.description)
      : undefined;
    message.product_card = (object.product_card !== undefined && object.product_card !== null)
      ? ProductFitScoreInfoScreen_Section_Example_ProductCard.fromPartial(object.product_card)
      : undefined;
    message.score_scale = (object.score_scale !== undefined && object.score_scale !== null)
      ? ProductFitScoreInfoScreen_Section_Example_ScoreScale.fromPartial(object.score_scale)
      : undefined;
    return message;
  },
};

function createBaseProductFitScoreInfoScreen_Section_Example_ProductCard(): ProductFitScoreInfoScreen_Section_Example_ProductCard {
  return { title: undefined, fit_score: undefined, image: "", description_title: undefined, description: undefined };
}

export const ProductFitScoreInfoScreen_Section_Example_ProductCard = {
  fromJSON(object: any): ProductFitScoreInfoScreen_Section_Example_ProductCard {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      fit_score: isSet(object.fit_score) ? ProductPoraScore.fromJSON(object.fit_score) : undefined,
      image: isSet(object.image) ? String(object.image) : "",
      description_title: isSet(object.description_title)
        ? AttributedString.fromJSON(object.description_title)
        : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
    };
  },

  toJSON(message: ProductFitScoreInfoScreen_Section_Example_ProductCard): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.fit_score !== undefined &&
      (obj.fit_score = message.fit_score ? ProductPoraScore.toJSON(message.fit_score) : undefined);
    message.image !== undefined && (obj.image = message.image);
    message.description_title !== undefined && (obj.description_title = message.description_title
      ? AttributedString.toJSON(message.description_title)
      : undefined);
    message.description !== undefined &&
      (obj.description = message.description ? AttributedString.toJSON(message.description) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductFitScoreInfoScreen_Section_Example_ProductCard>, I>>(
    base?: I,
  ): ProductFitScoreInfoScreen_Section_Example_ProductCard {
    return ProductFitScoreInfoScreen_Section_Example_ProductCard.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductFitScoreInfoScreen_Section_Example_ProductCard>, I>>(
    object: I,
  ): ProductFitScoreInfoScreen_Section_Example_ProductCard {
    const message = createBaseProductFitScoreInfoScreen_Section_Example_ProductCard();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.fit_score = (object.fit_score !== undefined && object.fit_score !== null)
      ? ProductPoraScore.fromPartial(object.fit_score)
      : undefined;
    message.image = object.image ?? "";
    message.description_title = (object.description_title !== undefined && object.description_title !== null)
      ? AttributedString.fromPartial(object.description_title)
      : undefined;
    message.description = (object.description !== undefined && object.description !== null)
      ? AttributedString.fromPartial(object.description)
      : undefined;
    return message;
  },
};

function createBaseProductFitScoreInfoScreen_Section_Example_ScoreScale(): ProductFitScoreInfoScreen_Section_Example_ScoreScale {
  return { segments: [] };
}

export const ProductFitScoreInfoScreen_Section_Example_ScoreScale = {
  fromJSON(object: any): ProductFitScoreInfoScreen_Section_Example_ScoreScale {
    return {
      segments: Array.isArray(object?.segments)
        ? object.segments.map((e: any) => ProductFitScoreInfoScreen_Section_Example_ScoreScale_Segment.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductFitScoreInfoScreen_Section_Example_ScoreScale): unknown {
    const obj: any = {};
    if (message.segments) {
      obj.segments = message.segments.map((e) =>
        e ? ProductFitScoreInfoScreen_Section_Example_ScoreScale_Segment.toJSON(e) : undefined
      );
    } else {
      obj.segments = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductFitScoreInfoScreen_Section_Example_ScoreScale>, I>>(
    base?: I,
  ): ProductFitScoreInfoScreen_Section_Example_ScoreScale {
    return ProductFitScoreInfoScreen_Section_Example_ScoreScale.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductFitScoreInfoScreen_Section_Example_ScoreScale>, I>>(
    object: I,
  ): ProductFitScoreInfoScreen_Section_Example_ScoreScale {
    const message = createBaseProductFitScoreInfoScreen_Section_Example_ScoreScale();
    message.segments =
      object.segments?.map((e) => ProductFitScoreInfoScreen_Section_Example_ScoreScale_Segment.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductFitScoreInfoScreen_Section_Example_ScoreScale_Segment(): ProductFitScoreInfoScreen_Section_Example_ScoreScale_Segment {
  return { color: undefined, label: undefined, value: 0 };
}

export const ProductFitScoreInfoScreen_Section_Example_ScoreScale_Segment = {
  fromJSON(object: any): ProductFitScoreInfoScreen_Section_Example_ScoreScale_Segment {
    return {
      color: isSet(object.color) ? Color.fromJSON(object.color) : undefined,
      label: isSet(object.label) ? AttributedString.fromJSON(object.label) : undefined,
      value: isSet(object.value) ? Number(object.value) : 0,
    };
  },

  toJSON(message: ProductFitScoreInfoScreen_Section_Example_ScoreScale_Segment): unknown {
    const obj: any = {};
    message.color !== undefined && (obj.color = message.color ? Color.toJSON(message.color) : undefined);
    message.label !== undefined && (obj.label = message.label ? AttributedString.toJSON(message.label) : undefined);
    message.value !== undefined && (obj.value = Math.round(message.value));
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductFitScoreInfoScreen_Section_Example_ScoreScale_Segment>, I>>(
    base?: I,
  ): ProductFitScoreInfoScreen_Section_Example_ScoreScale_Segment {
    return ProductFitScoreInfoScreen_Section_Example_ScoreScale_Segment.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductFitScoreInfoScreen_Section_Example_ScoreScale_Segment>, I>>(
    object: I,
  ): ProductFitScoreInfoScreen_Section_Example_ScoreScale_Segment {
    const message = createBaseProductFitScoreInfoScreen_Section_Example_ScoreScale_Segment();
    message.color = (object.color !== undefined && object.color !== null) ? Color.fromPartial(object.color) : undefined;
    message.label = (object.label !== undefined && object.label !== null)
      ? AttributedString.fromPartial(object.label)
      : undefined;
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseProductPoraScoreInfoScreen(): ProductPoraScoreInfoScreen {
  return { nav_bar: undefined, pora_score: undefined, description_title: "", description: undefined };
}

export const ProductPoraScoreInfoScreen = {
  fromJSON(object: any): ProductPoraScoreInfoScreen {
    return {
      nav_bar: isSet(object.nav_bar) ? ProductPoraScoreInfoScreen_NavBar.fromJSON(object.nav_bar) : undefined,
      pora_score: isSet(object.pora_score) ? ProductPoraScore.fromJSON(object.pora_score) : undefined,
      description_title: isSet(object.description_title) ? String(object.description_title) : "",
      description: isSet(object.description)
        ? ProductPoraScoreInfoScreen_Description.fromJSON(object.description)
        : undefined,
    };
  },

  toJSON(message: ProductPoraScoreInfoScreen): unknown {
    const obj: any = {};
    message.nav_bar !== undefined &&
      (obj.nav_bar = message.nav_bar ? ProductPoraScoreInfoScreen_NavBar.toJSON(message.nav_bar) : undefined);
    message.pora_score !== undefined &&
      (obj.pora_score = message.pora_score ? ProductPoraScore.toJSON(message.pora_score) : undefined);
    message.description_title !== undefined && (obj.description_title = message.description_title);
    message.description !== undefined && (obj.description = message.description
      ? ProductPoraScoreInfoScreen_Description.toJSON(message.description)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductPoraScoreInfoScreen>, I>>(base?: I): ProductPoraScoreInfoScreen {
    return ProductPoraScoreInfoScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductPoraScoreInfoScreen>, I>>(object: I): ProductPoraScoreInfoScreen {
    const message = createBaseProductPoraScoreInfoScreen();
    message.nav_bar = (object.nav_bar !== undefined && object.nav_bar !== null)
      ? ProductPoraScoreInfoScreen_NavBar.fromPartial(object.nav_bar)
      : undefined;
    message.pora_score = (object.pora_score !== undefined && object.pora_score !== null)
      ? ProductPoraScore.fromPartial(object.pora_score)
      : undefined;
    message.description_title = object.description_title ?? "";
    message.description = (object.description !== undefined && object.description !== null)
      ? ProductPoraScoreInfoScreen_Description.fromPartial(object.description)
      : undefined;
    return message;
  },
};

function createBaseProductPoraScoreInfoScreen_NavBar(): ProductPoraScoreInfoScreen_NavBar {
  return { title: "", image: "" };
}

export const ProductPoraScoreInfoScreen_NavBar = {
  fromJSON(object: any): ProductPoraScoreInfoScreen_NavBar {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      image: isSet(object.image) ? String(object.image) : "",
    };
  },

  toJSON(message: ProductPoraScoreInfoScreen_NavBar): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.image !== undefined && (obj.image = message.image);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductPoraScoreInfoScreen_NavBar>, I>>(
    base?: I,
  ): ProductPoraScoreInfoScreen_NavBar {
    return ProductPoraScoreInfoScreen_NavBar.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductPoraScoreInfoScreen_NavBar>, I>>(
    object: I,
  ): ProductPoraScoreInfoScreen_NavBar {
    const message = createBaseProductPoraScoreInfoScreen_NavBar();
    message.title = object.title ?? "";
    message.image = object.image ?? "";
    return message;
  },
};

function createBaseProductPoraScoreInfoScreen_Description(): ProductPoraScoreInfoScreen_Description {
  return { items: [] };
}

export const ProductPoraScoreInfoScreen_Description = {
  fromJSON(object: any): ProductPoraScoreInfoScreen_Description {
    return {
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => ProductPoraScoreInfoScreen_Description_Item.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductPoraScoreInfoScreen_Description): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map((e) => e ? ProductPoraScoreInfoScreen_Description_Item.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductPoraScoreInfoScreen_Description>, I>>(
    base?: I,
  ): ProductPoraScoreInfoScreen_Description {
    return ProductPoraScoreInfoScreen_Description.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductPoraScoreInfoScreen_Description>, I>>(
    object: I,
  ): ProductPoraScoreInfoScreen_Description {
    const message = createBaseProductPoraScoreInfoScreen_Description();
    message.items = object.items?.map((e) => ProductPoraScoreInfoScreen_Description_Item.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductPoraScoreInfoScreen_Description_Item(): ProductPoraScoreInfoScreen_Description_Item {
  return { item: undefined };
}

export const ProductPoraScoreInfoScreen_Description_Item = {
  fromJSON(object: any): ProductPoraScoreInfoScreen_Description_Item {
    return {
      item: isSet(object.string)
        ? { $case: "string", string: AttributedString.fromJSON(object.string) }
        : isSet(object.scale)
        ? { $case: "scale", scale: ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale.fromJSON(object.scale) }
        : undefined,
    };
  },

  toJSON(message: ProductPoraScoreInfoScreen_Description_Item): unknown {
    const obj: any = {};
    message.item?.$case === "string" &&
      (obj.string = message.item?.string ? AttributedString.toJSON(message.item?.string) : undefined);
    message.item?.$case === "scale" && (obj.scale = message.item?.scale
      ? ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale.toJSON(message.item?.scale)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductPoraScoreInfoScreen_Description_Item>, I>>(
    base?: I,
  ): ProductPoraScoreInfoScreen_Description_Item {
    return ProductPoraScoreInfoScreen_Description_Item.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductPoraScoreInfoScreen_Description_Item>, I>>(
    object: I,
  ): ProductPoraScoreInfoScreen_Description_Item {
    const message = createBaseProductPoraScoreInfoScreen_Description_Item();
    if (object.item?.$case === "string" && object.item?.string !== undefined && object.item?.string !== null) {
      message.item = { $case: "string", string: AttributedString.fromPartial(object.item.string) };
    }
    if (object.item?.$case === "scale" && object.item?.scale !== undefined && object.item?.scale !== null) {
      message.item = {
        $case: "scale",
        scale: ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale.fromPartial(object.item.scale),
      };
    }
    return message;
  },
};

function createBaseProductPoraScoreInfoScreen_Description_Item_PoraScoreScale(): ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale {
  return { segments: [] };
}

export const ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale = {
  fromJSON(object: any): ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale {
    return {
      segments: Array.isArray(object?.segments)
        ? object.segments.map((e: any) =>
          ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale_Segment.fromJSON(e)
        )
        : [],
    };
  },

  toJSON(message: ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale): unknown {
    const obj: any = {};
    if (message.segments) {
      obj.segments = message.segments.map((e) =>
        e ? ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale_Segment.toJSON(e) : undefined
      );
    } else {
      obj.segments = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale>, I>>(
    base?: I,
  ): ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale {
    return ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale>, I>>(
    object: I,
  ): ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale {
    const message = createBaseProductPoraScoreInfoScreen_Description_Item_PoraScoreScale();
    message.segments =
      object.segments?.map((e) => ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale_Segment.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseProductPoraScoreInfoScreen_Description_Item_PoraScoreScale_Segment(): ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale_Segment {
  return { color: undefined, label: "" };
}

export const ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale_Segment = {
  fromJSON(object: any): ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale_Segment {
    return {
      color: isSet(object.color) ? Color.fromJSON(object.color) : undefined,
      label: isSet(object.label) ? String(object.label) : "",
    };
  },

  toJSON(message: ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale_Segment): unknown {
    const obj: any = {};
    message.color !== undefined && (obj.color = message.color ? Color.toJSON(message.color) : undefined);
    message.label !== undefined && (obj.label = message.label);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale_Segment>, I>>(
    base?: I,
  ): ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale_Segment {
    return ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale_Segment.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale_Segment>, I>>(
    object: I,
  ): ProductPoraScoreInfoScreen_Description_Item_PoraScoreScale_Segment {
    const message = createBaseProductPoraScoreInfoScreen_Description_Item_PoraScoreScale_Segment();
    message.color = (object.color !== undefined && object.color !== null) ? Color.fromPartial(object.color) : undefined;
    message.label = object.label ?? "";
    return message;
  },
};

function createBaseActionOpenProductScreenPayload(): ActionOpenProductScreenPayload {
  return { product_id: "", product_screen: undefined };
}

export const ActionOpenProductScreenPayload = {
  fromJSON(object: any): ActionOpenProductScreenPayload {
    return {
      product_id: isSet(object.product_id) ? String(object.product_id) : "",
      product_screen: isSet(object.product_screen) ? ProductScreen.fromJSON(object.product_screen) : undefined,
    };
  },

  toJSON(message: ActionOpenProductScreenPayload): unknown {
    const obj: any = {};
    message.product_id !== undefined && (obj.product_id = message.product_id);
    message.product_screen !== undefined &&
      (obj.product_screen = message.product_screen ? ProductScreen.toJSON(message.product_screen) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ActionOpenProductScreenPayload>, I>>(base?: I): ActionOpenProductScreenPayload {
    return ActionOpenProductScreenPayload.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ActionOpenProductScreenPayload>, I>>(
    object: I,
  ): ActionOpenProductScreenPayload {
    const message = createBaseActionOpenProductScreenPayload();
    message.product_id = object.product_id ?? "";
    message.product_screen = (object.product_screen !== undefined && object.product_screen !== null)
      ? ProductScreen.fromPartial(object.product_screen)
      : undefined;
    return message;
  },
};

function createBaseProductScreenRequest(): ProductScreenRequest {
  return { request: undefined, product_id: "" };
}

export const ProductScreenRequest = {
  fromJSON(object: any): ProductScreenRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      product_id: isSet(object.product_id) ? String(object.product_id) : "",
    };
  },

  toJSON(message: ProductScreenRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    message.product_id !== undefined && (obj.product_id = message.product_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreenRequest>, I>>(base?: I): ProductScreenRequest {
    return ProductScreenRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreenRequest>, I>>(object: I): ProductScreenRequest {
    const message = createBaseProductScreenRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.product_id = object.product_id ?? "";
    return message;
  },
};

function createBaseLazyProductSectionRequestData(): LazyProductSectionRequestData {
  return { data: undefined };
}

export const LazyProductSectionRequestData = {
  fromJSON(object: any): LazyProductSectionRequestData {
    return {
      data: isSet(object.info)
        ? { $case: "info", info: LazyProductSectionRequestData_InfoRequestData.fromJSON(object.info) }
        : isSet(object.key_functions)
        ? {
          $case: "key_functions",
          key_functions: LazyProductSectionRequestData_KeyFunctionsRequestData.fromJSON(object.key_functions),
        }
        : isSet(object.header_data)
        ? {
          $case: "header_data",
          header_data: LazyProductSectionRequestData_HeaderDataRequest.fromJSON(object.header_data),
        }
        : isSet(object.alternatives_data)
        ? {
          $case: "alternatives_data",
          alternatives_data: LazyProductSectionRequestData_AlternativesDataRequest.fromJSON(object.alternatives_data),
        }
        : undefined,
    };
  },

  toJSON(message: LazyProductSectionRequestData): unknown {
    const obj: any = {};
    message.data?.$case === "info" && (obj.info = message.data?.info
      ? LazyProductSectionRequestData_InfoRequestData.toJSON(message.data?.info)
      : undefined);
    message.data?.$case === "key_functions" && (obj.key_functions = message.data?.key_functions
      ? LazyProductSectionRequestData_KeyFunctionsRequestData.toJSON(message.data?.key_functions)
      : undefined);
    message.data?.$case === "header_data" && (obj.header_data = message.data?.header_data
      ? LazyProductSectionRequestData_HeaderDataRequest.toJSON(message.data?.header_data)
      : undefined);
    message.data?.$case === "alternatives_data" && (obj.alternatives_data = message.data?.alternatives_data
      ? LazyProductSectionRequestData_AlternativesDataRequest.toJSON(message.data?.alternatives_data)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<LazyProductSectionRequestData>, I>>(base?: I): LazyProductSectionRequestData {
    return LazyProductSectionRequestData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LazyProductSectionRequestData>, I>>(
    object: I,
  ): LazyProductSectionRequestData {
    const message = createBaseLazyProductSectionRequestData();
    if (object.data?.$case === "info" && object.data?.info !== undefined && object.data?.info !== null) {
      message.data = {
        $case: "info",
        info: LazyProductSectionRequestData_InfoRequestData.fromPartial(object.data.info),
      };
    }
    if (
      object.data?.$case === "key_functions" &&
      object.data?.key_functions !== undefined &&
      object.data?.key_functions !== null
    ) {
      message.data = {
        $case: "key_functions",
        key_functions: LazyProductSectionRequestData_KeyFunctionsRequestData.fromPartial(object.data.key_functions),
      };
    }
    if (
      object.data?.$case === "header_data" &&
      object.data?.header_data !== undefined &&
      object.data?.header_data !== null
    ) {
      message.data = {
        $case: "header_data",
        header_data: LazyProductSectionRequestData_HeaderDataRequest.fromPartial(object.data.header_data),
      };
    }
    if (
      object.data?.$case === "alternatives_data" &&
      object.data?.alternatives_data !== undefined &&
      object.data?.alternatives_data !== null
    ) {
      message.data = {
        $case: "alternatives_data",
        alternatives_data: LazyProductSectionRequestData_AlternativesDataRequest.fromPartial(
          object.data.alternatives_data,
        ),
      };
    }
    return message;
  },
};

function createBaseLazyProductSectionRequestData_InfoRequestData(): LazyProductSectionRequestData_InfoRequestData {
  return { section_title: "", prompt: "" };
}

export const LazyProductSectionRequestData_InfoRequestData = {
  fromJSON(object: any): LazyProductSectionRequestData_InfoRequestData {
    return {
      section_title: isSet(object.section_title) ? String(object.section_title) : "",
      prompt: isSet(object.prompt) ? String(object.prompt) : "",
    };
  },

  toJSON(message: LazyProductSectionRequestData_InfoRequestData): unknown {
    const obj: any = {};
    message.section_title !== undefined && (obj.section_title = message.section_title);
    message.prompt !== undefined && (obj.prompt = message.prompt);
    return obj;
  },

  create<I extends Exact<DeepPartial<LazyProductSectionRequestData_InfoRequestData>, I>>(
    base?: I,
  ): LazyProductSectionRequestData_InfoRequestData {
    return LazyProductSectionRequestData_InfoRequestData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LazyProductSectionRequestData_InfoRequestData>, I>>(
    object: I,
  ): LazyProductSectionRequestData_InfoRequestData {
    const message = createBaseLazyProductSectionRequestData_InfoRequestData();
    message.section_title = object.section_title ?? "";
    message.prompt = object.prompt ?? "";
    return message;
  },
};

function createBaseLazyProductSectionRequestData_KeyFunctionsRequestData(): LazyProductSectionRequestData_KeyFunctionsRequestData {
  return { product_id: "" };
}

export const LazyProductSectionRequestData_KeyFunctionsRequestData = {
  fromJSON(object: any): LazyProductSectionRequestData_KeyFunctionsRequestData {
    return { product_id: isSet(object.product_id) ? String(object.product_id) : "" };
  },

  toJSON(message: LazyProductSectionRequestData_KeyFunctionsRequestData): unknown {
    const obj: any = {};
    message.product_id !== undefined && (obj.product_id = message.product_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<LazyProductSectionRequestData_KeyFunctionsRequestData>, I>>(
    base?: I,
  ): LazyProductSectionRequestData_KeyFunctionsRequestData {
    return LazyProductSectionRequestData_KeyFunctionsRequestData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LazyProductSectionRequestData_KeyFunctionsRequestData>, I>>(
    object: I,
  ): LazyProductSectionRequestData_KeyFunctionsRequestData {
    const message = createBaseLazyProductSectionRequestData_KeyFunctionsRequestData();
    message.product_id = object.product_id ?? "";
    return message;
  },
};

function createBaseLazyProductSectionRequestData_HeaderDataRequest(): LazyProductSectionRequestData_HeaderDataRequest {
  return { product_id: "" };
}

export const LazyProductSectionRequestData_HeaderDataRequest = {
  fromJSON(object: any): LazyProductSectionRequestData_HeaderDataRequest {
    return { product_id: isSet(object.product_id) ? String(object.product_id) : "" };
  },

  toJSON(message: LazyProductSectionRequestData_HeaderDataRequest): unknown {
    const obj: any = {};
    message.product_id !== undefined && (obj.product_id = message.product_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<LazyProductSectionRequestData_HeaderDataRequest>, I>>(
    base?: I,
  ): LazyProductSectionRequestData_HeaderDataRequest {
    return LazyProductSectionRequestData_HeaderDataRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LazyProductSectionRequestData_HeaderDataRequest>, I>>(
    object: I,
  ): LazyProductSectionRequestData_HeaderDataRequest {
    const message = createBaseLazyProductSectionRequestData_HeaderDataRequest();
    message.product_id = object.product_id ?? "";
    return message;
  },
};

function createBaseLazyProductSectionRequestData_AlternativesDataRequest(): LazyProductSectionRequestData_AlternativesDataRequest {
  return { product_id: "" };
}

export const LazyProductSectionRequestData_AlternativesDataRequest = {
  fromJSON(object: any): LazyProductSectionRequestData_AlternativesDataRequest {
    return { product_id: isSet(object.product_id) ? String(object.product_id) : "" };
  },

  toJSON(message: LazyProductSectionRequestData_AlternativesDataRequest): unknown {
    const obj: any = {};
    message.product_id !== undefined && (obj.product_id = message.product_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<LazyProductSectionRequestData_AlternativesDataRequest>, I>>(
    base?: I,
  ): LazyProductSectionRequestData_AlternativesDataRequest {
    return LazyProductSectionRequestData_AlternativesDataRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LazyProductSectionRequestData_AlternativesDataRequest>, I>>(
    object: I,
  ): LazyProductSectionRequestData_AlternativesDataRequest {
    const message = createBaseLazyProductSectionRequestData_AlternativesDataRequest();
    message.product_id = object.product_id ?? "";
    return message;
  },
};

function createBaseLazyProductStepInfoSectionRequestData(): LazyProductStepInfoSectionRequestData {
  return { data: undefined };
}

export const LazyProductStepInfoSectionRequestData = {
  fromJSON(object: any): LazyProductStepInfoSectionRequestData {
    return {
      data: isSet(object.info)
        ? { $case: "info", info: LazyProductStepInfoSectionRequestData_InfoRequestData.fromJSON(object.info) }
        : undefined,
    };
  },

  toJSON(message: LazyProductStepInfoSectionRequestData): unknown {
    const obj: any = {};
    message.data?.$case === "info" && (obj.info = message.data?.info
      ? LazyProductStepInfoSectionRequestData_InfoRequestData.toJSON(message.data?.info)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<LazyProductStepInfoSectionRequestData>, I>>(
    base?: I,
  ): LazyProductStepInfoSectionRequestData {
    return LazyProductStepInfoSectionRequestData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LazyProductStepInfoSectionRequestData>, I>>(
    object: I,
  ): LazyProductStepInfoSectionRequestData {
    const message = createBaseLazyProductStepInfoSectionRequestData();
    if (object.data?.$case === "info" && object.data?.info !== undefined && object.data?.info !== null) {
      message.data = {
        $case: "info",
        info: LazyProductStepInfoSectionRequestData_InfoRequestData.fromPartial(object.data.info),
      };
    }
    return message;
  },
};

function createBaseLazyProductStepInfoSectionRequestData_InfoRequestData(): LazyProductStepInfoSectionRequestData_InfoRequestData {
  return { section_title: "", prompt: "" };
}

export const LazyProductStepInfoSectionRequestData_InfoRequestData = {
  fromJSON(object: any): LazyProductStepInfoSectionRequestData_InfoRequestData {
    return {
      section_title: isSet(object.section_title) ? String(object.section_title) : "",
      prompt: isSet(object.prompt) ? String(object.prompt) : "",
    };
  },

  toJSON(message: LazyProductStepInfoSectionRequestData_InfoRequestData): unknown {
    const obj: any = {};
    message.section_title !== undefined && (obj.section_title = message.section_title);
    message.prompt !== undefined && (obj.prompt = message.prompt);
    return obj;
  },

  create<I extends Exact<DeepPartial<LazyProductStepInfoSectionRequestData_InfoRequestData>, I>>(
    base?: I,
  ): LazyProductStepInfoSectionRequestData_InfoRequestData {
    return LazyProductStepInfoSectionRequestData_InfoRequestData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LazyProductStepInfoSectionRequestData_InfoRequestData>, I>>(
    object: I,
  ): LazyProductStepInfoSectionRequestData_InfoRequestData {
    const message = createBaseLazyProductStepInfoSectionRequestData_InfoRequestData();
    message.section_title = object.section_title ?? "";
    message.prompt = object.prompt ?? "";
    return message;
  },
};

function createBaseLazyProductSectionRequest(): LazyProductSectionRequest {
  return { request: undefined, data: undefined };
}

export const LazyProductSectionRequest = {
  fromJSON(object: any): LazyProductSectionRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      data: isSet(object.data) ? LazyProductSectionRequestData.fromJSON(object.data) : undefined,
    };
  },

  toJSON(message: LazyProductSectionRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    message.data !== undefined &&
      (obj.data = message.data ? LazyProductSectionRequestData.toJSON(message.data) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<LazyProductSectionRequest>, I>>(base?: I): LazyProductSectionRequest {
    return LazyProductSectionRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LazyProductSectionRequest>, I>>(object: I): LazyProductSectionRequest {
    const message = createBaseLazyProductSectionRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.data = (object.data !== undefined && object.data !== null)
      ? LazyProductSectionRequestData.fromPartial(object.data)
      : undefined;
    return message;
  },
};

function createBaseLazyProductStepInfoSectionRequest(): LazyProductStepInfoSectionRequest {
  return { request: undefined, data: undefined };
}

export const LazyProductStepInfoSectionRequest = {
  fromJSON(object: any): LazyProductStepInfoSectionRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      data: isSet(object.data) ? LazyProductStepInfoSectionRequestData.fromJSON(object.data) : undefined,
    };
  },

  toJSON(message: LazyProductStepInfoSectionRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    message.data !== undefined &&
      (obj.data = message.data ? LazyProductStepInfoSectionRequestData.toJSON(message.data) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<LazyProductStepInfoSectionRequest>, I>>(
    base?: I,
  ): LazyProductStepInfoSectionRequest {
    return LazyProductStepInfoSectionRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LazyProductStepInfoSectionRequest>, I>>(
    object: I,
  ): LazyProductStepInfoSectionRequest {
    const message = createBaseLazyProductStepInfoSectionRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.data = (object.data !== undefined && object.data !== null)
      ? LazyProductStepInfoSectionRequestData.fromPartial(object.data)
      : undefined;
    return message;
  },
};

function createBaseLazyProductSectionResponse(): LazyProductSectionResponse {
  return { response: undefined };
}

export const LazyProductSectionResponse = {
  fromJSON(object: any): LazyProductSectionResponse {
    return {
      response: isSet(object.section)
        ? { $case: "section", section: ProductScreen_Section.fromJSON(object.section) }
        : isSet(object.error_message)
        ? { $case: "error_message", error_message: String(object.error_message) }
        : undefined,
    };
  },

  toJSON(message: LazyProductSectionResponse): unknown {
    const obj: any = {};
    message.response?.$case === "section" &&
      (obj.section = message.response?.section ? ProductScreen_Section.toJSON(message.response?.section) : undefined);
    message.response?.$case === "error_message" && (obj.error_message = message.response?.error_message);
    return obj;
  },

  create<I extends Exact<DeepPartial<LazyProductSectionResponse>, I>>(base?: I): LazyProductSectionResponse {
    return LazyProductSectionResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LazyProductSectionResponse>, I>>(object: I): LazyProductSectionResponse {
    const message = createBaseLazyProductSectionResponse();
    if (
      object.response?.$case === "section" &&
      object.response?.section !== undefined &&
      object.response?.section !== null
    ) {
      message.response = { $case: "section", section: ProductScreen_Section.fromPartial(object.response.section) };
    }
    if (
      object.response?.$case === "error_message" &&
      object.response?.error_message !== undefined &&
      object.response?.error_message !== null
    ) {
      message.response = { $case: "error_message", error_message: object.response.error_message };
    }
    return message;
  },
};

function createBaseLazyProductStepInfoSectionResponse(): LazyProductStepInfoSectionResponse {
  return { response: undefined };
}

export const LazyProductStepInfoSectionResponse = {
  fromJSON(object: any): LazyProductStepInfoSectionResponse {
    return {
      response: isSet(object.section)
        ? { $case: "section", section: ProductStepInfoScreen_Section.fromJSON(object.section) }
        : isSet(object.error_message)
        ? { $case: "error_message", error_message: String(object.error_message) }
        : undefined,
    };
  },

  toJSON(message: LazyProductStepInfoSectionResponse): unknown {
    const obj: any = {};
    message.response?.$case === "section" && (obj.section = message.response?.section
      ? ProductStepInfoScreen_Section.toJSON(message.response?.section)
      : undefined);
    message.response?.$case === "error_message" && (obj.error_message = message.response?.error_message);
    return obj;
  },

  create<I extends Exact<DeepPartial<LazyProductStepInfoSectionResponse>, I>>(
    base?: I,
  ): LazyProductStepInfoSectionResponse {
    return LazyProductStepInfoSectionResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LazyProductStepInfoSectionResponse>, I>>(
    object: I,
  ): LazyProductStepInfoSectionResponse {
    const message = createBaseLazyProductStepInfoSectionResponse();
    if (
      object.response?.$case === "section" &&
      object.response?.section !== undefined &&
      object.response?.section !== null
    ) {
      message.response = {
        $case: "section",
        section: ProductStepInfoScreen_Section.fromPartial(object.response.section),
      };
    }
    if (
      object.response?.$case === "error_message" &&
      object.response?.error_message !== undefined &&
      object.response?.error_message !== null
    ) {
      message.response = { $case: "error_message", error_message: object.response.error_message };
    }
    return message;
  },
};

function createBaseProductScreenHeaderKeyFunctionsRequest(): ProductScreenHeaderKeyFunctionsRequest {
  return { request: undefined, product_id: "" };
}

export const ProductScreenHeaderKeyFunctionsRequest = {
  fromJSON(object: any): ProductScreenHeaderKeyFunctionsRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      product_id: isSet(object.product_id) ? String(object.product_id) : "",
    };
  },

  toJSON(message: ProductScreenHeaderKeyFunctionsRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    message.product_id !== undefined && (obj.product_id = message.product_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreenHeaderKeyFunctionsRequest>, I>>(
    base?: I,
  ): ProductScreenHeaderKeyFunctionsRequest {
    return ProductScreenHeaderKeyFunctionsRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreenHeaderKeyFunctionsRequest>, I>>(
    object: I,
  ): ProductScreenHeaderKeyFunctionsRequest {
    const message = createBaseProductScreenHeaderKeyFunctionsRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.product_id = object.product_id ?? "";
    return message;
  },
};

function createBaseProductScreenHeaderPoraScoreRequest(): ProductScreenHeaderPoraScoreRequest {
  return { request: undefined, product_id: "" };
}

export const ProductScreenHeaderPoraScoreRequest = {
  fromJSON(object: any): ProductScreenHeaderPoraScoreRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      product_id: isSet(object.product_id) ? String(object.product_id) : "",
    };
  },

  toJSON(message: ProductScreenHeaderPoraScoreRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    message.product_id !== undefined && (obj.product_id = message.product_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreenHeaderPoraScoreRequest>, I>>(
    base?: I,
  ): ProductScreenHeaderPoraScoreRequest {
    return ProductScreenHeaderPoraScoreRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreenHeaderPoraScoreRequest>, I>>(
    object: I,
  ): ProductScreenHeaderPoraScoreRequest {
    const message = createBaseProductScreenHeaderPoraScoreRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.product_id = object.product_id ?? "";
    return message;
  },
};

function createBaseProductScreenHeaderQuickSummaryRequest(): ProductScreenHeaderQuickSummaryRequest {
  return { request: undefined, url: "" };
}

export const ProductScreenHeaderQuickSummaryRequest = {
  fromJSON(object: any): ProductScreenHeaderQuickSummaryRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      url: isSet(object.url) ? String(object.url) : "",
    };
  },

  toJSON(message: ProductScreenHeaderQuickSummaryRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    message.url !== undefined && (obj.url = message.url);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreenHeaderQuickSummaryRequest>, I>>(
    base?: I,
  ): ProductScreenHeaderQuickSummaryRequest {
    return ProductScreenHeaderQuickSummaryRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreenHeaderQuickSummaryRequest>, I>>(
    object: I,
  ): ProductScreenHeaderQuickSummaryRequest {
    const message = createBaseProductScreenHeaderQuickSummaryRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.url = object.url ?? "";
    return message;
  },
};

function createBaseProductScreenAlternativesRequest(): ProductScreenAlternativesRequest {
  return { request: undefined, url: "" };
}

export const ProductScreenAlternativesRequest = {
  fromJSON(object: any): ProductScreenAlternativesRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      url: isSet(object.url) ? String(object.url) : "",
    };
  },

  toJSON(message: ProductScreenAlternativesRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    message.url !== undefined && (obj.url = message.url);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreenAlternativesRequest>, I>>(
    base?: I,
  ): ProductScreenAlternativesRequest {
    return ProductScreenAlternativesRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreenAlternativesRequest>, I>>(
    object: I,
  ): ProductScreenAlternativesRequest {
    const message = createBaseProductScreenAlternativesRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.url = object.url ?? "";
    return message;
  },
};

function createBaseDeleteScanRequest(): DeleteScanRequest {
  return { request: undefined, id: "", category: "" };
}

export const DeleteScanRequest = {
  fromJSON(object: any): DeleteScanRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      id: isSet(object.id) ? String(object.id) : "",
      category: isSet(object.category) ? String(object.category) : "",
    };
  },

  toJSON(message: DeleteScanRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    message.id !== undefined && (obj.id = message.id);
    message.category !== undefined && (obj.category = message.category);
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteScanRequest>, I>>(base?: I): DeleteScanRequest {
    return DeleteScanRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DeleteScanRequest>, I>>(object: I): DeleteScanRequest {
    const message = createBaseDeleteScanRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.id = object.id ?? "";
    message.category = object.category ?? "";
    return message;
  },
};

function createBaseDeleteScanResponse(): DeleteScanResponse {
  return { deleted_id: "" };
}

export const DeleteScanResponse = {
  fromJSON(object: any): DeleteScanResponse {
    return { deleted_id: isSet(object.deleted_id) ? String(object.deleted_id) : "" };
  },

  toJSON(message: DeleteScanResponse): unknown {
    const obj: any = {};
    message.deleted_id !== undefined && (obj.deleted_id = message.deleted_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteScanResponse>, I>>(base?: I): DeleteScanResponse {
    return DeleteScanResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DeleteScanResponse>, I>>(object: I): DeleteScanResponse {
    const message = createBaseDeleteScanResponse();
    message.deleted_id = object.deleted_id ?? "";
    return message;
  },
};

function createBaseProductsScanHistoryRequest(): ProductsScanHistoryRequest {
  return { request: undefined, start_index: 0, category: "" };
}

export const ProductsScanHistoryRequest = {
  fromJSON(object: any): ProductsScanHistoryRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      start_index: isSet(object.start_index) ? Number(object.start_index) : 0,
      category: isSet(object.category) ? String(object.category) : "",
    };
  },

  toJSON(message: ProductsScanHistoryRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    message.start_index !== undefined && (obj.start_index = Math.round(message.start_index));
    message.category !== undefined && (obj.category = message.category);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsScanHistoryRequest>, I>>(base?: I): ProductsScanHistoryRequest {
    return ProductsScanHistoryRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsScanHistoryRequest>, I>>(object: I): ProductsScanHistoryRequest {
    const message = createBaseProductsScanHistoryRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.start_index = object.start_index ?? 0;
    message.category = object.category ?? "";
    return message;
  },
};

function createBaseProductsScanHistoryResponse(): ProductsScanHistoryResponse {
  return { history_items: [], next_page_start_index: 0, has_more: false, items_total_count: "", sections: [] };
}

export const ProductsScanHistoryResponse = {
  fromJSON(object: any): ProductsScanHistoryResponse {
    return {
      history_items: Array.isArray(object?.history_items)
        ? object.history_items.map((e: any) => ProductsScanHistoryItem.fromJSON(e))
        : [],
      next_page_start_index: isSet(object.next_page_start_index) ? Number(object.next_page_start_index) : 0,
      has_more: isSet(object.has_more) ? Boolean(object.has_more) : false,
      items_total_count: isSet(object.items_total_count) ? String(object.items_total_count) : "",
      sections: Array.isArray(object?.sections)
        ? object.sections.map((e: any) => ProductsScanHistoryResponse_Section.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductsScanHistoryResponse): unknown {
    const obj: any = {};
    if (message.history_items) {
      obj.history_items = message.history_items.map((e) => e ? ProductsScanHistoryItem.toJSON(e) : undefined);
    } else {
      obj.history_items = [];
    }
    message.next_page_start_index !== undefined &&
      (obj.next_page_start_index = Math.round(message.next_page_start_index));
    message.has_more !== undefined && (obj.has_more = message.has_more);
    message.items_total_count !== undefined && (obj.items_total_count = message.items_total_count);
    if (message.sections) {
      obj.sections = message.sections.map((e) => e ? ProductsScanHistoryResponse_Section.toJSON(e) : undefined);
    } else {
      obj.sections = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsScanHistoryResponse>, I>>(base?: I): ProductsScanHistoryResponse {
    return ProductsScanHistoryResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsScanHistoryResponse>, I>>(object: I): ProductsScanHistoryResponse {
    const message = createBaseProductsScanHistoryResponse();
    message.history_items = object.history_items?.map((e) => ProductsScanHistoryItem.fromPartial(e)) || [];
    message.next_page_start_index = object.next_page_start_index ?? 0;
    message.has_more = object.has_more ?? false;
    message.items_total_count = object.items_total_count ?? "";
    message.sections = object.sections?.map((e) => ProductsScanHistoryResponse_Section.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductsScanHistoryResponse_Section(): ProductsScanHistoryResponse_Section {
  return { section_title: "", items: [] };
}

export const ProductsScanHistoryResponse_Section = {
  fromJSON(object: any): ProductsScanHistoryResponse_Section {
    return {
      section_title: isSet(object.section_title) ? String(object.section_title) : "",
      items: Array.isArray(object?.items) ? object.items.map((e: any) => ProductsScanHistoryItem.fromJSON(e)) : [],
    };
  },

  toJSON(message: ProductsScanHistoryResponse_Section): unknown {
    const obj: any = {};
    message.section_title !== undefined && (obj.section_title = message.section_title);
    if (message.items) {
      obj.items = message.items.map((e) => e ? ProductsScanHistoryItem.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsScanHistoryResponse_Section>, I>>(
    base?: I,
  ): ProductsScanHistoryResponse_Section {
    return ProductsScanHistoryResponse_Section.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsScanHistoryResponse_Section>, I>>(
    object: I,
  ): ProductsScanHistoryResponse_Section {
    const message = createBaseProductsScanHistoryResponse_Section();
    message.section_title = object.section_title ?? "";
    message.items = object.items?.map((e) => ProductsScanHistoryItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductsScanHistoryItem(): ProductsScanHistoryItem {
  return {
    id: "",
    image: "",
    qualification: ProductQualification.PRODUCT_QUALIFICATION_NONE,
    qualification_title: "",
    title: "",
    subtitle: "",
    time_ago: "",
    action: undefined,
    is_disabled: false,
    footer_title: "",
    pora_score: undefined,
    image_content_mode: ImageContentMode.MODE_NONE,
    tags: [],
  };
}

export const ProductsScanHistoryItem = {
  fromJSON(object: any): ProductsScanHistoryItem {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      image: isSet(object.image) ? String(object.image) : "",
      qualification: isSet(object.qualification)
        ? productQualificationFromJSON(object.qualification)
        : ProductQualification.PRODUCT_QUALIFICATION_NONE,
      qualification_title: isSet(object.qualification_title) ? String(object.qualification_title) : "",
      title: isSet(object.title) ? String(object.title) : "",
      subtitle: isSet(object.subtitle) ? String(object.subtitle) : "",
      time_ago: isSet(object.time_ago) ? String(object.time_ago) : "",
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
      is_disabled: isSet(object.is_disabled) ? Boolean(object.is_disabled) : false,
      footer_title: isSet(object.footer_title) ? String(object.footer_title) : "",
      pora_score: isSet(object.pora_score) ? ProductPoraScore.fromJSON(object.pora_score) : undefined,
      image_content_mode: isSet(object.image_content_mode)
        ? imageContentModeFromJSON(object.image_content_mode)
        : ImageContentMode.MODE_NONE,
      tags: Array.isArray(object?.tags)
        ? object.tags.map((e: any) => Tag.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductsScanHistoryItem): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.image !== undefined && (obj.image = message.image);
    message.qualification !== undefined && (obj.qualification = productQualificationToJSON(message.qualification));
    message.qualification_title !== undefined && (obj.qualification_title = message.qualification_title);
    message.title !== undefined && (obj.title = message.title);
    message.subtitle !== undefined && (obj.subtitle = message.subtitle);
    message.time_ago !== undefined && (obj.time_ago = message.time_ago);
    message.action !== undefined && (obj.action = message.action ? ActionType.toJSON(message.action) : undefined);
    message.is_disabled !== undefined && (obj.is_disabled = message.is_disabled);
    message.footer_title !== undefined && (obj.footer_title = message.footer_title);
    message.pora_score !== undefined &&
      (obj.pora_score = message.pora_score ? ProductPoraScore.toJSON(message.pora_score) : undefined);
    message.image_content_mode !== undefined &&
      (obj.image_content_mode = imageContentModeToJSON(message.image_content_mode));
    if (message.tags) {
      obj.tags = message.tags.map((e) => e ? Tag.toJSON(e) : undefined);
    } else {
      obj.tags = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsScanHistoryItem>, I>>(base?: I): ProductsScanHistoryItem {
    return ProductsScanHistoryItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsScanHistoryItem>, I>>(object: I): ProductsScanHistoryItem {
    const message = createBaseProductsScanHistoryItem();
    message.id = object.id ?? "";
    message.image = object.image ?? "";
    message.qualification = object.qualification ?? ProductQualification.PRODUCT_QUALIFICATION_NONE;
    message.qualification_title = object.qualification_title ?? "";
    message.title = object.title ?? "";
    message.subtitle = object.subtitle ?? "";
    message.time_ago = object.time_ago ?? "";
    message.action = (object.action !== undefined && object.action !== null)
      ? ActionType.fromPartial(object.action)
      : undefined;
    message.is_disabled = object.is_disabled ?? false;
    message.footer_title = object.footer_title ?? "";
    message.pora_score = (object.pora_score !== undefined && object.pora_score !== null)
      ? ProductPoraScore.fromPartial(object.pora_score)
      : undefined;
    message.image_content_mode = object.image_content_mode ?? ImageContentMode.MODE_NONE;
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductScreen(): ProductScreen {
  return {
    sections: [],
    id: "",
    buttons: [],
    is_go_home_available: false,
    go_home_post_action: undefined,
    product_id: "",
    analytics_data: undefined,
    is_closable_by_swipe: false,
    snack_bar: undefined,
    assistant_button: undefined,
    alternativesScreen: undefined,
    report_quiz_id: "",
    report_action: undefined,
  };
}

export const ProductScreen = {
  fromJSON(object: any): ProductScreen {
    return {
      sections: Array.isArray(object?.sections)
        ? object.sections.map((e: any) => ProductScreen_Section.fromJSON(e))
        : [],
      id: isSet(object.id) ? String(object.id) : "",
      buttons: Array.isArray(object?.buttons) ? object.buttons.map((e: any) => ProductScreen_Button.fromJSON(e)) : [],
      is_go_home_available: isSet(object.is_go_home_available) ? Boolean(object.is_go_home_available) : false,
      go_home_post_action: isSet(object.go_home_post_action)
        ? ActionType.fromJSON(object.go_home_post_action)
        : undefined,
      product_id: isSet(object.product_id) ? String(object.product_id) : "",
      analytics_data: isSet(object.analytics_data)
        ? ProductScreen_AnalyticsData.fromJSON(object.analytics_data)
        : undefined,
      is_closable_by_swipe: isSet(object.is_closable_by_swipe) ? Boolean(object.is_closable_by_swipe) : false,
      snack_bar: isSet(object.snack_bar) ? SnackBar.fromJSON(object.snack_bar) : undefined,
      assistant_button: isSet(object.assistant_button) ? ImageButton.fromJSON(object.assistant_button) : undefined,
      alternativesScreen: isSet(object.alternativesScreen)
        ? ProductAlternativesScreen.fromJSON(object.alternativesScreen)
        : undefined,
      report_quiz_id: isSet(object.report_quiz_id) ? String(object.report_quiz_id) : "",
      report_action: isSet(object.report_action) ? ActionType.fromJSON(object.report_action) : undefined,
    };
  },

  toJSON(message: ProductScreen): unknown {
    const obj: any = {};
    if (message.sections) {
      obj.sections = message.sections.map((e) => e ? ProductScreen_Section.toJSON(e) : undefined);
    } else {
      obj.sections = [];
    }
    message.id !== undefined && (obj.id = message.id);
    if (message.buttons) {
      obj.buttons = message.buttons.map((e) => e ? ProductScreen_Button.toJSON(e) : undefined);
    } else {
      obj.buttons = [];
    }
    message.is_go_home_available !== undefined && (obj.is_go_home_available = message.is_go_home_available);
    message.go_home_post_action !== undefined && (obj.go_home_post_action = message.go_home_post_action
      ? ActionType.toJSON(message.go_home_post_action)
      : undefined);
    message.product_id !== undefined && (obj.product_id = message.product_id);
    message.analytics_data !== undefined && (obj.analytics_data = message.analytics_data
      ? ProductScreen_AnalyticsData.toJSON(message.analytics_data)
      : undefined);
    message.is_closable_by_swipe !== undefined && (obj.is_closable_by_swipe = message.is_closable_by_swipe);
    message.snack_bar !== undefined &&
      (obj.snack_bar = message.snack_bar ? SnackBar.toJSON(message.snack_bar) : undefined);
    message.assistant_button !== undefined &&
      (obj.assistant_button = message.assistant_button ? ImageButton.toJSON(message.assistant_button) : undefined);
    message.alternativesScreen !== undefined && (obj.alternativesScreen = message.alternativesScreen
      ? ProductAlternativesScreen.toJSON(message.alternativesScreen)
      : undefined);
    message.report_quiz_id !== undefined && (obj.report_quiz_id = message.report_quiz_id);
    message.report_action !== undefined &&
      (obj.report_action = message.report_action ? ActionType.toJSON(message.report_action) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen>, I>>(base?: I): ProductScreen {
    return ProductScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen>, I>>(object: I): ProductScreen {
    const message = createBaseProductScreen();
    message.sections = object.sections?.map((e) => ProductScreen_Section.fromPartial(e)) || [];
    message.id = object.id ?? "";
    message.buttons = object.buttons?.map((e) => ProductScreen_Button.fromPartial(e)) || [];
    message.is_go_home_available = object.is_go_home_available ?? false;
    message.go_home_post_action = (object.go_home_post_action !== undefined && object.go_home_post_action !== null)
      ? ActionType.fromPartial(object.go_home_post_action)
      : undefined;
    message.product_id = object.product_id ?? "";
    message.analytics_data = (object.analytics_data !== undefined && object.analytics_data !== null)
      ? ProductScreen_AnalyticsData.fromPartial(object.analytics_data)
      : undefined;
    message.is_closable_by_swipe = object.is_closable_by_swipe ?? false;
    message.snack_bar = (object.snack_bar !== undefined && object.snack_bar !== null)
      ? SnackBar.fromPartial(object.snack_bar)
      : undefined;
    message.assistant_button = (object.assistant_button !== undefined && object.assistant_button !== null)
      ? ImageButton.fromPartial(object.assistant_button)
      : undefined;
    message.alternativesScreen = (object.alternativesScreen !== undefined && object.alternativesScreen !== null)
      ? ProductAlternativesScreen.fromPartial(object.alternativesScreen)
      : undefined;
    message.report_quiz_id = object.report_quiz_id ?? "";
    message.report_action = (object.report_action !== undefined && object.report_action !== null)
      ? ActionType.fromPartial(object.report_action)
      : undefined;
    return message;
  },
};

function createBaseProductScreen_Section(): ProductScreen_Section {
  return { section: undefined };
}

export const ProductScreen_Section = {
  fromJSON(object: any): ProductScreen_Section {
    return {
      section: isSet(object.header)
        ? { $case: "header", header: ProductScreen_Section_Header.fromJSON(object.header) }
        : isSet(object.ratings_and_prices)
        ? {
          $case: "ratings_and_prices",
          ratings_and_prices: ProductScreen_Section_RatingsAndPrices.fromJSON(object.ratings_and_prices),
        }
        : isSet(object.info)
        ? { $case: "info", info: ProductScreen_Section_Info.fromJSON(object.info) }
        : isSet(object.list_with_icons)
        ? {
          $case: "list_with_icons",
          list_with_icons: ProductScreen_Section_ListWithIcons.fromJSON(object.list_with_icons),
        }
        : isSet(object.skin_id_match)
        ? { $case: "skin_id_match", skin_id_match: ProductScreen_Section_SkinIDMatch.fromJSON(object.skin_id_match) }
        : isSet(object.video_tutorial)
        ? {
          $case: "video_tutorial",
          video_tutorial: ProductScreen_Section_VideoTutorial.fromJSON(object.video_tutorial),
        }
        : isSet(object.alternatives)
        ? { $case: "alternatives", alternatives: ProductScreen_Section_Alternatives.fromJSON(object.alternatives) }
        : isSet(object.tiles_header)
        ? { $case: "tiles_header", tiles_header: ProductScreen_Section_TilesHeader.fromJSON(object.tiles_header) }
        : isSet(object.key_functions)
        ? { $case: "key_functions", key_functions: ProductScreen_Section_KeyFunctions.fromJSON(object.key_functions) }
        : isSet(object.ingredients)
        ? { $case: "ingredients", ingredients: ProductScreen_Section_Ingredients.fromJSON(object.ingredients) }
        : isSet(object.DEPRECATED_header)
        ? {
          $case: "DEPRECATED_header",
          DEPRECATED_header: ProductScreen_Section_Header.fromJSON(object.DEPRECATED_header),
        }
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section): unknown {
    const obj: any = {};
    message.section?.$case === "header" &&
      (obj.header = message.section?.header ? ProductScreen_Section_Header.toJSON(message.section?.header) : undefined);
    message.section?.$case === "ratings_and_prices" && (obj.ratings_and_prices = message.section?.ratings_and_prices
      ? ProductScreen_Section_RatingsAndPrices.toJSON(message.section?.ratings_and_prices)
      : undefined);
    message.section?.$case === "info" &&
      (obj.info = message.section?.info ? ProductScreen_Section_Info.toJSON(message.section?.info) : undefined);
    message.section?.$case === "list_with_icons" && (obj.list_with_icons = message.section?.list_with_icons
      ? ProductScreen_Section_ListWithIcons.toJSON(message.section?.list_with_icons)
      : undefined);
    message.section?.$case === "skin_id_match" && (obj.skin_id_match = message.section?.skin_id_match
      ? ProductScreen_Section_SkinIDMatch.toJSON(message.section?.skin_id_match)
      : undefined);
    message.section?.$case === "video_tutorial" && (obj.video_tutorial = message.section?.video_tutorial
      ? ProductScreen_Section_VideoTutorial.toJSON(message.section?.video_tutorial)
      : undefined);
    message.section?.$case === "alternatives" && (obj.alternatives = message.section?.alternatives
      ? ProductScreen_Section_Alternatives.toJSON(message.section?.alternatives)
      : undefined);
    message.section?.$case === "tiles_header" && (obj.tiles_header = message.section?.tiles_header
      ? ProductScreen_Section_TilesHeader.toJSON(message.section?.tiles_header)
      : undefined);
    message.section?.$case === "key_functions" && (obj.key_functions = message.section?.key_functions
      ? ProductScreen_Section_KeyFunctions.toJSON(message.section?.key_functions)
      : undefined);
    message.section?.$case === "ingredients" && (obj.ingredients = message.section?.ingredients
      ? ProductScreen_Section_Ingredients.toJSON(message.section?.ingredients)
      : undefined);
    message.section?.$case === "DEPRECATED_header" && (obj.DEPRECATED_header = message.section?.DEPRECATED_header
      ? ProductScreen_Section_Header.toJSON(message.section?.DEPRECATED_header)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section>, I>>(base?: I): ProductScreen_Section {
    return ProductScreen_Section.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section>, I>>(object: I): ProductScreen_Section {
    const message = createBaseProductScreen_Section();
    if (object.section?.$case === "header" && object.section?.header !== undefined && object.section?.header !== null) {
      message.section = { $case: "header", header: ProductScreen_Section_Header.fromPartial(object.section.header) };
    }
    if (
      object.section?.$case === "ratings_and_prices" &&
      object.section?.ratings_and_prices !== undefined &&
      object.section?.ratings_and_prices !== null
    ) {
      message.section = {
        $case: "ratings_and_prices",
        ratings_and_prices: ProductScreen_Section_RatingsAndPrices.fromPartial(object.section.ratings_and_prices),
      };
    }
    if (object.section?.$case === "info" && object.section?.info !== undefined && object.section?.info !== null) {
      message.section = { $case: "info", info: ProductScreen_Section_Info.fromPartial(object.section.info) };
    }
    if (
      object.section?.$case === "list_with_icons" &&
      object.section?.list_with_icons !== undefined &&
      object.section?.list_with_icons !== null
    ) {
      message.section = {
        $case: "list_with_icons",
        list_with_icons: ProductScreen_Section_ListWithIcons.fromPartial(object.section.list_with_icons),
      };
    }
    if (
      object.section?.$case === "skin_id_match" &&
      object.section?.skin_id_match !== undefined &&
      object.section?.skin_id_match !== null
    ) {
      message.section = {
        $case: "skin_id_match",
        skin_id_match: ProductScreen_Section_SkinIDMatch.fromPartial(object.section.skin_id_match),
      };
    }
    if (
      object.section?.$case === "video_tutorial" &&
      object.section?.video_tutorial !== undefined &&
      object.section?.video_tutorial !== null
    ) {
      message.section = {
        $case: "video_tutorial",
        video_tutorial: ProductScreen_Section_VideoTutorial.fromPartial(object.section.video_tutorial),
      };
    }
    if (
      object.section?.$case === "alternatives" &&
      object.section?.alternatives !== undefined &&
      object.section?.alternatives !== null
    ) {
      message.section = {
        $case: "alternatives",
        alternatives: ProductScreen_Section_Alternatives.fromPartial(object.section.alternatives),
      };
    }
    if (
      object.section?.$case === "tiles_header" &&
      object.section?.tiles_header !== undefined &&
      object.section?.tiles_header !== null
    ) {
      message.section = {
        $case: "tiles_header",
        tiles_header: ProductScreen_Section_TilesHeader.fromPartial(object.section.tiles_header),
      };
    }
    if (
      object.section?.$case === "key_functions" &&
      object.section?.key_functions !== undefined &&
      object.section?.key_functions !== null
    ) {
      message.section = {
        $case: "key_functions",
        key_functions: ProductScreen_Section_KeyFunctions.fromPartial(object.section.key_functions),
      };
    }
    if (
      object.section?.$case === "ingredients" &&
      object.section?.ingredients !== undefined &&
      object.section?.ingredients !== null
    ) {
      message.section = {
        $case: "ingredients",
        ingredients: ProductScreen_Section_Ingredients.fromPartial(object.section.ingredients),
      };
    }
    if (
      object.section?.$case === "DEPRECATED_header" &&
      object.section?.DEPRECATED_header !== undefined &&
      object.section?.DEPRECATED_header !== null
    ) {
      message.section = {
        $case: "DEPRECATED_header",
        DEPRECATED_header: ProductScreen_Section_Header.fromPartial(object.section.DEPRECATED_header),
      };
    }
    return message;
  },
};

function createBaseProductScreen_Section_Header(): ProductScreen_Section_Header {
  return { image_urls: [], brand: "", title: "", subtitle: undefined };
}

export const ProductScreen_Section_Header = {
  fromJSON(object: any): ProductScreen_Section_Header {
    return {
      image_urls: Array.isArray(object?.image_urls) ? object.image_urls.map((e: any) => String(e)) : [],
      brand: isSet(object.brand) ? String(object.brand) : "",
      title: isSet(object.title) ? String(object.title) : "",
      subtitle: isSet(object.subtitle) ? ProductScreen_Section_Header_Subtitle.fromJSON(object.subtitle) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_Header): unknown {
    const obj: any = {};
    if (message.image_urls) {
      obj.image_urls = message.image_urls.map((e) => e);
    } else {
      obj.image_urls = [];
    }
    message.brand !== undefined && (obj.brand = message.brand);
    message.title !== undefined && (obj.title = message.title);
    message.subtitle !== undefined &&
      (obj.subtitle = message.subtitle ? ProductScreen_Section_Header_Subtitle.toJSON(message.subtitle) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_Header>, I>>(base?: I): ProductScreen_Section_Header {
    return ProductScreen_Section_Header.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_Header>, I>>(object: I): ProductScreen_Section_Header {
    const message = createBaseProductScreen_Section_Header();
    message.image_urls = object.image_urls?.map((e) => e) || [];
    message.brand = object.brand ?? "";
    message.title = object.title ?? "";
    message.subtitle = (object.subtitle !== undefined && object.subtitle !== null)
      ? ProductScreen_Section_Header_Subtitle.fromPartial(object.subtitle)
      : undefined;
    return message;
  },
};

function createBaseProductScreen_Section_Header_Subtitle(): ProductScreen_Section_Header_Subtitle {
  return { items: [] };
}

export const ProductScreen_Section_Header_Subtitle = {
  fromJSON(object: any): ProductScreen_Section_Header_Subtitle {
    return {
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => ProductScreen_Section_Header_Subtitle_Item.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductScreen_Section_Header_Subtitle): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map((e) => e ? ProductScreen_Section_Header_Subtitle_Item.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_Header_Subtitle>, I>>(
    base?: I,
  ): ProductScreen_Section_Header_Subtitle {
    return ProductScreen_Section_Header_Subtitle.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_Header_Subtitle>, I>>(
    object: I,
  ): ProductScreen_Section_Header_Subtitle {
    const message = createBaseProductScreen_Section_Header_Subtitle();
    message.items = object.items?.map((e) => ProductScreen_Section_Header_Subtitle_Item.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductScreen_Section_Header_Subtitle_Item(): ProductScreen_Section_Header_Subtitle_Item {
  return { item: undefined };
}

export const ProductScreen_Section_Header_Subtitle_Item = {
  fromJSON(object: any): ProductScreen_Section_Header_Subtitle_Item {
    return {
      item: isSet(object.text)
        ? { $case: "text", text: String(object.text) }
        : isSet(object.rating)
        ? { $case: "rating", rating: String(object.rating) }
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_Header_Subtitle_Item): unknown {
    const obj: any = {};
    message.item?.$case === "text" && (obj.text = message.item?.text);
    message.item?.$case === "rating" && (obj.rating = message.item?.rating);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_Header_Subtitle_Item>, I>>(
    base?: I,
  ): ProductScreen_Section_Header_Subtitle_Item {
    return ProductScreen_Section_Header_Subtitle_Item.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_Header_Subtitle_Item>, I>>(
    object: I,
  ): ProductScreen_Section_Header_Subtitle_Item {
    const message = createBaseProductScreen_Section_Header_Subtitle_Item();
    if (object.item?.$case === "text" && object.item?.text !== undefined && object.item?.text !== null) {
      message.item = { $case: "text", text: object.item.text };
    }
    if (object.item?.$case === "rating" && object.item?.rating !== undefined && object.item?.rating !== null) {
      message.item = { $case: "rating", rating: object.item.rating };
    }
    return message;
  },
};

function createBaseProductScreen_Section_SkinIDMatch(): ProductScreen_Section_SkinIDMatch {
  return { items: [], header: undefined };
}

export const ProductScreen_Section_SkinIDMatch = {
  fromJSON(object: any): ProductScreen_Section_SkinIDMatch {
    return {
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => ProductScreen_Section_SkinIDMatch_Item.fromJSON(e))
        : [],
      header: isSet(object.header) ? ProductScreen_Section_SkinIDMatch_Header.fromJSON(object.header) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_SkinIDMatch): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map((e) => e ? ProductScreen_Section_SkinIDMatch_Item.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    message.header !== undefined &&
      (obj.header = message.header ? ProductScreen_Section_SkinIDMatch_Header.toJSON(message.header) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_SkinIDMatch>, I>>(
    base?: I,
  ): ProductScreen_Section_SkinIDMatch {
    return ProductScreen_Section_SkinIDMatch.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_SkinIDMatch>, I>>(
    object: I,
  ): ProductScreen_Section_SkinIDMatch {
    const message = createBaseProductScreen_Section_SkinIDMatch();
    message.items = object.items?.map((e) => ProductScreen_Section_SkinIDMatch_Item.fromPartial(e)) || [];
    message.header = (object.header !== undefined && object.header !== null)
      ? ProductScreen_Section_SkinIDMatch_Header.fromPartial(object.header)
      : undefined;
    return message;
  },
};

function createBaseProductScreen_Section_SkinIDMatch_Header(): ProductScreen_Section_SkinIDMatch_Header {
  return { qualification: ProductQualification.PRODUCT_QUALIFICATION_NONE, title: "", message: "" };
}

export const ProductScreen_Section_SkinIDMatch_Header = {
  fromJSON(object: any): ProductScreen_Section_SkinIDMatch_Header {
    return {
      qualification: isSet(object.qualification)
        ? productQualificationFromJSON(object.qualification)
        : ProductQualification.PRODUCT_QUALIFICATION_NONE,
      title: isSet(object.title) ? String(object.title) : "",
      message: isSet(object.message) ? String(object.message) : "",
    };
  },

  toJSON(message: ProductScreen_Section_SkinIDMatch_Header): unknown {
    const obj: any = {};
    message.qualification !== undefined && (obj.qualification = productQualificationToJSON(message.qualification));
    message.title !== undefined && (obj.title = message.title);
    message.message !== undefined && (obj.message = message.message);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_SkinIDMatch_Header>, I>>(
    base?: I,
  ): ProductScreen_Section_SkinIDMatch_Header {
    return ProductScreen_Section_SkinIDMatch_Header.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_SkinIDMatch_Header>, I>>(
    object: I,
  ): ProductScreen_Section_SkinIDMatch_Header {
    const message = createBaseProductScreen_Section_SkinIDMatch_Header();
    message.qualification = object.qualification ?? ProductQualification.PRODUCT_QUALIFICATION_NONE;
    message.title = object.title ?? "";
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseProductScreen_Section_SkinIDMatch_Item(): ProductScreen_Section_SkinIDMatch_Item {
  return { qualification: ProductQualification.PRODUCT_QUALIFICATION_NONE, title: "", content: undefined };
}

export const ProductScreen_Section_SkinIDMatch_Item = {
  fromJSON(object: any): ProductScreen_Section_SkinIDMatch_Item {
    return {
      qualification: isSet(object.qualification)
        ? productQualificationFromJSON(object.qualification)
        : ProductQualification.PRODUCT_QUALIFICATION_NONE,
      title: isSet(object.title) ? String(object.title) : "",
      content: isSet(object.content)
        ? ProductScreen_Section_SkinIDMatch_Item_Content.fromJSON(object.content)
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_SkinIDMatch_Item): unknown {
    const obj: any = {};
    message.qualification !== undefined && (obj.qualification = productQualificationToJSON(message.qualification));
    message.title !== undefined && (obj.title = message.title);
    message.content !== undefined && (obj.content = message.content
      ? ProductScreen_Section_SkinIDMatch_Item_Content.toJSON(message.content)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_SkinIDMatch_Item>, I>>(
    base?: I,
  ): ProductScreen_Section_SkinIDMatch_Item {
    return ProductScreen_Section_SkinIDMatch_Item.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_SkinIDMatch_Item>, I>>(
    object: I,
  ): ProductScreen_Section_SkinIDMatch_Item {
    const message = createBaseProductScreen_Section_SkinIDMatch_Item();
    message.qualification = object.qualification ?? ProductQualification.PRODUCT_QUALIFICATION_NONE;
    message.title = object.title ?? "";
    message.content = (object.content !== undefined && object.content !== null)
      ? ProductScreen_Section_SkinIDMatch_Item_Content.fromPartial(object.content)
      : undefined;
    return message;
  },
};

function createBaseProductScreen_Section_SkinIDMatch_Item_Content(): ProductScreen_Section_SkinIDMatch_Item_Content {
  return { content: undefined };
}

export const ProductScreen_Section_SkinIDMatch_Item_Content = {
  fromJSON(object: any): ProductScreen_Section_SkinIDMatch_Item_Content {
    return {
      content: isSet(object.list)
        ? { $case: "list", list: ProductScreen_Section_SkinIDMatch_Item_Content_List.fromJSON(object.list) }
        : isSet(object.banners)
        ? { $case: "banners", banners: ProductScreen_Section_SkinIDMatch_Item_Content_Banners.fromJSON(object.banners) }
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_SkinIDMatch_Item_Content): unknown {
    const obj: any = {};
    message.content?.$case === "list" && (obj.list = message.content?.list
      ? ProductScreen_Section_SkinIDMatch_Item_Content_List.toJSON(message.content?.list)
      : undefined);
    message.content?.$case === "banners" && (obj.banners = message.content?.banners
      ? ProductScreen_Section_SkinIDMatch_Item_Content_Banners.toJSON(message.content?.banners)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_SkinIDMatch_Item_Content>, I>>(
    base?: I,
  ): ProductScreen_Section_SkinIDMatch_Item_Content {
    return ProductScreen_Section_SkinIDMatch_Item_Content.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_SkinIDMatch_Item_Content>, I>>(
    object: I,
  ): ProductScreen_Section_SkinIDMatch_Item_Content {
    const message = createBaseProductScreen_Section_SkinIDMatch_Item_Content();
    if (object.content?.$case === "list" && object.content?.list !== undefined && object.content?.list !== null) {
      message.content = {
        $case: "list",
        list: ProductScreen_Section_SkinIDMatch_Item_Content_List.fromPartial(object.content.list),
      };
    }
    if (
      object.content?.$case === "banners" && object.content?.banners !== undefined && object.content?.banners !== null
    ) {
      message.content = {
        $case: "banners",
        banners: ProductScreen_Section_SkinIDMatch_Item_Content_Banners.fromPartial(object.content.banners),
      };
    }
    return message;
  },
};

function createBaseProductScreen_Section_SkinIDMatch_Item_Content_List(): ProductScreen_Section_SkinIDMatch_Item_Content_List {
  return { items: [] };
}

export const ProductScreen_Section_SkinIDMatch_Item_Content_List = {
  fromJSON(object: any): ProductScreen_Section_SkinIDMatch_Item_Content_List {
    return {
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => ProductScreen_Section_SkinIDMatch_Item_Content_List_Item.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductScreen_Section_SkinIDMatch_Item_Content_List): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map((e) =>
        e ? ProductScreen_Section_SkinIDMatch_Item_Content_List_Item.toJSON(e) : undefined
      );
    } else {
      obj.items = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_SkinIDMatch_Item_Content_List>, I>>(
    base?: I,
  ): ProductScreen_Section_SkinIDMatch_Item_Content_List {
    return ProductScreen_Section_SkinIDMatch_Item_Content_List.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_SkinIDMatch_Item_Content_List>, I>>(
    object: I,
  ): ProductScreen_Section_SkinIDMatch_Item_Content_List {
    const message = createBaseProductScreen_Section_SkinIDMatch_Item_Content_List();
    message.items = object.items?.map((e) => ProductScreen_Section_SkinIDMatch_Item_Content_List_Item.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseProductScreen_Section_SkinIDMatch_Item_Content_List_Item(): ProductScreen_Section_SkinIDMatch_Item_Content_List_Item {
  return { icon: "", text: "" };
}

export const ProductScreen_Section_SkinIDMatch_Item_Content_List_Item = {
  fromJSON(object: any): ProductScreen_Section_SkinIDMatch_Item_Content_List_Item {
    return { icon: isSet(object.icon) ? String(object.icon) : "", text: isSet(object.text) ? String(object.text) : "" };
  },

  toJSON(message: ProductScreen_Section_SkinIDMatch_Item_Content_List_Item): unknown {
    const obj: any = {};
    message.icon !== undefined && (obj.icon = message.icon);
    message.text !== undefined && (obj.text = message.text);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_SkinIDMatch_Item_Content_List_Item>, I>>(
    base?: I,
  ): ProductScreen_Section_SkinIDMatch_Item_Content_List_Item {
    return ProductScreen_Section_SkinIDMatch_Item_Content_List_Item.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_SkinIDMatch_Item_Content_List_Item>, I>>(
    object: I,
  ): ProductScreen_Section_SkinIDMatch_Item_Content_List_Item {
    const message = createBaseProductScreen_Section_SkinIDMatch_Item_Content_List_Item();
    message.icon = object.icon ?? "";
    message.text = object.text ?? "";
    return message;
  },
};

function createBaseProductScreen_Section_SkinIDMatch_Item_Content_Banners(): ProductScreen_Section_SkinIDMatch_Item_Content_Banners {
  return { banners: [] };
}

export const ProductScreen_Section_SkinIDMatch_Item_Content_Banners = {
  fromJSON(object: any): ProductScreen_Section_SkinIDMatch_Item_Content_Banners {
    return {
      banners: Array.isArray(object?.banners)
        ? object.banners.map((e: any) => ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductScreen_Section_SkinIDMatch_Item_Content_Banners): unknown {
    const obj: any = {};
    if (message.banners) {
      obj.banners = message.banners.map((e) =>
        e ? ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner.toJSON(e) : undefined
      );
    } else {
      obj.banners = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_SkinIDMatch_Item_Content_Banners>, I>>(
    base?: I,
  ): ProductScreen_Section_SkinIDMatch_Item_Content_Banners {
    return ProductScreen_Section_SkinIDMatch_Item_Content_Banners.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_SkinIDMatch_Item_Content_Banners>, I>>(
    object: I,
  ): ProductScreen_Section_SkinIDMatch_Item_Content_Banners {
    const message = createBaseProductScreen_Section_SkinIDMatch_Item_Content_Banners();
    message.banners =
      object.banners?.map((e) => ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner(): ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner {
  return { title: "", items: [] };
}

export const ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner = {
  fromJSON(object: any): ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner_Item.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    if (message.items) {
      obj.items = message.items.map((e) =>
        e ? ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner_Item.toJSON(e) : undefined
      );
    } else {
      obj.items = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner>, I>>(
    base?: I,
  ): ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner {
    return ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner>, I>>(
    object: I,
  ): ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner {
    const message = createBaseProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner();
    message.title = object.title ?? "";
    message.items =
      object.items?.map((e) => ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner_Item.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner_Item(): ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner_Item {
  return { icon: "", text: "" };
}

export const ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner_Item = {
  fromJSON(object: any): ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner_Item {
    return { icon: isSet(object.icon) ? String(object.icon) : "", text: isSet(object.text) ? String(object.text) : "" };
  },

  toJSON(message: ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner_Item): unknown {
    const obj: any = {};
    message.icon !== undefined && (obj.icon = message.icon);
    message.text !== undefined && (obj.text = message.text);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner_Item>, I>>(
    base?: I,
  ): ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner_Item {
    return ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner_Item.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner_Item>, I>>(
    object: I,
  ): ProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner_Item {
    const message = createBaseProductScreen_Section_SkinIDMatch_Item_Content_Banners_Banner_Item();
    message.icon = object.icon ?? "";
    message.text = object.text ?? "";
    return message;
  },
};

function createBaseProductScreen_Section_RatingsAndPrices(): ProductScreen_Section_RatingsAndPrices {
  return { title: "", average_rating: undefined, stores: [] };
}

export const ProductScreen_Section_RatingsAndPrices = {
  fromJSON(object: any): ProductScreen_Section_RatingsAndPrices {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      average_rating: isSet(object.average_rating)
        ? ProductScreen_Section_RatingsAndPrices_AverageRating.fromJSON(object.average_rating)
        : undefined,
      stores: Array.isArray(object?.stores)
        ? object.stores.map((e: any) => ProductScreen_Section_RatingsAndPrices_Store.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductScreen_Section_RatingsAndPrices): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.average_rating !== undefined && (obj.average_rating = message.average_rating
      ? ProductScreen_Section_RatingsAndPrices_AverageRating.toJSON(message.average_rating)
      : undefined);
    if (message.stores) {
      obj.stores = message.stores.map((e) => e ? ProductScreen_Section_RatingsAndPrices_Store.toJSON(e) : undefined);
    } else {
      obj.stores = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_RatingsAndPrices>, I>>(
    base?: I,
  ): ProductScreen_Section_RatingsAndPrices {
    return ProductScreen_Section_RatingsAndPrices.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_RatingsAndPrices>, I>>(
    object: I,
  ): ProductScreen_Section_RatingsAndPrices {
    const message = createBaseProductScreen_Section_RatingsAndPrices();
    message.title = object.title ?? "";
    message.average_rating = (object.average_rating !== undefined && object.average_rating !== null)
      ? ProductScreen_Section_RatingsAndPrices_AverageRating.fromPartial(object.average_rating)
      : undefined;
    message.stores = object.stores?.map((e) => ProductScreen_Section_RatingsAndPrices_Store.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductScreen_Section_RatingsAndPrices_AverageRating(): ProductScreen_Section_RatingsAndPrices_AverageRating {
  return { value: "", subtitle: "" };
}

export const ProductScreen_Section_RatingsAndPrices_AverageRating = {
  fromJSON(object: any): ProductScreen_Section_RatingsAndPrices_AverageRating {
    return {
      value: isSet(object.value) ? String(object.value) : "",
      subtitle: isSet(object.subtitle) ? String(object.subtitle) : "",
    };
  },

  toJSON(message: ProductScreen_Section_RatingsAndPrices_AverageRating): unknown {
    const obj: any = {};
    message.value !== undefined && (obj.value = message.value);
    message.subtitle !== undefined && (obj.subtitle = message.subtitle);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_RatingsAndPrices_AverageRating>, I>>(
    base?: I,
  ): ProductScreen_Section_RatingsAndPrices_AverageRating {
    return ProductScreen_Section_RatingsAndPrices_AverageRating.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_RatingsAndPrices_AverageRating>, I>>(
    object: I,
  ): ProductScreen_Section_RatingsAndPrices_AverageRating {
    const message = createBaseProductScreen_Section_RatingsAndPrices_AverageRating();
    message.value = object.value ?? "";
    message.subtitle = object.subtitle ?? "";
    return message;
  },
};

function createBaseProductScreen_Section_RatingsAndPrices_Store(): ProductScreen_Section_RatingsAndPrices_Store {
  return {
    image_url: "",
    name: "",
    price: "",
    DEPRECATED_rating: "",
    DEPRECATED_rating_subtitle: "",
    web_page_url: "",
    qualification: undefined,
  };
}

export const ProductScreen_Section_RatingsAndPrices_Store = {
  fromJSON(object: any): ProductScreen_Section_RatingsAndPrices_Store {
    return {
      image_url: isSet(object.image_url) ? String(object.image_url) : "",
      name: isSet(object.name) ? String(object.name) : "",
      price: isSet(object.price) ? String(object.price) : "",
      DEPRECATED_rating: isSet(object.DEPRECATED_rating) ? String(object.DEPRECATED_rating) : "",
      DEPRECATED_rating_subtitle: isSet(object.DEPRECATED_rating_subtitle)
        ? String(object.DEPRECATED_rating_subtitle)
        : "",
      web_page_url: isSet(object.web_page_url) ? String(object.web_page_url) : "",
      qualification: isSet(object.rating)
        ? { $case: "rating", rating: ProductScreen_Section_RatingsAndPrices_Store_Rating.fromJSON(object.rating) }
        : isSet(object.text)
        ? { $case: "text", text: String(object.text) }
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_RatingsAndPrices_Store): unknown {
    const obj: any = {};
    message.image_url !== undefined && (obj.image_url = message.image_url);
    message.name !== undefined && (obj.name = message.name);
    message.price !== undefined && (obj.price = message.price);
    message.DEPRECATED_rating !== undefined && (obj.DEPRECATED_rating = message.DEPRECATED_rating);
    message.DEPRECATED_rating_subtitle !== undefined &&
      (obj.DEPRECATED_rating_subtitle = message.DEPRECATED_rating_subtitle);
    message.web_page_url !== undefined && (obj.web_page_url = message.web_page_url);
    message.qualification?.$case === "rating" && (obj.rating = message.qualification?.rating
      ? ProductScreen_Section_RatingsAndPrices_Store_Rating.toJSON(message.qualification?.rating)
      : undefined);
    message.qualification?.$case === "text" && (obj.text = message.qualification?.text);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_RatingsAndPrices_Store>, I>>(
    base?: I,
  ): ProductScreen_Section_RatingsAndPrices_Store {
    return ProductScreen_Section_RatingsAndPrices_Store.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_RatingsAndPrices_Store>, I>>(
    object: I,
  ): ProductScreen_Section_RatingsAndPrices_Store {
    const message = createBaseProductScreen_Section_RatingsAndPrices_Store();
    message.image_url = object.image_url ?? "";
    message.name = object.name ?? "";
    message.price = object.price ?? "";
    message.DEPRECATED_rating = object.DEPRECATED_rating ?? "";
    message.DEPRECATED_rating_subtitle = object.DEPRECATED_rating_subtitle ?? "";
    message.web_page_url = object.web_page_url ?? "";
    if (
      object.qualification?.$case === "rating" &&
      object.qualification?.rating !== undefined &&
      object.qualification?.rating !== null
    ) {
      message.qualification = {
        $case: "rating",
        rating: ProductScreen_Section_RatingsAndPrices_Store_Rating.fromPartial(object.qualification.rating),
      };
    }
    if (
      object.qualification?.$case === "text" &&
      object.qualification?.text !== undefined &&
      object.qualification?.text !== null
    ) {
      message.qualification = { $case: "text", text: object.qualification.text };
    }
    return message;
  },
};

function createBaseProductScreen_Section_RatingsAndPrices_Store_Rating(): ProductScreen_Section_RatingsAndPrices_Store_Rating {
  return { value: "", subtitle: "", fade: false };
}

export const ProductScreen_Section_RatingsAndPrices_Store_Rating = {
  fromJSON(object: any): ProductScreen_Section_RatingsAndPrices_Store_Rating {
    return {
      value: isSet(object.value) ? String(object.value) : "",
      subtitle: isSet(object.subtitle) ? String(object.subtitle) : "",
      fade: isSet(object.fade) ? Boolean(object.fade) : false,
    };
  },

  toJSON(message: ProductScreen_Section_RatingsAndPrices_Store_Rating): unknown {
    const obj: any = {};
    message.value !== undefined && (obj.value = message.value);
    message.subtitle !== undefined && (obj.subtitle = message.subtitle);
    message.fade !== undefined && (obj.fade = message.fade);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_RatingsAndPrices_Store_Rating>, I>>(
    base?: I,
  ): ProductScreen_Section_RatingsAndPrices_Store_Rating {
    return ProductScreen_Section_RatingsAndPrices_Store_Rating.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_RatingsAndPrices_Store_Rating>, I>>(
    object: I,
  ): ProductScreen_Section_RatingsAndPrices_Store_Rating {
    const message = createBaseProductScreen_Section_RatingsAndPrices_Store_Rating();
    message.value = object.value ?? "";
    message.subtitle = object.subtitle ?? "";
    message.fade = object.fade ?? false;
    return message;
  },
};

function createBaseProductScreen_Section_Info(): ProductScreen_Section_Info {
  return {
    title: "",
    DEPRECATED_info: "",
    attributed_info: undefined,
    lazy_request_data: undefined,
    button: undefined,
  };
}

export const ProductScreen_Section_Info = {
  fromJSON(object: any): ProductScreen_Section_Info {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      DEPRECATED_info: isSet(object.DEPRECATED_info) ? String(object.DEPRECATED_info) : "",
      attributed_info: isSet(object.attributed_info) ? AttributedString.fromJSON(object.attributed_info) : undefined,
      lazy_request_data: isSet(object.lazy_request_data)
        ? LazyProductSectionRequestData.fromJSON(object.lazy_request_data)
        : undefined,
      button: isSet(object.button) ? ProductScreen_Section_Info_Button.fromJSON(object.button) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_Info): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.DEPRECATED_info !== undefined && (obj.DEPRECATED_info = message.DEPRECATED_info);
    message.attributed_info !== undefined &&
      (obj.attributed_info = message.attributed_info ? AttributedString.toJSON(message.attributed_info) : undefined);
    message.lazy_request_data !== undefined && (obj.lazy_request_data = message.lazy_request_data
      ? LazyProductSectionRequestData.toJSON(message.lazy_request_data)
      : undefined);
    message.button !== undefined &&
      (obj.button = message.button ? ProductScreen_Section_Info_Button.toJSON(message.button) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_Info>, I>>(base?: I): ProductScreen_Section_Info {
    return ProductScreen_Section_Info.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_Info>, I>>(object: I): ProductScreen_Section_Info {
    const message = createBaseProductScreen_Section_Info();
    message.title = object.title ?? "";
    message.DEPRECATED_info = object.DEPRECATED_info ?? "";
    message.attributed_info = (object.attributed_info !== undefined && object.attributed_info !== null)
      ? AttributedString.fromPartial(object.attributed_info)
      : undefined;
    message.lazy_request_data = (object.lazy_request_data !== undefined && object.lazy_request_data !== null)
      ? LazyProductSectionRequestData.fromPartial(object.lazy_request_data)
      : undefined;
    message.button = (object.button !== undefined && object.button !== null)
      ? ProductScreen_Section_Info_Button.fromPartial(object.button)
      : undefined;
    return message;
  },
};

function createBaseProductScreen_Section_Info_Button(): ProductScreen_Section_Info_Button {
  return { title: "", info_screen: undefined };
}

export const ProductScreen_Section_Info_Button = {
  fromJSON(object: any): ProductScreen_Section_Info_Button {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      info_screen: isSet(object.info_screen) ? ProductInfoScreen.fromJSON(object.info_screen) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_Info_Button): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.info_screen !== undefined &&
      (obj.info_screen = message.info_screen ? ProductInfoScreen.toJSON(message.info_screen) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_Info_Button>, I>>(
    base?: I,
  ): ProductScreen_Section_Info_Button {
    return ProductScreen_Section_Info_Button.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_Info_Button>, I>>(
    object: I,
  ): ProductScreen_Section_Info_Button {
    const message = createBaseProductScreen_Section_Info_Button();
    message.title = object.title ?? "";
    message.info_screen = (object.info_screen !== undefined && object.info_screen !== null)
      ? ProductInfoScreen.fromPartial(object.info_screen)
      : undefined;
    return message;
  },
};

function createBaseProductScreen_Section_ListWithIcons(): ProductScreen_Section_ListWithIcons {
  return { title: "", items: [] };
}

export const ProductScreen_Section_ListWithIcons = {
  fromJSON(object: any): ProductScreen_Section_ListWithIcons {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => ProductScreen_Section_ListWithIcons_Item.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductScreen_Section_ListWithIcons): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    if (message.items) {
      obj.items = message.items.map((e) => e ? ProductScreen_Section_ListWithIcons_Item.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_ListWithIcons>, I>>(
    base?: I,
  ): ProductScreen_Section_ListWithIcons {
    return ProductScreen_Section_ListWithIcons.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_ListWithIcons>, I>>(
    object: I,
  ): ProductScreen_Section_ListWithIcons {
    const message = createBaseProductScreen_Section_ListWithIcons();
    message.title = object.title ?? "";
    message.items = object.items?.map((e) => ProductScreen_Section_ListWithIcons_Item.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductScreen_Section_ListWithIcons_Item(): ProductScreen_Section_ListWithIcons_Item {
  return { icon: "", text: "" };
}

export const ProductScreen_Section_ListWithIcons_Item = {
  fromJSON(object: any): ProductScreen_Section_ListWithIcons_Item {
    return { icon: isSet(object.icon) ? String(object.icon) : "", text: isSet(object.text) ? String(object.text) : "" };
  },

  toJSON(message: ProductScreen_Section_ListWithIcons_Item): unknown {
    const obj: any = {};
    message.icon !== undefined && (obj.icon = message.icon);
    message.text !== undefined && (obj.text = message.text);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_ListWithIcons_Item>, I>>(
    base?: I,
  ): ProductScreen_Section_ListWithIcons_Item {
    return ProductScreen_Section_ListWithIcons_Item.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_ListWithIcons_Item>, I>>(
    object: I,
  ): ProductScreen_Section_ListWithIcons_Item {
    const message = createBaseProductScreen_Section_ListWithIcons_Item();
    message.icon = object.icon ?? "";
    message.text = object.text ?? "";
    return message;
  },
};

function createBaseProductScreen_Section_VideoTutorial(): ProductScreen_Section_VideoTutorial {
  return { section_title: "", tutorial_title: "", image_url: "", tags: [], action: undefined };
}

export const ProductScreen_Section_VideoTutorial = {
  fromJSON(object: any): ProductScreen_Section_VideoTutorial {
    return {
      section_title: isSet(object.section_title) ? String(object.section_title) : "",
      tutorial_title: isSet(object.tutorial_title) ? String(object.tutorial_title) : "",
      image_url: isSet(object.image_url) ? String(object.image_url) : "",
      tags: Array.isArray(object?.tags) ? object.tags.map((e: any) => Tag.fromJSON(e)) : [],
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_VideoTutorial): unknown {
    const obj: any = {};
    message.section_title !== undefined && (obj.section_title = message.section_title);
    message.tutorial_title !== undefined && (obj.tutorial_title = message.tutorial_title);
    message.image_url !== undefined && (obj.image_url = message.image_url);
    if (message.tags) {
      obj.tags = message.tags.map((e) => e ? Tag.toJSON(e) : undefined);
    } else {
      obj.tags = [];
    }
    message.action !== undefined && (obj.action = message.action ? ActionType.toJSON(message.action) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_VideoTutorial>, I>>(
    base?: I,
  ): ProductScreen_Section_VideoTutorial {
    return ProductScreen_Section_VideoTutorial.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_VideoTutorial>, I>>(
    object: I,
  ): ProductScreen_Section_VideoTutorial {
    const message = createBaseProductScreen_Section_VideoTutorial();
    message.section_title = object.section_title ?? "";
    message.tutorial_title = object.tutorial_title ?? "";
    message.image_url = object.image_url ?? "";
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.action = (object.action !== undefined && object.action !== null)
      ? ActionType.fromPartial(object.action)
      : undefined;
    return message;
  },
};

function createBaseProductScreen_Section_Alternatives(): ProductScreen_Section_Alternatives {
  return { title: "", cards: [], lazy_request_data: undefined };
}

export const ProductScreen_Section_Alternatives = {
  fromJSON(object: any): ProductScreen_Section_Alternatives {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      cards: Array.isArray(object?.cards)
        ? object.cards.map((e: any) => ProductScreen_Section_Alternatives_Card.fromJSON(e))
        : [],
      lazy_request_data: isSet(object.lazy_request_data)
        ? LazyProductSectionRequestData.fromJSON(object.lazy_request_data)
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_Alternatives): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    if (message.cards) {
      obj.cards = message.cards.map((e) => e ? ProductScreen_Section_Alternatives_Card.toJSON(e) : undefined);
    } else {
      obj.cards = [];
    }
    message.lazy_request_data !== undefined && (obj.lazy_request_data = message.lazy_request_data
      ? LazyProductSectionRequestData.toJSON(message.lazy_request_data)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_Alternatives>, I>>(
    base?: I,
  ): ProductScreen_Section_Alternatives {
    return ProductScreen_Section_Alternatives.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_Alternatives>, I>>(
    object: I,
  ): ProductScreen_Section_Alternatives {
    const message = createBaseProductScreen_Section_Alternatives();
    message.title = object.title ?? "";
    message.cards = object.cards?.map((e) => ProductScreen_Section_Alternatives_Card.fromPartial(e)) || [];
    message.lazy_request_data = (object.lazy_request_data !== undefined && object.lazy_request_data !== null)
      ? LazyProductSectionRequestData.fromPartial(object.lazy_request_data)
      : undefined;
    return message;
  },
};

function createBaseProductScreen_Section_Alternatives_Card(): ProductScreen_Section_Alternatives_Card {
  return {
    brand: "",
    title: "",
    price: "",
    image: "",
    tags: [],
    product_screen: undefined,
    pora_score: undefined,
    save_button: undefined,
    should_show_save_loading: false,
    should_disable_save_button: false,
    product_id: "",
  };
}

export const ProductScreen_Section_Alternatives_Card = {
  fromJSON(object: any): ProductScreen_Section_Alternatives_Card {
    return {
      brand: isSet(object.brand) ? String(object.brand) : "",
      title: isSet(object.title) ? String(object.title) : "",
      price: isSet(object.price) ? String(object.price) : "",
      image: isSet(object.image) ? String(object.image) : "",
      tags: Array.isArray(object?.tags)
        ? object.tags.map((e: any) => Tag.fromJSON(e))
        : [],
      product_screen: isSet(object.product_screen) ? ProductScreen.fromJSON(object.product_screen) : undefined,
      pora_score: isSet(object.pora_score) ? ProductPoraScore.fromJSON(object.pora_score) : undefined,
      save_button: isSet(object.save_button) ? ImageButton.fromJSON(object.save_button) : undefined,
      should_show_save_loading: isSet(object.should_show_save_loading)
        ? Boolean(object.should_show_save_loading)
        : false,
      should_disable_save_button: isSet(object.should_disable_save_button)
        ? Boolean(object.should_disable_save_button)
        : false,
      product_id: isSet(object.product_id) ? String(object.product_id) : "",
    };
  },

  toJSON(message: ProductScreen_Section_Alternatives_Card): unknown {
    const obj: any = {};
    message.brand !== undefined && (obj.brand = message.brand);
    message.title !== undefined && (obj.title = message.title);
    message.price !== undefined && (obj.price = message.price);
    message.image !== undefined && (obj.image = message.image);
    if (message.tags) {
      obj.tags = message.tags.map((e) => e ? Tag.toJSON(e) : undefined);
    } else {
      obj.tags = [];
    }
    message.product_screen !== undefined &&
      (obj.product_screen = message.product_screen ? ProductScreen.toJSON(message.product_screen) : undefined);
    message.pora_score !== undefined &&
      (obj.pora_score = message.pora_score ? ProductPoraScore.toJSON(message.pora_score) : undefined);
    message.save_button !== undefined &&
      (obj.save_button = message.save_button ? ImageButton.toJSON(message.save_button) : undefined);
    message.should_show_save_loading !== undefined && (obj.should_show_save_loading = message.should_show_save_loading);
    message.should_disable_save_button !== undefined &&
      (obj.should_disable_save_button = message.should_disable_save_button);
    message.product_id !== undefined && (obj.product_id = message.product_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_Alternatives_Card>, I>>(
    base?: I,
  ): ProductScreen_Section_Alternatives_Card {
    return ProductScreen_Section_Alternatives_Card.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_Alternatives_Card>, I>>(
    object: I,
  ): ProductScreen_Section_Alternatives_Card {
    const message = createBaseProductScreen_Section_Alternatives_Card();
    message.brand = object.brand ?? "";
    message.title = object.title ?? "";
    message.price = object.price ?? "";
    message.image = object.image ?? "";
    message.tags = object.tags?.map((e) => Tag.fromPartial(e)) || [];
    message.product_screen = (object.product_screen !== undefined && object.product_screen !== null)
      ? ProductScreen.fromPartial(object.product_screen)
      : undefined;
    message.pora_score = (object.pora_score !== undefined && object.pora_score !== null)
      ? ProductPoraScore.fromPartial(object.pora_score)
      : undefined;
    message.save_button = (object.save_button !== undefined && object.save_button !== null)
      ? ImageButton.fromPartial(object.save_button)
      : undefined;
    message.should_show_save_loading = object.should_show_save_loading ?? false;
    message.should_disable_save_button = object.should_disable_save_button ?? false;
    message.product_id = object.product_id ?? "";
    return message;
  },
};

function createBaseProductScreen_Section_TilesHeader(): ProductScreen_Section_TilesHeader {
  return {
    images: undefined,
    key_functions: undefined,
    title: undefined,
    shortcuts: [],
    shortcut_rows: undefined,
    product_id: "",
    pora_score: undefined,
    text_review: undefined,
    assistant_summary: undefined,
    grid: undefined,
  };
}

export const ProductScreen_Section_TilesHeader = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader {
    return {
      images: isSet(object.images) ? ProductScreen_Section_TilesHeader_Images.fromJSON(object.images) : undefined,
      key_functions: isSet(object.key_functions)
        ? ProductScreen_Section_TilesHeader_KeyFunctions.fromJSON(object.key_functions)
        : undefined,
      title: isSet(object.title) ? ProductScreen_Section_TilesHeader_Title.fromJSON(object.title) : undefined,
      shortcuts: Array.isArray(object?.shortcuts)
        ? object.shortcuts.map((e: any) => ProductScreen_Section_TilesHeader_Shortcut.fromJSON(e))
        : [],
      shortcut_rows: isSet(object.shortcut_rows)
        ? ProductScreen_Section_TilesHeader_Shortcuts.fromJSON(object.shortcut_rows)
        : undefined,
      product_id: isSet(object.product_id) ? String(object.product_id) : "",
      pora_score: isSet(object.pora_score)
        ? ProductScreen_Section_TilesHeader_Score.fromJSON(object.pora_score)
        : undefined,
      text_review: isSet(object.text_review)
        ? ProductScreen_Section_TilesHeader_TextReview.fromJSON(object.text_review)
        : undefined,
      assistant_summary: isSet(object.assistant_summary)
        ? ProductScreen_Section_TilesHeader_AssistantSummary.fromJSON(object.assistant_summary)
        : undefined,
      grid: isSet(object.grid) ? ProductScreen_Section_TilesHeader_Grid.fromJSON(object.grid) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader): unknown {
    const obj: any = {};
    message.images !== undefined &&
      (obj.images = message.images ? ProductScreen_Section_TilesHeader_Images.toJSON(message.images) : undefined);
    message.key_functions !== undefined && (obj.key_functions = message.key_functions
      ? ProductScreen_Section_TilesHeader_KeyFunctions.toJSON(message.key_functions)
      : undefined);
    message.title !== undefined &&
      (obj.title = message.title ? ProductScreen_Section_TilesHeader_Title.toJSON(message.title) : undefined);
    if (message.shortcuts) {
      obj.shortcuts = message.shortcuts.map((e) =>
        e ? ProductScreen_Section_TilesHeader_Shortcut.toJSON(e) : undefined
      );
    } else {
      obj.shortcuts = [];
    }
    message.shortcut_rows !== undefined && (obj.shortcut_rows = message.shortcut_rows
      ? ProductScreen_Section_TilesHeader_Shortcuts.toJSON(message.shortcut_rows)
      : undefined);
    message.product_id !== undefined && (obj.product_id = message.product_id);
    message.pora_score !== undefined && (obj.pora_score = message.pora_score
      ? ProductScreen_Section_TilesHeader_Score.toJSON(message.pora_score)
      : undefined);
    message.text_review !== undefined && (obj.text_review = message.text_review
      ? ProductScreen_Section_TilesHeader_TextReview.toJSON(message.text_review)
      : undefined);
    message.assistant_summary !== undefined && (obj.assistant_summary = message.assistant_summary
      ? ProductScreen_Section_TilesHeader_AssistantSummary.toJSON(message.assistant_summary)
      : undefined);
    message.grid !== undefined &&
      (obj.grid = message.grid ? ProductScreen_Section_TilesHeader_Grid.toJSON(message.grid) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader>, I>>(
    base?: I,
  ): ProductScreen_Section_TilesHeader {
    return ProductScreen_Section_TilesHeader.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader>, I>>(
    object: I,
  ): ProductScreen_Section_TilesHeader {
    const message = createBaseProductScreen_Section_TilesHeader();
    message.images = (object.images !== undefined && object.images !== null)
      ? ProductScreen_Section_TilesHeader_Images.fromPartial(object.images)
      : undefined;
    message.key_functions = (object.key_functions !== undefined && object.key_functions !== null)
      ? ProductScreen_Section_TilesHeader_KeyFunctions.fromPartial(object.key_functions)
      : undefined;
    message.title = (object.title !== undefined && object.title !== null)
      ? ProductScreen_Section_TilesHeader_Title.fromPartial(object.title)
      : undefined;
    message.shortcuts = object.shortcuts?.map((e) => ProductScreen_Section_TilesHeader_Shortcut.fromPartial(e)) || [];
    message.shortcut_rows = (object.shortcut_rows !== undefined && object.shortcut_rows !== null)
      ? ProductScreen_Section_TilesHeader_Shortcuts.fromPartial(object.shortcut_rows)
      : undefined;
    message.product_id = object.product_id ?? "";
    message.pora_score = (object.pora_score !== undefined && object.pora_score !== null)
      ? ProductScreen_Section_TilesHeader_Score.fromPartial(object.pora_score)
      : undefined;
    message.text_review = (object.text_review !== undefined && object.text_review !== null)
      ? ProductScreen_Section_TilesHeader_TextReview.fromPartial(object.text_review)
      : undefined;
    message.assistant_summary = (object.assistant_summary !== undefined && object.assistant_summary !== null)
      ? ProductScreen_Section_TilesHeader_AssistantSummary.fromPartial(object.assistant_summary)
      : undefined;
    message.grid = (object.grid !== undefined && object.grid !== null)
      ? ProductScreen_Section_TilesHeader_Grid.fromPartial(object.grid)
      : undefined;
    return message;
  },
};

function createBaseProductScreen_Section_TilesHeader_TextReview(): ProductScreen_Section_TilesHeader_TextReview {
  return { title: "", image: "", text: "", action: undefined, lazy_request: undefined };
}

export const ProductScreen_Section_TilesHeader_TextReview = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_TextReview {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      image: isSet(object.image) ? String(object.image) : "",
      text: isSet(object.text) ? String(object.text) : "",
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
      lazy_request: isSet(object.lazy_request)
        ? ProductScreenHeaderQuickSummaryRequest.fromJSON(object.lazy_request)
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_TextReview): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.image !== undefined && (obj.image = message.image);
    message.text !== undefined && (obj.text = message.text);
    message.action !== undefined && (obj.action = message.action ? ActionType.toJSON(message.action) : undefined);
    message.lazy_request !== undefined && (obj.lazy_request = message.lazy_request
      ? ProductScreenHeaderQuickSummaryRequest.toJSON(message.lazy_request)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_TextReview>, I>>(
    base?: I,
  ): ProductScreen_Section_TilesHeader_TextReview {
    return ProductScreen_Section_TilesHeader_TextReview.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_TextReview>, I>>(
    object: I,
  ): ProductScreen_Section_TilesHeader_TextReview {
    const message = createBaseProductScreen_Section_TilesHeader_TextReview();
    message.title = object.title ?? "";
    message.image = object.image ?? "";
    message.text = object.text ?? "";
    message.action = (object.action !== undefined && object.action !== null)
      ? ActionType.fromPartial(object.action)
      : undefined;
    message.lazy_request = (object.lazy_request !== undefined && object.lazy_request !== null)
      ? ProductScreenHeaderQuickSummaryRequest.fromPartial(object.lazy_request)
      : undefined;
    return message;
  },
};

function createBaseProductScreen_Section_TilesHeader_Score(): ProductScreen_Section_TilesHeader_Score {
  return { score: undefined, info_screen: undefined, should_load: false, score_info_screen: undefined };
}

export const ProductScreen_Section_TilesHeader_Score = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Score {
    return {
      score: isSet(object.score) ? ProductPoraScore.fromJSON(object.score) : undefined,
      info_screen: isSet(object.info_screen) ? ProductPoraScoreInfoScreen.fromJSON(object.info_screen) : undefined,
      should_load: isSet(object.should_load) ? Boolean(object.should_load) : false,
      score_info_screen: isSet(object.score_info_screen)
        ? ProductFitScoreInfoScreen.fromJSON(object.score_info_screen)
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Score): unknown {
    const obj: any = {};
    message.score !== undefined && (obj.score = message.score ? ProductPoraScore.toJSON(message.score) : undefined);
    message.info_screen !== undefined &&
      (obj.info_screen = message.info_screen ? ProductPoraScoreInfoScreen.toJSON(message.info_screen) : undefined);
    message.should_load !== undefined && (obj.should_load = message.should_load);
    message.score_info_screen !== undefined && (obj.score_info_screen = message.score_info_screen
      ? ProductFitScoreInfoScreen.toJSON(message.score_info_screen)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Score>, I>>(
    base?: I,
  ): ProductScreen_Section_TilesHeader_Score {
    return ProductScreen_Section_TilesHeader_Score.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Score>, I>>(
    object: I,
  ): ProductScreen_Section_TilesHeader_Score {
    const message = createBaseProductScreen_Section_TilesHeader_Score();
    message.score = (object.score !== undefined && object.score !== null)
      ? ProductPoraScore.fromPartial(object.score)
      : undefined;
    message.info_screen = (object.info_screen !== undefined && object.info_screen !== null)
      ? ProductPoraScoreInfoScreen.fromPartial(object.info_screen)
      : undefined;
    message.should_load = object.should_load ?? false;
    message.score_info_screen = (object.score_info_screen !== undefined && object.score_info_screen !== null)
      ? ProductFitScoreInfoScreen.fromPartial(object.score_info_screen)
      : undefined;
    return message;
  },
};

function createBaseProductScreen_Section_TilesHeader_Images(): ProductScreen_Section_TilesHeader_Images {
  return { product_image: "", user_photo_id: "" };
}

export const ProductScreen_Section_TilesHeader_Images = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Images {
    return {
      product_image: isSet(object.product_image) ? String(object.product_image) : "",
      user_photo_id: isSet(object.user_photo_id) ? String(object.user_photo_id) : "",
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Images): unknown {
    const obj: any = {};
    message.product_image !== undefined && (obj.product_image = message.product_image);
    message.user_photo_id !== undefined && (obj.user_photo_id = message.user_photo_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Images>, I>>(
    base?: I,
  ): ProductScreen_Section_TilesHeader_Images {
    return ProductScreen_Section_TilesHeader_Images.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Images>, I>>(
    object: I,
  ): ProductScreen_Section_TilesHeader_Images {
    const message = createBaseProductScreen_Section_TilesHeader_Images();
    message.product_image = object.product_image ?? "";
    message.user_photo_id = object.user_photo_id ?? "";
    return message;
  },
};

function createBaseProductScreen_Section_TilesHeader_KeyFunctions(): ProductScreen_Section_TilesHeader_KeyFunctions {
  return { be_aware_tags: [], good_for_tags: [], attributed_be_aware_tags: [], attributed_good_for_tags: [] };
}

export const ProductScreen_Section_TilesHeader_KeyFunctions = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_KeyFunctions {
    return {
      be_aware_tags: Array.isArray(object?.be_aware_tags)
        ? object.be_aware_tags.map((e: any) => String(e))
        : [],
      good_for_tags: Array.isArray(object?.good_for_tags) ? object.good_for_tags.map((e: any) => String(e)) : [],
      attributed_be_aware_tags: Array.isArray(object?.attributed_be_aware_tags)
        ? object.attributed_be_aware_tags.map((e: any) => AttributedString.fromJSON(e))
        : [],
      attributed_good_for_tags: Array.isArray(object?.attributed_good_for_tags)
        ? object.attributed_good_for_tags.map((e: any) => AttributedString.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_KeyFunctions): unknown {
    const obj: any = {};
    if (message.be_aware_tags) {
      obj.be_aware_tags = message.be_aware_tags.map((e) => e);
    } else {
      obj.be_aware_tags = [];
    }
    if (message.good_for_tags) {
      obj.good_for_tags = message.good_for_tags.map((e) => e);
    } else {
      obj.good_for_tags = [];
    }
    if (message.attributed_be_aware_tags) {
      obj.attributed_be_aware_tags = message.attributed_be_aware_tags.map((e) =>
        e ? AttributedString.toJSON(e) : undefined
      );
    } else {
      obj.attributed_be_aware_tags = [];
    }
    if (message.attributed_good_for_tags) {
      obj.attributed_good_for_tags = message.attributed_good_for_tags.map((e) =>
        e ? AttributedString.toJSON(e) : undefined
      );
    } else {
      obj.attributed_good_for_tags = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_KeyFunctions>, I>>(
    base?: I,
  ): ProductScreen_Section_TilesHeader_KeyFunctions {
    return ProductScreen_Section_TilesHeader_KeyFunctions.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_KeyFunctions>, I>>(
    object: I,
  ): ProductScreen_Section_TilesHeader_KeyFunctions {
    const message = createBaseProductScreen_Section_TilesHeader_KeyFunctions();
    message.be_aware_tags = object.be_aware_tags?.map((e) => e) || [];
    message.good_for_tags = object.good_for_tags?.map((e) => e) || [];
    message.attributed_be_aware_tags = object.attributed_be_aware_tags?.map((e) => AttributedString.fromPartial(e)) ||
      [];
    message.attributed_good_for_tags = object.attributed_good_for_tags?.map((e) => AttributedString.fromPartial(e)) ||
      [];
    return message;
  },
};

function createBaseProductScreen_Section_TilesHeader_Title(): ProductScreen_Section_TilesHeader_Title {
  return { product_type: undefined, text: undefined };
}

export const ProductScreen_Section_TilesHeader_Title = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Title {
    return {
      product_type: isSet(object.product_type) ? AttributedString.fromJSON(object.product_type) : undefined,
      text: isSet(object.text) ? AttributedString.fromJSON(object.text) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Title): unknown {
    const obj: any = {};
    message.product_type !== undefined &&
      (obj.product_type = message.product_type ? AttributedString.toJSON(message.product_type) : undefined);
    message.text !== undefined && (obj.text = message.text ? AttributedString.toJSON(message.text) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Title>, I>>(
    base?: I,
  ): ProductScreen_Section_TilesHeader_Title {
    return ProductScreen_Section_TilesHeader_Title.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Title>, I>>(
    object: I,
  ): ProductScreen_Section_TilesHeader_Title {
    const message = createBaseProductScreen_Section_TilesHeader_Title();
    message.product_type = (object.product_type !== undefined && object.product_type !== null)
      ? AttributedString.fromPartial(object.product_type)
      : undefined;
    message.text = (object.text !== undefined && object.text !== null)
      ? AttributedString.fromPartial(object.text)
      : undefined;
    return message;
  },
};

function createBaseProductScreen_Section_TilesHeader_Grid(): ProductScreen_Section_TilesHeader_Grid {
  return { items: [] };
}

export const ProductScreen_Section_TilesHeader_Grid = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Grid {
    return {
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => ProductScreen_Section_TilesHeader_Grid_Item.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Grid): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map((e) => e ? ProductScreen_Section_TilesHeader_Grid_Item.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Grid>, I>>(
    base?: I,
  ): ProductScreen_Section_TilesHeader_Grid {
    return ProductScreen_Section_TilesHeader_Grid.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Grid>, I>>(
    object: I,
  ): ProductScreen_Section_TilesHeader_Grid {
    const message = createBaseProductScreen_Section_TilesHeader_Grid();
    message.items = object.items?.map((e) => ProductScreen_Section_TilesHeader_Grid_Item.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductScreen_Section_TilesHeader_Grid_Item(): ProductScreen_Section_TilesHeader_Grid_Item {
  return { item: undefined };
}

export const ProductScreen_Section_TilesHeader_Grid_Item = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Grid_Item {
    return {
      item: isSet(object.shortcuts_row)
        ? {
          $case: "shortcuts_row",
          shortcuts_row: ProductScreen_Section_TilesHeader_Shortcuts_Row.fromJSON(object.shortcuts_row),
        }
        : isSet(object.assistant_summary)
        ? {
          $case: "assistant_summary",
          assistant_summary: ProductScreen_Section_TilesHeader_AssistantSummary.fromJSON(object.assistant_summary),
        }
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Grid_Item): unknown {
    const obj: any = {};
    message.item?.$case === "shortcuts_row" && (obj.shortcuts_row = message.item?.shortcuts_row
      ? ProductScreen_Section_TilesHeader_Shortcuts_Row.toJSON(message.item?.shortcuts_row)
      : undefined);
    message.item?.$case === "assistant_summary" && (obj.assistant_summary = message.item?.assistant_summary
      ? ProductScreen_Section_TilesHeader_AssistantSummary.toJSON(message.item?.assistant_summary)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Grid_Item>, I>>(
    base?: I,
  ): ProductScreen_Section_TilesHeader_Grid_Item {
    return ProductScreen_Section_TilesHeader_Grid_Item.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Grid_Item>, I>>(
    object: I,
  ): ProductScreen_Section_TilesHeader_Grid_Item {
    const message = createBaseProductScreen_Section_TilesHeader_Grid_Item();
    if (
      object.item?.$case === "shortcuts_row" &&
      object.item?.shortcuts_row !== undefined &&
      object.item?.shortcuts_row !== null
    ) {
      message.item = {
        $case: "shortcuts_row",
        shortcuts_row: ProductScreen_Section_TilesHeader_Shortcuts_Row.fromPartial(object.item.shortcuts_row),
      };
    }
    if (
      object.item?.$case === "assistant_summary" &&
      object.item?.assistant_summary !== undefined &&
      object.item?.assistant_summary !== null
    ) {
      message.item = {
        $case: "assistant_summary",
        assistant_summary: ProductScreen_Section_TilesHeader_AssistantSummary.fromPartial(
          object.item.assistant_summary,
        ),
      };
    }
    return message;
  },
};

function createBaseProductScreen_Section_TilesHeader_Shortcuts(): ProductScreen_Section_TilesHeader_Shortcuts {
  return { rows: [] };
}

export const ProductScreen_Section_TilesHeader_Shortcuts = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Shortcuts {
    return {
      rows: Array.isArray(object?.rows)
        ? object.rows.map((e: any) => ProductScreen_Section_TilesHeader_Shortcuts_Row.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Shortcuts): unknown {
    const obj: any = {};
    if (message.rows) {
      obj.rows = message.rows.map((e) => e ? ProductScreen_Section_TilesHeader_Shortcuts_Row.toJSON(e) : undefined);
    } else {
      obj.rows = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Shortcuts>, I>>(
    base?: I,
  ): ProductScreen_Section_TilesHeader_Shortcuts {
    return ProductScreen_Section_TilesHeader_Shortcuts.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Shortcuts>, I>>(
    object: I,
  ): ProductScreen_Section_TilesHeader_Shortcuts {
    const message = createBaseProductScreen_Section_TilesHeader_Shortcuts();
    message.rows = object.rows?.map((e) => ProductScreen_Section_TilesHeader_Shortcuts_Row.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductScreen_Section_TilesHeader_Shortcuts_Row(): ProductScreen_Section_TilesHeader_Shortcuts_Row {
  return { shortcuts: [], style: ProductScreen_Section_TilesHeader_Shortcuts_Row_Style.STYLE_NONE };
}

export const ProductScreen_Section_TilesHeader_Shortcuts_Row = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Shortcuts_Row {
    return {
      shortcuts: Array.isArray(object?.shortcuts)
        ? object.shortcuts.map((e: any) => ProductScreen_Section_TilesHeader_Shortcut.fromJSON(e))
        : [],
      style: isSet(object.style)
        ? productScreen_Section_TilesHeader_Shortcuts_Row_StyleFromJSON(object.style)
        : ProductScreen_Section_TilesHeader_Shortcuts_Row_Style.STYLE_NONE,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Shortcuts_Row): unknown {
    const obj: any = {};
    if (message.shortcuts) {
      obj.shortcuts = message.shortcuts.map((e) =>
        e ? ProductScreen_Section_TilesHeader_Shortcut.toJSON(e) : undefined
      );
    } else {
      obj.shortcuts = [];
    }
    message.style !== undefined &&
      (obj.style = productScreen_Section_TilesHeader_Shortcuts_Row_StyleToJSON(message.style));
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Shortcuts_Row>, I>>(
    base?: I,
  ): ProductScreen_Section_TilesHeader_Shortcuts_Row {
    return ProductScreen_Section_TilesHeader_Shortcuts_Row.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Shortcuts_Row>, I>>(
    object: I,
  ): ProductScreen_Section_TilesHeader_Shortcuts_Row {
    const message = createBaseProductScreen_Section_TilesHeader_Shortcuts_Row();
    message.shortcuts = object.shortcuts?.map((e) => ProductScreen_Section_TilesHeader_Shortcut.fromPartial(e)) || [];
    message.style = object.style ?? ProductScreen_Section_TilesHeader_Shortcuts_Row_Style.STYLE_NONE;
    return message;
  },
};

function createBaseProductScreen_Section_TilesHeader_Shortcut(): ProductScreen_Section_TilesHeader_Shortcut {
  return { shortcut: undefined };
}

export const ProductScreen_Section_TilesHeader_Shortcut = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Shortcut {
    return {
      shortcut: isSet(object.empty)
        ? { $case: "empty", empty: ProductScreen_Section_TilesHeader_Shortcut_Empty.fromJSON(object.empty) }
        : isSet(object.content)
        ? { $case: "content", content: ProductScreen_Section_TilesHeader_Shortcut_Content.fromJSON(object.content) }
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Shortcut): unknown {
    const obj: any = {};
    message.shortcut?.$case === "empty" && (obj.empty = message.shortcut?.empty
      ? ProductScreen_Section_TilesHeader_Shortcut_Empty.toJSON(message.shortcut?.empty)
      : undefined);
    message.shortcut?.$case === "content" && (obj.content = message.shortcut?.content
      ? ProductScreen_Section_TilesHeader_Shortcut_Content.toJSON(message.shortcut?.content)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Shortcut>, I>>(
    base?: I,
  ): ProductScreen_Section_TilesHeader_Shortcut {
    return ProductScreen_Section_TilesHeader_Shortcut.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Shortcut>, I>>(
    object: I,
  ): ProductScreen_Section_TilesHeader_Shortcut {
    const message = createBaseProductScreen_Section_TilesHeader_Shortcut();
    if (object.shortcut?.$case === "empty" && object.shortcut?.empty !== undefined && object.shortcut?.empty !== null) {
      message.shortcut = {
        $case: "empty",
        empty: ProductScreen_Section_TilesHeader_Shortcut_Empty.fromPartial(object.shortcut.empty),
      };
    }
    if (
      object.shortcut?.$case === "content" &&
      object.shortcut?.content !== undefined &&
      object.shortcut?.content !== null
    ) {
      message.shortcut = {
        $case: "content",
        content: ProductScreen_Section_TilesHeader_Shortcut_Content.fromPartial(object.shortcut.content),
      };
    }
    return message;
  },
};

function createBaseProductScreen_Section_TilesHeader_Shortcut_Empty(): ProductScreen_Section_TilesHeader_Shortcut_Empty {
  return { message: "" };
}

export const ProductScreen_Section_TilesHeader_Shortcut_Empty = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Shortcut_Empty {
    return { message: isSet(object.message) ? String(object.message) : "" };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Shortcut_Empty): unknown {
    const obj: any = {};
    message.message !== undefined && (obj.message = message.message);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Shortcut_Empty>, I>>(
    base?: I,
  ): ProductScreen_Section_TilesHeader_Shortcut_Empty {
    return ProductScreen_Section_TilesHeader_Shortcut_Empty.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Shortcut_Empty>, I>>(
    object: I,
  ): ProductScreen_Section_TilesHeader_Shortcut_Empty {
    const message = createBaseProductScreen_Section_TilesHeader_Shortcut_Empty();
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseProductScreen_Section_TilesHeader_Shortcut_Content(): ProductScreen_Section_TilesHeader_Shortcut_Content {
  return { content: undefined };
}

export const ProductScreen_Section_TilesHeader_Shortcut_Content = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Shortcut_Content {
    return {
      content: isSet(object.rating)
        ? { $case: "rating", rating: ProductScreen_Section_TilesHeader_Shortcut_Content_Rating.fromJSON(object.rating) }
        : isSet(object.tutorial)
        ? {
          $case: "tutorial",
          tutorial: ProductScreen_Section_TilesHeader_Shortcut_Content_Tutorial.fromJSON(object.tutorial),
        }
        : isSet(object.prices)
        ? { $case: "prices", prices: ProductScreen_Section_TilesHeader_Shortcut_Content_Prices.fromJSON(object.prices) }
        : isSet(object.alternatives)
        ? {
          $case: "alternatives",
          alternatives: ProductScreen_Section_TilesHeader_Shortcut_Content_Alternatives.fromJSON(object.alternatives),
        }
        : isSet(object.ingredients)
        ? {
          $case: "ingredients",
          ingredients: ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients.fromJSON(object.ingredients),
        }
        : isSet(object.reviewSummary)
        ? {
          $case: "reviewSummary",
          reviewSummary: ProductScreen_Section_TilesHeader_Shortcut_Content_ReviewSummary.fromJSON(
            object.reviewSummary,
          ),
        }
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Shortcut_Content): unknown {
    const obj: any = {};
    message.content?.$case === "rating" && (obj.rating = message.content?.rating
      ? ProductScreen_Section_TilesHeader_Shortcut_Content_Rating.toJSON(message.content?.rating)
      : undefined);
    message.content?.$case === "tutorial" && (obj.tutorial = message.content?.tutorial
      ? ProductScreen_Section_TilesHeader_Shortcut_Content_Tutorial.toJSON(message.content?.tutorial)
      : undefined);
    message.content?.$case === "prices" && (obj.prices = message.content?.prices
      ? ProductScreen_Section_TilesHeader_Shortcut_Content_Prices.toJSON(message.content?.prices)
      : undefined);
    message.content?.$case === "alternatives" && (obj.alternatives = message.content?.alternatives
      ? ProductScreen_Section_TilesHeader_Shortcut_Content_Alternatives.toJSON(message.content?.alternatives)
      : undefined);
    message.content?.$case === "ingredients" && (obj.ingredients = message.content?.ingredients
      ? ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients.toJSON(message.content?.ingredients)
      : undefined);
    message.content?.$case === "reviewSummary" && (obj.reviewSummary = message.content?.reviewSummary
      ? ProductScreen_Section_TilesHeader_Shortcut_Content_ReviewSummary.toJSON(message.content?.reviewSummary)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Shortcut_Content>, I>>(
    base?: I,
  ): ProductScreen_Section_TilesHeader_Shortcut_Content {
    return ProductScreen_Section_TilesHeader_Shortcut_Content.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Shortcut_Content>, I>>(
    object: I,
  ): ProductScreen_Section_TilesHeader_Shortcut_Content {
    const message = createBaseProductScreen_Section_TilesHeader_Shortcut_Content();
    if (object.content?.$case === "rating" && object.content?.rating !== undefined && object.content?.rating !== null) {
      message.content = {
        $case: "rating",
        rating: ProductScreen_Section_TilesHeader_Shortcut_Content_Rating.fromPartial(object.content.rating),
      };
    }
    if (
      object.content?.$case === "tutorial" &&
      object.content?.tutorial !== undefined &&
      object.content?.tutorial !== null
    ) {
      message.content = {
        $case: "tutorial",
        tutorial: ProductScreen_Section_TilesHeader_Shortcut_Content_Tutorial.fromPartial(object.content.tutorial),
      };
    }
    if (object.content?.$case === "prices" && object.content?.prices !== undefined && object.content?.prices !== null) {
      message.content = {
        $case: "prices",
        prices: ProductScreen_Section_TilesHeader_Shortcut_Content_Prices.fromPartial(object.content.prices),
      };
    }
    if (
      object.content?.$case === "alternatives" &&
      object.content?.alternatives !== undefined &&
      object.content?.alternatives !== null
    ) {
      message.content = {
        $case: "alternatives",
        alternatives: ProductScreen_Section_TilesHeader_Shortcut_Content_Alternatives.fromPartial(
          object.content.alternatives,
        ),
      };
    }
    if (
      object.content?.$case === "ingredients" &&
      object.content?.ingredients !== undefined &&
      object.content?.ingredients !== null
    ) {
      message.content = {
        $case: "ingredients",
        ingredients: ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients.fromPartial(
          object.content.ingredients,
        ),
      };
    }
    if (
      object.content?.$case === "reviewSummary" &&
      object.content?.reviewSummary !== undefined &&
      object.content?.reviewSummary !== null
    ) {
      message.content = {
        $case: "reviewSummary",
        reviewSummary: ProductScreen_Section_TilesHeader_Shortcut_Content_ReviewSummary.fromPartial(
          object.content.reviewSummary,
        ),
      };
    }
    return message;
  },
};

function createBaseProductScreen_Section_TilesHeader_Shortcut_Content_Rating(): ProductScreen_Section_TilesHeader_Shortcut_Content_Rating {
  return { value: "", accented: false, subtitle: "", info_screen: undefined };
}

export const ProductScreen_Section_TilesHeader_Shortcut_Content_Rating = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Shortcut_Content_Rating {
    return {
      value: isSet(object.value) ? String(object.value) : "",
      accented: isSet(object.accented) ? Boolean(object.accented) : false,
      subtitle: isSet(object.subtitle) ? String(object.subtitle) : "",
      info_screen: isSet(object.info_screen) ? ProductStoresInfoScreen.fromJSON(object.info_screen) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Shortcut_Content_Rating): unknown {
    const obj: any = {};
    message.value !== undefined && (obj.value = message.value);
    message.accented !== undefined && (obj.accented = message.accented);
    message.subtitle !== undefined && (obj.subtitle = message.subtitle);
    message.info_screen !== undefined &&
      (obj.info_screen = message.info_screen ? ProductStoresInfoScreen.toJSON(message.info_screen) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Shortcut_Content_Rating>, I>>(
    base?: I,
  ): ProductScreen_Section_TilesHeader_Shortcut_Content_Rating {
    return ProductScreen_Section_TilesHeader_Shortcut_Content_Rating.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Shortcut_Content_Rating>, I>>(
    object: I,
  ): ProductScreen_Section_TilesHeader_Shortcut_Content_Rating {
    const message = createBaseProductScreen_Section_TilesHeader_Shortcut_Content_Rating();
    message.value = object.value ?? "";
    message.accented = object.accented ?? false;
    message.subtitle = object.subtitle ?? "";
    message.info_screen = (object.info_screen !== undefined && object.info_screen !== null)
      ? ProductStoresInfoScreen.fromPartial(object.info_screen)
      : undefined;
    return message;
  },
};

function createBaseProductScreen_Section_TilesHeader_Shortcut_Content_Tutorial(): ProductScreen_Section_TilesHeader_Shortcut_Content_Tutorial {
  return { image: "", title: "", tag: undefined, video_title: "", action: undefined };
}

export const ProductScreen_Section_TilesHeader_Shortcut_Content_Tutorial = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Shortcut_Content_Tutorial {
    return {
      image: isSet(object.image) ? String(object.image) : "",
      title: isSet(object.title) ? String(object.title) : "",
      tag: isSet(object.tag) ? Tag.fromJSON(object.tag) : undefined,
      video_title: isSet(object.video_title) ? String(object.video_title) : "",
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Shortcut_Content_Tutorial): unknown {
    const obj: any = {};
    message.image !== undefined && (obj.image = message.image);
    message.title !== undefined && (obj.title = message.title);
    message.tag !== undefined && (obj.tag = message.tag ? Tag.toJSON(message.tag) : undefined);
    message.video_title !== undefined && (obj.video_title = message.video_title);
    message.action !== undefined && (obj.action = message.action ? ActionType.toJSON(message.action) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Shortcut_Content_Tutorial>, I>>(
    base?: I,
  ): ProductScreen_Section_TilesHeader_Shortcut_Content_Tutorial {
    return ProductScreen_Section_TilesHeader_Shortcut_Content_Tutorial.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Shortcut_Content_Tutorial>, I>>(
    object: I,
  ): ProductScreen_Section_TilesHeader_Shortcut_Content_Tutorial {
    const message = createBaseProductScreen_Section_TilesHeader_Shortcut_Content_Tutorial();
    message.image = object.image ?? "";
    message.title = object.title ?? "";
    message.tag = (object.tag !== undefined && object.tag !== null) ? Tag.fromPartial(object.tag) : undefined;
    message.video_title = object.video_title ?? "";
    message.action = (object.action !== undefined && object.action !== null)
      ? ActionType.fromPartial(object.action)
      : undefined;
    return message;
  },
};

function createBaseProductScreen_Section_TilesHeader_Shortcut_Content_Prices(): ProductScreen_Section_TilesHeader_Shortcut_Content_Prices {
  return { title: "", subtitle: "", tag: undefined, info_screen: undefined };
}

export const ProductScreen_Section_TilesHeader_Shortcut_Content_Prices = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Shortcut_Content_Prices {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      subtitle: isSet(object.subtitle) ? String(object.subtitle) : "",
      tag: isSet(object.tag) ? AttributedString.fromJSON(object.tag) : undefined,
      info_screen: isSet(object.info_screen) ? ProductStoresInfoScreen.fromJSON(object.info_screen) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Shortcut_Content_Prices): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.subtitle !== undefined && (obj.subtitle = message.subtitle);
    message.tag !== undefined && (obj.tag = message.tag ? AttributedString.toJSON(message.tag) : undefined);
    message.info_screen !== undefined &&
      (obj.info_screen = message.info_screen ? ProductStoresInfoScreen.toJSON(message.info_screen) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Shortcut_Content_Prices>, I>>(
    base?: I,
  ): ProductScreen_Section_TilesHeader_Shortcut_Content_Prices {
    return ProductScreen_Section_TilesHeader_Shortcut_Content_Prices.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Shortcut_Content_Prices>, I>>(
    object: I,
  ): ProductScreen_Section_TilesHeader_Shortcut_Content_Prices {
    const message = createBaseProductScreen_Section_TilesHeader_Shortcut_Content_Prices();
    message.title = object.title ?? "";
    message.subtitle = object.subtitle ?? "";
    message.tag = (object.tag !== undefined && object.tag !== null)
      ? AttributedString.fromPartial(object.tag)
      : undefined;
    message.info_screen = (object.info_screen !== undefined && object.info_screen !== null)
      ? ProductStoresInfoScreen.fromPartial(object.info_screen)
      : undefined;
    return message;
  },
};

function createBaseProductScreen_Section_TilesHeader_Shortcut_Content_Alternatives(): ProductScreen_Section_TilesHeader_Shortcut_Content_Alternatives {
  return { preview_images: [] };
}

export const ProductScreen_Section_TilesHeader_Shortcut_Content_Alternatives = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Shortcut_Content_Alternatives {
    return {
      preview_images: Array.isArray(object?.preview_images)
        ? object.preview_images.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Shortcut_Content_Alternatives): unknown {
    const obj: any = {};
    if (message.preview_images) {
      obj.preview_images = message.preview_images.map((e) => e);
    } else {
      obj.preview_images = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Shortcut_Content_Alternatives>, I>>(
    base?: I,
  ): ProductScreen_Section_TilesHeader_Shortcut_Content_Alternatives {
    return ProductScreen_Section_TilesHeader_Shortcut_Content_Alternatives.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Shortcut_Content_Alternatives>, I>>(
    object: I,
  ): ProductScreen_Section_TilesHeader_Shortcut_Content_Alternatives {
    const message = createBaseProductScreen_Section_TilesHeader_Shortcut_Content_Alternatives();
    message.preview_images = object.preview_images?.map((e) => e) || [];
    return message;
  },
};

function createBaseProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients(): ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients {
  return {
    title: undefined,
    subtitle: "",
    items: [],
    ingredients_list: undefined,
    ingredients_list_info_screen: undefined,
  };
}

export const ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      subtitle: isSet(object.subtitle) ? String(object.subtitle) : "",
      items: Array.isArray(object?.items)
        ? object.items.map((e: any) => ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients_Item.fromJSON(e))
        : [],
      ingredients_list: isSet(object.ingredients_list)
        ? ProductScreen_Section_Ingredients.fromJSON(object.ingredients_list)
        : undefined,
      ingredients_list_info_screen: isSet(object.ingredients_list_info_screen)
        ? ProductIngredientsListInfoScreen.fromJSON(object.ingredients_list_info_screen)
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.subtitle !== undefined && (obj.subtitle = message.subtitle);
    if (message.items) {
      obj.items = message.items.map((e) =>
        e ? ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients_Item.toJSON(e) : undefined
      );
    } else {
      obj.items = [];
    }
    message.ingredients_list !== undefined && (obj.ingredients_list = message.ingredients_list
      ? ProductScreen_Section_Ingredients.toJSON(message.ingredients_list)
      : undefined);
    message.ingredients_list_info_screen !== undefined &&
      (obj.ingredients_list_info_screen = message.ingredients_list_info_screen
        ? ProductIngredientsListInfoScreen.toJSON(message.ingredients_list_info_screen)
        : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients>, I>>(
    base?: I,
  ): ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients {
    return ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients>, I>>(
    object: I,
  ): ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients {
    const message = createBaseProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.subtitle = object.subtitle ?? "";
    message.items =
      object.items?.map((e) => ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients_Item.fromPartial(e)) ||
      [];
    message.ingredients_list = (object.ingredients_list !== undefined && object.ingredients_list !== null)
      ? ProductScreen_Section_Ingredients.fromPartial(object.ingredients_list)
      : undefined;
    message.ingredients_list_info_screen =
      (object.ingredients_list_info_screen !== undefined && object.ingredients_list_info_screen !== null)
        ? ProductIngredientsListInfoScreen.fromPartial(object.ingredients_list_info_screen)
        : undefined;
    return message;
  },
};

function createBaseProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients_Item(): ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients_Item {
  return { title: undefined, risk_color: undefined };
}

export const ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients_Item = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients_Item {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      risk_color: isSet(object.risk_color) ? Color.fromJSON(object.risk_color) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients_Item): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.risk_color !== undefined &&
      (obj.risk_color = message.risk_color ? Color.toJSON(message.risk_color) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients_Item>, I>>(
    base?: I,
  ): ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients_Item {
    return ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients_Item.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients_Item>, I>>(
    object: I,
  ): ProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients_Item {
    const message = createBaseProductScreen_Section_TilesHeader_Shortcut_Content_Ingredients_Item();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.risk_color = (object.risk_color !== undefined && object.risk_color !== null)
      ? Color.fromPartial(object.risk_color)
      : undefined;
    return message;
  },
};

function createBaseProductScreen_Section_TilesHeader_Shortcut_Content_ReviewSummary(): ProductScreen_Section_TilesHeader_Shortcut_Content_ReviewSummary {
  return { image: "", title: undefined, subtitle: undefined, action: undefined };
}

export const ProductScreen_Section_TilesHeader_Shortcut_Content_ReviewSummary = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_Shortcut_Content_ReviewSummary {
    return {
      image: isSet(object.image) ? String(object.image) : "",
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      subtitle: isSet(object.subtitle) ? AttributedString.fromJSON(object.subtitle) : undefined,
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_Shortcut_Content_ReviewSummary): unknown {
    const obj: any = {};
    message.image !== undefined && (obj.image = message.image);
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.subtitle !== undefined &&
      (obj.subtitle = message.subtitle ? AttributedString.toJSON(message.subtitle) : undefined);
    message.action !== undefined && (obj.action = message.action ? ActionType.toJSON(message.action) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Shortcut_Content_ReviewSummary>, I>>(
    base?: I,
  ): ProductScreen_Section_TilesHeader_Shortcut_Content_ReviewSummary {
    return ProductScreen_Section_TilesHeader_Shortcut_Content_ReviewSummary.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_Shortcut_Content_ReviewSummary>, I>>(
    object: I,
  ): ProductScreen_Section_TilesHeader_Shortcut_Content_ReviewSummary {
    const message = createBaseProductScreen_Section_TilesHeader_Shortcut_Content_ReviewSummary();
    message.image = object.image ?? "";
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.subtitle = (object.subtitle !== undefined && object.subtitle !== null)
      ? AttributedString.fromPartial(object.subtitle)
      : undefined;
    message.action = (object.action !== undefined && object.action !== null)
      ? ActionType.fromPartial(object.action)
      : undefined;
    return message;
  },
};

function createBaseProductScreen_Section_TilesHeader_AssistantSummary(): ProductScreen_Section_TilesHeader_AssistantSummary {
  return {
    title: undefined,
    message: undefined,
    buttons: [],
    default_buttons_max_count: 0,
    more_button_title: undefined,
  };
}

export const ProductScreen_Section_TilesHeader_AssistantSummary = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_AssistantSummary {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      message: isSet(object.message) ? AttributedString.fromJSON(object.message) : undefined,
      buttons: Array.isArray(object?.buttons)
        ? object.buttons.map((e: any) => ProductScreen_Section_TilesHeader_AssistantSummary_Button.fromJSON(e))
        : [],
      default_buttons_max_count: isSet(object.default_buttons_max_count) ? Number(object.default_buttons_max_count) : 0,
      more_button_title: isSet(object.more_button_title)
        ? AttributedString.fromJSON(object.more_button_title)
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_AssistantSummary): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.message !== undefined &&
      (obj.message = message.message ? AttributedString.toJSON(message.message) : undefined);
    if (message.buttons) {
      obj.buttons = message.buttons.map((e) =>
        e ? ProductScreen_Section_TilesHeader_AssistantSummary_Button.toJSON(e) : undefined
      );
    } else {
      obj.buttons = [];
    }
    message.default_buttons_max_count !== undefined &&
      (obj.default_buttons_max_count = Math.round(message.default_buttons_max_count));
    message.more_button_title !== undefined && (obj.more_button_title = message.more_button_title
      ? AttributedString.toJSON(message.more_button_title)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_AssistantSummary>, I>>(
    base?: I,
  ): ProductScreen_Section_TilesHeader_AssistantSummary {
    return ProductScreen_Section_TilesHeader_AssistantSummary.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_AssistantSummary>, I>>(
    object: I,
  ): ProductScreen_Section_TilesHeader_AssistantSummary {
    const message = createBaseProductScreen_Section_TilesHeader_AssistantSummary();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.message = (object.message !== undefined && object.message !== null)
      ? AttributedString.fromPartial(object.message)
      : undefined;
    message.buttons =
      object.buttons?.map((e) => ProductScreen_Section_TilesHeader_AssistantSummary_Button.fromPartial(e)) || [];
    message.default_buttons_max_count = object.default_buttons_max_count ?? 0;
    message.more_button_title = (object.more_button_title !== undefined && object.more_button_title !== null)
      ? AttributedString.fromPartial(object.more_button_title)
      : undefined;
    return message;
  },
};

function createBaseProductScreen_Section_TilesHeader_AssistantSummary_Button(): ProductScreen_Section_TilesHeader_AssistantSummary_Button {
  return { title: undefined, is_primary: false, action: undefined };
}

export const ProductScreen_Section_TilesHeader_AssistantSummary_Button = {
  fromJSON(object: any): ProductScreen_Section_TilesHeader_AssistantSummary_Button {
    return {
      title: isSet(object.title) ? AttributedString.fromJSON(object.title) : undefined,
      is_primary: isSet(object.is_primary) ? Boolean(object.is_primary) : false,
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_TilesHeader_AssistantSummary_Button): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title ? AttributedString.toJSON(message.title) : undefined);
    message.is_primary !== undefined && (obj.is_primary = message.is_primary);
    message.action !== undefined && (obj.action = message.action ? ActionType.toJSON(message.action) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_AssistantSummary_Button>, I>>(
    base?: I,
  ): ProductScreen_Section_TilesHeader_AssistantSummary_Button {
    return ProductScreen_Section_TilesHeader_AssistantSummary_Button.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_TilesHeader_AssistantSummary_Button>, I>>(
    object: I,
  ): ProductScreen_Section_TilesHeader_AssistantSummary_Button {
    const message = createBaseProductScreen_Section_TilesHeader_AssistantSummary_Button();
    message.title = (object.title !== undefined && object.title !== null)
      ? AttributedString.fromPartial(object.title)
      : undefined;
    message.is_primary = object.is_primary ?? false;
    message.action = (object.action !== undefined && object.action !== null)
      ? ActionType.fromPartial(object.action)
      : undefined;
    return message;
  },
};

function createBaseProductScreen_Section_KeyFunctions(): ProductScreen_Section_KeyFunctions {
  return { title: "", subtitle: "", sections: [], lazy_request_data: undefined };
}

export const ProductScreen_Section_KeyFunctions = {
  fromJSON(object: any): ProductScreen_Section_KeyFunctions {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      subtitle: isSet(object.subtitle) ? String(object.subtitle) : "",
      sections: Array.isArray(object?.sections)
        ? object.sections.map((e: any) => ProductScreen_Section_KeyFunctions_Section.fromJSON(e))
        : [],
      lazy_request_data: isSet(object.lazy_request_data)
        ? LazyProductSectionRequestData.fromJSON(object.lazy_request_data)
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_KeyFunctions): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.subtitle !== undefined && (obj.subtitle = message.subtitle);
    if (message.sections) {
      obj.sections = message.sections.map((e) => e ? ProductScreen_Section_KeyFunctions_Section.toJSON(e) : undefined);
    } else {
      obj.sections = [];
    }
    message.lazy_request_data !== undefined && (obj.lazy_request_data = message.lazy_request_data
      ? LazyProductSectionRequestData.toJSON(message.lazy_request_data)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_KeyFunctions>, I>>(
    base?: I,
  ): ProductScreen_Section_KeyFunctions {
    return ProductScreen_Section_KeyFunctions.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_KeyFunctions>, I>>(
    object: I,
  ): ProductScreen_Section_KeyFunctions {
    const message = createBaseProductScreen_Section_KeyFunctions();
    message.title = object.title ?? "";
    message.subtitle = object.subtitle ?? "";
    message.sections = object.sections?.map((e) => ProductScreen_Section_KeyFunctions_Section.fromPartial(e)) || [];
    message.lazy_request_data = (object.lazy_request_data !== undefined && object.lazy_request_data !== null)
      ? LazyProductSectionRequestData.fromPartial(object.lazy_request_data)
      : undefined;
    return message;
  },
};

function createBaseProductScreen_Section_KeyFunctions_Section(): ProductScreen_Section_KeyFunctions_Section {
  return {
    title: "",
    functions_type: ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_NONE,
    functions: [],
    is_always_visible: false,
    content: undefined,
  };
}

export const ProductScreen_Section_KeyFunctions_Section = {
  fromJSON(object: any): ProductScreen_Section_KeyFunctions_Section {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      functions_type: isSet(object.functions_type)
        ? productKeyFunctionTypeFromJSON(object.functions_type)
        : ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_NONE,
      functions: Array.isArray(object?.functions)
        ? object.functions.map((e: any) => ProductScreen_Section_KeyFunctions_Function.fromJSON(e))
        : [],
      is_always_visible: isSet(object.is_always_visible) ? Boolean(object.is_always_visible) : false,
      content: isSet(object.tags)
        ? { $case: "tags", tags: ProductScreen_Section_KeyFunctions_Section_Tags.fromJSON(object.tags) }
        : isSet(object.scales)
        ? { $case: "scales", scales: ProductScreen_Section_KeyFunctions_Section_Scales.fromJSON(object.scales) }
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_KeyFunctions_Section): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.functions_type !== undefined && (obj.functions_type = productKeyFunctionTypeToJSON(message.functions_type));
    if (message.functions) {
      obj.functions = message.functions.map((e) =>
        e ? ProductScreen_Section_KeyFunctions_Function.toJSON(e) : undefined
      );
    } else {
      obj.functions = [];
    }
    message.is_always_visible !== undefined && (obj.is_always_visible = message.is_always_visible);
    message.content?.$case === "tags" && (obj.tags = message.content?.tags
      ? ProductScreen_Section_KeyFunctions_Section_Tags.toJSON(message.content?.tags)
      : undefined);
    message.content?.$case === "scales" && (obj.scales = message.content?.scales
      ? ProductScreen_Section_KeyFunctions_Section_Scales.toJSON(message.content?.scales)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_KeyFunctions_Section>, I>>(
    base?: I,
  ): ProductScreen_Section_KeyFunctions_Section {
    return ProductScreen_Section_KeyFunctions_Section.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_KeyFunctions_Section>, I>>(
    object: I,
  ): ProductScreen_Section_KeyFunctions_Section {
    const message = createBaseProductScreen_Section_KeyFunctions_Section();
    message.title = object.title ?? "";
    message.functions_type = object.functions_type ?? ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_NONE;
    message.functions = object.functions?.map((e) => ProductScreen_Section_KeyFunctions_Function.fromPartial(e)) || [];
    message.is_always_visible = object.is_always_visible ?? false;
    if (object.content?.$case === "tags" && object.content?.tags !== undefined && object.content?.tags !== null) {
      message.content = {
        $case: "tags",
        tags: ProductScreen_Section_KeyFunctions_Section_Tags.fromPartial(object.content.tags),
      };
    }
    if (object.content?.$case === "scales" && object.content?.scales !== undefined && object.content?.scales !== null) {
      message.content = {
        $case: "scales",
        scales: ProductScreen_Section_KeyFunctions_Section_Scales.fromPartial(object.content.scales),
      };
    }
    return message;
  },
};

function createBaseProductScreen_Section_KeyFunctions_Section_Tags(): ProductScreen_Section_KeyFunctions_Section_Tags {
  return { title: "", functions_type: ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_NONE, functions: [] };
}

export const ProductScreen_Section_KeyFunctions_Section_Tags = {
  fromJSON(object: any): ProductScreen_Section_KeyFunctions_Section_Tags {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      functions_type: isSet(object.functions_type)
        ? productKeyFunctionTypeFromJSON(object.functions_type)
        : ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_NONE,
      functions: Array.isArray(object?.functions)
        ? object.functions.map((e: any) => ProductScreen_Section_KeyFunctions_Function.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProductScreen_Section_KeyFunctions_Section_Tags): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.functions_type !== undefined && (obj.functions_type = productKeyFunctionTypeToJSON(message.functions_type));
    if (message.functions) {
      obj.functions = message.functions.map((e) =>
        e ? ProductScreen_Section_KeyFunctions_Function.toJSON(e) : undefined
      );
    } else {
      obj.functions = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_KeyFunctions_Section_Tags>, I>>(
    base?: I,
  ): ProductScreen_Section_KeyFunctions_Section_Tags {
    return ProductScreen_Section_KeyFunctions_Section_Tags.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_KeyFunctions_Section_Tags>, I>>(
    object: I,
  ): ProductScreen_Section_KeyFunctions_Section_Tags {
    const message = createBaseProductScreen_Section_KeyFunctions_Section_Tags();
    message.title = object.title ?? "";
    message.functions_type = object.functions_type ?? ProductKeyFunctionType.PRODUCT_KEY_FUNCTION_TYPE_NONE;
    message.functions = object.functions?.map((e) => ProductScreen_Section_KeyFunctions_Function.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProductScreen_Section_KeyFunctions_Section_Scales(): ProductScreen_Section_KeyFunctions_Section_Scales {
  return { scales: [], limit: 0 };
}

export const ProductScreen_Section_KeyFunctions_Section_Scales = {
  fromJSON(object: any): ProductScreen_Section_KeyFunctions_Section_Scales {
    return {
      scales: Array.isArray(object?.scales)
        ? object.scales.map((e: any) => ProductScreen_Section_KeyFunctions_Section_Scales_Scale.fromJSON(e))
        : [],
      limit: isSet(object.limit) ? Number(object.limit) : 0,
    };
  },

  toJSON(message: ProductScreen_Section_KeyFunctions_Section_Scales): unknown {
    const obj: any = {};
    if (message.scales) {
      obj.scales = message.scales.map((e) =>
        e ? ProductScreen_Section_KeyFunctions_Section_Scales_Scale.toJSON(e) : undefined
      );
    } else {
      obj.scales = [];
    }
    message.limit !== undefined && (obj.limit = Math.round(message.limit));
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_KeyFunctions_Section_Scales>, I>>(
    base?: I,
  ): ProductScreen_Section_KeyFunctions_Section_Scales {
    return ProductScreen_Section_KeyFunctions_Section_Scales.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_KeyFunctions_Section_Scales>, I>>(
    object: I,
  ): ProductScreen_Section_KeyFunctions_Section_Scales {
    const message = createBaseProductScreen_Section_KeyFunctions_Section_Scales();
    message.scales =
      object.scales?.map((e) => ProductScreen_Section_KeyFunctions_Section_Scales_Scale.fromPartial(e)) || [];
    message.limit = object.limit ?? 0;
    return message;
  },
};

function createBaseProductScreen_Section_KeyFunctions_Section_Scales_Scale(): ProductScreen_Section_KeyFunctions_Section_Scales_Scale {
  return { label: "", value: "", steps_count: 0, filled_steps_count: 0, info_screen: undefined };
}

export const ProductScreen_Section_KeyFunctions_Section_Scales_Scale = {
  fromJSON(object: any): ProductScreen_Section_KeyFunctions_Section_Scales_Scale {
    return {
      label: isSet(object.label) ? String(object.label) : "",
      value: isSet(object.value) ? String(object.value) : "",
      steps_count: isSet(object.steps_count) ? Number(object.steps_count) : 0,
      filled_steps_count: isSet(object.filled_steps_count) ? Number(object.filled_steps_count) : 0,
      info_screen: isSet(object.info_screen) ? ProductStepInfoScreen.fromJSON(object.info_screen) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_KeyFunctions_Section_Scales_Scale): unknown {
    const obj: any = {};
    message.label !== undefined && (obj.label = message.label);
    message.value !== undefined && (obj.value = message.value);
    message.steps_count !== undefined && (obj.steps_count = Math.round(message.steps_count));
    message.filled_steps_count !== undefined && (obj.filled_steps_count = Math.round(message.filled_steps_count));
    message.info_screen !== undefined &&
      (obj.info_screen = message.info_screen ? ProductStepInfoScreen.toJSON(message.info_screen) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_KeyFunctions_Section_Scales_Scale>, I>>(
    base?: I,
  ): ProductScreen_Section_KeyFunctions_Section_Scales_Scale {
    return ProductScreen_Section_KeyFunctions_Section_Scales_Scale.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_KeyFunctions_Section_Scales_Scale>, I>>(
    object: I,
  ): ProductScreen_Section_KeyFunctions_Section_Scales_Scale {
    const message = createBaseProductScreen_Section_KeyFunctions_Section_Scales_Scale();
    message.label = object.label ?? "";
    message.value = object.value ?? "";
    message.steps_count = object.steps_count ?? 0;
    message.filled_steps_count = object.filled_steps_count ?? 0;
    message.info_screen = (object.info_screen !== undefined && object.info_screen !== null)
      ? ProductStepInfoScreen.fromPartial(object.info_screen)
      : undefined;
    return message;
  },
};

function createBaseProductScreen_Section_KeyFunctions_Function(): ProductScreen_Section_KeyFunctions_Function {
  return { title: "", info_screen: undefined, attributed_title: undefined };
}

export const ProductScreen_Section_KeyFunctions_Function = {
  fromJSON(object: any): ProductScreen_Section_KeyFunctions_Function {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      info_screen: isSet(object.info_screen) ? ProductKeyFunctionInfoScreen.fromJSON(object.info_screen) : undefined,
      attributed_title: isSet(object.attributed_title) ? AttributedString.fromJSON(object.attributed_title) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_KeyFunctions_Function): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.info_screen !== undefined &&
      (obj.info_screen = message.info_screen ? ProductKeyFunctionInfoScreen.toJSON(message.info_screen) : undefined);
    message.attributed_title !== undefined &&
      (obj.attributed_title = message.attributed_title ? AttributedString.toJSON(message.attributed_title) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_KeyFunctions_Function>, I>>(
    base?: I,
  ): ProductScreen_Section_KeyFunctions_Function {
    return ProductScreen_Section_KeyFunctions_Function.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_KeyFunctions_Function>, I>>(
    object: I,
  ): ProductScreen_Section_KeyFunctions_Function {
    const message = createBaseProductScreen_Section_KeyFunctions_Function();
    message.title = object.title ?? "";
    message.info_screen = (object.info_screen !== undefined && object.info_screen !== null)
      ? ProductKeyFunctionInfoScreen.fromPartial(object.info_screen)
      : undefined;
    message.attributed_title = (object.attributed_title !== undefined && object.attributed_title !== null)
      ? AttributedString.fromPartial(object.attributed_title)
      : undefined;
    return message;
  },
};

function createBaseProductScreen_Section_Ingredients(): ProductScreen_Section_Ingredients {
  return { title: "", ingredients: [], is_always_visible: false, ingredients_count_to_show: 0 };
}

export const ProductScreen_Section_Ingredients = {
  fromJSON(object: any): ProductScreen_Section_Ingredients {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      ingredients: Array.isArray(object?.ingredients)
        ? object.ingredients.map((e: any) => ProductScreen_Section_Ingredients_Ingredient.fromJSON(e))
        : [],
      is_always_visible: isSet(object.is_always_visible) ? Boolean(object.is_always_visible) : false,
      ingredients_count_to_show: isSet(object.ingredients_count_to_show) ? Number(object.ingredients_count_to_show) : 0,
    };
  },

  toJSON(message: ProductScreen_Section_Ingredients): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    if (message.ingredients) {
      obj.ingredients = message.ingredients.map((e) =>
        e ? ProductScreen_Section_Ingredients_Ingredient.toJSON(e) : undefined
      );
    } else {
      obj.ingredients = [];
    }
    message.is_always_visible !== undefined && (obj.is_always_visible = message.is_always_visible);
    message.ingredients_count_to_show !== undefined &&
      (obj.ingredients_count_to_show = Math.round(message.ingredients_count_to_show));
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_Ingredients>, I>>(
    base?: I,
  ): ProductScreen_Section_Ingredients {
    return ProductScreen_Section_Ingredients.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_Ingredients>, I>>(
    object: I,
  ): ProductScreen_Section_Ingredients {
    const message = createBaseProductScreen_Section_Ingredients();
    message.title = object.title ?? "";
    message.ingredients = object.ingredients?.map((e) => ProductScreen_Section_Ingredients_Ingredient.fromPartial(e)) ||
      [];
    message.is_always_visible = object.is_always_visible ?? false;
    message.ingredients_count_to_show = object.ingredients_count_to_show ?? 0;
    return message;
  },
};

function createBaseProductScreen_Section_Ingredients_Ingredient(): ProductScreen_Section_Ingredients_Ingredient {
  return { title: "", info_screen: undefined, risk_title: "", risk_color: undefined };
}

export const ProductScreen_Section_Ingredients_Ingredient = {
  fromJSON(object: any): ProductScreen_Section_Ingredients_Ingredient {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      info_screen: isSet(object.info_screen) ? ProductIngredientInfoScreen.fromJSON(object.info_screen) : undefined,
      risk_title: isSet(object.risk_title) ? String(object.risk_title) : "",
      risk_color: isSet(object.risk_color) ? Color.fromJSON(object.risk_color) : undefined,
    };
  },

  toJSON(message: ProductScreen_Section_Ingredients_Ingredient): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.info_screen !== undefined &&
      (obj.info_screen = message.info_screen ? ProductIngredientInfoScreen.toJSON(message.info_screen) : undefined);
    message.risk_title !== undefined && (obj.risk_title = message.risk_title);
    message.risk_color !== undefined &&
      (obj.risk_color = message.risk_color ? Color.toJSON(message.risk_color) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Section_Ingredients_Ingredient>, I>>(
    base?: I,
  ): ProductScreen_Section_Ingredients_Ingredient {
    return ProductScreen_Section_Ingredients_Ingredient.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Section_Ingredients_Ingredient>, I>>(
    object: I,
  ): ProductScreen_Section_Ingredients_Ingredient {
    const message = createBaseProductScreen_Section_Ingredients_Ingredient();
    message.title = object.title ?? "";
    message.info_screen = (object.info_screen !== undefined && object.info_screen !== null)
      ? ProductIngredientInfoScreen.fromPartial(object.info_screen)
      : undefined;
    message.risk_title = object.risk_title ?? "";
    message.risk_color = (object.risk_color !== undefined && object.risk_color !== null)
      ? Color.fromPartial(object.risk_color)
      : undefined;
    return message;
  },
};

function createBaseProductScreen_Button(): ProductScreen_Button {
  return {
    title: "",
    deprecated_action: undefined,
    is_active: false,
    style: ProductScreen_Button_Style.PRIMARY,
    action: undefined,
    filled_button: undefined,
  };
}

export const ProductScreen_Button = {
  fromJSON(object: any): ProductScreen_Button {
    return {
      title: isSet(object.title) ? String(object.title) : "",
      deprecated_action: isSet(object.deprecated_action) ? ActionType.fromJSON(object.deprecated_action) : undefined,
      is_active: isSet(object.is_active) ? Boolean(object.is_active) : false,
      style: isSet(object.style)
        ? productScreen_Button_StyleFromJSON(object.style)
        : ProductScreen_Button_Style.PRIMARY,
      action: isSet(object.action) ? ProductScreen_Button_Action.fromJSON(object.action) : undefined,
      filled_button: isSet(object.filled_button) ? FilledButton.fromJSON(object.filled_button) : undefined,
    };
  },

  toJSON(message: ProductScreen_Button): unknown {
    const obj: any = {};
    message.title !== undefined && (obj.title = message.title);
    message.deprecated_action !== undefined &&
      (obj.deprecated_action = message.deprecated_action ? ActionType.toJSON(message.deprecated_action) : undefined);
    message.is_active !== undefined && (obj.is_active = message.is_active);
    message.style !== undefined && (obj.style = productScreen_Button_StyleToJSON(message.style));
    message.action !== undefined &&
      (obj.action = message.action ? ProductScreen_Button_Action.toJSON(message.action) : undefined);
    message.filled_button !== undefined &&
      (obj.filled_button = message.filled_button ? FilledButton.toJSON(message.filled_button) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Button>, I>>(base?: I): ProductScreen_Button {
    return ProductScreen_Button.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Button>, I>>(object: I): ProductScreen_Button {
    const message = createBaseProductScreen_Button();
    message.title = object.title ?? "";
    message.deprecated_action = (object.deprecated_action !== undefined && object.deprecated_action !== null)
      ? ActionType.fromPartial(object.deprecated_action)
      : undefined;
    message.is_active = object.is_active ?? false;
    message.style = object.style ?? ProductScreen_Button_Style.PRIMARY;
    message.action = (object.action !== undefined && object.action !== null)
      ? ProductScreen_Button_Action.fromPartial(object.action)
      : undefined;
    message.filled_button = (object.filled_button !== undefined && object.filled_button !== null)
      ? FilledButton.fromPartial(object.filled_button)
      : undefined;
    return message;
  },
};

function createBaseProductScreen_Button_Action(): ProductScreen_Button_Action {
  return { action: undefined };
}

export const ProductScreen_Button_Action = {
  fromJSON(object: any): ProductScreen_Button_Action {
    return {
      action: isSet(object.close)
        ? { $case: "close", close: ProductScreen_Button_Action_Close.fromJSON(object.close) }
        : isSet(object.dynamic)
        ? { $case: "dynamic", dynamic: ProductScreen_Button_Action_Dynamic.fromJSON(object.dynamic) }
        : undefined,
    };
  },

  toJSON(message: ProductScreen_Button_Action): unknown {
    const obj: any = {};
    message.action?.$case === "close" &&
      (obj.close = message.action?.close ? ProductScreen_Button_Action_Close.toJSON(message.action?.close) : undefined);
    message.action?.$case === "dynamic" && (obj.dynamic = message.action?.dynamic
      ? ProductScreen_Button_Action_Dynamic.toJSON(message.action?.dynamic)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Button_Action>, I>>(base?: I): ProductScreen_Button_Action {
    return ProductScreen_Button_Action.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Button_Action>, I>>(object: I): ProductScreen_Button_Action {
    const message = createBaseProductScreen_Button_Action();
    if (object.action?.$case === "close" && object.action?.close !== undefined && object.action?.close !== null) {
      message.action = { $case: "close", close: ProductScreen_Button_Action_Close.fromPartial(object.action.close) };
    }
    if (object.action?.$case === "dynamic" && object.action?.dynamic !== undefined && object.action?.dynamic !== null) {
      message.action = {
        $case: "dynamic",
        dynamic: ProductScreen_Button_Action_Dynamic.fromPartial(object.action.dynamic),
      };
    }
    return message;
  },
};

function createBaseProductScreen_Button_Action_Close(): ProductScreen_Button_Action_Close {
  return {};
}

export const ProductScreen_Button_Action_Close = {
  fromJSON(_: any): ProductScreen_Button_Action_Close {
    return {};
  },

  toJSON(_: ProductScreen_Button_Action_Close): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Button_Action_Close>, I>>(
    base?: I,
  ): ProductScreen_Button_Action_Close {
    return ProductScreen_Button_Action_Close.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Button_Action_Close>, I>>(
    _: I,
  ): ProductScreen_Button_Action_Close {
    const message = createBaseProductScreen_Button_Action_Close();
    return message;
  },
};

function createBaseProductScreen_Button_Action_Dynamic(): ProductScreen_Button_Action_Dynamic {
  return { action: undefined, target_screen: ProductScreen_Button_Action_TargetScreen.TARGET_SCREEN_NONE };
}

export const ProductScreen_Button_Action_Dynamic = {
  fromJSON(object: any): ProductScreen_Button_Action_Dynamic {
    return {
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
      target_screen: isSet(object.target_screen)
        ? productScreen_Button_Action_TargetScreenFromJSON(object.target_screen)
        : ProductScreen_Button_Action_TargetScreen.TARGET_SCREEN_NONE,
    };
  },

  toJSON(message: ProductScreen_Button_Action_Dynamic): unknown {
    const obj: any = {};
    message.action !== undefined && (obj.action = message.action ? ActionType.toJSON(message.action) : undefined);
    message.target_screen !== undefined &&
      (obj.target_screen = productScreen_Button_Action_TargetScreenToJSON(message.target_screen));
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_Button_Action_Dynamic>, I>>(
    base?: I,
  ): ProductScreen_Button_Action_Dynamic {
    return ProductScreen_Button_Action_Dynamic.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_Button_Action_Dynamic>, I>>(
    object: I,
  ): ProductScreen_Button_Action_Dynamic {
    const message = createBaseProductScreen_Button_Action_Dynamic();
    message.action = (object.action !== undefined && object.action !== null)
      ? ActionType.fromPartial(object.action)
      : undefined;
    message.target_screen = object.target_screen ?? ProductScreen_Button_Action_TargetScreen.TARGET_SCREEN_NONE;
    return message;
  },
};

function createBaseProductScreen_AnalyticsData(): ProductScreen_AnalyticsData {
  return { name: "", brand: "", expert_analysis_used: false };
}

export const ProductScreen_AnalyticsData = {
  fromJSON(object: any): ProductScreen_AnalyticsData {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      brand: isSet(object.brand) ? String(object.brand) : "",
      expert_analysis_used: isSet(object.expert_analysis_used) ? Boolean(object.expert_analysis_used) : false,
    };
  },

  toJSON(message: ProductScreen_AnalyticsData): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.brand !== undefined && (obj.brand = message.brand);
    message.expert_analysis_used !== undefined && (obj.expert_analysis_used = message.expert_analysis_used);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductScreen_AnalyticsData>, I>>(base?: I): ProductScreen_AnalyticsData {
    return ProductScreen_AnalyticsData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductScreen_AnalyticsData>, I>>(object: I): ProductScreen_AnalyticsData {
    const message = createBaseProductScreen_AnalyticsData();
    message.name = object.name ?? "";
    message.brand = object.brand ?? "";
    message.expert_analysis_used = object.expert_analysis_used ?? false;
    return message;
  },
};

function createBaseProductsSearchScreen(): ProductsSearchScreen {
  return { promo: undefined, productCount: 0, evolutionActions: [], video: "", buttonText: "" };
}

export const ProductsSearchScreen = {
  fromJSON(object: any): ProductsSearchScreen {
    return {
      promo: isSet(object.promo) ? ProductsSearchScreen_PromoText.fromJSON(object.promo) : undefined,
      productCount: isSet(object.productCount) ? Number(object.productCount) : 0,
      evolutionActions: Array.isArray(object?.evolutionActions)
        ? object.evolutionActions.map((e: any) => ProductsSearchScreen_Action.fromJSON(e))
        : [],
      video: isSet(object.video) ? String(object.video) : "",
      buttonText: isSet(object.buttonText) ? String(object.buttonText) : "",
    };
  },

  toJSON(message: ProductsSearchScreen): unknown {
    const obj: any = {};
    message.promo !== undefined &&
      (obj.promo = message.promo ? ProductsSearchScreen_PromoText.toJSON(message.promo) : undefined);
    message.productCount !== undefined && (obj.productCount = Math.round(message.productCount));
    if (message.evolutionActions) {
      obj.evolutionActions = message.evolutionActions.map((e) => e ? ProductsSearchScreen_Action.toJSON(e) : undefined);
    } else {
      obj.evolutionActions = [];
    }
    message.video !== undefined && (obj.video = message.video);
    message.buttonText !== undefined && (obj.buttonText = message.buttonText);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsSearchScreen>, I>>(base?: I): ProductsSearchScreen {
    return ProductsSearchScreen.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsSearchScreen>, I>>(object: I): ProductsSearchScreen {
    const message = createBaseProductsSearchScreen();
    message.promo = (object.promo !== undefined && object.promo !== null)
      ? ProductsSearchScreen_PromoText.fromPartial(object.promo)
      : undefined;
    message.productCount = object.productCount ?? 0;
    message.evolutionActions = object.evolutionActions?.map((e) => ProductsSearchScreen_Action.fromPartial(e)) || [];
    message.video = object.video ?? "";
    message.buttonText = object.buttonText ?? "";
    return message;
  },
};

function createBaseProductsSearchScreen_PromoText(): ProductsSearchScreen_PromoText {
  return { header: undefined, description: undefined };
}

export const ProductsSearchScreen_PromoText = {
  fromJSON(object: any): ProductsSearchScreen_PromoText {
    return {
      header: isSet(object.header) ? AttributedString.fromJSON(object.header) : undefined,
      description: isSet(object.description) ? AttributedString.fromJSON(object.description) : undefined,
    };
  },

  toJSON(message: ProductsSearchScreen_PromoText): unknown {
    const obj: any = {};
    message.header !== undefined && (obj.header = message.header ? AttributedString.toJSON(message.header) : undefined);
    message.description !== undefined &&
      (obj.description = message.description ? AttributedString.toJSON(message.description) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsSearchScreen_PromoText>, I>>(base?: I): ProductsSearchScreen_PromoText {
    return ProductsSearchScreen_PromoText.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsSearchScreen_PromoText>, I>>(
    object: I,
  ): ProductsSearchScreen_PromoText {
    const message = createBaseProductsSearchScreen_PromoText();
    message.header = (object.header !== undefined && object.header !== null)
      ? AttributedString.fromPartial(object.header)
      : undefined;
    message.description = (object.description !== undefined && object.description !== null)
      ? AttributedString.fromPartial(object.description)
      : undefined;
    return message;
  },
};

function createBaseProductsSearchScreen_Particles(): ProductsSearchScreen_Particles {
  return { density: 0, scale: 0 };
}

export const ProductsSearchScreen_Particles = {
  fromJSON(object: any): ProductsSearchScreen_Particles {
    return {
      density: isSet(object.density) ? Number(object.density) : 0,
      scale: isSet(object.scale) ? Number(object.scale) : 0,
    };
  },

  toJSON(message: ProductsSearchScreen_Particles): unknown {
    const obj: any = {};
    message.density !== undefined && (obj.density = message.density);
    message.scale !== undefined && (obj.scale = message.scale);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsSearchScreen_Particles>, I>>(base?: I): ProductsSearchScreen_Particles {
    return ProductsSearchScreen_Particles.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsSearchScreen_Particles>, I>>(
    object: I,
  ): ProductsSearchScreen_Particles {
    const message = createBaseProductsSearchScreen_Particles();
    message.density = object.density ?? 0;
    message.scale = object.scale ?? 0;
    return message;
  },
};

function createBaseProductsSearchScreen_ProductCounter(): ProductsSearchScreen_ProductCounter {
  return { count: 0, duration: 0 };
}

export const ProductsSearchScreen_ProductCounter = {
  fromJSON(object: any): ProductsSearchScreen_ProductCounter {
    return {
      count: isSet(object.count) ? Number(object.count) : 0,
      duration: isSet(object.duration) ? Number(object.duration) : 0,
    };
  },

  toJSON(message: ProductsSearchScreen_ProductCounter): unknown {
    const obj: any = {};
    message.count !== undefined && (obj.count = Math.round(message.count));
    message.duration !== undefined && (obj.duration = message.duration);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsSearchScreen_ProductCounter>, I>>(
    base?: I,
  ): ProductsSearchScreen_ProductCounter {
    return ProductsSearchScreen_ProductCounter.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsSearchScreen_ProductCounter>, I>>(
    object: I,
  ): ProductsSearchScreen_ProductCounter {
    const message = createBaseProductsSearchScreen_ProductCounter();
    message.count = object.count ?? 0;
    message.duration = object.duration ?? 0;
    return message;
  },
};

function createBaseProductsSearchScreen_Action(): ProductsSearchScreen_Action {
  return { action: undefined };
}

export const ProductsSearchScreen_Action = {
  fromJSON(object: any): ProductsSearchScreen_Action {
    return {
      action: isSet(object.born)
        ? { $case: "born", born: ProductsSearchScreen_Action_Born.fromJSON(object.born) }
        : isSet(object.blast)
        ? { $case: "blast", blast: ProductsSearchScreen_Action_Blast.fromJSON(object.blast) }
        : isSet(object.finalMove)
        ? { $case: "finalMove", finalMove: ProductsSearchScreen_Action_FinalMove.fromJSON(object.finalMove) }
        : undefined,
    };
  },

  toJSON(message: ProductsSearchScreen_Action): unknown {
    const obj: any = {};
    message.action?.$case === "born" &&
      (obj.born = message.action?.born ? ProductsSearchScreen_Action_Born.toJSON(message.action?.born) : undefined);
    message.action?.$case === "blast" &&
      (obj.blast = message.action?.blast ? ProductsSearchScreen_Action_Blast.toJSON(message.action?.blast) : undefined);
    message.action?.$case === "finalMove" && (obj.finalMove = message.action?.finalMove
      ? ProductsSearchScreen_Action_FinalMove.toJSON(message.action?.finalMove)
      : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsSearchScreen_Action>, I>>(base?: I): ProductsSearchScreen_Action {
    return ProductsSearchScreen_Action.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsSearchScreen_Action>, I>>(object: I): ProductsSearchScreen_Action {
    const message = createBaseProductsSearchScreen_Action();
    if (object.action?.$case === "born" && object.action?.born !== undefined && object.action?.born !== null) {
      message.action = { $case: "born", born: ProductsSearchScreen_Action_Born.fromPartial(object.action.born) };
    }
    if (object.action?.$case === "blast" && object.action?.blast !== undefined && object.action?.blast !== null) {
      message.action = { $case: "blast", blast: ProductsSearchScreen_Action_Blast.fromPartial(object.action.blast) };
    }
    if (
      object.action?.$case === "finalMove" &&
      object.action?.finalMove !== undefined &&
      object.action?.finalMove !== null
    ) {
      message.action = {
        $case: "finalMove",
        finalMove: ProductsSearchScreen_Action_FinalMove.fromPartial(object.action.finalMove),
      };
    }
    return message;
  },
};

function createBaseProductsSearchScreen_Action_Born(): ProductsSearchScreen_Action_Born {
  return { particles: undefined, delay: 0, sleep: 0 };
}

export const ProductsSearchScreen_Action_Born = {
  fromJSON(object: any): ProductsSearchScreen_Action_Born {
    return {
      particles: isSet(object.particles) ? ProductsSearchScreen_Particles.fromJSON(object.particles) : undefined,
      delay: isSet(object.delay) ? Number(object.delay) : 0,
      sleep: isSet(object.sleep) ? Number(object.sleep) : 0,
    };
  },

  toJSON(message: ProductsSearchScreen_Action_Born): unknown {
    const obj: any = {};
    message.particles !== undefined &&
      (obj.particles = message.particles ? ProductsSearchScreen_Particles.toJSON(message.particles) : undefined);
    message.delay !== undefined && (obj.delay = message.delay);
    message.sleep !== undefined && (obj.sleep = message.sleep);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsSearchScreen_Action_Born>, I>>(
    base?: I,
  ): ProductsSearchScreen_Action_Born {
    return ProductsSearchScreen_Action_Born.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsSearchScreen_Action_Born>, I>>(
    object: I,
  ): ProductsSearchScreen_Action_Born {
    const message = createBaseProductsSearchScreen_Action_Born();
    message.particles = (object.particles !== undefined && object.particles !== null)
      ? ProductsSearchScreen_Particles.fromPartial(object.particles)
      : undefined;
    message.delay = object.delay ?? 0;
    message.sleep = object.sleep ?? 0;
    return message;
  },
};

function createBaseProductsSearchScreen_Action_Blast(): ProductsSearchScreen_Action_Blast {
  return { promo: undefined, particles: undefined, counter: undefined, waveLifetime: 0, sleep: 0 };
}

export const ProductsSearchScreen_Action_Blast = {
  fromJSON(object: any): ProductsSearchScreen_Action_Blast {
    return {
      promo: isSet(object.promo) ? ProductsSearchScreen_PromoText.fromJSON(object.promo) : undefined,
      particles: isSet(object.particles) ? ProductsSearchScreen_Particles.fromJSON(object.particles) : undefined,
      counter: isSet(object.counter) ? ProductsSearchScreen_ProductCounter.fromJSON(object.counter) : undefined,
      waveLifetime: isSet(object.waveLifetime) ? Number(object.waveLifetime) : 0,
      sleep: isSet(object.sleep) ? Number(object.sleep) : 0,
    };
  },

  toJSON(message: ProductsSearchScreen_Action_Blast): unknown {
    const obj: any = {};
    message.promo !== undefined &&
      (obj.promo = message.promo ? ProductsSearchScreen_PromoText.toJSON(message.promo) : undefined);
    message.particles !== undefined &&
      (obj.particles = message.particles ? ProductsSearchScreen_Particles.toJSON(message.particles) : undefined);
    message.counter !== undefined &&
      (obj.counter = message.counter ? ProductsSearchScreen_ProductCounter.toJSON(message.counter) : undefined);
    message.waveLifetime !== undefined && (obj.waveLifetime = message.waveLifetime);
    message.sleep !== undefined && (obj.sleep = message.sleep);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsSearchScreen_Action_Blast>, I>>(
    base?: I,
  ): ProductsSearchScreen_Action_Blast {
    return ProductsSearchScreen_Action_Blast.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsSearchScreen_Action_Blast>, I>>(
    object: I,
  ): ProductsSearchScreen_Action_Blast {
    const message = createBaseProductsSearchScreen_Action_Blast();
    message.promo = (object.promo !== undefined && object.promo !== null)
      ? ProductsSearchScreen_PromoText.fromPartial(object.promo)
      : undefined;
    message.particles = (object.particles !== undefined && object.particles !== null)
      ? ProductsSearchScreen_Particles.fromPartial(object.particles)
      : undefined;
    message.counter = (object.counter !== undefined && object.counter !== null)
      ? ProductsSearchScreen_ProductCounter.fromPartial(object.counter)
      : undefined;
    message.waveLifetime = object.waveLifetime ?? 0;
    message.sleep = object.sleep ?? 0;
    return message;
  },
};

function createBaseProductsSearchScreen_Action_FinalMove(): ProductsSearchScreen_Action_FinalMove {
  return { promo: undefined, productCount: 0, particlesScale: 0, duration: 0 };
}

export const ProductsSearchScreen_Action_FinalMove = {
  fromJSON(object: any): ProductsSearchScreen_Action_FinalMove {
    return {
      promo: isSet(object.promo) ? ProductsSearchScreen_PromoText.fromJSON(object.promo) : undefined,
      productCount: isSet(object.productCount) ? Number(object.productCount) : 0,
      particlesScale: isSet(object.particlesScale) ? Number(object.particlesScale) : 0,
      duration: isSet(object.duration) ? Number(object.duration) : 0,
    };
  },

  toJSON(message: ProductsSearchScreen_Action_FinalMove): unknown {
    const obj: any = {};
    message.promo !== undefined &&
      (obj.promo = message.promo ? ProductsSearchScreen_PromoText.toJSON(message.promo) : undefined);
    message.productCount !== undefined && (obj.productCount = Math.round(message.productCount));
    message.particlesScale !== undefined && (obj.particlesScale = message.particlesScale);
    message.duration !== undefined && (obj.duration = message.duration);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductsSearchScreen_Action_FinalMove>, I>>(
    base?: I,
  ): ProductsSearchScreen_Action_FinalMove {
    return ProductsSearchScreen_Action_FinalMove.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductsSearchScreen_Action_FinalMove>, I>>(
    object: I,
  ): ProductsSearchScreen_Action_FinalMove {
    const message = createBaseProductsSearchScreen_Action_FinalMove();
    message.promo = (object.promo !== undefined && object.promo !== null)
      ? ProductsSearchScreen_PromoText.fromPartial(object.promo)
      : undefined;
    message.productCount = object.productCount ?? 0;
    message.particlesScale = object.particlesScale ?? 0;
    message.duration = object.duration ?? 0;
    return message;
  },
};

function createBaseSubscriptionManagementPageData(): SubscriptionManagementPageData {
  return { provider: "", subscription_id: "", user_id: "" };
}

export const SubscriptionManagementPageData = {
  fromJSON(object: any): SubscriptionManagementPageData {
    return {
      provider: isSet(object.provider) ? String(object.provider) : "",
      subscription_id: isSet(object.subscription_id) ? String(object.subscription_id) : "",
      user_id: isSet(object.user_id) ? String(object.user_id) : "",
    };
  },

  toJSON(message: SubscriptionManagementPageData): unknown {
    const obj: any = {};
    message.provider !== undefined && (obj.provider = message.provider);
    message.subscription_id !== undefined && (obj.subscription_id = message.subscription_id);
    message.user_id !== undefined && (obj.user_id = message.user_id);
    return obj;
  },

  create<I extends Exact<DeepPartial<SubscriptionManagementPageData>, I>>(base?: I): SubscriptionManagementPageData {
    return SubscriptionManagementPageData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SubscriptionManagementPageData>, I>>(
    object: I,
  ): SubscriptionManagementPageData {
    const message = createBaseSubscriptionManagementPageData();
    message.provider = object.provider ?? "";
    message.subscription_id = object.subscription_id ?? "";
    message.user_id = object.user_id ?? "";
    return message;
  },
};

function createBaseEncryptedSubscriptionManagementPageData(): EncryptedSubscriptionManagementPageData {
  return { encrypted_smp: "" };
}

export const EncryptedSubscriptionManagementPageData = {
  fromJSON(object: any): EncryptedSubscriptionManagementPageData {
    return { encrypted_smp: isSet(object.encrypted_smp) ? String(object.encrypted_smp) : "" };
  },

  toJSON(message: EncryptedSubscriptionManagementPageData): unknown {
    const obj: any = {};
    message.encrypted_smp !== undefined && (obj.encrypted_smp = message.encrypted_smp);
    return obj;
  },

  create<I extends Exact<DeepPartial<EncryptedSubscriptionManagementPageData>, I>>(
    base?: I,
  ): EncryptedSubscriptionManagementPageData {
    return EncryptedSubscriptionManagementPageData.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<EncryptedSubscriptionManagementPageData>, I>>(
    object: I,
  ): EncryptedSubscriptionManagementPageData {
    const message = createBaseEncryptedSubscriptionManagementPageData();
    message.encrypted_smp = object.encrypted_smp ?? "";
    return message;
  },
};

function createBaseImageButton(): ImageButton {
  return { image: "", action: undefined, resources: [], before_animation_delay: 0 };
}

export const ImageButton = {
  fromJSON(object: any): ImageButton {
    return {
      image: isSet(object.image) ? String(object.image) : "",
      action: isSet(object.action) ? ActionType.fromJSON(object.action) : undefined,
      resources: Array.isArray(object?.resources)
        ? object.resources.map((e: any) => ImageButton_Resource.fromJSON(e))
        : [],
      before_animation_delay: isSet(object.before_animation_delay) ? Number(object.before_animation_delay) : 0,
    };
  },

  toJSON(message: ImageButton): unknown {
    const obj: any = {};
    message.image !== undefined && (obj.image = message.image);
    message.action !== undefined && (obj.action = message.action ? ActionType.toJSON(message.action) : undefined);
    if (message.resources) {
      obj.resources = message.resources.map((e) => e ? ImageButton_Resource.toJSON(e) : undefined);
    } else {
      obj.resources = [];
    }
    message.before_animation_delay !== undefined && (obj.before_animation_delay = message.before_animation_delay);
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageButton>, I>>(base?: I): ImageButton {
    return ImageButton.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageButton>, I>>(object: I): ImageButton {
    const message = createBaseImageButton();
    message.image = object.image ?? "";
    message.action = (object.action !== undefined && object.action !== null)
      ? ActionType.fromPartial(object.action)
      : undefined;
    message.resources = object.resources?.map((e) => ImageButton_Resource.fromPartial(e)) || [];
    message.before_animation_delay = object.before_animation_delay ?? 0;
    return message;
  },
};

function createBaseImageButton_Resource(): ImageButton_Resource {
  return { name: "", appear_probability: 0 };
}

export const ImageButton_Resource = {
  fromJSON(object: any): ImageButton_Resource {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      appear_probability: isSet(object.appear_probability) ? Number(object.appear_probability) : 0,
    };
  },

  toJSON(message: ImageButton_Resource): unknown {
    const obj: any = {};
    message.name !== undefined && (obj.name = message.name);
    message.appear_probability !== undefined && (obj.appear_probability = Math.round(message.appear_probability));
    return obj;
  },

  create<I extends Exact<DeepPartial<ImageButton_Resource>, I>>(base?: I): ImageButton_Resource {
    return ImageButton_Resource.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ImageButton_Resource>, I>>(object: I): ImageButton_Resource {
    const message = createBaseImageButton_Resource();
    message.name = object.name ?? "";
    message.appear_probability = object.appear_probability ?? 0;
    return message;
  },
};

function createBaseProductPageReport(): ProductPageReport {
  return { product_id: "", user_id: "", comment: "", problem_tags: [], screenshot: undefined };
}

export const ProductPageReport = {
  fromJSON(object: any): ProductPageReport {
    return {
      product_id: isSet(object.product_id) ? String(object.product_id) : "",
      user_id: isSet(object.user_id) ? String(object.user_id) : "",
      comment: isSet(object.comment) ? String(object.comment) : "",
      problem_tags: Array.isArray(object?.problem_tags)
        ? object.problem_tags.map((e: any) => productPageReport_ProblemTagFromJSON(e))
        : [],
      screenshot: isSet(object.screenshot) ? Image.fromJSON(object.screenshot) : undefined,
    };
  },

  toJSON(message: ProductPageReport): unknown {
    const obj: any = {};
    message.product_id !== undefined && (obj.product_id = message.product_id);
    message.user_id !== undefined && (obj.user_id = message.user_id);
    message.comment !== undefined && (obj.comment = message.comment);
    if (message.problem_tags) {
      obj.problem_tags = message.problem_tags.map((e) => productPageReport_ProblemTagToJSON(e));
    } else {
      obj.problem_tags = [];
    }
    message.screenshot !== undefined &&
      (obj.screenshot = message.screenshot ? Image.toJSON(message.screenshot) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductPageReport>, I>>(base?: I): ProductPageReport {
    return ProductPageReport.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductPageReport>, I>>(object: I): ProductPageReport {
    const message = createBaseProductPageReport();
    message.product_id = object.product_id ?? "";
    message.user_id = object.user_id ?? "";
    message.comment = object.comment ?? "";
    message.problem_tags = object.problem_tags?.map((e) => e) || [];
    message.screenshot = (object.screenshot !== undefined && object.screenshot !== null)
      ? Image.fromPartial(object.screenshot)
      : undefined;
    return message;
  },
};

function createBaseProductPageReportRequest(): ProductPageReportRequest {
  return { request: undefined, report: undefined };
}

export const ProductPageReportRequest = {
  fromJSON(object: any): ProductPageReportRequest {
    return {
      request: isSet(object.request) ? Request.fromJSON(object.request) : undefined,
      report: isSet(object.report) ? ProductPageReport.fromJSON(object.report) : undefined,
    };
  },

  toJSON(message: ProductPageReportRequest): unknown {
    const obj: any = {};
    message.request !== undefined && (obj.request = message.request ? Request.toJSON(message.request) : undefined);
    message.report !== undefined &&
      (obj.report = message.report ? ProductPageReport.toJSON(message.report) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<ProductPageReportRequest>, I>>(base?: I): ProductPageReportRequest {
    return ProductPageReportRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ProductPageReportRequest>, I>>(object: I): ProductPageReportRequest {
    const message = createBaseProductPageReportRequest();
    message.request = (object.request !== undefined && object.request !== null)
      ? Request.fromPartial(object.request)
      : undefined;
    message.report = (object.report !== undefined && object.report !== null)
      ? ProductPageReport.fromPartial(object.report)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
