import React, { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from "react"
import {
  Button,
  Flex,
  Heading,
  Image as ChakraImage,
  Text,
  IconButton,
  useDisclosure,
  Icon,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Box,
  IconButtonProps,
} from "@chakra-ui/react"

import {
  apiSaveFacePhotoUrl,
  apiSaveFaceScanUrl,
  faceScanAnalysis,
  sendFileToImageStorage,
} from "~/api"
import type { ColorString, QuizId, URLString } from "~/types"
import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import {
  NextButton,
  NextButtonBottomSlideUpContainer as NextButtonContainer,
} from "~/components/shared/NextButton"
import type { NextPageCb } from "~/hooks/useNextQuiz"
import { useQuizHistory, useUserId } from "~/hooks/useQuizHistory"
import { TT, TTString } from "~/components/shared/AttributedString"
import { useAmplitude } from "~/hooks/analytics/useAmplitude"
import { createStopwatch } from "~/utils"

import { FaceScanLoading } from "./FaceScanLoading"
import { base64ToBlob, base64ToUrl, fileToBase64 } from "./fileToBase64"
import defaultImage from "./assets/selfie-photo.webp?url"
import { useScanStore } from "./useScanStore"
import { FaceScannerPushModal } from "./FaceScannerPushModal"
import { VFlex } from "~/components/shared/VFlex"
import { QuestionMarkIcon } from "./Icons"
import { ResponseStatus } from "~/generated/networking"
import { ServerApiError } from "~/errors"

const FileInput: FC<{ label: string; onChange: (f: File) => void }> = ({
  label,
  onChange,
  ...props
}) => {
  const ref = useRef<HTMLInputElement>(null)
  const { logFaceScanStatus } = useAmplitude()
  const onClick = () => {
    if (ref.current) {
      logFaceScanStatus("startSelectPhoto")
      ref.current.click()
    }
  }
  const onChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0]
      if (file) {
        onChange(file)
      }
    },
    [onChange]
  )
  return (
    <>
      <Button variant="next" w="100%" minH="56px" onClick={onClick} {...props}>
        {label}
      </Button>
      <input
        type="file"
        name="picture"
        accept="image/*"
        capture="user"
        style={{ display: "none" }}
        ref={ref}
        onChange={onChangeHandler}
      />
    </>
  )
}

const ConsentModalContent = () => (
  <ModalContent borderRadius="brand24" marginTop="76px" marginX={6} shadow="LoviBig">
    <ModalCloseButton
      color="Base/baseSecondary"
      borderRadius="full"
      top={2}
      right={4}
      sx={{ "--close-button-size": "40px" }}
      _hover={{
        bgColor: "Base/neutralPrimary",
        transform: "scale(85%)",
      }}
      _active={{
        bgColor: "Base/neutralPrimary",
        transform: "scale(85%)",
      }}
    />
    <ModalBody padding={4}>
      <VFlex gap={2}>
        <Box textStyle="Subtitle/Primary">Privacy Policy</Box>
        <Box textStyle="Paragraph/Secondary" whiteSpace="pre-line">
          {`We will save your photo on our side for the purpose of providing it to you in the app.
The photo will be deleted from our servers within 24 hours. You can request earlier deletion by contacting us at `}
          <a href="mailto:care@lovi.care">care@lovi.care</a>
        </Box>
      </VFlex>
    </ModalBody>
  </ModalContent>
)

const ConsentButton = (props: IconButtonProps) => (
  <IconButton
    icon={<Icon as={QuestionMarkIcon} boxSize={6} />}
    fontSize={6}
    isRound={true}
    color="Base/baseSecondary"
    bgColor="Base/neutralPrimary"
    w={10}
    h={10}
    _hover={{
      bgColor: "Base/neutralPrimary",
      transform: "scale(85%)",
    }}
    _active={{
      bgColor: "Base/neutralPrimary",
      transform: "scale(85%)",
    }}
    {...props}
  />
)

type Variant = {
  text: string
}

const defaultTitle = "Let’s analyze your skin"
const defaultDescription =
  "Take a selfie to get your skin analysis. Make sure your face is well-lit and all the makeup is removed."

const SCAN_STORAGE_OPTS = {
  maxDimension: window.screen.availHeight * window.screen.pixelDepth,
  instant: true,
} as const

const preloadImage = (src: string) => {
  /* TODO create unified system for preload external resources */
  const img = new Image()
  img.src = src
}

const preloadImages = (...images: string[]) => {
  return new Promise((resolve, _reject) => {
    images.forEach(preloadImage)
    resolve(undefined)
  })
}

const applyMask = async (
  ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
  width: number,
  height: number,
  maskColor: string,
  maskImg: ImageBitmap
) => {
  ctx.drawImage(maskImg, 0, 0, width, height)

  ctx.globalCompositeOperation = "multiply"
  ctx.fillStyle = maskColor
  ctx.fillRect(0, 0, width, height)
  ctx.globalCompositeOperation = "screen"
}

/* TODO resize image */
const makeImageCanvas = async (image: File) => {
  const img = await createImageBitmap(image)
  const width = img.width
  const height = img.height

  const imageCanvas = document.createElement("canvas")
  const ctx = imageCanvas.getContext("2d")
  imageCanvas.width = width
  imageCanvas.height = height
  if (!ctx) return

  ctx.drawImage(img, 0, 0)

  return imageCanvas
}

const makeMasksCanvas = async (
  photoCanvas: HTMLCanvasElement,
  masks: [ColorString, Base64String][]
) => {
  const ctx = photoCanvas.getContext("2d")
  const width = photoCanvas.width
  const height = photoCanvas.height
  if (!ctx) return

  ctx.fillStyle = "#00000044"
  ctx.fillRect(0, 0, width, height)

  // const masksCanvas = new OffscreenCanvas(width, height)
  const masksCanvas = document.createElement("canvas")
  const ctx2 = masksCanvas.getContext("2d")
  masksCanvas.width = width
  masksCanvas.height = height

  if (!ctx2) return

  const masksImages: ImageBitmap[] = await Promise.all(
    masks.map(([_color, b64]) => createImageBitmap(base64ToBlob(b64)))
  )

  let idx = 0
  for await (const i of masks) {
    const [maskColor, _] = i
    const bitmap = masksImages[idx]
    if (bitmap) {
      await applyMask(ctx2, width, height, maskColor, bitmap)
    }
    idx++
  }

  ctx2.drawImage(photoCanvas, 0, 0, width, height)

  return masksCanvas
}

const toBlobAsync = (
  canvas: HTMLCanvasElement,
  type = "image/png",
  quality = 1
): Promise<Blob | null> =>
  new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob)
      },
      type,
      quality
    )
  })

const canvasToStorage = async (canvas: HTMLCanvasElement) => {
  let storageUrl: URLString | undefined = undefined
  if (canvas) {
    const blob = await toBlobAsync(canvas, "image/jpeg", 0.95)
    if (blob) {
      storageUrl = await sendFileToImageStorage(blob, SCAN_STORAGE_OPTS)
    }
  }
  return storageUrl
}

const savePhoto = async (userId: UserId, quizId: QuizId, file: File) => {
  const photoCanvas = await makeImageCanvas(file)
  let photoStorageUrl: URLString | undefined = undefined
  if (photoCanvas) {
    photoStorageUrl = await canvasToStorage(photoCanvas)
    if (photoStorageUrl) {
      await apiSaveFacePhotoUrl(userId, quizId, photoStorageUrl)
      preloadImages(photoStorageUrl)
      return photoCanvas
    }
  }
  return false
}

const useUploadScan = ({ onSuccess, onError }: { onSuccess: () => void; onError: () => void }) => {
  const { userId } = useUserId()
  const [scan, setScan] = useState<URLString>("" as URLString)
  const [status, setStatus] = useState<"init" | "loading" | "done">("init")
  const { put: putScanStore, clear: clearScanStore } = useScanStore()
  const { logFaceScanStatus, logFaceScanError, logFaceScanAnalysisTime } = useAmplitude()
  const {
    params: { quiz },
  } = useQuizHistory()

  const onFileUpload = useCallback(
    async (file: File) => {
      setStatus("loading")
      if (file && userId && quiz) {
        const timeFromStart = createStopwatch()
        try {
          const encoded = await fileToBase64(file)
          if (encoded) {
            const encodedScan = base64ToUrl(encoded)
            setScan(encodedScan)
            const encodeTime = timeFromStart()

            const [analysisResult, photoCanvas] = await Promise.all([
              faceScanAnalysis(userId, encoded),
              savePhoto(userId, quiz, file),
            ])
            const analysisTime = timeFromStart()

            const { scanId, masks } = analysisResult
            if (photoCanvas) {
              const canvas = await makeMasksCanvas(photoCanvas, Object.values(masks))
              let scanWithMasksUrl = undefined
              if (canvas) {
                scanWithMasksUrl = await canvasToStorage(canvas)
              }

              if (scanWithMasksUrl) {
                await apiSaveFaceScanUrl(userId, quiz, scanWithMasksUrl, scanId)

                putScanStore({ id: scanId, image: scanWithMasksUrl })
                preloadImages(scanWithMasksUrl)
              }
              const putResponseToStoreTime = timeFromStart()
              const times = {
                encode: encodeTime,
                analysis: analysisTime - encodeTime,
                putToStore: putResponseToStoreTime - analysisTime,
              }

              logFaceScanAnalysisTime(times)
              setStatus("done")
              onSuccess()
            }
          }
        } catch (error) {
          const analysisTime = timeFromStart()
          const times = { analysis: analysisTime }
          logFaceScanAnalysisTime(times)

          clearScanStore()
          logFaceScanError()

          const result = window.confirm("Oops... It doesn't look like a selfie. Try Again?")
          if (result) {
            setStatus("init")
          } else {
            onError()
          }
        }
      }
    },
    [onSuccess, onError, userId, putScanStore, clearScanStore]
  )

  useEffect(() => {
    logFaceScanStatus(status)
  }, [status])

  const onSkip = useCallback(() => {
    clearScanStore()
    onError()
  }, [onError, clearScanStore])

  return { onSkip, onFileUpload, status, scan }
}

export const FaceScannerInitPage: FC<{
  enablePhotoConsent?: boolean
  title?: TTString
  description?: TTString
  image?: string
  variants?: Variant[]
  next: NextPageCb
}> = ({
  enablePhotoConsent = false,
  title = defaultTitle,
  description = defaultDescription,
  image = defaultImage,
  variants = [],
  next,
}) => {
  const scanQuestionResponse = variants[0]?.text ?? "Take a selfie"
  const skipButtonEnabled = variants.length > 1
  const skipScanQuestionResponse = variants[1]?.text ?? "Do it later"
  const {
    isOpen: isOpenPushModal,
    onOpen: onOpenPushModal,
    onClose: onClosePushModal,
  } = useDisclosure()
  const {
    isOpen: isOpenConsentModal,
    onOpen: onOpenConsentModal,
    onClose: onCloseConsentModal,
  } = useDisclosure()
  const { onSkip, status, scan, onFileUpload } = useUploadScan({
    onSuccess: () => {
      next([scanQuestionResponse])
    },
    onError: () => {
      next([skipScanQuestionResponse])
    },
  })

  if (status === "loading" || status === "done") {
    return <FaceScanLoading scan={scan} />
  }

  return (
    <BasePageWrapper>
      <Flex justifyContent="flex-end" alignItems="flex-end">
        <ChakraImage
          w="full"
          minH="245px"
          alt=""
          src={image || defaultImage}
          borderRadius="2xl"
          sx={{ aspectRatio: "327/245" }}
        />
        {enablePhotoConsent && (
          <Box position="absolute">
            <Modal
              isOpen={isOpenConsentModal}
              onClose={onCloseConsentModal}
              motionPreset="slideInBottom"
            >
              <ConsentModalContent />
            </Modal>
            <ConsentButton
              aria-label="Privacy policy"
              position="relative"
              top={-2}
              left={-2}
              onClick={onOpenConsentModal}
            />
          </Box>
        )}
      </Flex>

      <VFlex gap={3} alignItems="center" mt={6}>
        <Heading size="Header/Primary" textAlign="center">
          <TT>{title}</TT>
        </Heading>
        <Text textStyle="Paragraph/Primary" textAlign="center">
          <TT>{description}</TT>
        </Text>
      </VFlex>

      <FaceScannerPushModal isOpen={isOpenPushModal} onClose={onClosePushModal}>
        <VFlex gap={2}>
          <FileInput label="Let’s take a selfie" onChange={onFileUpload} />
          {skipButtonEnabled && (
            <NextButton
              bgColor="Base/neutralSecondary"
              variant="nextWhite"
              label="Continue without a selfie"
              onClick={onSkip}
            />
          )}
        </VFlex>
      </FaceScannerPushModal>

      <NextButtonContainer visible>
        <VFlex gap={2}>
          <FileInput label={scanQuestionResponse} onChange={onFileUpload} />
          {skipButtonEnabled && (
            <NextButton
              variant="nextWhite"
              label={skipScanQuestionResponse}
              onClick={onOpenPushModal}
            />
          )}
        </VFlex>
      </NextButtonContainer>
    </BasePageWrapper>
  )
}
