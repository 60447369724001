import { useCallback } from "react"
import { useHistory } from "react-router-dom"
import * as Sentry from "@sentry/react"
import {
  captureException as amplitudeCaptureException,
  captureError as amplitudeCaptureError,
} from "./analytics/useAmplitude"
import {
  captureException as gaCaptureException,
  captureError as gaCaptureError,
} from "./analytics/useGoogleAnalytics"

export const useCaptureException = () => {
  const history = useHistory()
  const captureException = useCallback(
    (err: unknown) => {
      Sentry.captureException(err)
      amplitudeCaptureException(JSON.stringify(err))
      gaCaptureException(JSON.stringify(err))
      console.error(err)
      /* FIXME use separate hook for redirect/navigation */
      history.push("/error")
    },
    [history]
  )
  return captureException
}

export const logError = (message: string) => {
  console.error(message)
  Sentry.captureMessage(message, "error")
  amplitudeCaptureError(message)
  gaCaptureError(message)
}
