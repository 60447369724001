import { createReducer } from "@reduxjs/toolkit"

import type { QuizType } from "~/api"
import type { QuestionId, QuizId } from "~/types"
import {
  addQuiz,
  releaseAnswers,
  saveAnswer,
  setLoadingInProcess,
  setLoadingStop,
  setUserIdUpdated,
} from "./actions"

const surveyInitState: {
  quizOrder: QuizId[]
  quiz: Record<QuizId, QuizType>
  answers: Record<QuizId, Record<QuestionId, string[]>>
  loading: boolean
  userIdUpdated: boolean
} = {
  quizOrder: [],
  quiz: {},
  answers: {},
  loading: false,
  userIdUpdated: false,
}

export const surveyReducer = createReducer(surveyInitState, (builder) => {
  builder.addCase(addQuiz, (state, action) => {
    if (!state.quizOrder.includes(action.payload.id)) {
      state.quizOrder.push(action.payload.id)
    }
    state.quiz[action.payload.id] = action.payload
  })

  builder.addCase(saveAnswer, (state, action) => {
    const { answers, quizId } = action.payload

    if (answers) {
      state.answers[quizId] = { ...answers }
    }
  })

  builder.addCase(releaseAnswers, (state) => {
    state.answers = {}
  })

  builder.addCase(setLoadingInProcess, (state) => {
    state.loading = true
  })

  builder.addCase(setLoadingStop, (state) => {
    state.loading = false
  })

  builder.addCase(setUserIdUpdated, (state) => {
    state.userIdUpdated = true
  })
})
