import { useMemo } from "react"

export const useMetaPixel = () => {
  // fixme
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const getFbq = () => window.fbq || (() => {})

  return useMemo(
    () => ({
      logCompleteRegistration: () => {
        getFbq()("trackCustom", "CompleteRegistration")
      },
      logContact: (email: string) => {
        getFbq()("track", "Contact", { email })
      },
    }),
    []
  )
}
