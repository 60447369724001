import { ComponentStyleConfig } from "@chakra-ui/theme"
import { ButtonTheme } from "~/components/shared/Button"
import { CheckboxTheme } from "~/components/shared/Checkbox"
import { SingleSelectItemTheme } from "~/components/shared/SingleSelect"
import { NumSelectItemTheme } from "~/components/shared/NumRangeInput"
import { MultiSelectItemTheme } from "~/components/shared/MultiSelect"
import { ProgressBarsPageTheme } from "~/components/pages/ProgressBarsPage"
import { getTextStyles } from "./textStyles"

const Link: ComponentStyleConfig = {
  baseStyle: {
    color: "auto",
    _hover: {
      textDecoration: "none",
    },
    _active: {
      textDecoration: "none",
    },
  },
}
const Input: ComponentStyleConfig = {
  baseStyle: {
    control: {
      /* TODO */
      bg: "Base/neutralPrimary",
      borderRadius: "2xl",
      borderWidth: "1px",
      borderColor: "Base/neutralSecondary",
      _focus: {
        borderColor: "Base/accentPrimary",
      },
    },
  },
}
const TextareaTheme = (theme: Theme): ComponentStyleConfig => ({
  variants: {
    outline: {
      ...getTextStyles(theme)["Paragraph/Primary"],
      bg: "Base/neutralPrimary",
      py: 4,
      px: 5,
      borderRadius: "16px",
      borderWidth: "1px",
      color: "Base/basePrimary",
      borderColor: "Base/baseTertiary",
      _focus: {
        borderColor: "Base/accentPrimary",
      },
      _placeholder: {
        color: "Base/baseSecondary",
      },
      _focusVisible: {
        borderColor: "Base/accentPrimary",
        boxShadow: "none",
      },
    },
  },
})
const Tag: ComponentStyleConfig = {
  variants: {
    outline: {
      color: "green",
      outlineStyle: "dotted",
    },
  },
}
const headingSizes = (theme: Theme) =>
  Object.fromEntries(
    Object.entries(getTextStyles(theme)).filter(
      ([name]) => name.startsWith("Medium") || name.startsWith("Header") || name.startsWith("Lead")
    )
  )
const HeadingTheme = (theme: Theme): ComponentStyleConfig => ({
  baseStyle: {
    fontFamily: "heading",
    fontWeight: 500,
  },
  sizes: {
    quoteScreen1: {
      fontSize: "32px",
      lineHeight: "36px",
    },
    quoteScreen2: {
      fontSize: "24px",
      lineHeight: "28px",
    },
    messageLarge: {
      fontSize: "44px",
      lineHeight: "48px",
    },
    h1: {
      fontSize: "32px",
      lineHeight: "36px",
    },
    h2: {
      fontSize: "26px",
      lineHeight: "32px",
    },
    MediumPrimary: {
      fontSize: "32px",
      lineHeight: "36px",
    },
    MediumSecondary: {
      fontSize: "24px",
      lineHeight: "28px",
    },
    ...headingSizes(theme),
  },
  variants: {
    text: {
      fontFamily: "body",
    },
  },
})

const TooltipTheme = (theme: Theme): ComponentStyleConfig => ({
  sizes: getTextStyles(theme),
})

export const getComponents = (theme: Theme) => ({
  Button: ButtonTheme(theme),
  Input,
  Checkbox: CheckboxTheme(theme),
  Heading: HeadingTheme(theme),
  Link,
  Tag,
  Tooltip: TooltipTheme(theme),
  Textarea: TextareaTheme(theme),
  SingleSelectItem: SingleSelectItemTheme(theme),
  NumSelectItem: NumSelectItemTheme(theme),
  MultiSelectItem: MultiSelectItemTheme(theme),
  ProgressBarsPage: ProgressBarsPageTheme(theme),
})
