import type { QuestionV2Type, QuizType } from "~/api"
import { ProgressBarTheme } from "~/api/QuestionType"
import { getText } from "~/config"
import type { QuestionId, QuizId } from "~/types"
import { getQuizUrl } from "~/utils/getQuizUrl"
import type { RootState as State } from ".."

const quizByIndex =
  (index: number) =>
  (state: State): QuizType | undefined => {
    const quizId: QuizId | undefined = state.survey.quizOrder[index]
    if (!quizId) {
      return undefined
    }

    return state.survey.quiz[quizId]
  }

type Route = {
  path: string
  question: QuestionV2Type
}

export const applicationRoutes = (state: State): Route[] => {
  const quizIds = state.survey.quizOrder

  const routes: Route[] = []

  for (const quizId of quizIds) {
    state.survey.quiz[quizId]?.questions_v2?.forEach((question: QuestionV2Type) => {
      const path = getQuizUrl(quizId, question.parameters?.id as QuestionId).split("?")[0] ?? ""
      routes.push({
        path,
        question,
      })
    })
  }

  return routes
}

const getQuestionIndex = (quizData: QuizType, questionId: QuestionId) => {
  if (!quizData || !Array.isArray(quizData.questions_v2)) {
    return undefined
  }
  const index = quizData.questions_v2.findIndex(
    (question) => question.parameters?.id === questionId
  )
  if (index === -1) {
    return undefined
  }
  return index
}

const getQuizIndex = (quizId: QuizId) => (state: State) => {
  const { quizOrder } = state.survey

  if (!quizId) {
    return -1
  }

  for (let i = 0; i < quizOrder.length; i++) {
    if (quizOrder[i] === quizId) {
      return i
    }
  }
  return -1
}

type GetQuestionResult =
  | { status: "no_quiz_fetched" }
  | { status: "quiz_undefined" }
  | { status: "question_undefined" }
  | { status: "survey_accomplished" }
  | { status: "next_question"; quizId: QuizId; questionId: QuestionId }
  | { status: "prev_question"; quizId: QuizId; questionId: QuestionId }
  | { status: "first_question_shown"; quizId: QuizId; questionId: QuestionId }
  | { status: "prev_quiz"; quizId: QuizId; questionId: QuestionId }
  | { status: "next_quiz"; quizId: QuizId }

export const nextQuestion =
  (quizId: QuizId | undefined, questionId: QuestionId | undefined) =>
  (state: State): GetQuestionResult => {
    /* FIXME simplify this */
    if (!quizId) {
      return {
        status: "quiz_undefined",
      }
    }

    const quiz = state.survey.quiz[quizId]

    if (!quiz) {
      return {
        status: "no_quiz_fetched",
      }
    }

    if (!questionId) {
      return {
        status: "question_undefined",
      }
    }

    const index = getQuestionIndex(quiz, questionId)

    if (typeof index === "number") {
      const question = quiz.questions_v2?.[index + 1]
      if (question && question.parameters) {
        return {
          quizId,
          questionId: question.parameters.id,
          status: "next_question",
        }
      }
    }

    if (quiz?.on_completion_action?.$case === "new_test_request") {
      return {
        quizId: quiz.on_completion_action.new_test_request.test_id,
        status: "next_quiz",
      }
    }

    return {
      status: "survey_accomplished",
    }
  }

export const prevQuestionSelector =
  (quizId: QuizId | undefined, questionId: QuestionId | undefined) =>
  (state: State): GetQuestionResult => {
    if (quizId) {
      const quiz = state.survey.quiz[quizId]

      if (quiz && questionId) {
        const questionIndex = getQuestionIndex(quiz, questionId)

        if (questionIndex && questionIndex > 0) {
          const question = quiz.questions_v2?.[questionIndex - 1]
          if (question && question.parameters) {
            return {
              quizId,
              questionId: question.parameters.id,
              status: "prev_question",
            }
          }
        }

        if (questionIndex === 0) {
          const quizIndex = getQuizIndex(quizId)(state)
          if (typeof quizIndex === "undefined") {
            return {
              status: "no_quiz_fetched",
            }
          }

          if (quizIndex === 0) {
            return {
              quizId,
              questionId,
              status: "first_question_shown",
            }
          }

          const quiz = quizByIndex(quizIndex - 1)(state)
          if (quiz) {
            const question = lastQuestion(quiz)
            if (question && question.parameters) {
              return {
                quizId: quiz.id,
                questionId: question.parameters.id,
                status: "prev_quiz",
              }
            }
          }
        }
      }
    }

    return {
      status: "no_quiz_fetched",
    }
  }

const lastQuestion = (quiz: QuizType): QuestionV2Type | undefined => {
  const questions = quiz.questions_v2 ?? []
  return questions[questions.length - 1]
}

const getQuizData = (quizId: QuizId) => (state: State) => {
  // TODO: might be refactored
  const idx = getQuizIndex(quizId)(state)
  if (typeof idx !== "undefined") {
    return quizByIndex(idx)(state)
  }
  return undefined
}

export const getPrefetchData =
  (quizId: QuizId) =>
  (state: State): string[] => {
    if (quizId) {
      return getQuizData(quizId)(state)?.prefetch_images ?? []
    }
    return []
  }

export const getQuestionData = (quizId: QuizId, questionId: QuestionId) => (state: State) => {
  const quiz = getQuizData(quizId)(state)

  if (!quiz || !quiz.questions_v2 || quiz.questions_v2.length === 0) {
    return undefined
  }

  for (let i = 0; i < quiz.questions_v2.length; i++) {
    if (questionId === quiz.questions_v2[i]?.parameters?.id) {
      return quiz.questions_v2[i]
    }
  }

  // The question wasn't found
  return undefined
}
export const getProgressBarTheme =
  (quizId: QuizId, questionId: QuestionId) =>
  (state: State): ProgressBarTheme => {
    const data = getQuestionData(quizId, questionId)(state)
    return (data?.parameters?.progress_bar_theme ?? "blue") as ProgressBarTheme
  }

export const getProgressIndicator = (quizId: QuizId, questionId: QuestionId) => (state: State) => {
  const data = getQuestionData(quizId, questionId)(state)
  return data?.parameters?.progress_indicator
}

export const getNextButtonLabel =
  (quizId: QuizId, questionId: QuestionId) =>
  (state: State): string => {
    const data = getQuestionData(quizId, questionId)(state)
    return data?.parameters?.button_text ?? getText("nextButtonLabel")
  }

const getQuestionIndexInQuiz = (quizId: QuizId, questionId: QuestionId) => (state: State) => {
  const quiz = getQuizData(quizId)(state)

  if (!quiz || !quiz.questions_v2 || quiz.questions_v2.length === 0) {
    return -1
  }

  let i = 0
  for (; i < quiz.questions_v2.length; i++) {
    if (questionId === quiz.questions_v2[i]?.parameters?.id) {
      return i
    }
  }

  // The question wasn't found
  return -1
}

export const getQuestionNumberInSurvey =
  (quizId: QuizId | undefined, questionId: QuestionId | undefined) => (state: State) => {
    if (!quizId || !questionId) {
      return 0
    }

    const data = getQuestionData(quizId, questionId)(state)

    if (!data) {
      return 0
    }

    const { page_num: pageNumber = 0 } = data.parameters ?? {}

    return pageNumber
  }

const questionsInQuiz = (quizId: QuizId) => (state: State) => {
  const quizData = state.survey.quiz[quizId]

  if (!quizData || !quizData.questions_v2) {
    return -1
  }

  return quizData.questions_v2.length
}

export const isLastQuestionInQuiz =
  (quizId: QuizId | undefined, questionId: QuestionId | undefined) => (state: State) => {
    if (!quizId || !questionId) {
      return false
    }

    const quizData = state?.survey?.quiz[quizId]

    if (!quizData) {
      return false
    }

    const index = getQuestionIndex(quizData, questionId)
    const len = quizData.questions_v2?.length ?? -1
    return index === len - 1
  }

export const isLastQuestionInSurvey =
  (quizId: QuizId | undefined, questionId: QuestionId | undefined) => (state: State) => {
    if (!quizId || !questionId) {
      return undefined
    }
    const total = questionsInQuiz(quizId)(state)
    const index = getQuestionIndexInQuiz(quizId, questionId)(state)
    if (index === -1 || total === -1) {
      return false
    }

    return index === total - 1
  }

export const getCompleteAction =
  (quizId: QuizId | undefined) =>
  (state: State): QuizType["on_completion_action"] | undefined => {
    if (!quizId) {
      return undefined
    }

    const { on_completion_action } = getQuizData(quizId)(state) ?? {}
    return on_completion_action
  }

export const currentAnswers = (quizId: QuizId | undefined) => (state: State) => {
  if (!quizId) {
    return {}
  }
  return state.survey.answers[quizId] ?? {}
}

export const selectIsQuizLoading = (state: State) => {
  return state.survey.loading
}

export const selectUserIdUpdated = (state: State) => {
  return state.survey.userIdUpdated
}
