import { mergeDeepRight } from "rambda"
import kebabCase from "@stdlib/string-base-kebabcase"
import type { QuizId, URLString } from "./types"

type ConfigConstants = {
  startQuiz: QuizId
  quizUrlPrefix: string
}
type ConfigApi = {
  get_quiz: URLString
  get_user_id: URLString
  save_answers: URLString
  face_scan_analysis: URLString
  face_scan_report: URLString
  update_user_id: URLString
  log: URLString
}
type ConfigAuth = {
  domain: string
  clientId: string
}
type Config = {
  instance: "INTERVIEW" | "SUBSCRIPTION" | "INTERVIEW2"
  theme: Theme
  config_url: URLString
  redirect_url: URLString
  endpoints: ConfigApi
  constants: ConfigConstants
  auth: ConfigAuth
  style: Record<string, string>
  text: Record<"nextButtonLabel" | "lastNextButtonLabel", string>
  sentry: {
    dsn: string | undefined
  }
  environment: "production" | "testing" | "development"
}

const getInstance = (domain: string): Config["instance"] => {
  switch (true) {
    case domain.includes("subscription."):
      return "SUBSCRIPTION"
    case domain.includes("interview2."):
      return "INTERVIEW2"
    default:
      return "INTERVIEW"
  }
}

const defaultConfig: Config = {
  instance: import.meta.env["REACT_APP_INSTANCE"] ?? getInstance(window.location.host),
  theme: import.meta.env["REACT_APP_THEME"] === "lovi" ? "lovi" : "pora",
  config_url: (import.meta.env["REACT_APP_CONFIG_URL"] ||
    "https://api.pora.ai/interview/web_config") as URLString,
  redirect_url: "https://pay.lovi.care/" as URLString,
  endpoints: {
    get_user_id: "https://api.pora.ai/interview/get_user_id" as URLString,
    get_quiz: "https://api.pora.ai/interview/quiz" as URLString,
    save_answers: "https://api.pora.ai/interview/save" as URLString,
    face_scan_analysis: "https://api.pora.ai/visual_analysis/scan" as URLString,
    face_scan_report: "https://api.pora.ai/scan_report" as URLString,
    update_user_id: "https://api.pora.ai/interview/update_user_id" as URLString,
    log: "https://api.pora.ai/interview/web_config" as URLString,
  },
  text: {
    nextButtonLabel: "Next",
    lastNextButtonLabel: "Pay and Use",
  },
  style: {
    /* see variables.scss */
    border_radius_button: "30px",
    border_radius_progress_bar: "6px",

    color_border_secondary: "#BCD1EB",
    color_background_secondary: "#fff",

    color_icon_primary: "#D4F5FD",
    color_text_primary: "#0C2330",
    color_text_inverted: "#fff",
    color_text_secondary: "#73879D",

    color_action_primary: "#00C8F5",
    color_action_primary_hover: "#00AFD6",
    color_action_secondary: "#fff",
    color_action_secondary_pressed: "#E0F9FF",
    color_action_secondary_disabled: "#E1E8F0",

    color_button_background_disabled: "#F0F4F5",
    color_input_border: "#D7DFE0",
  },
  constants: {
    startQuiz: "quick" as QuizId,
    quizUrlPrefix: "quiz",
  },
  auth: {
    domain: import.meta.env["REACT_APP_AUTH0_DOMAIN"] || "auth.pora.ai",
    clientId: import.meta.env["REACT_APP_AUTH0_CLIENT_ID"] || "6IxlFiYT8fgZI4CT2tuPGkijkSuIaJ1d",
  },
  sentry: {
    dsn: import.meta.env["REACT_APP_SENTRY_DSN"],
  },
  environment:
    (import.meta.env["REACT_APP_ENVIRONMENT"] as "development") ??
    (window.location.href.toString().includes("test") ? "testing" : "production"),
}
let config: Config = defaultConfig

export const isSubscriptionInstance = () => config.instance === "SUBSCRIPTION"

export const setupConfig = (configReceived: Config): void => {
  config = mergeDeepRight(defaultConfig, configReceived)
  setCSS(config.style)
}

function setCSS(style: Config["style"] = {}): void {
  Object.keys(style).forEach((key) => {
    // console.log(`--${key}`, style[key]);
    document.documentElement.style.setProperty(`--${kebabCase(key)}`, `${style[key]}`)
  })
}

export function getConfig() {
  return config
}

export function getText(key: keyof (typeof config)["text"]) {
  return config.text[key]
}
