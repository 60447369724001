import React from "react"
import { createIcon } from "@chakra-ui/react"

export const MagicIcon = createIcon({
  viewBox: "0 0 64 65",
  defaultProps: {
    width: "64",
    height: "65",
    fill: "none",
  },

  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.1472 0.504443C46.3155 0.707806 57.5507 9.38486 61.8158 21.851C66.3116 34.9913 64.1079 50.1773 52.9856 58.4885C41.4509 67.1079 25.551 66.1356 13.9283 57.6353C2.41285 49.2135 -2.93122 34.4468 1.61874 20.9213C6.03356 7.7976 19.3083 0.290725 33.1472 0.504443Z"
        fill="#EC79A2"
      />
      <path
        d="M16.6514 39.5867C17.7099 37.5883 14.7122 36.0005 13.6537 37.9989C12.5951 39.9974 15.5929 41.5852 16.6514 39.5867Z"
        fill="white"
      />
      <path
        d="M25.0521 36.4023C26.3312 27.3489 29.3018 25.6978 34.5511 25.364L34.5008 25.3638C30.2201 25.347 26.5258 25.3325 26.6829 15.7705C25.7199 19.9379 26.1106 24.8728 17.0604 25.3047C25.1043 27.1475 23.8944 28.236 25.0521 36.4023Z"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.0578 22.2808C46.5042 21.4663 45.2824 19.2967 43.836 20.1112C42.3895 20.9258 43.6114 23.0954 45.0578 22.2808Z"
        fill="white"
      />
      <path
        d="M41.8687 40.7009C40.1237 36.2385 41.9322 35.5173 44.2344 33.8472C41.664 34.5369 39.259 35.4071 36.8635 31.0224C37.4965 33.1848 38.5502 35.9944 34.5442 38.8087C38.6771 37.3138 39.2431 37.3447 41.8687 40.7009Z"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.736 42.2673C51.4786 41.2859 50.0066 38.6719 48.2639 39.6533C46.5212 40.6347 47.9933 43.2487 49.736 42.2673Z"
        fill="white"
      />
      <path
        d="M35.6033 50.7329C36.7651 50.0786 35.7837 48.336 34.6219 48.9902C33.4601 49.6445 34.4415 51.3871 35.6033 50.7329Z"
        fill="white"
      />
    </>
  ),
})
export const QuestionMarkIcon = createIcon({
  viewBox: "0 0 24 24",
  defaultProps: {
    width: "24",
    height: "24",
    fill: "none",
  },
  path: (
    <path
      d="M11.5981 15.4489C11.0321 15.4489 10.745 15.0798 10.745 14.5548V14.038C10.745 12.5942 11.6884 11.6345 12.9763 10.7403C14.0263 10.0021 14.7563 9.3458 14.7563 8.17275C14.7563 6.74541 13.5013 5.84307 11.9017 5.84307C10.581 5.84307 9.54736 6.43369 9.129 7.99229C9.00596 8.39424 8.72705 8.64033 8.30869 8.64033C7.8083 8.64033 7.5376 8.31221 7.5376 7.84463C7.5376 7.53291 7.61143 7.15557 7.78369 6.77002C8.48096 5.18682 10.1052 4.30908 11.9591 4.30908C14.4528 4.30908 16.4544 5.72002 16.4544 8.18096C16.4544 9.76416 15.5603 10.8634 14.1493 11.8149C12.8368 12.7173 12.4513 13.1685 12.4349 14.2513V14.5302C12.4349 15.0552 12.1724 15.4489 11.5981 15.4489ZM11.5981 19.936C10.9583 19.936 10.4333 19.411 10.4333 18.7712C10.4333 18.1231 10.9583 17.6063 11.5981 17.6063C12.2544 17.6063 12.7712 18.1231 12.7712 18.7712C12.7712 19.411 12.2544 19.936 11.5981 19.936Z"
      fill="currentColor"
    />
  ),
})
