import { createIcon } from "@chakra-ui/react"
import React from "react"

export const LogoIcon = createIcon({
  viewBox: "0 0 62 32",
  defaultProps: {
    width: "62px",
    height: "32px",
    fill: "none",
  },
  path: (
    <path
      d="M32.708 0c-1.718 0-3.124 1.406-3.124 3.163a3.133 3.133 0 003.124 3.124c1.757 0 3.163-1.406 3.163-3.124A3.15 3.15 0 0032.708 0zM18.2 27.205a16.452 16.452 0 01-5.607 3.404c-3.002 1.086-5.687 1.014-7.829.078-2.129-.93-3.588-2.664-4.176-4.62-.587-1.957-.302-4.159 1.083-5.903 1.39-1.752 3.695-2.807 6.703-2.907 3.515-.117 7.045 1.218 10.26 3.287.037-.09.075-.181.111-.274 1.51-3.82 1.66-9.608-1.841-18.116L20.166.812c3.716 9.029 3.816 15.809 1.86 20.755-.826 2.091-2.188 4.11-3.826 5.638zm-1.4-3.645c-1.542 1.848-3.504 3.042-5.408 3.731-2.314.838-4.047.674-5.215.163-1.18-.515-1.92-1.439-2.21-2.402-.29-.964-.13-1.943.467-2.695.59-.743 1.802-1.499 4.058-1.574 2.63-.087 5.504.956 8.308 2.777zm3.773 2.95c.119.108.237.219.355.33 2.64 2.496 6.474 4.7 10.799 5.097 4.414.405 9.147-1.097 13.367-5.685l-2.597-2.389c-3.51 3.818-7.18 4.86-10.448 4.56-3.358-.307-6.486-2.055-8.697-4.146a36.55 36.55 0 00-.784-.72c-.45.845-1.238 2.073-1.995 2.953zm4.635-9.487c0 4.233 3.111 7.858 7.858 7.858 4.811 0 7.922-3.625 7.922-7.858 0-4.234-3.11-7.858-7.922-7.858-4.747 0-7.858 3.624-7.858 7.858zm12.284 0c0 2.566-1.732 4.747-4.426 4.747-2.63 0-4.362-2.181-4.362-4.747s1.732-4.747 4.362-4.747c2.694 0 4.426 2.18 4.426 4.747zm3.025-7.473h3.753l4.105 9.942 4.17-9.942h3.752L49.53 24.496h-2.342L40.518 9.55zm17.661 0h3.496v14.946h-3.496V9.55z"
      fill="#000"
      fillRule="evenodd"
    />
  ),
})

export const ArrowIcon = createIcon({
  viewBox: "0 0 16 16",
  defaultProps: {
    height: "16px",
    width: "16px",
    fill: "none",
  },
  path: (
    <path
      d="M7 3L11.6429 7.28571C11.1877 8.71429 8.36555 11.8571 7.27311 13"
      stroke="#ABBBCC"
      strokeWidth="2"
      strokeLinecap="round"
    />
  ),
})
