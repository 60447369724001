import type { QuestionId, QuizId } from "../types"
import { getInterviewId } from "~/api/index"

export const getSaveBody = (
  userID: UserId,
  quizId: QuizId,
  answers: Record<QuestionId, string[]> = {},
  nextQuizId?: QuizId
) => {
  const quizAnswers = Object.keys(answers)
    .map((_question) => {
      const question = _question as QuestionId
      if (!answers[question]) {
        return null
      }

      return {
        question_id: question,
        values: answers[question]?.filter((answer) => Boolean(answer)),
      }
    })
    .filter(Boolean)

  return {
    request: {
      userID,
    },
    test_id: quizId,
    next_quiz_id: nextQuizId,
    answers: quizAnswers,
    interview_id: getInterviewId(),
  }
}
