import React from "react"
import { Flex } from "@chakra-ui/react"
import { FlexProps } from "@chakra-ui/layout"

export const BasePageWrapper = (props: FlexProps) => {
  return (
    <Flex
      w="full"
      //minH="100vh" TODO check this
      flexDirection="column"
      justifyContent="flex-start"
      paddingTop="64px"
      paddingX={6}
      paddingBottom="88px"
      {...props}
    />
  )
}
