import { QuestionId } from "../types"

export const mergeAnswers = (
  existing: Record<QuestionId, string[]> = {},
  question: QuestionId,
  newAnswer: string[]
): Record<QuestionId, string[]> => {
  return {
    ...existing,
    [question]: newAnswer,
  }
}
