import React, { useEffect, FC } from "react"

import { EmailInputPage } from "../EmailInputPage/EmailInputPage"
import { MessageListPage } from "../MessageListPage"
import { MessagesLoaderPage } from "../MessagesLoaderPage/MessagesLoaderPage"
import { Page404 } from "../404Page/404Page"
import { QuestionPageMultiSelect } from "../QuestionPageMultiSelect/QuestionPageMultiSelect"
import { QuestionPageSingleSelect } from "../QuestionPageSingleSelect/QuestionPageSingleSelect"
import { QuotePage } from "../QuotePage/QuotePage"
import { SplashPage } from "../SplashPage/SplashPage"
import { StartPage } from "../StartPage/StartPage"
import { TitlesLoaderPage } from "../TitlesLoaderPage/TitlesLoaderPage"
import { SummaryPage } from "~/components/pages/SummaryPage/SummaryPage"
import { StaticPage } from "~/components/pages/StaticPage"
import { SlideLoaderPage } from "../SlideLoaderPage"

import { SentryRoute } from "../../shared/sentry"
import { SlidingSwitch } from "../../../libs/sliding-routes"

import { useAmplitude } from "../../../hooks/analytics/useAmplitude"
import { useQuizHistory } from "../../../hooks/useQuizHistory"
import { useNextPage } from "../../../hooks/useNextQuiz"
import { getConfig } from "../../../config"
import { useSelector } from "../../../hooks/redux"
import { getPrefetchData, getQuestionData } from "../../../store/survey/selectors"
import { riveWASMResource } from "~/components/shared/RiveImage"

import { Navigation } from "./Navigation"
import { MessageWithBackgroundPage } from "../MessageWithBackgroundPage/MessageWithBackgroundPage"
import { toAttributedString } from "../../shared/AttributedString"
import { ProgressBarsPage, ProgressBarsWithReviewsPage } from "~/components/pages/ProgressBarsPage"
import { ReviewPage } from "~/components/pages/ReviewPage/ReviewPage"
import { PrePaywallMessagePage } from "~/components/pages/PrePaywallMessagePage/PrePaywallMessagePage"
import { NumRangeInputPage } from "~/components/pages/NumRangeInputPage/NumRangeInputPage"
import { GraphPageGT } from "~/components/pages/GraphPageGT/GraphPageGT"
import { WelcomeLoadingPage } from "~/components/pages/WelcomeLoadingPage/WelcomeLoadingPage"
import { RangeInputPage } from "~/components/pages/RangeInputPage/RangeInputPage"
import { ProductFitPage } from "~/components/pages/ProductFitPage/ProductFitPage"
import { ProductFitResultPage } from "~/components/pages/ProductFitResultPage/ProductFitResultPage"
import { QuestionV2Type } from "~/api/QuestionType"
import { FaceareaSelectPage } from "~/components/pages/FaceareaSelectPage"
import { WelcomeSelectPage } from "~/components/pages/WelcomeSelectPage"
import { WelcomeSelect2Page } from "~/components/pages/WelcomeSelect2Page"
import { ProductFit2Page } from "~/components/pages/ProductFit2Page/ProductFit2Page"
import { WelcomeSliderPage } from "~/components/pages/WelcomeSliderPage/WelcomeSliderPage"
import { ProductFit3Page } from "~/components/pages/ProductFit3Page/ProductFit3Page"
import { WellnessProfilePage } from "~/components/pages/WellnessProfilePage"
import { SubscriptionStatusPage } from "~/components/pages/SubscriptionStatusPage/SubscriptionStatusPage"
import { MessageWithAnswersPage } from "~/components/pages/MessageWithAnswersPage/MessageWithAnswersPage"
import { FeedbackPage } from "~/components/pages/FeedbackPage/FeedbackPage"
import { SubOfferPage } from "~/components/pages/SubOfferPage/SubOfferPage"
import { IfYouCancelPage } from "~/components/pages/IfYouCancelPage/IfYouCancelPage"
import { MessageWithImagePage } from "../MessageWithImagePage"
import { MessageWithImageAndProgressbarPage } from "../MessageWithImageAndProgressbarPage"
import { FaceScannerInitPage, FaceScannerResultPage } from "../FaceScannerPage"
import { ProgressGraphPage } from "~/components/pages/ProgressGraphPage/ProgressGraphPage"
import { ProgressWeeksPage } from "~/components/pages/ProgressWeeksPage/ProgressWeeksPage"
import { ProgressBarsWithQuestionsPage } from "~/components/pages/ProgressBarsPage/ProgressBarsWithQuestionsPage"
import { PreloadedStatic } from "~/static_images_to_preload"
import { WelcomeLuvlyPage } from "~/components/pages/WelcomeLuvlyPage"
import { WelcomeSelect3Page } from "~/components/pages/WelcomeSelect3Page"
import { ProductFitResult2Page } from "../ProductFitResult2Page"
import { AboutProgram } from "~/components/pages/AboutProgram/AboutProgram"
import { WelcomeSelect4Page } from "~/components/pages/WelcomeSelect4Page"
import { DateInputPage } from "../DateInputPage"
import { logError } from "~/hooks/useCaptureException"
import { WelcomeSelect5Page } from "~/components/pages/WelcomeSelect5Page"

const PageChooser: FC = () => {
  const next = useNextPage()
  const {
    params: { quiz: quizId, question: questionId },
  } = useQuizHistory()
  const questionData = useSelector(getQuestionData(quizId!, questionId!))

  const { logQuizView } = useAmplitude()

  useEffect(() => {
    logQuizView()
  }, [quizId, questionId, logQuizView])

  if (questionData) {
    const { question_screen: questionScreen, parameters } = questionData

    if (!questionScreen) {
      return <Page404 question={questionData} next={next} />
    }

    if (questionScreen.$case === "if_you_cancel_screen") {
      const { title, image, variants } = questionScreen.if_you_cancel_screen
      return <IfYouCancelPage title={title} image={image!} variants={variants} next={next} />
    }

    if (questionScreen.$case === "sub_offer_screen") {
      const { title, description, primary_subtitle, rows, help_texts, variants } =
        questionScreen.sub_offer_screen
      return (
        <SubOfferPage
          title={title}
          description={description}
          primarySubtitle={primary_subtitle}
          rows={rows}
          helpTexts={help_texts}
          variants={variants}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "feedback_screen") {
      const { title, skip_button_text } = questionScreen.feedback_screen
      const buttonText = parameters?.button_text
      return (
        <FeedbackPage
          title={title}
          nextButtonText={buttonText}
          skipButtonText={skip_button_text}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "message_with_answers_screen") {
      const { variants, title, description, image } = questionScreen.message_with_answers_screen
      return (
        <MessageWithAnswersPage
          title={title}
          description={description}
          image={image!}
          variants={variants}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "product_fit_3_screen") {
      const { title, description, description2, product_images, view_variant } =
        questionScreen.product_fit_3_screen
      return (
        <ProductFit3Page
          title={title}
          description={description}
          description2={description2}
          productImages={product_images}
          variant={view_variant}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "product_fit_2_screen") {
      const { title, description, description2, product_images, view_variant } =
        questionScreen.product_fit_2_screen
      return (
        <ProductFit2Page
          title={title}
          description={description}
          description2={description2}
          productImages={product_images}
          variant={view_variant}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "welcome_select_screen") {
      const { title, image, description, variants, select_title } =
        questionScreen.welcome_select_screen
      return (
        <WelcomeSelectPage
          variants={variants}
          description={description}
          image={image}
          title={title}
          next={next}
          selectTitle={select_title}
        />
      )
    }

    if (questionScreen.$case === "welcome_select_2_screen") {
      const { title, image, description, variants, select_title } =
        questionScreen.welcome_select_2_screen
      return (
        <WelcomeSelect2Page
          variants={variants}
          description={description}
          image={image}
          title={title}
          next={next}
          selectTitle={select_title}
        />
      )
    }

    if (questionScreen.$case === "welcome_select_3_screen") {
      const { title, image, description, variants, select_title } =
        questionScreen.welcome_select_3_screen
      return (
        <WelcomeSelect3Page
          variants={variants}
          description={description}
          image={image}
          title={title}
          next={next}
          selectTitle={select_title}
        />
      )
    }

    if (questionScreen.$case === "welcome_select_4_screen") {
      const { title, image, description, variants, select_title } =
        questionScreen.welcome_select_4_screen
      return (
        <WelcomeSelect4Page
          variants={variants}
          description={description}
          image={image}
          title={title}
          next={next}
          selectTitle={select_title}
        />
      )
    }

    if (questionScreen.$case === "welcome_select_5_screen") {
      const { title, image, description, variants, select_title } =
        questionScreen.welcome_select_5_screen
      return (
        <WelcomeSelect5Page
          variants={variants}
          description={description}
          video={image}
          title={title}
          next={next}
          selectTitle={select_title}
        />
      )
    }

    if (questionScreen.$case === "welcome_select_luvly_screen") {
      const { title, image, description, variants, select_title } =
        questionScreen.welcome_select_luvly_screen
      return (
        <WelcomeLuvlyPage
          variants={variants}
          description={description}
          image={image}
          title={title}
          next={next}
          selectTitle={select_title}
        />
      )
    }

    if (questionScreen.$case === "discrete_slider_screen") {
      const { up_title: upTitle, title, answers } = questionScreen.discrete_slider_screen
      return (
        <RangeInputPage
          upTitle={upTitle}
          title={title}
          leftText={answers[0] || "Answer will be here very soon..."}
          rightText={answers[1] || "Answer will be here very soon..."}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "welcome_loading_screen") {
      const { title, image, description } = questionScreen.welcome_loading_screen
      return (
        <WelcomeLoadingPage description={description} image={image} title={title} next={next} />
      )
    }

    if (questionScreen.$case === "welcome_slider_screen") {
      const { slides, skippable } = questionScreen.welcome_slider_screen

      return <WelcomeSliderPage skippable={skippable} slides={slides} next={next} />
    }

    if (questionScreen.$case === "graph_page_gt_screen") {
      const { description, image } = questionScreen.graph_page_gt_screen
      return <GraphPageGT weeksNumber={6} image={image} description={description} next={next} />
    }

    if (questionScreen.$case === "graph_page_gt_new_date_screen") {
      const { description, image } = questionScreen.graph_page_gt_new_date_screen
      return (
        <GraphPageGT
          prevWeeksNumber={6}
          weeksNumber={4}
          image={image}
          description={description}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "pre_paywall_message_screen") {
      const { title, image } = questionScreen.pre_paywall_message_screen
      return <PrePaywallMessagePage image={image} title={title} next={next} />
    }

    if (questionScreen.$case === "num_range_input_screen") {
      const { title, up_title: upTitle, answers } = questionScreen.num_range_input_screen
      const options = answers || ["1", "2", "3", "4", "5"]
      const leftText = "Strongly disagree"
      const rightText = "Strongly agree"
      return (
        <NumRangeInputPage
          options={options}
          leftText={leftText}
          rightText={rightText}
          upTitle={upTitle}
          title={title}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "review_screen") {
      const { title, image, author, date, text } = questionScreen.review_screen
      const { view_variant = "" } = parameters ?? {}
      return (
        <ReviewPage
          author={author}
          date={date}
          text={text}
          image={image}
          title={title}
          next={next}
          variant={view_variant === "reviewScreen.appstore" ? "appstore" : "trustpilot"}
        />
      )
    }

    if (questionScreen.$case === "progress_graph_screen") {
      const { image } = questionScreen.progress_graph_screen
      return <ProgressGraphPage image={image} next={next} />
    }

    if (questionScreen.$case === "progress_weeks_screen") {
      const { image, title, view_variant, main_issue } = questionScreen.progress_weeks_screen
      return (
        <ProgressWeeksPage
          mainIssue={main_issue}
          viewVariant={view_variant}
          title={title}
          image={image}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "single_variant_screen") {
      const {
        image,
        variants: variantsTitles,
        attributed_title,
        title,
        description,
        layout,
        variant_properties,
        up_title: upTitle,
      } = questionScreen.single_variant_screen
      const { view_variant } = parameters ?? {}

      return (
        <QuestionPageSingleSelect
          layout={layout}
          title={attributed_title ?? toAttributedString(title)}
          upTitle={upTitle}
          description={description}
          image={image}
          variantsTitles={variantsTitles}
          variantProperties={variant_properties}
          next={next}
          viewVariant={view_variant}
        />
      )
    }

    if (questionScreen.$case === "multiple_variants_screen") {
      const {
        image,
        variants: variantsTitles,
        title,
        attributed_title,
        description,
        layout,
        variant_properties = {},
      } = questionScreen.multiple_variants_screen

      return (
        <QuestionPageMultiSelect
          title={attributed_title ?? toAttributedString(title)}
          description={description}
          image={image}
          layout={layout}
          next={next}
          variantsTitles={variantsTitles}
          variantProperties={variant_properties}
        />
      )
    }

    if (questionScreen.$case === "welcome_main_screen") {
      const { variants /* attributed_title, */, title, description, image } =
        questionScreen.welcome_main_screen
      return (
        <StartPage
          variants={variants}
          title={/* attributed_title ??  */ toAttributedString(title)}
          description={description}
          parameters={parameters}
          image={image ?? ""}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "message_screen") {
      const {
        attributed_title,
        title,
        description,
        image,
        video,
        image_height = 1,
        image_width = 1,
      } = questionScreen.message_screen
      return (
        <SplashPage
          title={attributed_title ?? toAttributedString(title)}
          description={description}
          image={image}
          video={video}
          imageWidth={image_width}
          imageHeight={image_height}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "message_with_background_screen") {
      const {
        attributed_title,
        title,
        background_image: background,
        description,
        attributed_description,
      } = questionScreen.message_with_background_screen

      return (
        <MessageWithBackgroundPage
          title={attributed_title ?? toAttributedString(title)}
          description={attributed_description || description}
          background={background}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "card_messages_loader_screen") {
      const {
        loader_options: { DisplaySecondsPerScreen: delay = 1 } = {},
        messages,
        process_title,
      } = questionScreen.card_messages_loader_screen
      return (
        <MessagesLoaderPage
          delay={delay}
          subtitle={process_title}
          messages={messages}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "titles_loader_screen") {
      const {
        loader_options: { DisplaySecondsPerScreen: delay = 1 } = {},
        messages,
        final_message,
      } = questionScreen.titles_loader_screen
      return <TitlesLoaderPage delay={delay} messages={[...messages, final_message]} next={next} />
    }

    if (questionScreen.$case === "email_screen") {
      const data = questionScreen.email_screen
      return <EmailInputPage {...data} next={next} />
    }

    if (questionScreen.$case === "quote_screen") {
      const { attributed_title, title, description, image, author } = questionScreen.quote_screen
      return (
        <QuotePage
          author={author}
          title={description}
          message={attributed_title ?? toAttributedString(title)}
          background={image}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "progress_bars_screen") {
      const { attributed_title, title, progress_bars } = questionScreen.progress_bars_screen
      return (
        <ProgressBarsPage
          title={attributed_title ?? toAttributedString(title)}
          progressBars={progress_bars}
          description={undefined}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "progress_bars_with_reviews_screen") {
      const { attributed_title, title, progress_bars } =
        questionScreen.progress_bars_with_reviews_screen
      return (
        <ProgressBarsWithReviewsPage
          title={attributed_title ?? toAttributedString(title)}
          progressBars={progress_bars}
          description={undefined}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "progress_bars_with_questions_screen") {
      const { attributed_title, title, progress_bars } =
        questionScreen.progress_bars_with_questions_screen
      return (
        <ProgressBarsWithQuestionsPage
          title={attributed_title ?? toAttributedString(title)}
          progressBars={progress_bars}
          description={undefined}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "message_list_screen") {
      const { title, items } = questionScreen.message_list_screen
      return <MessageListPage title={title} items={items} next={next} />
    }

    if (questionScreen.$case === "pre_paywall_summary_screen") {
      const { title, graph_title, summary_list, graph_img } =
        questionScreen.pre_paywall_summary_screen
      return (
        <SummaryPage
          summaryList={summary_list}
          header={title}
          graphImg={graph_img}
          graphHeader={graph_title}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "slide_loader_screen") {
      const { process_title: processTitle, slides = [] } = questionScreen.slide_loader_screen
      return <SlideLoaderPage processTitle={processTitle} slides={slides} next={next} />
    }

    if (questionScreen.$case === "product_fit_result_screen") {
      const { fit_products, not_fit_products, title } = questionScreen.product_fit_result_screen
      return (
        <ProductFitResultPage
          title={title}
          fit_products={fit_products}
          not_fit_products={not_fit_products}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "product_fit_result_2_screen") {
      const { fit_products: { products_list } = { products_list: [] }, title } =
        questionScreen.product_fit_result_2_screen
      return <ProductFitResult2Page title={title} products={products_list} next={next} />
    }

    if (questionScreen.$case === "product_fit_screen") {
      const { product_images, view_variant, title } = questionScreen.product_fit_screen
      return (
        <ProductFitPage
          variant={view_variant}
          productImages={product_images}
          title={title}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "face_area_select_screen") {
      const { title, description, variant } = questionScreen.face_area_select_screen
      return (
        <FaceareaSelectPage title={title} description={description} variant={variant} next={next} />
      )
    }

    if (questionScreen.$case === "wellness_profile_screen") {
      const { level, properties, age_image } = questionScreen.wellness_profile_screen
      return (
        <WellnessProfilePage
          level={level}
          properties={properties}
          ageTitle={age_image?.title ?? ""}
          ageImage={age_image?.image}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "static_about_pora_program_screen") {
      const { title } = questionScreen.static_about_pora_program_screen
      return <StaticPage title={title} variant="static_about_pora_program_screen" next={next} />
    }

    if (questionScreen.$case === "static_cosmetologist_vs_pora_screen") {
      const { title } = questionScreen.static_cosmetologist_vs_pora_screen
      return <StaticPage title={title} variant="static_cosmetologist_vs_pora_screen" next={next} />
    }

    if (questionScreen.$case === "static_self_care_vs_pora_screen") {
      const { title } = questionScreen.static_self_care_vs_pora_screen
      return <StaticPage title={title} variant="static_self_care_vs_pora_screen" next={next} />
    }

    if (questionScreen.$case === "static_esthetician_vs_lovi") {
      const { title } = questionScreen.static_esthetician_vs_lovi
      return <StaticPage title={title} variant="static_esthetician_vs_lovi" next={next} />
    }

    if (questionScreen.$case === "static_what_is_face_yoga") {
      const { title } = questionScreen.static_what_is_face_yoga
      return <StaticPage title={title} variant="static_what_is_face_yoga" next={next} />
    }

    if (questionScreen.$case === "static_trust_doctors") {
      const { title } = questionScreen.static_trust_doctors
      return <StaticPage title={title} variant="static_trust_doctors" next={next} />
    }

    if (questionScreen.$case === "static_science_research") {
      const { title } = questionScreen.static_science_research
      return <StaticPage title={title} variant="static_science_research" next={next} />
    }

    if (questionScreen.$case === "static_about_program_2") {
      const { title } = questionScreen.static_about_program_2
      return <StaticPage title={title} variant="static_about_program_2" next={next} />
    }

    if (questionScreen.$case === "static_cosmetologist_vs_lovi_screen") {
      const { title } = questionScreen.static_cosmetologist_vs_lovi_screen
      return <StaticPage title={title} variant="static_cosmetologist_vs_lovi_screen" next={next} />
    }

    if (questionScreen.$case === "message_with_image_screen") {
      const { description, view_variant } = parameters ?? {}
      const { title, image } = questionScreen.message_with_image_screen
      return (
        <MessageWithImagePage
          variant={view_variant === "messageWithImage.firstText" ? "firstText" : "firstImage"}
          title={title}
          image={image}
          description={description}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "message_with_image_and_progressbar_screen") {
      const { title, image, description } = questionScreen.message_with_image_and_progressbar_screen
      return (
        <MessageWithImageAndProgressbarPage
          title={title}
          image={image}
          description={description}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "subscription_status_screen") {
      const { title, description, subscription_status } = questionScreen.subscription_status_screen
      return (
        <SubscriptionStatusPage
          title={title}
          description={description}
          subscriptionStatus={subscription_status}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "scan_screen") {
      const { title, description, image, variants } = questionScreen.scan_screen
      return (
        <FaceScannerInitPage
          title={title}
          description={description}
          image={image}
          variants={variants}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "scan_2_screen") {
      const { title, description, image, variants } = questionScreen.scan_2_screen
      return (
        <FaceScannerInitPage
          enablePhotoConsent={true}
          title={title}
          description={description}
          image={image}
          variants={variants}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "scan_results_screen") {
      const { issues } = questionScreen.scan_results_screen
      const { title, description } = parameters ?? {}

      return (
        <FaceScannerResultPage
          title={title}
          description={description}
          issues={issues}
          next={next}
        />
      )
    }

    if (questionScreen.$case === "about_program_screen") {
      const data = questionScreen.about_program_screen

      return <AboutProgram {...data} next={next} />
    }

    if (questionScreen.$case === "date_screen") {
      const { title, description, view_variant = "" } = parameters ?? {}
      const variant =
        (
          {
            "date.before": "BEFORE",
            "date.after": "AFTER",
          } as const
        )[view_variant] ?? "UNKNOWN"

      return <DateInputPage title={title} description={description} next={next} variant={variant} />
    }

    return <Page404 question={questionData} next={next} />
  }

  logError(`Unknown question ${quizId}/${questionId}`)
  return <div className="loader-pulsar" />
  /*
  switch (true) {
    case "scan_invitation":
      return ScanInvitationPage;
    case "scan_instructions":
      return ScanInstructionPage;
    case "scan":
      return <ScanPage question={question} next={next}/>;
  }
  */
}

const prefix = getConfig().constants.quizUrlPrefix

const Prefetch: FC = () => {
  const {
    params: { quiz: quizId },
  } = useQuizHistory()
  const images = useSelector(getPrefetchData(quizId!))

  function getLink(link: string) {
    if (link.includes(".riv")) {
      return <link key={link} href={link} rel="preload" as="fetch" crossOrigin="anonymous" />
    }

    return <link key={link} href={link} rel="preload" as="image" />
  }

  return (
    <>
      <link rel="preload" href={riveWASMResource} as="fetch" crossOrigin="anonymous" />
      {Object.values(PreloadedStatic).map(getLink)}
      {images.map(getLink)}
    </>
  )
}

export const QuizPages: FC<{ pages: { path: string; question: QuestionV2Type }[] }> = ({
  pages,
}) => {
  return (
    <>
      <Navigation />
      <SlidingSwitch pages={pages}>
        <SentryRoute path={`/${prefix}/:quizId/:questionId/`}>
          <PageChooser />
        </SentryRoute>
      </SlidingSwitch>
      <Prefetch />
    </>
  )
}
