import { URLString } from "~/types"

export const fileToBase64 = (file: File): Promise<Base64String | undefined> =>
  new Promise<Base64String | undefined>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onerror = reject
    reader.onload = () => {
      let encoded = reader.result?.toString().replace(/^data:(.*,)?/, "")
      if (encoded && encoded.length % 4 > 0) {
        encoded += "=".repeat(4 - (encoded.length % 4))
      }
      return resolve(encoded as Base64String)
    }
  })

export const base64ToBlob = (base64: string, contentType = "") => {
  const byteCharacters = atob(base64)
  const byteArrays = []

  for (let i = 0; i < byteCharacters.length; i++) {
    byteArrays.push(byteCharacters.charCodeAt(i))
  }

  const byteArray = new Uint8Array(byteArrays)
  return new Blob([byteArray], { type: contentType })
}

export const base64ToUrl = (b64: Base64String): URLString =>
  (b64.startsWith("data:") ? b64 : `data:;base64,${b64}`) as URLString
