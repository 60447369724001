import React, { FC, ReactNode } from "react"
import {
  Box,
  BoxProps,
  Flex,
  Heading,
  Icon,
  List,
  ListItem,
  OrderedList,
  useDisclosure,
  SimpleGrid,
} from "@chakra-ui/react"
import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import {
  NextButton,
  NextButtonBottomSlideUpContainer as NextButtonContainer,
} from "~/components/shared/NextButton"
import lineBgSrc from "./assets/line.svg"
import arrowBgSrc from "./assets/arrow.svg"
import num1Src from "./assets/num-1.svg"
import num2Src from "./assets/num-2.svg"
import num3Src from "./assets/num-3.svg"
import { NextPageCb } from "~/hooks/useNextQuiz"
import { SkinIssues } from "~/generated/paywall"
import { FaceScanResultRenderContainer } from "./FaceScanResultRenderContainer"
import { IssuesListItem } from "./IssueListItem"
import { MagicIcon } from "./Icons"
import { Scale } from "~/components/shared/Scale/Scale"
import { IfTTExists, TTString, TT } from "~/components/shared/AttributedString"

const IssuesListContent: FC<{ issues: SkinIssues[] }> = ({ issues }) => {
  const { isOpen, onToggle } = useDisclosure()
  const headIssues = issues.slice(0, 2)
  const tailIssues = issues.slice(2)
  return (
    <>
      <Box textStyle="Subtitle/Secondary">You are here</Box>
      <List spacing={3}>
        {headIssues.map((issue) => (
          <IssuesListItem key={issue} issue={issue} />
        ))}
        {isOpen && tailIssues.map((issue) => <IssuesListItem key={issue} issue={issue} />)}
      </List>
      {tailIssues.length > 0 && (
        <Box
          textStyle="Subtitle/Tertiary"
          onClick={onToggle}
          cursor="pointer"
          color="Base/accentPrimary"
        >
          {isOpen ? "Show less" : `And ${tailIssues.length} more`}
        </Box>
      )}
    </>
  )
}

export const IssuesListWrapper: FC<BoxProps> = ({ children }) => (
  <Flex direction="row" gap={2} alignItems="stretch">
    <Flex direction="column" gap={3}>
      {children}
    </Flex>
    <Box
      display="flex"
      w="48px"
      bgRepeat="no-repeat"
      bgPosition="center left"
      bgSize="7px 610px"
      bgImage={`url(${lineBgSrc})`}
      justifyContent="right"
      alignItems="center"
    >
      <img src={arrowBgSrc} width="36" height="18" />
    </Box>
    <Flex direction="column" alignItems="center" alignSelf="center">
      <Icon as={MagicIcon} h="64px" w="64px" margin={0} />
      <Box mt={2} textStyle="Subtitle/Secondary" textAlign="center">
        Let&apos;s get dream skin
      </Box>
    </Flex>
  </Flex>
)

export const IssuesList: FC<{ issues: SkinIssues[] }> = ({ issues }) =>
  issues && issues.length > 0 ? (
    <IssuesListWrapper>
      <IssuesListContent issues={issues} />
    </IssuesListWrapper>
  ) : null

const SectionDivider: FC<BoxProps> = () => (
  <Flex direction="column" gap={2} bgColor="Base/neutralSecondary" w="full">
    <Box w="full" h={6} borderTopRadius={0} borderBottomRadius="3xl" bgColor="white" />
    <Box w="full" h={6} borderTopRadius="3xl" borderBottomRadius={0} bgColor="white" />
  </Flex>
)

const TextListItem: FC<{ icon: string; children: ReactNode }> = ({ icon, children }) => (
  <ListItem
    _before={{
      content: '""',
      backgroundImage: icon,
      backgroundSize: "contain",
      display: "inline-block",
      width: "20px",
      height: "20px",
      marginLeft: "-20px",
      position: "relative",
      top: "3px",
    }}
  >
    {children}
  </ListItem>
)

const TextList: FC = () => (
  <Box>
    <Heading size="Header/Secondary" mb={4}>
      Your program is
    </Heading>

    <OrderedList listStyleType="none" spacing={2} textStyle="Paragraph/Primary">
      <TextListItem icon={num1Src}>Based on your skin data</TextListItem>
      <TextListItem icon={num2Src}>Tailored for significant skin improvement</TextListItem>
      <TextListItem icon={num3Src}>
        Designed to provide long-lasting results through skincare guidance
      </TextListItem>
    </OrderedList>
  </Box>
)

export const FaceScannerResultPage: FC<{
  title: TTString
  description: TTString
  issues: SkinIssues[]
  next: NextPageCb
}> = ({ title, description, issues, next }) => (
  <BasePageWrapper minH="full" overflowY="auto" paddingX={0} bgColor="white">
    <Flex gap={6} direction="column" paddingX={6}>
      <Flex direction="column" gap={3}>
        <IfTTExists if={title}>
          <Heading as="h1" size="Header/Primary" whiteSpace="pre-line">
            <TT>{title}</TT>
          </Heading>
        </IfTTExists>
        <IfTTExists if={description}>
          <Box textStyle="Paragraph/Primary" whiteSpace="pre-line">
            <TT>{description}</TT>
          </Box>
        </IfTTExists>
      </Flex>
      <SimpleGrid templateColumns="1fr 1fr" columnGap={6} rowGap={2} alignItems="center">
        <FaceScanResultRenderContainer
          sx={{ aspectRatio: "0.8" }}
          gridRow="1/5"
          borderRadius="brand24"
        />
        <Scale title="Hydration" maxPercent={42} color="Other/Info" />
        <Scale title="Elasticity" maxPercent={60} color="Other/Green" />
        <Scale title="Complexion" maxPercent={87} color="Other/New" />
        <Scale title="Texture" maxPercent={51} color="Other/Warning" />
      </SimpleGrid>
    </Flex>

    <SectionDivider />

    <Box paddingX={6}>
      <IssuesList issues={issues} />
    </Box>

    <SectionDivider />

    <Flex direction="column" paddingX={6} mb={8}>
      <TextList />
    </Flex>

    <NextButtonContainer visible>
      <NextButton onClick={next} />
    </NextButtonContainer>
  </BasePageWrapper>
)
