import React, { FC } from "react"
import { Redirect } from "react-router-dom"
import { createPortal } from "react-dom"
import { Box, Divider, Heading, List, Link, ListItem } from "@chakra-ui/react"

import { SubscriptionStatus } from "~/generated/interview_service"
import { TT, TTString } from "~/components/shared/AttributedString"
import { NextPageCb } from "~/hooks/useNextQuiz"
import { ArrowIcon, LogoIcon } from "./Icons"

export const SubscriptionStatusPage: FC<{
  title: TTString
  description: TTString
  subscriptionStatus: SubscriptionStatus
  next: NextPageCb
}> = ({ next: onNext, subscriptionStatus, title, description }) => {
  if (
    !subscriptionStatus ||
    subscriptionStatus === SubscriptionStatus.UNKNOWN ||
    subscriptionStatus === SubscriptionStatus.UNRECOGNIZED
  ) {
    return <Redirect to="/error" push />
  }

  // If you edit this strings, you have to update airtable answers as well
  // because, depending on the answer corresponding screens will be shown
  const cancelAnswer = "Cancel subscription"
  const renewAnswer = "Renew the subscription"

  return (
    <Box h="full" bgColor="Base/neutralSecondary" display="flex" flexDirection="column">
      <Background />
      <Box bgColor="white" px={6} pb={6} borderBottomRadius="24px">
        <Box py={4} mb={2} textAlign="center">
          <LogoIcon />
        </Box>
        <Heading as="h1" size="Header/Primary">
          <TT>{title}</TT>
        </Heading>
        <Box mt={2} textStyle="Paragraph/Primary" textColor="Base/baseSecondary">
          <TT>{description}</TT>
        </Box>
      </Box>
      <Box bgColor="white" flex={1} mt={2} borderTopRadius="24px" px={6} py={2}>
        <List>
          <ListItem>
            <Link
              px={0}
              py={4}
              textStyle="Subtitle/Secondary"
              textColor="Base/basePrimary"
              href="mailto:care@lovi.care"
              display="flex"
              alignItems="center"
            >
              <Box flex={1}>Contact support</Box>
              <ArrowIcon ml={4} />
            </Link>
          </ListItem>
          {subscriptionStatus === SubscriptionStatus.ACTIVE && (
            <>
              <Divider my={2} />
              <ListItem
                px={0}
                py={4}
                textStyle="Subtitle/Secondary"
                display="flex"
                alignItems="center"
                cursor="pointer"
                onClick={() => onNext([cancelAnswer])}
              >
                <Box textColor="Other/Error" flex={1}>
                  {cancelAnswer}
                </Box>
                <ArrowIcon ml={4} />
              </ListItem>
            </>
          )}
          {false && subscriptionStatus === SubscriptionStatus.INACTIVE && (
            <>
              <Divider my={2} />
              <ListItem
                px={0}
                py={4}
                textStyle="Subtitle/Secondary"
                display="flex"
                alignItems="center"
                cursor="pointer"
                onClick={() => onNext([renewAnswer])}
              >
                <Box flex={1}>{renewAnswer}</Box>
                <ArrowIcon ml={4} />
              </ListItem>
            </>
          )}
        </List>
      </Box>
    </Box>
  )
}

function Background() {
  return (
    <>
      {createPortal(
        <Box
          pos="fixed"
          left={0}
          right={0}
          bottom={0}
          top={0}
          bgColor="Base/neutralSecondary"
          zIndex={-2}
        />,
        document.body
      )}
    </>
  )
}
